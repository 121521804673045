import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Input } from "../Components";
import { AutomaticQuestion } from "../Automatic Forms/AutomaticComponents";
import ReactTooltip from 'react-tooltip';
import { AiFillQuestionCircle } from "react-icons/ai";
import theme from '../Theme/theme'
import { Collapse } from "react-bootstrap";
import { processID } from "../Automatic Forms/Utils";
import { RadioGroup } from "../Components";
export const MultipleChoicePreview = ({ q, q_family }) => {
    let inputStyle = {}
    const {
        register,
        watch,
        formState: { errors }
    } = useFormContext();

    let options = []
    q['options'].forEach((option, i) => {
        options.push({ label: option, value: option })
    })
    // console.log('options', options)
    const instructions = q.instructions ? q.instructions : null
    // q.questionID = q.questionID + '-preview'
    const q_preview = JSON.parse(JSON.stringify(q))
    q_preview.questionID = q.questionID + '-preview'
    // console.log('q_preview', q_preview)

    const [parentChecked, setParentChecked] = useState(watch(q.questionID));
    const parentConditions = [];


    q_family.forEach(followup_q => {
        if (followup_q.parent_conditions) {
            if (Object.keys(followup_q.parent_conditions).length > 0) {
                // console.log('followup_q', followup_q)
                const parentConditionValue = followup_q.parent_conditions[followup_q.parent_questionID][1];
                // console.log('parentConditionValue', parentConditionValue)
                const previewID = `${q.questionID}-${processID(parentConditionValue)}`
                followup_q.parent_conditions[q.questionID] = [previewID, parentConditionValue]

                parentConditions.push(parentConditionValue);
            }
        }
    })
    // console.log('parentConditions', parentConditions)


    useEffect(() => {

        const subscription = watch((value, { name, type }) => {
            // console.log(name, value)
            if (name == q_preview.questionID)
                setParentChecked(watch(name))

        })
        return () => subscription.unsubscribe();
    }, [watch]);
    useEffect(() => {
        // console.log('parentChecked', parentChecked)
    }, [parentChecked]);

    return <>
        <RadioGroup
            style={inputStyle}
            className="col-sm-12 col-md-10 col-lg-10 mb-3"
            label={watch(`${q.questionID}-question`) ? watch(`${q.questionID}-question`) : q_preview['question_text']}
            name={q_preview['questionID']}
            options={options}
            instructions={instructions}
            q={q}
        />
        {
            Object.keys(q_family).length > 0 && parentConditions.includes(parentChecked) && <Collapse in={parentChecked}>
                <div className="row conditional-container">

                    {q_family.map(followup_q => {

                        // console.log('followup_q.parent_questionID', followup_q.parent_questionID, 'q.questionID', q.questionID)
                        if (followup_q.parent_questionID + '-preview' === q_preview.questionID) {

                            const independentQ = JSON.parse(JSON.stringify(followup_q));
                            independentQ.parent_questionID = ""; //independent now, no need for parents
                            independentQ.questionID = independentQ.questionID + '-preview';
                            let previewOptions = [];
                            independentQ.options.forEach(option => {
                                const watch_option = watch(`${followup_q.questionID}-${processID(option)}`)
                                if (watch_option) {
                                    previewOptions.push(watch_option);

                                }
                                else {
                                    if (option.includes('New Option')) {
                                        previewOptions.push('New Option');

                                    }
                                    else {
                                        previewOptions.push(option);

                                    }
                                }

                            })
                            independentQ.options = previewOptions
                            // console.log('independentQ.options', independentQ.options)
                            independentQ.question_text = watch(`${followup_q.questionID}`)

                            if (!independentQ.question_text) {
                                independentQ.question_text = watch(`${followup_q.questionID}-question`)

                                if (!independentQ.question_text) {
                                    independentQ.question_text = followup_q.question_text
                                }

                            }
                            if (independentQ.parent_conditions) {
                                if (independentQ.parent_conditions) {
                                    let previewParentConditions = {}
                                    if (Object.keys(independentQ.parent_conditions).length > 0) {
                                        const parent_conditions = independentQ.parent_conditions;
                                        const parentConditionValue = parent_conditions[q.questionID][1];
                                        previewParentConditions[q_preview.questionID] = [`${q_preview.questionID}-${processID(parentConditionValue)}`, parentConditionValue]
                                        // console.log('previewParentConditions', previewParentConditions, parent_conditions)
                                        independentQ.parent_conditions = previewParentConditions

                                        if (parentChecked == parentConditionValue) {
                                            return <AutomaticQuestion
                                                q={independentQ}

                                            />
                                        }

                                    }

                                }
                            }

                        }

                    })}

                </div>
            </Collapse>
        }

    </>


}