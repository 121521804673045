import React, { useState } from 'react';
import axios from 'axios';
export const ChatGPT = () => {
    const [input, setInput] = useState('');
    const [response, setResponse] = useState('');

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleSubmit = async () => {
        try {
            const result = await axios.post('https://api.openai.com/v1/chat/completions', {
                model: "gpt-3.5-turbo", // specify the model you want to use
                // model: "gpt-4", // specify the model you want to use

                messages: [{
                    role: "system",
                    content: "You are a helpful assistant."
                }, {
                    role: "user",
                    content: input
                }]
            }, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                    'Content-Type': 'application/json'
                }
            });

            setResponse(result.data.choices[0].message.content);
        } catch (error) {
            console.error("Error fetching ChatGPT data: ", error);
        }
    };

    return (
        <div>
            <input type="text" value={input} onChange={handleInputChange} />
            <button onClick={handleSubmit}>Submit</button>
            <p>Response from ChatGPT: {response}</p>
        </div>
    );
};

