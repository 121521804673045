import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import theme from "../Theme/theme";
import { Input, RadioGroup, Checkbox, Textarea, ConditionalPreview, FieldAnnotation } from "../Components";
// import { useForm, FormProvider } from "react-hook-form";
import { useFormContext } from "react-hook-form";

import * as Yup from "yup";
import Form from 'react-bootstrap/Form';
import { yupResolver } from "@hookform/resolvers/yup";
import { allQuestionTypes, countableQuestionTypes, makeid, processID } from "./Utils";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { AiFillQuestionCircle, AiFillCloseCircle, AiOutlineHolder, AiOutlineUpCircle, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { MultipleChoiceOption } from "./MultipleChoiceOption";
import { MultipleChoiceDnD } from "./MultipleChoiceDnD";
import { FileUploadInput } from "./FileUploadInput";
import { MatrixDnD } from "./MatrixDnD";
import Collapse from 'react-bootstrap/Collapse';
import { MatrixPreview } from "../Components/MatrixQuestion";
import { ConditionalDnD } from "./ConditionalDnD";
import { colorFamily, colorFamilyLight } from "../Fixed Sources/colorScales";
import { MultipleChoicePreview } from "../Components/MultipleChoicePreview";
import { CheckBoxPreview } from "../Components/CheckBoxPreview";
// fake data generator
const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        questionID: `item-${k}`,
        question_text: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, item, conditionalDict) => {
    let { parent_questionID, parent_conditions } = item;
    parent_questionID = parent_questionID ? parent_questionID : null;
    let borderColor = theme.highlightColor
    let backgroundColor = "#f5f7f5"


    // if (parent_questionID) {
    //     if (parent_conditions) {
    //         if (Object.keys(parent_conditions).length > 0) {
    //             // console.log('parentQuestionID', parentQuestionID)
    //             const parentConditionID = parent_conditions[parent_questionID][0];
    //             backgroundColor = conditionalDict[parentConditionID]
    //         }
    //     }
    //     // console.log('parent_questionID', parent_questionID)
    // }
    return {
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        backgroundColor: isDragging ? "white" : backgroundColor, //gray
        // border: `2px ${isDragging ? 'dashed' : 'solid'} ${theme.highlightColor}`,
        // border: `2px ${isDragging ? 'dashed' : 'outset'} ${borderColor}`,
        border: `3px ${isDragging ? `dashed ${borderColor}` : 'outset white'}`,

        borderRadius: '20px',
        // styles we need to apply on draggables
        ...draggableStyle
    }
};

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? theme.dragColor : "lightgrey",
    padding: grid,
    // width: 500,                                                                                                                                                                                                                                                                                                                  
    position: "relative",
    borderRadius: '10px',
});

const queryAttr = "data-rbd-drag-handle-draggable-id";
const QuestionLayout = ({ question, setItems, items, conditionalDict, formAction }) => {
    const {
        watch, register, formState: { errors }
    } = useFormContext();


    const questionType = question.question_type
    const questionID = question.questionID;
    const [previewOpen, setPreviewOpen] = useState(false);
    let previewQuestion = JSON.parse(JSON.stringify(question))
    previewQuestion.question_text = watch(`${previewQuestion.questionID}-question`)
    let q_family = [previewQuestion]

    let formOrder = []




    items.forEach(q => {
        if (q.parent_questionID == question.questionID) {
            q_family.push(q);
            const newOrder = { id: q.questionID, content: q.question_text }
            formOrder.push(newOrder)
        }

    })
    // console.log('cformOrder', formOrder)

    const [childrenFormOrder, setChildrenFormOrder] = useState(formOrder);
    useEffect(() => {
        if (question.question_type == 'conditional') {
            // console.log('childrenFormOrder', childrenFormOrder);
            let cFormIDs = [];
            childrenFormOrder.forEach(cForm => {
                cFormIDs.push(cForm.id);
            })
            // console.log('cFormIDs', cFormIDs)
            let newItems = JSON.parse(JSON.stringify(items))
            newItems.forEach(q => {
                // console.log('q', q, 'question', question)
                if (!cFormIDs.includes(q.questionID)) {
                    if (q.parent_questionID == question.questionID) { //used to be my parent
                        // console.log('null parent', q.questionID);
                        q.parent_questionID = null; //not anymore
                    }
                }
                else { //now is my parent
                    // const indexOf = cFormIDs.indexOf(q.questionID)
                    // console.log(question.questionID, 'is now parent of ', q.questionID)
                    q.parent_questionID = question.questionID;
                }
                if (q.parent_questionID == q.questionID) {
                    q.parent_questionID = null;
                }



            })
            console.log('newItems', newItems)
            setItems([...newItems])
        }

    }, [childrenFormOrder])

    if (questionType && allQuestionTypes[questionType]) {
        let defaultQuestion = allQuestionTypes[questionType][1]
        let defaultDescription = '...Additional Description'



        // console.log(allQuestionTypes[questionType])
        if (questionType == 'short_answer' || questionType == 'long_answer'
            || questionType == 'date' || questionType == 'section_header'
            || questionType == 'email' || questionType == 'phone_number'
            || questionType == 'plain_text' || questionType == 'sign_date'
            || questionType == 'file_upload') {
            // console.log('question', question)
            defaultQuestion = !question.question_text.includes('untitled') ? question.question_text : ''
            const parent_questionID = question.parent_questionID ? question.parent_questionID : null;

            return <div>
                <FileUploadInput
                    name={question.questionID}
                    label={`(${allQuestionTypes[questionType][0]}) Type your question: `}
                    placeholder={allQuestionTypes[questionType][1]}
                    defaultValue={defaultQuestion}
                    instructions={`This is a ${allQuestionTypes[questionType][0]} field.`}
                    defaultCheckValue={question.required}
                    defaultEncryptValue={question.encrypted}
                    questionType={questionType}
                    q={question}
                />
            </div>
        }
        else if (questionType == 'matrix') {
            // console.log('question', question)
            const previewQuestion = JSON.parse(JSON.stringify(question));
            let previewRowOptions = [];
            let previewColumnsOptions = [];


            // console.log('previewQuestion', previewQuestion)
            previewQuestion.options.forEach(option => {
                // console.log('previewRowOption', option)
                if (option) {
                    const value = watch(`${previewQuestion.questionID}-${processID(option)}`);
                    previewRowOptions.push(value)
                }
                else {
                    previewRowOptions.push('')
                }



            })
            previewQuestion.columns_options.forEach(option => {
                // console.log('previewColumnsOption', option)
                if (option) {
                    const value = watch(`${previewQuestion.questionID}-${processID(option)}`);
                    previewColumnsOptions.push(value)
                }
                else {
                    previewColumnsOptions.push('')
                }
            })


            previewQuestion.options = previewRowOptions;
            previewQuestion.columns_options = previewColumnsOptions;

            let rowOptions = []
            let columnOptions = []

            let requiredOptions = []
            let currentQuestionItem = {}
            // console.log('items', items)
            items.forEach(item => {
                if (item.questionID == question.questionID) {
                    if (item.options)
                        rowOptions = item.options.map(option => ({ id: processID(option), content: option }))
                    if (item.columns_options) {
                        columnOptions = item.columns_options.map(option => ({ id: processID(option), content: option }))
                    }
                    if (item.requiredOptions && questionType == 'checkbox') {
                        requiredOptions = item.requiredOptions;

                    }
                    currentQuestionItem = item;
                }
            })
            // console.log('options', options)

            defaultQuestion = !question.question_text.includes('untitled') ? question.question_text : ''
            if (question.question_description) {
                if (!question.question_description.includes('untitled')) {
                    defaultDescription = question.question_description
                }
                else {
                    defaultDescription = ''
                }
            }
            else {
                defaultDescription = ''
            }
            const updateRowOptions = (localOptions) => {
                console.log('localOptions', localOptions)
                console.log('currentQuestionList', items)
                const newItems = [];
                items.forEach(item => {
                    console.log(item.questionID, question.questionID, localOptions)
                    if (item.questionID == question.questionID) {

                        item.options = localOptions.map(lOption => lOption.content);
                        console.log('item', item)
                    }
                    newItems.push(item) //newItems contains IDs of the options, not the values!
                    // console.log(newItems)
                })

                // setOptions([...options, 'new option'])
                setItems(newItems);
            }
            const updateColumnOptions = (localOptions) => {
                console.log('localOptions', localOptions)
                console.log('currentQuestionList', items)
                const newItems = [];
                items.forEach(item => {
                    console.log(item.questionID, question.questionID, localOptions)
                    if (item.questionID == question.questionID) {

                        item.columns_options = localOptions.map(lOption => lOption.content);
                        console.log('item', item)
                    }
                    newItems.push(item) //newItems contains IDs of the options, not the values!
                    // console.log(newItems)
                })

                // setOptions([...options, 'new option'])
                setItems(newItems);
            }
            // console.log(options)
            return <div className='row'>

                <Input
                    name={`${question.questionID}-question`}
                    label={`(${allQuestionTypes[questionType][0]}) Type your matrix question:`}
                    className="mb-3 col-md-12"
                    placeholder={defaultQuestion}
                    defaultValue={defaultQuestion}
                    instructions={`This is a ${allQuestionTypes[questionType][0]} question.`}
                    required={true}

                />
                {/* <Input
                    name={`${question.questionID}-description`}
                    label={`Additional text, description, or instructions`}
                    className="mb-3 col-md-12"
                    placeholder={defaultDescription}
                    defaultValue={defaultDescription}
                /> */}
                <FieldAnnotation question={question} />
                {
                    Object.keys(countableQuestionTypes).includes(questionType) &&
                    <Row style={{ margin: "5 0 5 0" }}>
                        <Col md='2' className='center-text-container' style={{ marginLeft: 2 }}>Speech-out Keyword(s):</Col>
                        <Col md='8' style={{ paddingLeft: 0 }}>
                            <div>
                                <div className='form-check'>

                                    <input
                                        type={"text"}
                                        className={`form-control ${errors[questionID + '-keywords'] ? "is-invalid" : ""}`}
                                        id={questionID + '-keywords'}
                                        placeholder={'Sample Keywords'}
                                        defaultValue={question.keywords ? question.keywords : ''}
                                        {...register(questionID + '-keywords')}
                                    />
                                    <div className="invalid-feedback">{errors[questionID + '-keywords']?.message}</div>

                                </div>
                            </div>
                        </Col>
                        {/* <Col md='2' className='center-text-container' style={{ marginLeft: 2 }}>Speech-out Keyword(s):</Col> */}

                    </Row>


                }
                <div className='row mx-2'>
                    <MatrixDnD buttonText={"Add a row option"} options={rowOptions} updateOptions={updateRowOptions} question={question} optionKeywords={question.option_keywords} />
                    <MatrixDnD buttonText={"Add a column option"} options={columnOptions} updateOptions={updateColumnOptions} question={question} optionKeywords={question.column_option_keywords} />

                </div>
                <div className='mt-2' >
                    <Button
                        className='button-outline-theme'
                        onClick={() => setPreviewOpen(!previewOpen)}
                        aria-controls={`${question.questionID}-collapse-text`}
                        aria-expanded={previewOpen}
                    >
                        Preview {previewOpen ? <AiOutlineUp style={{ marginBottom: 2 }} /> : <AiOutlineDown style={{ marginBottom: 2 }} />}
                    </Button>
                    <Collapse in={previewOpen} >
                        <div className='preview-question' id={`${question.questionID}-collapse-text`} style={{ backgroundColor: 'white' }}>
                            <MatrixPreview q={previewQuestion} />

                        </div>
                    </Collapse>
                </div>
            </div>

        }
        else if (questionType == 'multiple_choice' || questionType == 'checkbox') {

            let options = []
            let requiredOptions = []
            let currentQuestionItem = {}
            let q_family = []

            items.forEach(item => {
                if (item.questionID == question.questionID) {
                    if (item.options)
                        options = item.options.map(option => ({ id: processID(option), content: option }))
                    if (item.requiredOptions && questionType == 'checkbox') {
                        requiredOptions = item.requiredOptions;

                    }
                    currentQuestionItem = item;
                }
                // console.log(question)
                if (item.parent_questionID === question.questionID) {
                    q_family.push(item);

                }
            })
            // console.log('options', options)

            defaultQuestion = !question.question_text.includes('untitled') ? question.question_text : ''

            const updateOptions = (localOptions) => {
                console.log('localOptions', localOptions)
                console.log('currentQuestionList', items)
                const newItems = [];
                items.forEach(item => {
                    console.log(item.questionID, question.questionID, localOptions)
                    if (item.questionID == question.questionID) {

                        item.options = localOptions.map(lOption => lOption.content);
                        item.option_keywords = localOptions.map(lOption => lOption.defaultKeywords);
                        console.log('item', item)
                    }
                    newItems.push(item) //newItems contains IDs of the options, not the values!
                    // console.log(newItems)
                })
                setItems(newItems);
            }

            return <div>

                <Input
                    name={`${question.questionID}-question`}
                    // name='hello'
                    label={`(${allQuestionTypes[questionType][0]}) Type your question:`}
                    className="mb-3 col-md-12"
                    placeholder={defaultQuestion}
                    defaultValue={defaultQuestion}
                    instructions={`This is a ${allQuestionTypes[questionType][0]} question.`}
                    required={true}
                //autoFocus
                />
                <FieldAnnotation question={question} />
                {
                    Object.keys(countableQuestionTypes).includes(questionType) &&
                    <Row style={{ margin: "5 0 5 0" }}>
                        <Col md='2' className='center-text-container' style={{ marginLeft: 2 }}>Speech-out Keyword(s):</Col>
                        <Col md='8' style={{ paddingLeft: 0 }}>
                            <div>
                                <div className='form-check'>

                                    <input
                                        type={"text"}
                                        className={`form-control ${errors[questionID + '-keywords'] ? "is-invalid" : ""}`}
                                        id={questionID + '-keywords'}
                                        placeholder={'Sample Keywords'}
                                        defaultValue={question.keywords ? question.keywords : ''}
                                        {...register(questionID + '-keywords')}
                                    />
                                    <div className="invalid-feedback">{errors[questionID + '-keywords']?.message}</div>

                                </div>
                            </div>
                        </Col>
                        {/* <Col md='2' className='center-text-container' style={{ marginLeft: 2 }}>Speech-out Keyword(s):</Col> */}

                    </Row>
                }
                <div className='row mx-2'>
                    <MultipleChoiceDnD
                        options={options}
                        updateOptions={updateOptions}
                        question={question}
                        requiredOptions={requiredOptions}
                        currentQuestionList={items}
                        setCurrentQuestionList={setItems}
                        conditionalDict={conditionalDict}
                    />

                </div>
                <div className='mt-2'>
                    <Button
                        className='button-outline-theme'
                        onClick={() => setPreviewOpen(!previewOpen)}
                        aria-controls={`${question.questionID}-collapse-text`}
                        aria-expanded={previewOpen}
                    >
                        Preview {previewOpen ? <AiOutlineUp style={{ marginBottom: 2 }} /> : <AiOutlineDown style={{ marginBottom: 2 }} />}
                    </Button>
                    <Collapse in={previewOpen} style={{ backgroundColor: 'white' }}>
                        <div className='preview-question' id={`${question.questionID}-collapse-text`}>
                            {questionType == 'multiple_choice' && <MultipleChoicePreview q={question}
                                q_family={q_family} />}
                            {questionType == 'checkbox' && <CheckBoxPreview q={question}
                                q_family={q_family} />}
                        </div>
                    </Collapse>
                </div>

            </div>
        }
        else if (questionType == 'dropdown') {
            let { question_text, options, option_keywords, required } = question;
            const newQuestionChecked = question_text.includes('untitled')
            const placeholder = newQuestionChecked ? 'What is your current university/institution?' : question_text
            const defaultQuestion = newQuestionChecked ? '' : question_text
            required = required ? required : false;

            // console.log('question', question)
            let sampleOptions = Array.from({ length: 5 }, (v, k) => k).map(k => 'Option ' + (k + 1))
            let sampleKeywords = Array.from({ length: 5 }, (v, k) => k).map(k => 'keywords ' + (k + 1))

            const sampleOptionsPlaceholder = newQuestionChecked ? sampleOptions.join('\n') : options.join('\n')
            const sampleKeywordsPlaceholder = newQuestionChecked ? sampleKeywords.join('\n') : option_keywords.join('\n')

            const defaultOptions = newQuestionChecked ? '' : sampleOptionsPlaceholder
            const defaultKeywords = newQuestionChecked ? '' : sampleKeywordsPlaceholder

            // console.log(sampleOptions)
            // console.log('question', question)
            return <div>
                <Input
                    name={`${question.questionID}-question`}
                    // name='hello'
                    label={`(${allQuestionTypes[questionType][0]}) Type your question: `}
                    className="mb-3 col-md-12"
                    placeholder={placeholder}
                    defaultValue={defaultQuestion}
                    required={true}
                    instructions={`This is a ${allQuestionTypes[questionType][0]} question.`}
                />
                <Row>
                    <Col md='10'>
                        <FieldAnnotation question={question} />
                    </Col>
                    <Col md='2'>
                        <div>
                            <label>Required</label>

                            <input
                                className="form-check-input col form-check-inline mx-2"
                                type="checkbox"
                                id={question.questionID + '-required'}
                                // value={true}
                                value={question.questionID + '-required'}
                                defaultChecked={required}
                                {...register(question.questionID + '-required')}
                            />
                        </div>
                    </Col>
                </Row>

                <i>Note: Each option should be on a <b>separate</b> line.</i>
                <Row>
                    <Col md='8'>
                        <Textarea
                            name={`${question.questionID}-options`}
                            required={true}
                            // name='hello'
                            label={`List your options (ONE per line): `}
                            className="mb-3"
                            placeholder={sampleOptionsPlaceholder}
                            defaultValue={defaultOptions}
                            instructions={`Each option should be on a separate line.`} />
                    </Col>
                    <Col md='4'>
                        <Textarea
                            name={`${question.questionID}-option-keywords`}
                            required={true}
                            // name='hello'
                            label={`Option Keywords `}
                            className="mb-3"
                            placeholder={sampleKeywordsPlaceholder}
                            defaultValue={defaultKeywords}
                            instructions={`Each phrase should be on a separate line. The size of the list should match the length of the option list.`} />
                    </Col>
                </Row>

                <Row style={{ margin: "5 0 5 0" }}>

                    <Col md='2' className='center-text-container'>Speech-out Keyword(s):</Col>
                    <Col md='8' style={{ paddingLeft: 0 }}>
                        <div>

                            <div className='form-check'>

                                <input
                                    type={"text"}
                                    className={`form-control ${errors[questionID + '-keywords'] ? "is-invalid" : ""}`}
                                    id={questionID + '-keywords'}
                                    placeholder={'Sample Keywords'}
                                    defaultValue={question.keywords ? question.keywords : ''}
                                    {...register(questionID + '-keywords')}
                                />
                                <div className="invalid-feedback">{errors[questionID + '-keywords']?.message}</div>

                            </div>
                        </div>

                    </Col>

                </Row>

            </div>
        }
        else if (questionType == 'conditional') {
            defaultQuestion = !question.question_text.includes('untitled') ? question.question_text : ''
            const parent_questionID = question.parent_questionID ? question.parent_questionID : null;
            let borderColor = theme.highlightColor

            if (parent_questionID) {
                console.log('parent_questionID', parent_questionID)
                borderColor = conditionalDict[parent_questionID] ? conditionalDict[parent_questionID] : borderColor;
            }

            let borderColorChild = theme.highlightColor
            if (conditionalDict[question.questionID]) {
                borderColorChild = conditionalDict[question.questionID]
            }
            return <div>

                {conditionalDict[question.questionID] &&
                    <div>
                        {/* <p>Question Parent:  </p><div className='square-family'></div> */}
                        <div><span><i><b>Root of: </b></i></span>
                            <div class="square-family" style={{ backgroundColor: borderColorChild }}></div>
                        </div>

                    </div>
                }

                <Input
                    name={`${question.questionID}-question`}
                    // name='hello'
                    label={`(${allQuestionTypes[questionType][0]}) Type your conditional question:`}
                    className="mb-3 col-md-12"
                    placeholder={defaultQuestion}
                    defaultValue={defaultQuestion}
                    instructions={`This is a ${allQuestionTypes[questionType][0]} question.`}
                    required={true}

                />


                <Dropdown className=''
                    onSelect={(eventKey, event) => {
                        const clickedForm = items[eventKey];
                        console.log('adding ', eventKey, clickedForm)
                        const newCForm = { id: items[eventKey].questionID, content: items[eventKey].question_text }
                        setChildrenFormOrder([...childrenFormOrder, newCForm])
                    }}>
                    <Dropdown.Toggle variant={''}
                        className={'button-outline-theme mb-2'}
                        id="dropdown-basic">
                        Add a dependent question
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {items && items.map((item, index) => {
                            if (item.questionID != question.questionID && !item.parent_questionID) {
                                if (item.question_type != 'conditional') {
                                    // console.log(getValues())
                                    let value = watch(`${item.questionID}`);
                                    if (!value) {
                                        value = watch(`${item.questionID}-question`);
                                        if (!value) {
                                            value = item.question_text;
                                        }
                                    }
                                    // if (item.question_type == 'checkbox' || item.question_type == 'multiple-choice' ) {
                                    // }
                                    return <Dropdown.Item eventKey={index}>{value}</Dropdown.Item>
                                }

                            }
                            else {
                                // console.log('not chosen item', item)
                            }
                        })}
                    </Dropdown.Menu>
                </Dropdown >
                <h6>List of Dependencies: </h6>

                <ConditionalDnD formOrder={childrenFormOrder} setFormOrder={setChildrenFormOrder} />
                <div className='mt-2'>
                    <Button
                        className='button-outline-theme'
                        onClick={() => setPreviewOpen(!previewOpen)}
                        aria-controls={`${question.questionID}-collapse-text`}
                        aria-expanded={previewOpen}
                    >
                        Preview {previewOpen ? <AiOutlineUp style={{ marginBottom: 2 }} /> : <AiOutlineDown style={{ marginBottom: 2 }} />}
                    </Button>
                    <Collapse in={previewOpen}>
                        <div className='preview-question' id={`${question.questionID}-collapse-text`}>
                            <ConditionalPreview
                                // style={{}}
                                className="col-sm-12 col-md-6 col-lg-6 mb-3"
                                questions={q_family}
                            // instructions={instructions}

                            />

                        </div>
                    </Collapse>
                </div>
            </div >
        }
        return <div>
            {`(${allQuestionTypes[questionType][0]}) ${question.question_text}`}
        </div>

    }
    return <></>





}

const DragNDropList = ({ currentQuestionList, setTempItems, formAction }) => {
    const newSchemaObject = {}
    const conditionalDict = {}
    let questionLibrary = {}
    currentQuestionList.map(q => { questionLibrary[q.questionID] = q })
    currentQuestionList.forEach(qObj => {
        const questionType = qObj.question_type
        if (questionType == 'short_answer' || questionType == 'long_answer'
            || questionType == 'date' || questionType == 'section_header'
            || questionType == 'email' || questionType == 'phone_number'
            || questionType == 'plain_text' || questionType == 'sign_date' || questionType == 'dropdown'
            || questionType == 'matrix' || questionType == 'file_upload') {
            newSchemaObject[qObj.questionID] = Yup.string().required;
            if (qObj.parent_questionID) {
                if (qObj.parent_conditions) {
                    if (Object.keys(qObj.parent_conditions).length > 0) {
                        const parent_conditions = qObj.parent_conditions;
                        for (let [p_qID, p_cond_arr] of Object.entries(parent_conditions)) {
                            if (!conditionalDict[p_cond_arr[0]]) {
                                conditionalDict[p_cond_arr[0]] = colorFamilyLight[Object.keys(conditionalDict).length];
                                console.log('conditionalDict[p_cond_arr[0]]', p_cond_arr[0], conditionalDict[p_cond_arr[0]])

                            }

                        }
                    }
                }
            }
        }
        if (questionType == 'conditional') {
            conditionalDict[qObj.questionID] = colorFamily[Object.keys(conditionalDict).length];
        }

    })
    // console.log('conditionalDict', conditionalDict)
    const validationSchema = Yup.object().shape(newSchemaObject);
    const formOptions = { resolver: yupResolver(validationSchema) };
    const methods = useFormContext(formOptions);
    const { handleSubmit, setError, reset, watch, register, setValue, getValues, formState: { errors } } = methods;

    // console.log(currentQuestionList)
    const [placeholderProps, setPlaceholderProps] = useState({});
    // const [items, setItems] = useState(getItems(10));
    // const [items, setItems] = useState(currentQuestionList);
    const items = currentQuestionList;



    useEffect(() => {
        if (currentQuestionList.length > 0) {
            // setItems([...currentQuestionList])
            // console.log('currentQuestionList in DragnDrop', currentQuestionList)
        }
    }, [currentQuestionList])

    // useEffect(() => {
    //     setTempItems(items)
    // }, [items])


    const onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        setPlaceholderProps({})
        // setItems(items => reorder(items, result.source.index, result.destination.index));
        setTempItems(items => reorder(items, result.source.index, result.destination.index))

    };

    const onDragUpdate = update => {
        if (!update.destination) {
            return;
        }
        const draggableId = update.draggableId;
        const destinationIndex = update.destination.index;

        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        if (!draggedDOM) {
            return;
        }
        const { clientHeight, clientWidth } = draggedDOM;

        const clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) + [...draggedDOM.parentNode.children]
            .slice(0, destinationIndex)
            .reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft)
        });
    };
    const [options, setOptions] = useState([])
    useEffect(() => {
        // console.log(options)
    }, [options])
    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    return (
        <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => {

                    return <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {items.map((item, index) => {
                            let { parent_questionID, parent_conditions } = item;
                            parent_questionID = parent_questionID ? parent_questionID : null;
                            let parentQuestionValue = ''
                            let parentConditionValue = ''
                            let parentConditionID = ''
                            let parentBorderColor = "#f5f7f5"
                            if (parent_questionID) {
                                parentQuestionValue = questionLibrary[parent_questionID].question_text;
                                if (parent_conditions) {
                                    if (Object.keys(parent_conditions).length > 0) {
                                        // console.log('parentQuestionID', parentQuestionID)
                                        parentConditionValue = parent_conditions[parent_questionID][1];
                                        parentConditionID = parent_conditions[parent_questionID][0];
                                        parentBorderColor = conditionalDict[parentConditionID]
                                    }
                                }
                                // console.log('parent_questionID', parent_questionID)
                            }
                            return <Draggable key={item.questionID} draggableId={item.questionID} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            item,
                                            conditionalDict
                                        )}

                                    >

                                        <span className='float-end'><AiFillCloseCircle className='float-end' style={{
                                            color: 'gray', fontSize: '20px'
                                        }} onClick={() => {
                                            console.log('delete ' + index)
                                            items.splice(index, 1); //delete the option
                                            console.log(items)
                                            // setItems([...items]);
                                            setTempItems([...items]);

                                            // unregister(name);
                                            // onClick();
                                        }} /></span>
                                        <span {...provided.dragHandleProps}>
                                            <AiOutlineHolder

                                            />
                                        </span>
                                        {parentConditionValue != '' && parentQuestionValue != '' && <Row
                                            style={{
                                                backgroundColor: parentBorderColor,
                                                border: `3px dashed gray`,
                                                borderRadius: 10, margin: 10,
                                            }}>
                                            <Col md='6' className="d-flex align-items-center justify-content-center">
                                                <p style={{ marginBottom: 0 }}>Parent Question: <b>{parentQuestionValue}</b></p>
                                            </Col>
                                            <Col md='6' className="d-flex align-items-center justify-content-center">
                                                <p style={{ marginBottom: 0 }}>Required Value: <b>{parentConditionValue}</b></p>
                                            </Col>
                                        </Row>}
                                        {/* {item.question_text} */}
                                        {/* <QuestionLayout question={item} setItems={setItems} items={items} /> */}
                                        <QuestionLayout
                                            question={item}
                                            setItems={setTempItems}
                                            items={items}
                                            conditionalDict={conditionalDict}
                                            formAction={formAction} />


                                    </div>
                                )}
                            </Draggable>
                        }

                        )}

                        {provided.placeholder}
                        {/* <CustomPlaceholder snapshot={snapshot} /> */}
                        <div style={{
                            position: "absolute",
                            top: placeholderProps.clientY,
                            left: placeholderProps.clientX,
                            height: placeholderProps.clientHeight,
                            background: "tomato",
                            width: placeholderProps.clientWidth
                        }} />
                    </div>
                }

                }
            </Droppable>
        </DragDropContext>
    );
};
export default DragNDropList;
