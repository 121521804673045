
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Form from 'react-bootstrap/Form';
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, RadioGroup } from "../Components";
import { db } from "../Firebase/firebasedb";
import { doc, setDoc, orderBy, serverTimestamp, collection, query, where, onSnapshot } from "firebase/firestore";
import { Button } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import ReactTooltip from 'react-tooltip';
import { AiFillQuestionCircle, AiFillCloseCircle } from "react-icons/ai";
import theme from '../Theme/theme'
import "./automatic_form.css"
import { institutionsList } from "../Fixed Sources/institutions";

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value) || child.props.children.startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);
export const NewFormSummary = ({ formData,
    formID, setFormID,
    setLatestUnuploadedForm, fileInputRef,
    setBlinkingFormID }) => {
    const allInstitutions = Object.keys(institutionsList);
    // const allRoles = {
    //     'Student': 'student',
    //     'Student Mentor': 'student-mentor',
    //     'Faculty': 'faculty',
    //     'Administrator': 'administrator'
    // }
    const allRoles = {
        'student': 'Student',
        'student-mentor': 'Student Mentor',
        'faculty': 'Faculty',
        'administrator': 'Administrator'
    }
    const [currentAllowedRoles, setCurrentAllowedRoles] = useState([])

    // const allRoles = ['student', 'student-mentor', 'faculty', 'administrator']
    // const allRolesFull = ['Student', 'Student Mentor', 'Faculty', 'Administrator']
    const validationSchema = Yup.object().shape({
        // formTitle: Yup.string().required("Required"),
        formTitle: Yup.string().required('Cannot be empty!')
            .test("Required", 'Another form with this name already exists! If the form is yours, please upload a revised version from the table below.', (result) => {
                let valid = true;
                if (formLibrary) {
                    formLibrary.forEach(form => {
                        console.log(formID, form.formID)
                        // if (formID == form.formID) {
                        //     valid = true;
                        //     // return true;
                        // }
                        if (formID != form.formID && form.formTitle.toLowerCase() == result.toLowerCase()) {
                            // console.log('here')
                            valid = false;
                        }
                    })
                }
                // if (result == 'Duplicate Form')
                //     return false;
                return valid;
            }),
        allowedInstitutions: Yup.array().test("Required", "Please choose at least one institution to share the form with!", (result) => {
            console.log(result);
            if (currentFormDomain == 'Specific') {
                if (!result || result.length <= 0)
                    return false;
            }
            return true;
        }),
        allowedRoles: Yup.array().test("Required", 'At least one account type must be specified!', (result) => {
            // console.log(currentAllowedRoles)
            if (!result || result.length <= 0) {
                return false;
            }
            return true;
        }),
        formDomain: Yup.string().required()


    });
    const [formLibrary, setFormLibrary] = useState([]);
    const [currentEventKey, setCurrentEventKey] = useState(null)




    useEffect(() => {
        // console.log(formData);

        const formLibraryRef = collection(db, "form_library");
        const q = query(formLibraryRef)
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const formUploadHistData = []
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                const rawData = doc.data();
                const createdDate = rawData.createdAt.toDate().toDateString()
                const createdTime = rawData.createdAt.toDate().toLocaleTimeString('en-US');
                const formFormat = rawData.formFormat ? rawData.formFormat : 'csv'
                const formName = rawData.form_questions[0].question_type == 'form_title' ? rawData.form_questions[0].question_text : 'Untitled Form'
                const status = rawData.status ? rawData.status : 'published'
                const action = status == 'published' ? "Unpublish" : 'Publish'
                // const accessibleTo = rawData.accessibleTo ? rawData.accessibleTo : ['student', 'student-mentor', 'faculty', 'administrator']
                const formDomain = rawData.formDomain ? rawData.formDomain : 'Common'
                const allowedInstitutions = rawData.allowedInstitutions ? rawData.allowedInstitutions : []
                const allowedRoles = rawData.allowedRoles ? rawData.allowedRoles : []
                formUploadHistData.push({
                    formID: rawData.formID,
                    formTitle: formName,
                    createdAt: `${createdDate}, ${createdTime}`,
                    timeStamp: rawData.createdAt,
                    formFormat: formFormat,
                    status: status.toUpperCase(),
                    action: action,
                    reupload: 'reupload',
                    // accessibleTo: accessibleTo.join('||'),
                    deleteAction: 'deleteAction',
                    formDomain,
                    allowedInstitutions,
                    allowedRoles
                })

            });
            // formUploadHistData.sort(function(x, y){
            //     return y.timeStamp - x.timeStamp; //sort y before x
            // })
            setFormLibrary(formUploadHistData)
            // console.log(formUploadHistData)
        });

    }, [formData])


    const formOptions = { resolver: yupResolver(validationSchema) };
    const methods = useForm(formOptions);
    const { handleSubmit, reset, register, setValue, getValues, formState: { errors } } = methods;
    console.log(errors)
    const [numberOfQuestions, setNumberOfQuestions] = useState(0)
    const notQuestionTypes = ['plain_text', 'form_title', 'section_header']
    // console.log(formData)
    useEffect(() => {
        let formTitle = 'Untitled Form';
        let formDomain = 'Common';
        let allowedInstitutions = [];
        let allowedRoles = []

        if (formData && formData.form_questions[0].question_type == 'form_title') {
            formTitle = formData.form_questions[0].question_text
            if (formID) {
                formLibrary.forEach(form => {
                    if (form.formID == formID) {
                        allowedInstitutions = form.allowedInstitutions ? form.allowedInstitutions : []
                        formDomain = form.formDomain ? form.formDomain : 'Common'
                        allowedRoles = form.allowedRoles ? form.allowedRoles : []
                        // console.log(allowedRoles)

                    }
                })

            }
            //
            setValue('formTitle', formTitle)
            setValue('formDomain', formDomain)
            setValue('allowedInstitutions', allowedInstitutions)
            setValue('allowedRoles', [...allowedRoles])

            setCurrentFormDomain(formDomain);
            setCurrentAllowedInstitutions([...allowedInstitutions]);
            setCurrentAllowedRoles([...allowedRoles])


            let count = 0
            formData.form_questions.forEach(question => {
                if (!notQuestionTypes.includes(question.question_type)) {
                    count += 1
                }
            })
            setNumberOfQuestions(count);
        }
    }, [formData, formID, formLibrary])
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button variant={errors['allowedInstitutions'] && currentAllowedInstitutions.length <= 0 ? 'outline-danger' : 'outline-info'}
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}>{children}
            &#x25bc;</Button>
    ));
    function writeToFirebase(data, merge = false) {
        console.log(data)
        setDoc(doc(db, "form_library", data.formID), data, { merge: merge })
            .then(() => {
                // setFormID(data.formID);
                if (merge == false) {
                    console.log(`Form ${data.formID} written to Firebase successfully`)
                    alert(`Form ${data.formID} written to Firebase successfully`)
                    setBlinkingFormID(data.formID)

                }
                else {
                    console.log(`Form ${data.formID} revised successfully`)
                    alert(`Form ${data.formID} revised successfully`)
                    setBlinkingFormID(data.formID)
                }

                // setFileProcessedSuccessfully(Date.now());
            }).catch(err => {
                console.log(err)
                alert(err)
                // setFileProcessedSuccessfully(Date.now());

            });

    }
    const processID = (rawID) => {
        return rawID.replace(/[^a-zA-Z0-9 ]/g, '');
    }

    const handleUpload = (data, e) => {
        e.preventDefault();
        const newFormTitle = data.formTitle;
        const formDomain = data.formDomain? data.formDomain: 'Common';
        const allowedInstitutions = data.allowedInstitutions ? data.allowedInstitutions: []
        const allowedRoles = data.allowedRoles ? data.allowedRoles : [];

        if (formData) {
            if (formData.form_questions[0].question_type == 'form_title') {
                formData.form_questions[0].question_text = newFormTitle
                formData.form_questions[0].questionID = processID(newFormTitle)

            }
            else {
                formData.form_questions = [{
                    question_type: 'form_title',
                    question_text: newFormTitle,
                    questionID: processID(newFormTitle)
                }].concat(formData.form_questions)
            }
            formData = { ...formData, formDomain, allowedInstitutions, allowedRoles }
        }
        else {
            console.log('File was not selected')
        }
        // console.log(data)
        console.log(formData)
        if (formID)
            writeToFirebase(formData, true)
        else {
            writeToFirebase(formData)
        }
    }
    const selectedDomain = register('formDomain', { required: true })
    const selectedInstitutions = register('allowedInstitutions', { required: true })
    const selectedRoles = register('allowedRoles', { required: true })

    const [currentAllowedInstitutions, setCurrentAllowedInstitutions] = useState([])


    const [currentFormDomain, setCurrentFormDomain] = useState('Common')

    const buttonOutlineStyle = { borderColor: theme.highlightColor, color: theme.highlightColor }
    const buttonFillStyle = { borderColor: theme.highlightColor, backgroundColor: theme.highlightColor, color: 'white' }
    // useEffect(() => {
    //     console.log(currentAllowedInstitutions);
    // }, [currentAllowedInstitutions])
    return <div>
        {/* <p>Form Title: {formTitle}</p> */}
        <ReactTooltip backgroundColor={theme.highlightColor} />

        <div className="card m-3 border-light">

            <div className="card-body">
                <h2>{formID ? 'Revised' : 'New'} Form Overview {formID && <span>(ID: <i>{formID}</i>)</span>} </h2>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(handleUpload)}>
                        {/* <form onSubmit={handleRegistration}> */}

                        <div className="row">
                            <Input
                                name="formTitle"
                                label="Form Name"
                                className="mb-3 col-xl-6"
                            />
                            {/* <Input
                                name="lastName"
                                label="Last Name"
                                className="mb-3 col-xl-6"
                            /> */}
                            <p>Number of Questions: {numberOfQuestions}</p>
                            <Dropdown className=''
                                onSelect={(eventKey, event) => {
                                    if (!currentAllowedRoles.includes(eventKey)) {
                                        setValue('allowedRoles', [...currentAllowedRoles, eventKey]);
                                        setCurrentAllowedRoles([...currentAllowedRoles, eventKey])
                                    }
                                }}>
                                <Dropdown.Toggle variant={errors['allowedRoles'] && currentAllowedRoles.length <= 0 ? 'outline-danger' : ''}
                                    className={errors['allowedRoles'] && currentAllowedRoles.length <= 0 ? '' : 'button-outline-theme'}
                                    id="dropdown-basic">
                                    Specify role level:
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {Object.keys(allRoles).map(role => {
                                        return <Dropdown.Item eventKey={role}>{allRoles[role]}</Dropdown.Item>
                                    })}
                                </Dropdown.Menu>
                                <AiFillQuestionCircle style={{
                                    marginLeft: '2px', marginBottom: '3px',
                                    color: theme.highlightColor, fontSize: '17px'
                                }} data-tip={'Specify which account type can access this form.'} />

                            </Dropdown>
                            <div className="my-2 col-md-6">
                                {Object.keys(allRoles).map(role => {

                                    if (currentAllowedRoles.includes(role)) {
                                        return <Button className="mx-2" variant='secondary'>{allRoles[role]}<AiFillCloseCircle style={{
                                            marginLeft: '5px', marginBottom: '3px',
                                            color: 'white', fontSize: '17px'
                                        }} onClick={() => {
                                            let allowedRoles = currentAllowedRoles
                                            var index = allowedRoles.indexOf(role);
                                            if (index !== -1) {

                                                allowedRoles.splice(index, 1);
                                                console.log(allowedRoles)
                                                setValue('allowedRoles', [...allowedRoles])
                                                setCurrentAllowedRoles([...allowedRoles])
                                            }
                                        }} /> </Button>
                                    }
                                    return <></>

                                })}

                                <div className="is-invalid">

                                </div>
                                <div className="invalid-feedback mb-2">
                                    {errors['allowedRoles'] && currentAllowedRoles.length <= 0 ?
                                        errors['allowedRoles'].message : ''}
                                </div>
                            </div>
                            <Dropdown className='mb-3'
                                onSelect={(eventKey, event) => { setValue('formDomain', eventKey); setCurrentFormDomain(eventKey) }}>
                                <Dropdown.Toggle className='button-outline-theme' id="dropdown-basic">
                                    Form Domain  {currentFormDomain ? `(${currentFormDomain}) ` : ' '}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey='Common'>Common</Dropdown.Item>
                                    <Dropdown.Item eventKey='Specific' >Specific</Dropdown.Item>
                                </Dropdown.Menu>
                                <AiFillQuestionCircle style={{
                                    marginLeft: '2px', marginBottom: '3px',
                                    color: theme.highlightColor, fontSize: '17px'
                                }} data-tip={'Common: Available to all institutions.\nSpecific: available to one or more certain institutions.'} />

                            </Dropdown>

                            {currentFormDomain == 'Specific' && <div>
                                <Dropdown className='col-md-6  mx-2'
                                    onSelect={(eventKey, event) => {
                                        setCurrentAllowedInstitutions([...currentAllowedInstitutions, eventKey])
                                        setValue('allowedInstitutions', [...currentAllowedInstitutions, eventKey]);
                                        setCurrentEventKey(eventKey)
                                    }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        Add an Institution
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu} >
                                        {allInstitutions.map(institute => {
                                            return <Dropdown.Item eventKey={institute} active={institute == currentEventKey}>{institute}</Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="is-invalid">
                                    {/* <div className="invalid-feedback">hello22</div> */}

                                </div>
                                <div className="invalid-feedback mb-2">{errors['institution'] && !currentEventKey ? errors['institution'].message : ''}</div>
                                <div className="mb-2 col-md-6">
                                    {allInstitutions.map(institution => {
                                        const displayStyle = currentAllowedInstitutions.includes(institution) ? 'block' : 'none'

                                        return <Button style={{ display: displayStyle }} className="mx-4" variant='secondary'>{institution}<AiFillCloseCircle style={{
                                            marginLeft: '5px', marginBottom: '3px',
                                            color: 'white', fontSize: '17px'
                                        }} onClick={() => {
                                            let allowedInstitutions = currentAllowedInstitutions
                                            var index = allowedInstitutions.indexOf(institution);
                                            if (index !== -1) {

                                                allowedInstitutions.splice(index, 1);
                                                console.log(allowedInstitutions)
                                                setValue('allowedInstitutions', [...allowedInstitutions])
                                                setCurrentAllowedInstitutions([...allowedInstitutions])
                                            }
                                        }} /> </Button>
                                    })}
                                    {/* <Button className="mx-1" variant='secondary'>Institution 1 <AiFillCloseCircle style={{
                                        marginLeft: '2px', marginBottom: '3px',
                                        color: 'white', fontSize: '17px'
                                    }} /> </Button>
                                    <Button className="mx-1" variant='secondary'>Institution 2 </Button> */}
                                    <div className="is-invalid">
                                        {/* <div className="invalid-feedback">hello22</div> */}

                                    </div>
                                    <div className="invalid-feedback mb-2">{errors['allowedInstitutions'] && currentAllowedInstitutions.length <= 0 ? errors['allowedInstitutions'].message : ''}</div>
                                </div>
                            </div>}

                        </div>

                        <div className="row d-flex justify-content-center my-3">
                            <div className="form-group">
                                <button type="submit" className={`btn ${formID ? 'btn-warning' : 'button-fill-theme'} mr-1`}>
                                    Upload {formID ? 'Revised' : 'New'} Form
                                </button>
                                {formID ? <Button className='mx-2' variant='outline-secondary' onClick={() => {
                                    setFormID(null)
                                    setLatestUnuploadedForm(null);
                                    fileInputRef.current.value = "";
                                }}>Cancel Revision </Button> : <Button className='mx-2' variant='outline-secondary' onClick={() => {
                                    setFormID(null)
                                    setLatestUnuploadedForm(null);
                                    fileInputRef.current.value = "";
                                }}>Cancel </Button>}

                            </div>
                        </div>
                        <small className="text-muted">
                            By clicking the 'Upload' button, you confirm that you accept
                            our Terms of use and Privacy Policy.
                        </small>
                    </form>
                </FormProvider>
            </div>
        </div>

    </div>
}