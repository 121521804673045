import React, { useState, useEffect, useRef } from "react";
import { db } from "../Firebase/firebasedb";
import {
    doc, setDoc, getDocs, onSnapshot,
    serverTimestamp, query, where, collection, orderBy, getDoc, writeBatch,
    deleteDoc
} from "firebase/firestore";
// import { Button } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { AutomaticBot } from "./AutomaticChatbot";
import { AutomaticQuestion } from "./AutomaticComponents";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import cloneDeep from 'lodash/cloneDeep';
import { Modal, Button } from "react-bootstrap";
import './automatic_form.css'
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import names from  'random-names-generator'
import { uniqueNamesGenerator, adjectives, colors, animals, names, NumberDictionary } from 'unique-names-generator';
import { loremIpsum, name, surname, fullname, username } from 'react-lorem-ipsum';
import wordsToNumbers from "words-to-numbers";
import { cities, states, majors } from "./fake_sources"
import { faker } from '@faker-js/faker';
import { countableQuestionTypes, encryptableQuestionTypes, FormLibrary, generateRandomMajor, generateRandomNumber, HomePageURL, makeid, processID, randomDate, RedirectToHomePage } from "./Utils";
import theme from "../Theme/theme";
import { devAccounts, getFirebaseDocumentID, isCampusLead, isHubLead, isPortalAdmin } from "../Fixed Sources/accountTypes";
import { parseMultiple, parseMultiple2 } from '../Automatic Forms/Utils'
import { AiFillQuestionCircle, AiOutlineFilePdf, AiOutlineInfoCircle, AiOutlinePrinter } from "react-icons/ai";
import ReactTooltip from 'react-tooltip';
import { base64Encode } from "@firebase/util";
import JsPDF from 'jspdf';
import "../Fixed Sources/book-webfont-normal"
import "../Fixed Sources/NotoSerif-Black-normal"
import "../Fixed Sources/NotoSerif-Light-normal"
import "../Fixed Sources/Segoe UI-normal"
import Confetti from 'react-confetti'
import ProgressBar from "@ramonak/react-progress-bar";
import useWindowSize from "./useWindowSize";
import "./automatic_form.css"
export default function FormRenderingReadOnly() {

    const [renderPage, setRenderPage] = useState(true);
    const { width, height } = useWindowSize();
    const highlightColor = '#6E48AA'
    const { formId } = useParams();
    const [formSubmissionID, setFormSubmissionID] = useState(makeid(20));
    const [scrollHeight, setScrollHeight] = useState(height);
    const [formQuestions, setFormQuestions] = useState([]);
    const [formMetadata, setFormMetadata] = useState(null);
    const [validationSchema, setValidationSchema] = useState({})
    const methods = useForm({ resolver: yupResolver(Yup.object().shape(validationSchema)) });

    const { handleSubmit, watch, reset, setValue, getValues, formState: { errors } } = methods;
    const formValues = watch();
    console.log('formValues', formValues);
    const [latestRetrievedForm, setLatestRetrievedForm] = useState(null);
    const [previousSubmissions, setPreviousSubmissions] = useState({});
    const [satisfied, setSatisfied] = useState(false)
    const [formRetrieved, setFormRetrieved] = useState(null);

    //Authentication Parameters
    const [userData, setUserData] = useState(null);

    const auth = getAuth();
    const [user, setUser] = useState(null);
    const [pdfHover, setPdfHover] = useState(false);
    const [printerHover, setPrinterHover] = useState(false);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                // const uid = user.uid;
                // ...
                setUser(user);
            } else {
            }
        });
        return () => unsubscribe();
    }, [auth]);
    useEffect(() => {
        if (user) {
            const docRef = doc(db, "Users", user.uid);
            getDoc(docRef).then((docSnap) => {
                if (docSnap.exists()) {
                    console.log("Document data:", docSnap.data());
                    const data = docSnap.data();
                    setUserData(data);
                    console.log(data.atype)
                    const firebaseDocID = getFirebaseDocumentID(data.atype)
                }
            });
        }
    }, [user]);
    //Bot parameters
    const thisform = useRef();
    const handleSnapshot = (document, userData, fromLibrary) => {
        if (document.exists()) {
            const data = document.data();
            const formDomain = data.formDomain ? data.formDomain : 'Common'
            const allowedRoles = data.allowedRoles ? data.allowedRoles : []
            const allowedInstitutions = data.allowedInstitutions ? data.allowedInstitutions : []
            let renderPage = false;
            if (formDomain === 'Specific') {
                if (userData) {
                    if (isHubLead(userData.atype) || isPortalAdmin(userData.atype)) {
                        renderPage = true;
                    }
                    else if (data.allowedInstitutions && data.allowedInstitutions.length > 0 && userData.institution) {
                        data.allowedInstitutions.forEach(institution => {
                            if (institution.includes(userData.institution)) {
                                renderPage = true;
                            }
                        })
                    }
                }
            }
            else {
                if (data.excludedInstitutions && data.excludedInstitutions.length > 0) {
                    if (userData && !data.excludedInstitutions.includes(userData.institution)) {
                        renderPage = true;
                    }
                }
                else {
                    renderPage = true;
                }
            }
            setRenderPage(renderPage);
            console.log("Document data:", data);
            setFormQuestions(data['form_questions']);
            setFormMetadata(data);
        } else {
            console.log("No such document!");
            setFormQuestions(null)

            if (fromLibrary) {
                // If doc does not exist in form_library, check in form_library_recently_deleted
                const docRef2 = doc(db, "form_library_recently_deleted", formId);
                onSnapshot(docRef2, (document) => handleSnapshot(document, userData, false));
            }
        }
    };

    useEffect(() => {
        // const docRef = doc(db, "form_library", formId);
        const docRef1 = doc(db, "form_library", formId);
        onSnapshot(docRef1, (doc) => handleSnapshot(doc, userData, true));
        if (user) {
            const formUploadRef = collection(db, "automatic_form_submissions");
            const q = query(formUploadRef, where("userID", "==", user.uid), where('formID', '==', formId), orderBy("createdAt", "desc"))
            const qPrev = query(formUploadRef, where("userID", "==", user.uid), orderBy("createdAt", "desc"))

            const formUploadTempRef = collection(db, "automatic_form_submissions_temp");
            const qTemp = query(formUploadTempRef, where("userID", "==", user.uid), where('formID', '==', formId), orderBy("createdAt", "desc"))

            const latestRetrievedForm = []
            const previousSubmissionsServer = {}
            getDocs(qPrev).then(querySnapshot => {
                if (querySnapshot.size > 0) {
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        const rawData = doc.data();
                        console.log('Latest Form: ' + rawData.formSubmissionID)
                        if (rawData.createdAt) {
                            const createdDate = rawData.createdAt.toDate().toDateString()
                            const createdTime = rawData.createdAt.toDate().toLocaleTimeString('en-US');
                            rawData.createdDateTime = `${createdDate}, ${createdTime}`
                        }
                        if (!Object.keys(previousSubmissionsServer).includes(rawData.formID)) {
                            previousSubmissionsServer[rawData.formID] = rawData;
                        }

                        // console.log(rawData);
                    });

                    setPreviousSubmissions({ ...previousSubmissions, ...previousSubmissionsServer })
                }
            })
            getDocs(qTemp).then(querySnapshotTemp => { //Check latest saves,
                if (querySnapshotTemp.size > 0) { //if yes, pull save
                    querySnapshotTemp.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        const rawData = doc.data();
                        console.log('Latest Saved Form: ' + rawData.formSubmissionID)
                        if (rawData.createdAt) {
                            const createdDate = rawData.createdAt.toDate().toDateString()
                            const createdTime = rawData.createdAt.toDate().toLocaleTimeString('en-US');
                            rawData.createdDateTime = `${createdDate}, ${createdTime}`
                        }

                        latestRetrievedForm.push(rawData)
                        if (!Object.keys(previousSubmissionsServer).includes(rawData.formID)) {
                            previousSubmissionsServer[rawData.formID] = rawData;
                        }

                        // console.log(rawData);
                    });

                    setLatestRetrievedForm(latestRetrievedForm[0])
                    setFormSubmissionID(latestRetrievedForm[0].formSubmissionID);
                    setFormRetrieved('saved');
                    setPreviousSubmissions({ ...previousSubmissions, ...previousSubmissionsServer })

                }
                else { //if not, pull previous completed 
                    getDocs(q).then(querySnapshot => {
                        if (querySnapshot.size > 0) {
                            querySnapshot.forEach((doc) => {
                                // doc.data() is never undefined for query doc snapshots
                                const rawData = doc.data();
                                console.log('Latest Form: ' + rawData.formSubmissionID)
                                if (rawData.createdAt) {
                                    const createdDate = rawData.createdAt.toDate().toDateString()
                                    const createdTime = rawData.createdAt.toDate().toLocaleTimeString('en-US');
                                    rawData.createdDateTime = `${createdDate}, ${createdTime}`
                                }

                                latestRetrievedForm.push(rawData)
                                if (!Object.keys(previousSubmissionsServer).includes(rawData.formID)) {
                                    previousSubmissionsServer[rawData.formID] = rawData;
                                }

                            });

                            setLatestRetrievedForm(latestRetrievedForm[0])
                            console.log('latestRetrievedForm', latestRetrievedForm[0]);
                            setFormRetrieved('completed');
                            setFormSubmissionID(latestRetrievedForm[0].formSubmissionID);
                            console.log('retrieved formSubmissionID', formSubmissionID)
                            setPreviousSubmissions({ ...previousSubmissions, ...previousSubmissionsServer })
                        }
                        else {
                            setFormRetrieved(false)
                        }

                    })
                }
            })
        }
    }, [formId, user, userData, formRetrieved]);
    useEffect(() => {

        if (formQuestions && formQuestions.length > 0) {
            let count = 0;
            formQuestions.forEach(q => {
                if (q['question_type'] in countableQuestionTypes) {
                    count += 1
                }
            })
            console.log("Number of Questions: ", count)
        }
    }, [formQuestions])
    useEffect(() => {
        console.log('satisfied', satisfied)
    }, [satisfied])
    const generatePDF = (formMetadata, formContent) => {
        // console.log('formMetadata', formMetadata);
        const margins = {
            top: 40,
            bottom: 60,
            left: 40,
            width: 522
        };
        const formTitle = formMetadata ? formMetadata.formTitle : 'Untitled_Form.pdf'
        const doc = new JsPDF('portrait', 'pt', 'a4');
        // doc.addFileToVFS("../Fixed Sources/standard-book-webfont.ttf", myFont);
        // doc.addFont("standard-book-webfont.ttf", "bookFont", "normal");
        doc.setFont("Segoe UI", 'normal');
        // doc.setFont('courier')
        console.log('getFontList', doc.getFontList());
        doc.setFontSize(8);
        // doc.setLineWidth("100");
        doc.setCharSpace('2');
        const opt = {
            callback: function (doc) {
                doc.save(formTitle);
                // to open the generated PDF in browser window
                // window.open(jsPdf.output('bloburl'));
            },
            margin: 20,
            // margin: [70, 70, 70, 70],

            autoPaging: 'text',
            html2canvas: {
                allowTaint: true,
                dpi: 300,
                letterRendering: true,
                logging: false,
                scale: .43,
            }
        };
        doc.html(document.querySelector('#thisform'), opt);
        // doc.html('<html style="font-family: NotoSerif-Black;">University of Missouri', opt);


    }

    useEffect(() => {

    }, [previousSubmissions])

    return (
        <div className="parent-element" style={{ padding: 10, backgroundColor: '#f5f6f7', borderRadius: 10 }}>
            <ReactTooltip backgroundColor={highlightColor} />
            {renderPage == true ? <main>
                <FormProvider {...methods}>
                    {!user && <h2>YOU ARE NOT LOGGED IN! Please Login to fill out the form!</h2>}
                    {/* <PrintExportComponent userData={userData} /> */}
                    <div className="no-print float-end mt-2" >
                        {/* <AiOutlineFilePdf onClick={() => { generatePDF(formMetadata) }} style={{ fontSize: 24 }} data-tip={'Export PDF'} /> */}
                        <AiOutlineFilePdf
                            onMouseEnter={() => setPdfHover(true)}
                            onMouseLeave={() => setPdfHover(false)}
                            onClick={() => {
                                exportPDF(formMetadata, thisform);
                            }}
                            style={{ fontSize: 35, color: pdfHover ? 'darkred' : 'red' }}
                            data-tip={'Export PDF'}
                        />

                        <AiOutlinePrinter
                            onMouseEnter={() => setPrinterHover(true)}
                            onMouseLeave={() => setPrinterHover(false)}
                            onClick={() => { window.print() }}
                            style={{ marginLeft: 5, fontSize: 39, color: printerHover ? 'blue' : '#87CEEB' }}
                            data-tip={'Print'}
                        />
                    </div>
                    {
                        // true
                        user
                            && formQuestions
                            && formMetadata
                            && userData
                            && latestRetrievedForm
                            ?
                            <form className='print_content' ref={thisform} id={'thisform'} style={{ letterSpacing: "0.01px" }}>

                                <div className="watermark"
                                    style={{
                                        fontSize: formQuestions.length > 10 ? '100px' : '50px'
                                    }}>
                                    READ-ONLY
                                </div>

                                {formQuestions.map(q => {
                                    // console.log('question', q);
                                    q.readonly = true;

                                    return <AutomaticQuestion
                                        q={q}
                                        formQuestions={formQuestions}
                                        latestRetrievedForm={latestRetrievedForm}
                                    />



                                })}

                            </form> : <h2>FORM DOES NOT EXIST OR YOU DON'T HAVE PERMISSION TO VIEW THIS FORM! <br></br>
                                <small>PLEASE CONTACT YOUR INSTITUTION ADMINISTRATOR FOR MORE INFORMATION! </small></h2>}
                </FormProvider>

            </main > : <main>Error: Domain-Institution do not match Form's requirements. Please double-check the system and try again.</main>
            }
        </div>)
}
export const exportPDF = (formMetadata, formRef) => {
    console.log('formMetadata', formMetadata, 'formRef', formRef);
    const margins = {
        top: 40,
        bottom: 60,
        left: 40,
        width: 522
    };
    const formTitle = formMetadata ? formMetadata.formTitle : 'Untitled_Form.pdf'
    const doc = new JsPDF('portrait', 'pt', 'a4');
    // doc.addFileToVFS("../Fixed Sources/standard-book-webfont.ttf", myFont);
    // doc.addFont("standard-book-webfont.ttf", "bookFont", "normal");
    doc.setFont("Segoe UI", 'normal');
    // doc.setFont('courier')
    console.log('getFontList', doc.getFontList());
    doc.setFontSize(8);
    // doc.setLineWidth("100");
    doc.setCharSpace('2');
    const opt = {
        callback: function (doc) {
            doc.save(formTitle);
            // to open the generated PDF in browser window
            // window.open(jsPdf.output('bloburl'));
        },
        margin: 20,
        // margin: [70, 70, 70, 70],

        autoPaging: 'text',
        html2canvas: {
            allowTaint: true,
            dpi: 300,
            letterRendering: true,
            logging: false,
            scale: .43,
        }
    };
    // doc.html(document.querySelector('#thisform'), opt);
    doc.html(formRef.current, opt);

};