import React from "react";
import { useFormContext } from "react-hook-form";
import ReactTooltip from 'react-tooltip';
import { AiFillQuestionCircle } from "react-icons/ai";
import theme from '../Theme/theme'
import "../Automatic Forms/automatic_form.css"
export const Select = ({
  name,
  label,
  className,
  labelClass,
  inputClass = "form-control",
  options,
  instructions,
  required,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const highlightColor = theme.highlightColor
  // console.log('errors', errors[name].message)

  return (
    <div className={className}>
      <ReactTooltip backgroundColor={highlightColor} />

      <label
        htmlFor={name}
        aria-label={label}
        className={`form-label ${labelClass} ${errors[name] ? 'text-danger' : ''}`}
      >
        {label} {required && "*"}
        {instructions && <AiFillQuestionCircle style={{ marginLeft: '2px', marginBottom: '3px', color: highlightColor, fontSize: '17px' }} data-tip={instructions} />}

      </label>
      {/* {`${inputClass} ${errors[name] ? "is-invalid" : ""}`} */}
      <select
        id={name}
        // className={`${inputClass} ${errors[name] ? "is-invalid" : ""}`}
        className={`form-select ${errors[name] ? 'is-invalid' : ''}`}
        {...register(name)}
        {...rest}
      >
        <option>Please select an option:</option>
        {/* <option>Alabama</option> */}

        {options.map((value) => (
          <option>
            {value}
          </option>
        ))}
      </select>
      <div className="form-label invalid-feedback">{errors[name]?.message}</div>

    </div>
  );
};
