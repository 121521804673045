import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { BarCharts } from './BarCharts';
import { LineChart } from './LineCharts';
import { db } from "../Firebase/firebasedb";
import { doc, setDoc, getDoc, orderBy, serverTimestamp, collection, query, where, onSnapshot, getDocs } from "firebase/firestore";
import { PieChart } from "./PieChart";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Input, RadioGroup, Textarea, Checkbox, CheckboxFollowup } from "../Components";
import { BivariableCharts } from "./BivariableCharts";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { CustomMenu, FormLibrary, RoleValidationComponent, generateRandomAnswersBulk, generateRandomNumber, notQuestionTypes, removeSpecialCharacters } from "../Automatic Forms/Utils";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { AiFillCloseCircle, AiFillQuestionCircle, AiFillSave } from "react-icons/ai";
import theme from "../Theme/theme";
import { colorFamily, getRandomColor } from "../Fixed Sources/colorScales";
import ReactTooltip from "react-tooltip";
import { NavBar } from "../Components/NavBar";
import { allRoles, isCampusLead, isHubLead, isPortalAdmin } from "../Fixed Sources/accountTypes";
import { BsClock, BsCloudCheck, BsCloudSlash, BsFillArchiveFill } from "react-icons/bs";
import { RadarChart } from "./RadarChart";
import { Line, Pie } from "react-chartjs-2";


export const ChartRenderingUser = ({ userData }) => {
    console.log('userData', userData)

    const [chartData, setChartData] = useState(null);
    const [currentSelectedChart, setCurrentSelectedChart] = useState("Bar Chart");
    const [currentColor, setCurrentColor] = useState(getRandomColor())
    const [chartTitle, setChartTitle] = useState(null);
    const [institutionsList, setInstitutionsList] = useState([]);
    const [currentAllowedRoles, setCurrentAllowedRoles] = useState([])
    const [currentAllowedInstitutions, setCurrentAllowedInstitutions] = useState([])
    const [currentExcludedInstitutions, setCurrentExcludedInstitutions] = useState([])
    const [requiredInstitutions, setRequiredInstitutions] = useState(null)

    const [currentEventKey, setCurrentEventKey] = useState(null)
    const [currentFormDomain, setCurrentFormDomain] = useState('Common')
    const [currentStatus, setCurrentStatus] = useState('unpublished')
    const [valid, setValid] = useState(false);
    const changeChartStatus = (newStatus) => {
        setCurrentStatus(newStatus); // Or however you update the chart's status in your state
        // Put any other code you need to execute when the status changes here
    }

    const { chartID } = useParams();

    useEffect(() => {

        const docRef2 = doc(db, "chart_library", chartID);

        const unsubscribe = onSnapshot(docRef2, (doc) => {
            if (doc.exists()) {
                console.log('docSnap.data()', doc.data())
                setChartData(doc.data());
                const remoteChartData = doc.data();
                if (remoteChartData) {
                    const { formDomain, excludedInstitutions, allowedInstitutions, allowedRoles, chartType, color, title, status } = remoteChartData;
                    if (chartType) {
                        setCurrentSelectedChart(chartType);
                    }
                    if (color) {
                        setCurrentColor(color)
                    }
                    if (title) {
                        setChartTitle(title)
                    }
                    if (status) {
                        setCurrentStatus(status);
                    }
                    if (formDomain) {
                        setCurrentFormDomain(formDomain)
                    }
                    if (allowedInstitutions) {
                        setCurrentAllowedInstitutions(allowedInstitutions)
                    }
                    if (excludedInstitutions) {
                        setCurrentExcludedInstitutions(excludedInstitutions)
                    }
                    if (allowedRoles) {
                        setCurrentAllowedRoles(allowedRoles);
                    }
                    // setValue('formDomain', formDomain);
                    // setValue('allowedInstitutions', allowedInstitutions);

                }
            } else {
                console.log("No such document!");
            }
        });

        // Clean up the onSnapshot listener when the component is unmounted
        return () => unsubscribe();
    }, [chartID, db]);
    useEffect(() => {
        if (currentFormDomain) {
            if (currentFormDomain == 'Common') {
                if (currentExcludedInstitutions.length > 0) {
                    let remainingInstitutions = institutionsList.filter(institution => !currentExcludedInstitutions.includes(institution));
                    setRequiredInstitutions([...remainingInstitutions]);
                }
                else {
                    setRequiredInstitutions(null);
                }
            }
            else if (currentFormDomain == 'Specific') {
                if (currentAllowedInstitutions.length > 0) {
                    setRequiredInstitutions([...currentAllowedInstitutions]);
                }

            }
        }
    }, [institutionsList, currentAllowedInstitutions, currentFormDomain, currentExcludedInstitutions])
    // useEffect(() => {

    // }, [chartData])
    // useEffect(() => {
    //     setValue('formDomain', currentFormDomain);
    // }, [currentFormDomain])
    // useEffect(() => {
    //     setValue('allowedRoles', currentAllowedRoles);
    // }, [currentAllowedRoles])
    // useEffect(() => {
    //     setValue('status', currentStatus);
    // }, [currentStatus])
    // useEffect(() => {
    //     setValue('allowedInstitutions', [...currentAllowedInstitutions]);
    // }, [currentAllowedInstitutions])
    // useEffect(() => {
    //     setValue('excludedInstitutions', [...currentExcludedInstitutions]);
    // }, [currentExcludedInstitutions])
    // useEffect(() => {
    //     if (chartTitle && chartData) {
    //         chartData.title = chartTitle;
    //         setChartData({ ...chartData });
    //         // setValue('chartTitle', chartTitle);
    //         // setValue('chartTitle', chartTitle)
    //     }
    // }, [chartTitle])
    // useEffect(() => {
    //     if (currentColor && chartData) {
    //         console.log('currentColor', currentColor, chartData)
    //         chartData.color = currentColor;
    //         setChartData({ ...chartData });
    //     }
    // }, [currentColor])

    if (!chartData) {
        return <div>Loading {chartID} - {chartTitle}...</div>;
    }

    return (
        <div>
            <br></br>
            <hr></hr>
            <ReactTooltip />

            <RoleValidationComponent
                // requiredRoles={['student']}
                requiredRoles={[...currentAllowedRoles, 'portal-admin']}
                requiredInstitutions={requiredInstitutions}
                redirect={false}
                setValidExternal={setValid} />

            {/* Your other publishing settings components... */}
            {/* <p>Valid: {valid ? "true" : "false"} Status: {currentStatus}</p> */}
            {/* <p>Valid: {valid ? "true" : "false"} Status: {currentStatus}</p>
            {userData && <div>
                {(isPortalAdmin(userData.atype) || userData.userID == chartData.createdBy) && <div>
                    {chartData && <details className='detail-summary' open>
                        <summary style={{ fontSize: 20 }}>Chart Preview</summary>
                        <RenderSelectedChart chartType={currentSelectedChart} chartData={chartData} />
                    </details>}
                </div>}

                {valid && currentStatus == 'published' ? <div>
                    {chartData && <details className='detail-summary' open>
                        <summary style={{ fontSize: 20 }}>Chart Preview</summary>
                        <RenderSelectedChart chartType={currentSelectedChart} chartData={chartData} />
                    </details>}
                </div> : <>
                    <div>
                        <h3>Chart is not available or you do not have permission to view this chart.</h3>
                    </div>
                </>}
            </div>} */}

            {userData && (
                <ChartPreview
                    userData={userData}
                    chartData={chartData}
                    currentStatus={currentStatus}
                    currentSelectedChart={currentSelectedChart}
                    valid={valid}
                />
            )}
        </div >
    );
};




const ChartPreview = ({ userData, chartData, currentStatus, currentSelectedChart, valid }) => {
    const isAllowedToViewOwner = isPortalAdmin(userData.atype) || userData.userID === chartData.createdBy;
    if (isAllowedToViewOwner) {
        return <div>
            {currentStatus === 'unpublished' && <h3 className='text-center text-danger'>This chart is currently {currentStatus}, but you have permission to view and edit it.</h3>}
            {currentStatus === 'published' && <h3 className='text-center text-success'>This chart is currently {currentStatus} and you have permission to view and edit it.</h3>}
            {currentStatus === 'awaiting-approval' &&
                <h3 className='text-center text-warning'>This chart is currently {currentStatus.replace('-', ' ')}, but you have permission to view and edit it.</h3>}
            {currentStatus === 'archived' &&
                <h3 className='text-center text-secondary'>This chart is currently {currentStatus}, but you have permission to view and edit it.</h3>}

            <details className='detail-summary' open>
                <summary style={{ fontSize: 20 }}>Chart Preview</summary>

                <RenderSelectedChart chartType={currentSelectedChart} chartData={chartData} />
            </details>
        </div>

    }
    const isAllowedToView = valid && currentStatus === 'published'
    if (isAllowedToView) {
        return <div>
            <details className='detail-summary' open>
                <summary style={{ fontSize: 20 }}>Chart Preview </summary>
                <RenderSelectedChart chartType={currentSelectedChart} chartData={chartData} />
            </details>
        </div>


    }

    if (!valid || !chartData || (currentStatus === 'unpublished' && !isAllowedToView)) {
        return (
            <div>
                <h3>Chart is not available or you do not have permission to view this chart.</h3>
            </div>
        );
    }



    return null;
};

const ChartWithDescription = ({ description, ChartComponent, chartData }) => {
    return (
        <>
            <p className='text-center'>Description: <i>{description}</i></p>
            <ChartComponent chartData={chartData} />
        </>
    );
};

export const RenderSelectedChart = ({ chartType, chartData }) => {
    const { description = '' } = chartData;

    const chartMappings = {
        'Pie Chart': PieChart,
        'Bar Chart': BarCharts,
        'Line Chart': LineChart,
        'Radar Chart': RadarChart
    };

    const SelectedChartComponent = chartMappings[chartType];

    if (SelectedChartComponent) {
        return <ChartWithDescription description={description} ChartComponent={SelectedChartComponent} chartData={chartData} />;
    }

    return <></>;
};