import React, { useEffect } from 'react';
import { createSpeechlySpeechRecognition } from '@speechly/speech-recognition-polyfill';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { ChatIcon, CloseIcon, SubmitIcon, MicIcon } from "./icons";
import {
  SubmitButton,
} from "./components";
import wordsToNumbers from "words-to-numbers";
const { search } = require("fast-fuzzy");
const appId = '784ad18a-9991-446d-9909-b91a4665dba6';
const SpeechlySpeechRecognition = createSpeechlySpeechRecognition(appId);
SpeechRecognition.applyPolyfill(SpeechlySpeechRecognition);
const Dictaphone = ({ setInputValue }) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  function getNumericMonth(monthAbbr) {
    return (String(monthNames.indexOf(monthAbbr) + 1))
    //.padStart(2, '0'))
  }
  const Word2Number = (utterance) => {
    let [month, ...rest] = utterance.split(" ");
    let monthMatch = search(month, monthNames)[0];
    let numericMonth = getNumericMonth(monthMatch)
    // console.log(monthMatch)
    // console.log(numericMonth)

    rest = wordsToNumbers(rest.join(" "), { fuzzy: true });
    // console.log(rest)
    let [day, ...year] = rest.split(" ");
    year = year.join("");
    const returnedString = `${numericMonth}/${day}/${year}`
    // return [month, day, year].join(" ");
    return returnedString;
  };
  console.log(Word2Number("Heard of hearing"))
  // console.log(Word2Number("janury first nineteen ninety nine"))
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const startListening = () => SpeechRecognition.startListening({ interimResults: true, continuous: true });

  const onTouchEnd = () => {
    SpeechRecognition.stopListening();
    setInputValue({
      inputValue: transcript.toLowerCase(),
      speaking: false
    });


  }
  const onTouchStart = () => {
    resetTranscript();
    startListening();
    setInputValue({
      speaking: true
    });

  }
  const onClick = () => {
    if (listening) {
      onTouchEnd();
      console.log(SpeechRecognition.interimResults);

    }
    else {
      onTouchStart();
    }

  }

  useEffect(() => {
    // let temp = transcript.toString().split(/[\s-]+/);
    // let temp = 'two-thousand'.split(/[\s-]+/);
    // console.log(temp)
    // let convertedString = ''
    // temp.forEach((w) => {
    //   let convertedNum = text2num('thirty first');
    //   console.log(convertedNum)
    //   if (convertedNum == '') {
    //     convertedString +=` ${w}`
    //   }
    //   else {
    //     convertedString += ` ${convertedNum.toString()}`
    //   }
    // })
    // console.log(convertedString)
    if (listening) {
      setInputValue({
        inputValue: transcript.toLowerCase()
      });
    }
  }, [listening, transcript])
  // <p>Microphone: {listening ? 'on' : 'off'} <MicIcon/></p>

  return (

    <SubmitButton
      // className="rsc-submit-button"
      // style={submitButtonStyle}
      onClick={onClick}
      // invalid={inputInvalid}
      // disabled={disabled}
      speaking={listening}
    >
      <MicIcon />
      <p style={{ padding: 0, margin: 0 }}><i style={listening ? { color: 'red' } : { color: 'purple' }}>
        Mic: {listening ? 'on' : 'off'} </i></p>
    </SubmitButton>
  );
};
export default Dictaphone;