import {
  Navbar,
  // Nav,
  // NavDropdown,
  Container,
  // Form,
  // FormControl,
  // Button,
} from "react-bootstrap";
import soar from "../img/soar.png";

const Header = () => {
  return (
    <Navbar bg="light" expand="llg" className="py-0 fs-6">
      <Container fluid>
        <Navbar.Brand href="/" className="fs-6">
          <img
            /* src="https://tapdintostem.org/wp-content/uploads/2021/12/12142021TAPDINTOSTEM-LOGO-Low-Res-336799.jpg" */
            src={soar}
            width="60"
            height="30"
            className="d-inline-block align-top"
            alt="SOAR logo"
          />
          Surmounting Obstacles for Academic Resilience
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Header;
