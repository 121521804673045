import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import FormCreate from "./FormCreate";
import { NavBar } from "../Components/NavBar";
import { RoleValidationComponent } from "./Utils";
import FormRenderingReadOnly from "./FormRenderingReadOnly";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { useEffect, useState } from "react";

const RenderComponent = ({ formID, action, userData = null }) => {
  const analytics = getAnalytics();

  useEffect(() => {
    if (formID && formID !== '' && userData) {
      const { atype, userID, institution } = userData;
      setUserId(analytics, userID);

      if (formID.toLowerCase() === 'create') {
        logEvent(analytics, 'form_create', { timeStamp: new Date(), userID, atype, institution });
      }
      else if (action) {
        if (action.toLowerCase() === 'edit') {
          logEvent(analytics, 'form_edit', { timeStamp: new Date(), formID, userID, atype, institution });
        }
        else if (action.toLowerCase() === 'readonly') {
          document.title = "SOAR - Form Read-only";
          logEvent(analytics, 'form_readonly', { timeStamp: new Date(), formID, userID, atype, institution });
        }
      } else {
        document.title = "SOAR - Form Rendering";
        logEvent(analytics, 'form_rendering', { timeStamp: new Date(), formID, userID, atype, institution });
      }
    }
  }, [formID, action, userData]); // Dependencies

  // Rendering logic
  if (formID && formID !== '' && userData) {
    if (formID.toLowerCase() === 'create') {
      return <FormCreate formAction='create' />
    }
    else if (action) {
      if (action.toLowerCase() === 'edit') {
        return <FormCreate formAction='edit' editFormID={formID} />
      }
      if (action.toLowerCase() === 'readonly') {
        return <FormRenderingReadOnly />
      }
    } else {
      return <Outlet />
    }
  } else {
    return <></>
  }
}

const Forms = () => {
  const { formId, action } = useParams();
  console.log(formId, action);
  const analytics = getAnalytics();
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    if (userData) {
      const { atype, userID, institution } = userData
      console.log('userData', userData)
      setUserId(analytics, userID);
    }
  }, [userData])
  return (
    <>
      {/* <h2>All Forms</h2> */}
      <NavBar setUserDataExternal={setUserData} />
      <RenderComponent formID={formId} action={action} userData={userData} />
      {/* {formId && formId.toLowerCase() == 'create' ? <FormCreate /> : <Outlet />} */}
    </>
  );
};
export default Forms;