import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { BarCharts } from './BarCharts';
import { LineChart } from './LineCharts';
import { db } from "../Firebase/firebasedb";
import { doc, setDoc, getDoc, orderBy, serverTimestamp, collection, query, where, onSnapshot } from "firebase/firestore";
import { PieChart } from "./PieChart";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Input, RadioGroup, Textarea, Checkbox, CheckboxFollowup } from "../Components";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { saveAs } from "file-saver";
import { Col, DropdownButton, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { getRGB, makeid, random_rgb, random_rgba } from "../Automatic Forms/Utils";
import { RadarChart } from "./RadarChart";
import { BsArrowCounterclockwise } from 'react-icons/bs'
import theme from "../Theme/theme";
import MapChart from "./MapChart";
import "./automatic_chart.css"
import Modal from 'react-bootstrap/Modal';
import { colorFamily, colorScales, getRandomColor } from "../Fixed Sources/colorScales.js";
import { AiFillEye, AiFillSave } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import ReactTooltip from "react-tooltip";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-info"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
const CustomToggle2 = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-warning"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
const CustomToggleChartSelection = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-secondary"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().includes(value),
                    )}
                </ul>
            </div>
        );
    },
);

export function RandomDefaultCharts({
    latestAnswerDict,
    rawData,
    formData,
    requestedChartType = null,
    handleShowStatusModal = null }) {
    //Drop-down
    // console.log('json', JSON.stringify(latestAnswerDict, null, 2));
    // console.log('json', JSON.stringify(formData, null, 2));

    const chartRef = useRef(null);
    const [chartID] = useState(makeid(6));
    const excludedKeys = ['formID', 'formSubmissionID', 'createdAt', 'userID']
    const {
        register,
        watch,
        formState: { errors },
        getValues,
    } = useFormContext();

    const stacked = watch(`${chartID}-stacked`)
    console.log('stacked', stacked);
    console.log(getValues())
    useEffect(() => {
        console.log('chartRef', chartRef);
        if (chartRef.current) {

        }
    }, [chartRef])

    const [chartTitle, setChartTitle] = useState(null);
    let formDict = {}
    if (formData) {
        formDict = formData.form_questions.reduce((acc, question) => {
            acc[question.questionID] = question.question_text;
            return acc;
        }, {});
    }

    const [chartDimension, setChartDimension] = useState({
        width: 500,
        height: 500
    });

    const [currentEventKey, setCurrentEventKey] = useState(null)
    const [currentEventKey2, setCurrentEventKey2] = useState(null)
    const [randomColor, setRandomColor] = useState(getRGB(getRandomColor()))
    const numberOfRandomColors = 10;

    let initialColors = JSON.parse(JSON.stringify(colorFamily));
    initialColors = initialColors.map(getRGB);
    [...Array(numberOfRandomColors)].forEach((e, i) => {
        const random_color = random_rgb();
        initialColors.push(random_color)
    });
    const [randomColors, setRandomColors] = useState(initialColors);
    const [randomPalette, setRandomPallete] = useState(colorScales[Math.floor(Math.random() * colorScales.length)])
    useEffect(() => {
        // console.log('randomColors: ', randomColors)
    }, [randomColors])

    const [allVariables, setAllVariables] = useState([]);
    const [refreshClick, setRefreshClick] = useState(0);
    const { formId } = useParams();
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Bi-Variable Chart',
            },
        },
    })

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);
    useEffect(() => {
        console.log('show', show);
    }, [show])
    const handleParentClick = (e) => {
        console.log('parent');
    }

    const handleChildClick = (e) => {
        e.stopPropagation();
        console.log('child');
    }

    useEffect(() => {
        if (chartTitle) {
            // console.log(chartData)
            setChartData({
                ...chartData,
                title: chartTitle,
                chartType: currentSelectedChart ? currentSelectedChart : 'N/A',
                // labels: Object.keys(aggregatedData),
                // values: Object.values(aggregatedData)
            })
            setChartOptions({
                ...chartOptions, plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: chartTitle,
                    },
                },
            })
        }
    }, [chartTitle])
    useEffect(() => {
        if (chartDimension) {
            // console.log(chartData)
            setChartData({
                ...chartData,
                dimension: chartDimension,
            })
        }
    }, [chartDimension])
    useEffect(() => {
        if (chartData) {
            console.log('chartData', chartData)
        }
    }, [chartData])
    useEffect(() => {
        if (allVariables.length > 0) {
            // console.log(allVariables)
            if (currentEventKey == null) {
                let [primaryVar, chartType] = SelectRandomVariableAndChart(allVariables);
                setCurrentEventKey(primaryVar);
                setCurrentSelectedChart(chartType);

            }
        }
    }, [allVariables])
    useEffect(() => {
        console.log('refreshClick', refreshClick);
        if (allVariables.length > 0) {
            // console.log(allVariables)

            let [primaryVar, chartType] = SelectRandomVariableAndChart(allVariables);
            console.log('randomVar', primaryVar)
            console.log('randomChartType', chartType)

            setCurrentEventKey(primaryVar);
            setCurrentSelectedChart(chartType);


        }
    }, [refreshClick, allVariables])
    const SelectRandomVariableAndChart = (allVariables) => {
        const potentialMapKeys = ['State']
        if (allVariables.length > 0) {
            let mapKeys = []
            allVariables.forEach(variable => {
                if (potentialMapKeys.includes(variable)) {
                    mapKeys.push(variable);
                }
            })
            let randomNum = Math.floor(Math.random() * 100);
            if (requestedChartType != null) {
                if (requestedChartType == 'Map Chart') {
                    if (mapKeys.length > 0) {
                        const randomVar = mapKeys[Math.floor(Math.random() * mapKeys.length)];
                        return [randomVar, requestedChartType]
                    }
                    else {
                        return [null, null]
                    }

                }
                else {
                    let randomVar = allVariables[Math.floor(Math.random() * allVariables.length)];
                    while (potentialMapKeys.includes(randomVar)) {
                        randomVar = allVariables[Math.floor(Math.random() * allVariables.length)];
                    }
                    // return [randomVar, 'Line Chart']
                    // return [randomVar, 'Bar Chart']

                    return [randomVar, requestedChartType]
                }
            }
            else {
                if (randomNum < 20) {
                    const randomVar = mapKeys[Math.floor(Math.random() * mapKeys.length)];
                    return [randomVar, 'Map Chart']
                }
                else {
                    const randomVar = allVariables[Math.floor(Math.random() * allVariables.length)];
                    const randomChart = supportedCharts[Math.floor(Math.random() * supportedCharts.length)];
                    if (potentialMapKeys.includes(randomVar)) {
                        return [randomVar, 'Map Chart']
                    }
                    // return [randomVar, 'Line Chart']
                    // return [randomVar, 'Bar Chart']

                    return [randomVar, randomChart]
                }
            }


            // return ['State', 'Map Chart']

        }
    }
    useEffect(() => {
        if (currentEventKey && latestAnswerDict) {
            console.log(currentEventKey, currentEventKey2)

            if (currentEventKey != currentEventKey2 && currentEventKey2 != null && currentEventKey2.toLowerCase() != 'null') {
                // console.log(rawData);
                let allSecondaryValues = new Set(latestAnswerDict[currentEventKey2])
                allSecondaryValues = Array.from(allSecondaryValues)
                let uniquePrimaryXValues = new Set(latestAnswerDict[currentEventKey])
                const aggregateDict = {}
                allSecondaryValues.forEach(val => {
                    aggregateDict[val] = {}
                    uniquePrimaryXValues.forEach(prim_val => {
                        aggregateDict[val][prim_val] = 0;
                    })
                })

                if (rawData && rawData.length > 0) {
                    rawData.forEach(data => {
                        let primaryKeyValue = data[currentEventKey]
                        let secondaryKeyValue = data[currentEventKey2]

                        if (typeof primaryKeyValue === 'object') { //first key is checkbox answer
                            for (const [eachKey, eachValue] of Object.entries(primaryKeyValue)) {
                                if (typeof secondaryKeyValue === 'object') {
                                    for (const [eachKey2, eachValue2] of Object.entries(secondaryKeyValue)) {
                                        if (typeof eachValue2 === 'string' && typeof eachValue === 'string')
                                            aggregateDict[eachValue2][eachValue] += 1 //both are answers to checkboxes
                                    }
                                }
                                else {
                                    if (typeof secondaryKeyValue === 'string' && typeof eachValue === 'string')
                                        aggregateDict[secondaryKeyValue][eachValue] += 1 //just the primary key is checkbox

                                }
                            }
                        }
                        else if (typeof primaryKeyValue === 'string') { //first key is a normal answer
                            if (typeof secondaryKeyValue === 'object') { //second key is checkbox answer
                                for (const [eachKey2, eachValue2] of Object.entries(secondaryKeyValue)) {
                                    // console.log('eachValue2', eachValue2)
                                    if (typeof eachValue2 === 'string')
                                        aggregateDict[eachValue2][primaryKeyValue] += 1 //both are answers to checkboxes
                                }
                            }
                            else { //second key is normal answer
                                if (aggregateDict[secondaryKeyValue])
                                    aggregateDict[secondaryKeyValue][primaryKeyValue] += 1
                                else {

                                    aggregateDict[secondaryKeyValue] = { primaryKeyValue: 0 }
                                }
                            }
                        }
                        else {
                            console.log('not supported', primaryKeyValue)
                        }
                    })

                }
                console.log(aggregateDict)
                const backgroundColors = [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'limegreen', 'pink'
                ]
                let datasets = []
                const labels = Array.from(uniquePrimaryXValues)
                allSecondaryValues.forEach((label, index) => {
                    datasets.push({
                        label: label,
                        data: Object.values(aggregateDict[label]),
                        backgroundColor: backgroundColors[index % backgroundColors.length]
                    })
                })

                const chartData = {
                    labels,
                    datasets: datasets
                };
                setChartData({
                    ...chartData,
                    title: `${currentEventKey} by ${currentEventKey2}`,
                    data: chartData,
                    dimension: chartDimension
                })
                setChartOptions({
                    ...chartOptions, plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: `${currentEventKey} by ${currentEventKey2}`,
                        },
                    },
                })
                console.log(datasets)

            }
            else {
                // console.log('hereee', latestAnswerDict)

                const mainKey = currentEventKey

                let aggregatedData = {}
                const uniqueValues = new Set(latestAnswerDict[mainKey])
                uniqueValues.forEach(val => {
                    aggregatedData[val] = 0

                })
                console.log('mainKey', mainKey, formDict[mainKey])
                // console.log('latestAnswerDict', latestAnswerDict)
                if (latestAnswerDict[mainKey]) {
                    latestAnswerDict[mainKey].forEach(val => {
                        aggregatedData[val] += 1
                    })
                }
                // console.log('aggregatedData', aggregatedData)

                setChartData({
                    title: formDict[mainKey] ? formDict[mainKey] : mainKey,
                    data: aggregatedData,
                })

            }
            // console.log('here')
            // const mainKey = currentEventKey

            // let aggregatedData = {}
            // const uniqueValues = new Set(latestAnswerDict[mainKey])
            // uniqueValues.forEach(val => {
            //     aggregatedData[val] = 0

            // })
            // latestAnswerDict[mainKey].forEach(val => {
            //     aggregatedData[val] += 1
            // })

            // setChartData({
            //     title: mainKey,
            //     data: aggregatedData,
            // })


        }

    }, [currentEventKey, currentEventKey2, latestAnswerDict])
    useEffect(() => {
        if (latestAnswerDict) {
            console.log("latestAnswerDict", latestAnswerDict);
            let allKeys = Object.keys(latestAnswerDict);
            allKeys.sort();
            setAllVariables(allKeys)
        }
    }, [latestAnswerDict])



    const supportedCharts = ['Bar Chart', 'Pie Chart', 'Line Chart', 'Radar Chart']
    const supportedChartsDouble = ['Bar Chart']

    const [currentSelectedChart, setCurrentSelectedChart] = useState(supportedCharts[0])
    useEffect(() => {
        if (!supportedChartsDouble.includes(currentSelectedChart)) {
            setCurrentEventKey2(null) //reset secondary key if chart doesn't support 2 dimensions
        }
    }, [currentSelectedChart])
    const saveCanvas = () => {
        //save to png
        const canvasSave = document.getElementById('mainChart');
        canvasSave.toBlob(function (blob) {
            saveAs(blob, "testing.png")
        })
    }
    const RenderSelectedChart = ({ chartType, chartData, chartRef = null, latestAnswerDict }) => {
        chartData.chartType = chartType
        if (chartType == 'Pie Chart') {
            chartData.color = randomColors;

            return <PieChart chartData={chartData} chartRef={chartRef} />
        }
        else if (chartType == 'Bar Chart') {
            chartData.color = randomColor;
            console.log('randomColor (Bar chart)', randomColor)
            return <BarCharts chartData={chartData} chartRef={chartRef} />
        }
        else if (chartType == 'Line Chart') {
            chartData.color = randomColor;

            return <LineChart chartData={chartData} chartRef={chartRef} />
        }
        else if (chartType == 'Radar Chart') {
            chartData.color = randomColor;

            return <RadarChart chartData={chartData} chartRef={chartRef} />
        }
        else if (chartType == 'Map Chart') {
            // chartData.color = randomColor;

            return <MapChart latestAnswerDict={latestAnswerDict} colorPallete={randomPalette} />
        }
        return <></>
    }
    const [saveSuccessfully, setSaveSuccessfully] = useState(false)
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [saveSuccessfully]);
    const writeToFirebase = (chartData) => {
        const docRef = doc(db, "chart_library", chartID);
        let passed = true;
        let { title, description } = chartData;
        if (!title || title == '') {
            title = 'Untitled Chart'
        }
        if (!description || description == '') {
            description = ''
        }
        if (passed) {
            // clearErrors(`chartTitle-${chartID}`)
            // clearErrors(`chartDescription-${chartID}`)
            let finalChartData = {
                ...chartData,
                chartID,
                title, description,
                status: 'unpublished',
                chartType: currentSelectedChart ? currentSelectedChart : 'N/A'
            }
            console.log('saving...', finalChartData);
            setSaveSuccessfully(true);

            setDoc(docRef, finalChartData).then(() => {
                setSaveSuccessfully(true);
                // alert(`${chartID} written to Firebase successfully. Please click Edit Chart's Publishing Settings to publish the chart.`)
                if (handleShowStatusModal) {
                    handleShowStatusModal(`Chart ${chartID} written to Firebase successfully. 
                    Please click Edit Chart's Publishing Settings to publish the chart.`)
                }
            });
        }

    }

    // const allVariables = ['Red', 'Blue', 'Orange', 'Red-Orange']
    const RandomChartsRendering = ({ primaryVariable, secondaryVariable, chartData, chartRef, currentSelectedChart, latestAnswerDict }) => {
        console.log('primaryVariable', primaryVariable)
        console.log('currentSelectedChart', currentSelectedChart)

        // console.log('hello')
        if (chartData) {
            if (primaryVariable) {

                if (secondaryVariable && secondaryVariable != primaryVariable) {
                    if (secondaryVariable.toLowerCase() != 'null') {
                        console.log(chartData.data)
                        if (currentSelectedChart == 'Bar Chart') {
                            let localChartOptions = chartOptions;

                            if (stacked) {
                                localChartOptions = {
                                    ...chartOptions, scales: {
                                        x: {
                                            stacked: true,
                                        },
                                        y: {
                                            stacked: true,
                                        },
                                    },
                                }
                            }
                            else {
                                localChartOptions = {
                                    ...chartOptions, scales: {
                                        x: {
                                            stacked: false,
                                        },
                                        y: {
                                            stacked: false,
                                        },
                                    },
                                }
                            }
                            return <div className='col-md-12'>
                                {/* <Line options={chartOptions} data={chartData.data}
                                height="400px"
                                width="500px"
                                ref = {chartRef}
                                id = 'mainChart'
                            /> */}

                                <Bar options={localChartOptions} data={chartData.data}
                                    height="400px"
                                    width="500px"
                                    ref={chartRef}
                                    id='mainChart'
                                />
                            </div>
                        }

                    }
                }
                else {
                    console.log('Single Variable Only!')
                    console.log('primaryVariable', primaryVariable)

                    return <>
                        <Row>
                            <h4 className='text-center'>Chart ID: {chartID}</h4>
                        </Row>
                        <RenderSelectedChart
                            chartType={currentSelectedChart}
                            chartData={chartData}
                            // chartRef={chartRef}
                            latestAnswerDict={latestAnswerDict} />
                    </>
                }
            }

        }
        return <></>

    }
    const MemoizedRandomChartsRendering = React.memo(RandomChartsRendering);
    return <div>
        <ReactTooltip />

        <Row className='mt-3'>
            <Col md='2'>
                <Button style={{ margin: 10 }} variant='outline-theme' size='sm'
                    // style={{ float: 'right' }}
                    onClick={(e) => {
                        handleChildClick(e);
                        setRefreshClick(refreshClick + 1);
                    }}>
                    <BsArrowCounterclockwise style={{
                        marginRight: '3px', color: theme.highlightColor,
                        fontSize: '14px'
                    }} />
                    Refresh

                </Button>
            </Col>
            <Col md='8'>

            </Col>
            <Col md='2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex' }}>
                    {/* {saveSuccessfully ? 'block' : 'hidden'} */}
                    <Link style={{ display: saveSuccessfully ? 'block' : 'none', textDecoration: 'none', color: 'black' }} to={`/charts/${chartID}`} target="_blank" rel="noopener noreferrer">
                        <AiFillEye style={{ marginTop: 0, fontSize: 32, cursor: 'pointer', color: '#474d4a' }} data-tip="See real-time chart rendering" />
                    </Link>


                    <Link style={{ display: saveSuccessfully ? 'block' : 'none', textDecoration: 'none', color: 'black' }} to={`/charts/${chartID}/edit`} target="_blank" rel="noopener noreferrer">
                        <FaEdit style={{ fontSize: 28, cursor: 'pointer', marginBottom: 6, color: '#474d4a', marginLeft: 7 }} data-tip="Edit chart's publishing settings" />
                    </Link>

                    <AiFillSave style={{ color: '#474d4a', fontSize: 29, cursor: 'pointer', marginLeft: 3, marginTop: 1, marginRight: 10 }} onClick={() => { writeToFirebase(chartData); }} data-tip='Save chart to database' />
                </div>
            </Col>

        </Row>
        <div className='chart-container'
            style={{ padding: 5 }}
            onClick={handleShow}>


            <MemoizedRandomChartsRendering
                primaryVariable={currentEventKey}
                secondaryVariable={currentEventKey2}
                chartData={chartData}
                // chartRef={chartRef}
                currentSelectedChart={currentSelectedChart}
                latestAnswerDict={latestAnswerDict}
            />


        </div>
        <Modal show={show} onHide={handleClose}
            fullscreen={true}
            // dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title>Data Visualization - {requestedChartType}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MemoizedRandomChartsRendering
                    primaryVariable={currentEventKey}
                    secondaryVariable={currentEventKey2}
                    chartData={chartData}
                    // chartRef={chartRef}
                    currentSelectedChart={currentSelectedChart}
                    latestAnswerDict={latestAnswerDict}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    handleClose();
                }}>
                    Close
                </Button>
                {/* <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button> */}
            </Modal.Footer>
        </Modal>
    </div>
}
