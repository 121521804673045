
import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import Form from 'react-bootstrap/Form';
import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox, Input, RadioGroup, Select } from "../Components";
import { db } from "../Firebase/firebasedb";
import { doc, setDoc, orderBy, serverTimestamp, collection, query, where, onSnapshot, getDocs } from "firebase/firestore";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import ReactTooltip from 'react-tooltip';
import { AiOutlineRead, AiFillQuestionCircle, AiFillCloseCircle, AiFillFastForward, AiFillFastBackward } from "react-icons/ai";
import theme from '../Theme/theme'
import "./automatic_form.css"
import { institutionsList } from "../Fixed Sources/institutions";
import { CustomMenu, containsSpecialCharacters, countWords, countableQuestionTypes, encryptableQuestionTypes, getAcronym, getFirstTwoWords, getInsideParentheses, isKeywordApplicable, makeid, notQuestionTypes, processID, processKeywords, removeSpecialCharacters } from "./Utils";
import DragNDropList from "./DragNDropList";
import { allQuestionTypes, RoleValidationComponent } from './Utils'
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import { allRoles, formPrefixes, formPrefixes2, isCampusLead, isHubLead } from "../Fixed Sources/accountTypes";
import { format } from "date-fns";
import Card from 'react-bootstrap/Card';
import { SpinningCircles, Bars } from 'react-loading-icons'
import isEqual from 'lodash/isEqual';
const FormOrderTracker = ({ formID, formLongName }) => {
    // const [allFormOrders, setAllFormOrders] = useState([]);
    const [allFormOrders, setAllFormOrders] = useState({});
    const targetFormID = formID;
    const docNames = ["master_form_order", "faculty_form_order", "campuslead_form_order", "hublead_form_order"];
    const sequenceNames = ['Student', 'Faculty', 'Campus Lead', 'Hub Lead']

    useEffect(() => {
        const unsubscribes = docNames.map((docName, index) => {
            const docRef = doc(db, 'automatic_form_order', docName);
            return onSnapshot(docRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const formOrders = docSnapshot.data().formOrder;
                    formOrders.forEach(formOrder => {
                        if (formOrder.id === targetFormID) {
                            setAllFormOrders(prevOrders => ({
                                ...prevOrders,
                                [docName]: { docIndex: index, formOrders }
                            }));
                        }
                    });
                } else {
                    console.log(`No document found with name '${docName}'`);
                }
            }, (error) => {
                console.log(`Error fetching document '${docName}':`, error);
            });
        });
        return () => unsubscribes.forEach(unsubscribe => unsubscribe());
    }, []);
    const allFormOrdersArray = Object.values(allFormOrders);
    return (
        <details open>
            <summary style={{ fontSize: 20, fontWeight: 'bold' }}>
                Form Sequences
            </summary>

            <div>
                {allFormOrdersArray.map(({ docIndex, formOrders }, index) => (
                    <div key={index}>
                        <Card style={{ border: 'solid 2px #ddd', borderRadius: '20', marginBottom: '10px' }}>
                            <Card.Body>
                                <Card.Title>Target role: {sequenceNames[docIndex]}</Card.Title>
                                {formOrders.map((formOrder, formIndex) => {
                                    const style = {
                                        paddingLeft: `${formIndex * 10}px`,  // Increase the left padding based on the form index
                                    };

                                    if (formOrder.id == formID) {
                                        return (
                                            <Card.Text key={formIndex} style={style}>
                                                <b>⎣ {formLongName} (This Form)</b>
                                                <Link style={{ margin: 5 }} to={`/forms/${formOrder.id}`} target="_blank" rel="noopener noreferrer">
                                                    View
                                                </Link>
                                                <span className="disabled-link">Edit</span>
                                            </Card.Text>
                                        );
                                    }
                                    return (
                                        <Card.Text key={formIndex} style={style}>
                                            ⎣ {formOrder.content}
                                            <Link style={{ margin: 5 }} to={`/forms/${formOrder.id}`} target="_blank" rel="noopener noreferrer">
                                                View
                                            </Link>
                                            <Link to={`/forms/${formOrder.id}/edit`} target="_blank" rel="noopener noreferrer">
                                                Edit
                                            </Link>
                                        </Card.Text>
                                    );
                                })}
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </details>
    );
};
export default function FormCreate({ formAction, editFormID }) {
    const todayDate = new Date()
    const tomorrowDate = new Date(todayDate);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1)
    const [renderPage, setRenderPage] = useState(true);
    const [isBlinking, setIsBlinking] = useState(false);
    const [lastUpdatedDateString, setLastUpdatedDateString] = useState(null);


    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);

    // console.log('Over 10 years ', getAcronym('Over 10 years '))
    const [institutionsList, setInstitutionsList] = useState([]);
    const [keywordDict, setKeywordDict] = useState({});
    const [currentKeyWordDict, setCurrentKeywordDict] = useState({});


    // Your Cloud Function's URL
    const functionUrl = 'https://extractkeywords-lzlb5fvlsa-uc.a.run.app/';

    // The text you want to analyze
    const text = 'Years of experience with students with disabilities';
    const mobileDocRef = doc(db, 'information_sources', 'mobile_keywords');

    useEffect(() => {
        const docRef = doc(db, "information_sources", 'institutions');
        const unsub1 = onSnapshot(docRef, (docSnap) => {
            if (docSnap.exists()) {
                const data = docSnap.data();
                setInstitutionsList(data['institution_list'])
            } else {

            }
        });


        const unsub2 = onSnapshot(mobileDocRef, (docSnap) => {
            if (docSnap.exists()) {
                const data = docSnap.data();
                setKeywordDict({ ...data });
            } else {

            }
        });
        return () => { unsub1(); unsub2(); }
    }, [])
    const [loading, setLoading] = useState(true);
    async function checkKeywordsAndSave(text, data) {
        let keywords = processKeywords(text).split(' ');
        const detectedAcronyms = getInsideParentheses(text)
        if (detectedAcronyms != '') {
            // console.log('detectedAcronyms', detectedAcronyms)
            if (countWords(detectedAcronyms) <= 3) {
                return [detectedAcronyms];
            }
        }

        if (countWords(text) > 3) {
            // Check if the keywords for the text already exist
            const processedText = processID(text);
            if (data && data[processedText]) {
                console.log('Keywords already exist!', text, data[processedText]);
                if (data[processedText] && data[processedText].length > 0)
                    return data[processedText];
            }
            console.log('calling URL', text);
            const firstTwoWords = getFirstTwoWords(text)
            // console.log('firstTwoWords', firstTwoWords)
            keywords = ['Wait for URI', ...firstTwoWords]

            // If the keywords do not exist, fetch them

            const URI = `${functionUrl}?text=${encodeURIComponent(text)}`
            // console.log('URI', URI)
            const response = await fetch(URI);
            keywords = await response.json();

            if (keywords.length == 0) {
                keywords = getFirstTwoWords(text);
            }

            // Add the keywords to the data
            if (!data[processedText] || data[processedText] != keywords) {
                data[processedText] = keywords;
                // Save the data to Firestore
                setDoc(mobileDocRef, data, { merge: true });
                console.log('write keyword to firestore');
            }


        }

        return keywords;
    }

    let newFormID = makeid(8)
    if (formAction == 'edit') {
        newFormID = editFormID
    }

    const [formURL, setFormURL] = useState(formAction == 'edit' ? `/forms/${editFormID}` : null)
    useEffect(() => {
        if (formAction == 'create') {
            document.title = "SOAR - Form Creation";
        }
        else if (formAction == 'edit') {
            document.title = "SOAR - Form Edit";
        }
    }, []); // Empty dependency array ensures this effect runs once when the component mounts
    const [formData, setFormData] = useState(null)

    const [currentAllowedRoles, setCurrentAllowedRoles] = useState([])
    const [currentQuestionList, setCurrentQuestionList] = useState([])


    const validationSchema = Yup.object().shape({
        formID: Yup.string().required('Cannot be empty!')
            .test("Required", 'Another form with this ID already exists!', (result) => {
                let valid = true;
                if (formLibrary) {
                    // console.log('formLibrary', formLibrary)
                    formLibrary.forEach(form => {
                        // console.log(formID, form.formID)
                        if (result == form.formID && formAction != 'edit') {
                            // console.log('here')
                            valid = false;
                        }
                    })
                }
                // if (result == 'Duplicate Form')
                //     return false;
                return valid;
            }),
        formTitle: Yup.string().required('Cannot be empty!')
            .test('No Special Character', 'Your form cannot contain special characters!', (result) => {
                if (containsSpecialCharacters(result)) {
                    return false;
                }
                return true;
            })
            .test("Required", 'Another form with this name already exists! If the form is yours, please upload a revised version from the table below.', (result) => {
                let valid = true;
                if (formLibrary) {
                    formLibrary.forEach(form => {
                        // console.log(formID, form.formID)
                        if (getValues('formID') != form.formID && form.formTitle.toLowerCase() == result.toLowerCase()) {
                            // console.log('here')
                            valid = false;
                        }
                    })
                }
                // if (result == 'Duplicate Form')
                //     return false;
                return valid;
            }),
        allowedInstitutions: Yup.array().test("Required", "Please choose at least one institution to share the form with!", (result) => {
            // console.log(result);
            if (currentFormDomain == 'Specific') {
                if (!result || result.length <= 0)
                    return false;
            }
            return true;
        }),
        allowedRoles: Yup.array().test("Required", 'At least one account type must be specified!', (result) => {
            // console.log(currentAllowedRoles)
            if (!result || result.length <= 0) {
                return false;
            }
            return true;
        }),
        startDate: Yup.string().test('date required', 'Date Required!', (result) => {
            if (!startDateRequired)
                return true
            if (result != '')
                return true
            return false
        }).test('start date vs end date comparison', 'Start Date cannot be after Deadline!', (result) => {
            const date1 = new Date(startDate);
            const date2 = new Date(endDate);

            // console.log(endDate);
            // console.log('startDateRequired', startDateRequired)
            if (!startDateRequired || !endDateRequired)
                return true
            return date1 < date2;
        }),
        endDate: Yup.string().test('date required', 'Date Required!', (result) => {
            if (!endDateRequired)
                return true
            if (result != '')
                return true
            return false
        }).test('start date vs end date comparison', 'Deadline cannot be before Start Date!', (result) => {
            const date1 = new Date(startDate);
            const date2 = new Date(endDate);

            // console.log(endDate);
            // console.log(startDate)
            if (!startDateRequired || !endDateRequired)
                return true

            return date1 < date2;
        }),
        formPrefix: Yup.string().required('Please select one prefix!'),
        formPrefix2: Yup.string().required('Please select one prefix!')



    });
    const [formLibrary, setFormLibrary] = useState([]);
    const [mostRecentFormData, setMostRecentFormData] = useState([])
    const [currentEventKey, setCurrentEventKey] = useState(null)
    const allPrerequisites = {}



    /*
    Refresh if there's a new change 
    */
    const [firstFetched, setFirstFetched] = useState(false);
    const [showRefresh, setShowRefresh] = useState(false);
    const handleClose = () => setShowRefresh(false);

    const handleRefresh = () => {
        // Implement your refresh logic here
        window.location.reload();  // Simple page reload as an example

        handleClose();
    };
    /////////////
    const addNewQuestion = (questionType) => {

        const currentQuestionListCopy = currentQuestionList
        const questionTypeFull = allQuestionTypes[questionType]
        const newQuestion = {
            options: [],
            questionID: `${questionType}_${makeid(5)}`,
            question_text: `untitled_${questionType}_question_${currentQuestionListCopy.length + 1}`,
            question_description: null,
            question_type: questionType,
            parent_questionID: null

        }
        if (questionType == 'matrix') {
            newQuestion.columns_options = []
        }
        setCurrentQuestionList([...currentQuestionList, newQuestion])
        setNumberOfQuestions(numberOfQuestions + 1);


    }
    const addExistingQuestions = async (formData) => {
        if (formData) {
            const { form_questions } = formData;
            const questionList = []
            let questionLibrary = {}
            form_questions.map(q => { questionLibrary[q.questionID] = q })
            for (const q of form_questions) {
                const questionType = q.question_type
                const questionTypeFull = allQuestionTypes[questionType]
                if (questionTypeFull) {

                    let { keywords, questionID, question_text, question_type, option_keywords, options, field_annotations, parent_conditions, parent_questionID } = q;
                    if (!keywords) {
                        keywords = ''
                    }
                    if (!option_keywords) {
                        option_keywords = []
                    }
                    if (field_annotations) {
                        for (const [key, value] of Object.entries(field_annotations)) {
                            console.log('key', key, value)
                            setValue(`${key}-description`, value)
                            setValue(`${key}-description-parent`, true)
                        }
                    }

                    if (parent_questionID) {
                        const parent_question = questionLibrary[parent_questionID]
                        const parent_condition_value = parent_conditions[parent_questionID][1]
                        // console.log('parent question', questionLibrary[parent_questionID])
                        let { options: p_options } = parent_question
                        // console.log('p_options', p_options, 'parent_condition_value', parent_conditions)

                        if (!p_options.includes(parent_condition_value)) {
                            parent_conditions = null;
                            parent_questionID = null
                        }
                    }
                    /*Automatic Keywords*/
                    // if (Object.keys(countableQuestionTypes).includes(question_type)) {
                    // if (!keywords) {

                    //     keywords = await checkKeywordsAndSave(question_text, keywordDict)
                    //     keywords = keywords.join(' ')
                    //     while (Object.values(currentKeyWordDict).includes(keywords)) {
                    //         let index = 0;
                    //         let acronym = getAcronym(question_text);
                    //         keywords += ` ${acronym.slice(0, 4)}`;
                    //     }
                    //     // currentKeyWordDict[`${questionID}-keywords`] = keywords;
                    //     setCurrentKeywordDict({ ...currentKeyWordDict, [`${questionID}-keywords`]: keywords })


                    //     keywords = processKeywords(keywords);
                    //     console.log(question_text, 'final keywords', keywords)

                    // }
                    // else {
                    //     console.log('main keywords exist from server!')

                    // }



                    // }
                    // if (questionType == 'multiple_choice' || questionType == 'checkbox' || questionType == 'dropdown') {
                    // console.log('!option_keywords || option_keywords.length == 0', !option_keywords || option_keywords.length == 0)
                    // if (!option_keywords || option_keywords.length == 0) {
                    //     option_keywords = [];
                    //     // console.log('no keywords for options', options);

                    //     for (const option of options) {
                    //         let serverKeywords = option;

                    //         serverKeywords = await checkKeywordsAndSave(option, keywordDict)
                    //         serverKeywords = serverKeywords.join(' ')
                    //         // while (Object.values(currentKeyWordDict).includes(serverKeywords)) {
                    //         //     let acronym = getAcronym(option);
                    //         //     serverKeywords += ` ${acronym.slice(0, 4)}`;
                    //         // }
                    //         console.log('option', option, 'serverKeywords', serverKeywords)

                    //         setCurrentKeywordDict({ ...currentKeyWordDict, [`${questionID}-${processID(option)}-keywords`]: serverKeywords })
                    //         serverKeywords = processKeywords(serverKeywords);
                    //         option_keywords.push(serverKeywords);
                    //     }
                    //     // console.log('option_keywords', option_keywords)
                    // }
                    // else {
                    //     console.log('option keywords exist from server!')
                    // }
                    // }

                    const newQuestion = {
                        options: q.options ? q.options : [],
                        columns_options: q.columns_options ? q.columns_options : [],
                        questionID,
                        parent_questionID: parent_questionID,
                        parent_conditions: parent_conditions,
                        question_description: q.question_description ? q.question_description : null,
                        question_text,
                        question_type,
                        keywords,
                        field_annotations
                    }
                    if (questionType == 'multiple_choice' || questionType == 'checkbox' || questionType == 'dropdown') {
                        newQuestion.requiredOptions = q.requiredOptions ? q.requiredOptions : []
                        newQuestion.option_keywords = option_keywords
                    }
                    else
                        newQuestion.required = q.required == false ? q.required : true
                    if (questionType == 'matrix') {
                        newQuestion.column_option_keywords = q.column_option_keywords ? q.column_option_keywords : []
                        newQuestion.option_keywords = option_keywords

                    }
                    if (encryptableQuestionTypes.includes(questionType)) {
                        newQuestion.encrypted = q.encrypted ? q.encrypted : false
                    }
                    questionList.push(newQuestion)

                }
                else {
                    console.log(`${questionType} not supported!`)
                }

            }
            setTimeout(() => {
                setLoading(false); // Set loading to false when the request is complete.
            }, 1000);
            // console.log('questionList', questionList)
            setCurrentQuestionList(questionList)
            setNumberOfQuestions(questionList.length);
        }
    }
    useEffect(() => {
        // console.log(formData);
        if (firstFetched) {
            const formLibraryRef = collection(db, "form_library");
            const q = query(formLibraryRef)
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const formUploadHistData = []
                let foundFormToEdit = false;
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    const rawData = doc.data();
                    const createdDate = rawData.createdAt.toDate().toDateString()
                    const createdTime = rawData.createdAt.toDate().toLocaleTimeString('en-US');
                    const formFormat = rawData.formFormat ? rawData.formFormat : 'csv'
                    const formName = rawData.form_questions[0].question_type == 'form_title' ? rawData.form_questions[0].question_text : 'Untitled Form'
                    const status = rawData.status ? rawData.status : 'published'
                    const action = status == 'published' ? "Unpublish" : 'Publish'
                    // const accessibleTo = rawData.accessibleTo ? rawData.accessibleTo : ['student', 'student-mentor', 'faculty', 'administrator']
                    const formDomain = rawData.formDomain ? rawData.formDomain : 'Common'
                    const allowedInstitutions = rawData.allowedInstitutions ? rawData.allowedInstitutions : []
                    const allowedRoles = rawData.allowedRoles ? rawData.allowedRoles : []
                    formUploadHistData.push({
                        formID: rawData.formID,
                        formTitle: formName,
                        createdAt: `${createdDate}, ${createdTime}`,
                        timeStamp: rawData.createdAt,
                        formFormat: formFormat,
                        status: status.toUpperCase(),
                        action: action,
                        reupload: 'reupload',
                        // accessibleTo: accessibleTo.join('||'),
                        // deleteAction: 'deleteAction',
                        formDomain,
                        allowedInstitutions,
                        allowedRoles,
                    })
                    // console.log(rawData.formID, editFormID)

                    if (formAction == 'edit') {
                        // console.log(rawData.formID, editFormID)
                        if (rawData.formID == editFormID) {
                            // console.log('found')
                            // setFormData(rawData);
                            if (!isEqual(rawData, formData)) {
                                setMostRecentFormData(rawData);
                                setShowRefresh(true);
                            }
                            const lastEdited = rawData.lastEdited ? rawData.lastEdited : rawData.createdAt
                            const createdDate = lastEdited.toDate().toDateString()
                            const createdTime = lastEdited.toDate().toLocaleTimeString('en-US');
                            setLastUpdatedDateString(`${createdDate}, ${createdTime}`)

                            foundFormToEdit = true;

                            // addExistingQuestions(rawData)
                        }

                    }

                });
                // setMostRecentFormData([...formUploadHistData])

            });

            // Return the unsubscribe function to be called on component unmount
            return () => {
                unsubscribe();
            };
        }


    }, [firstFetched, formData])
    useEffect(() => {
        const formLibraryRef = collection(db, "form_library");
        const q = query(formLibraryRef);

        // Use getDocs instead of onSnapshot
        getDocs(q).then((querySnapshot) => {
            const formUploadHistData = [];
            let foundFormToEdit = false;

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                const rawData = doc.data();
                const createdDate = rawData.createdAt.toDate().toDateString()
                const createdTime = rawData.createdAt.toDate().toLocaleTimeString('en-US');
                const formFormat = rawData.formFormat ? rawData.formFormat : 'csv'
                const formName = rawData.form_questions[0].question_type == 'form_title' ? rawData.form_questions[0].question_text : 'Untitled Form'
                const status = rawData.status ? rawData.status : 'published'
                const action = status == 'published' ? "Unpublish" : 'Publish'
                // const accessibleTo = rawData.accessibleTo ? rawData.accessibleTo : ['student', 'student-mentor', 'faculty', 'administrator']
                const formDomain = rawData.formDomain ? rawData.formDomain : 'Common'
                const allowedInstitutions = rawData.allowedInstitutions ? rawData.allowedInstitutions : []
                const allowedRoles = rawData.allowedRoles ? rawData.allowedRoles : []
                formUploadHistData.push({
                    formID: rawData.formID,
                    formTitle: formName,
                    createdAt: `${createdDate}, ${createdTime}`,
                    timeStamp: rawData.createdAt,
                    formFormat: formFormat,
                    status: status.toUpperCase(),
                    action: action,
                    reupload: 'reupload',
                    // accessibleTo: accessibleTo.join('||'),
                    // deleteAction: 'deleteAction',
                    formDomain,
                    allowedInstitutions,
                    allowedRoles,
                })
                // console.log(rawData.formID, editFormID)

                if (formAction == 'edit') {
                    // console.log(rawData.formID, editFormID)
                    if (rawData.formID == editFormID) {
                        // console.log('found')
                        setFormData(rawData);
                        const lastEdited = rawData.lastEdited ? rawData.lastEdited : rawData.createdAt
                        const createdDate = lastEdited.toDate().toDateString()
                        const createdTime = lastEdited.toDate().toLocaleTimeString('en-US');
                        setLastUpdatedDateString(`${createdDate}, ${createdTime}`)

                        foundFormToEdit = true;

                        addExistingQuestions(rawData)
                    }

                }

            });

            setFormLibrary([...formUploadHistData]);
            setFirstFetched(true);
            if (formAction === 'edit' && foundFormToEdit === false) {
                setRenderPage(false);
            }
        });
    }, []);
    useEffect(() => {
        // console.log('currentKeyWordDict', currentKeyWordDict)
    }, [currentKeyWordDict])


    const formOptions = { resolver: yupResolver(validationSchema) };
    const methods = useForm(formOptions);
    const { handleSubmit, reset, watch, register, setError, setValue, getValues, setFocus, formState: { errors } } = methods;
    const [submitAttempted, setSubmitAttempted] = useState(false);
    console.log('errors outside', errors)
    // const firstError = Object.keys(errors).reduce((field, a) => {
    //     return !!errors[field] ? field : a;
    // }, null);
    // console.log('firstError', firstError)

    // if (firstError) {
    //     setFocus(firstError);
    // }
    useEffect(() => {
        if (submitAttempted) {
            console.log('errors inside', errors)
            const firstError = Object.keys(errors).reduce((field, a) => {
                return !!errors[field] ? field : a;
            }, null);

            if (firstError) {
                setFocus(firstError);
            }

            // Reset the flag so we don't keep setting focus
            setSubmitAttempted(false);
        }
    }, [submitAttempted, errors]);

    const [numberOfQuestions, setNumberOfQuestions] = useState(0)
    // console.log(formData)
    const formID = formAction == 'edit' ? newFormID : watch('formID')
    if (formAction == 'edit') {
        setValue('formID', newFormID)
    }
    const startDateRequired = watch('startDateRequired');
    const endDateRequired = watch('endDateRequired');
    const startDate = watch('startDate');
    const endDate = watch('endDate');
    const generateKeywordsOnSubmit = watch('generateKeywordsOnSubmit');
    useEffect(() => {
        const subscription = watch(async (value, { name, type }) => {
            if (name == 'generateKeywordsOnSubmit') {
                const generateKeywords = value['generateKeywordsOnSubmit']

                if (generateKeywords) {
                    const promises = []; // Create an array to store all your promises
                    let optionPromises = []; // Array to hold the option keyword promises
                    let matrixPromises = []; // This line declares the matrixPromises array.

                    setLoading(true);
                    const currentQuestionListCopy = []
                    for (const q of currentQuestionList) {
                        let { keywords, questionID, question_text, question_type } = q;
                        const questionType = question_type

                        // console.log('value', value, questionID)
                        question_text = value[questionID];
                        keywords = value[`${questionID}-keywords`];
                        // console.log('current keywords', keywords, value)
                        if (Object.keys(countableQuestionTypes).includes(question_type)) {
                            /*Normal Questions*/
                            if (questionType == 'short_answer' || questionType == 'long_answer'
                                || questionType == 'date' ||
                                questionType == 'email' || questionType == 'phone_number' || questionType == 'file_upload') {
                                question_text = value[questionID];
                            }
                            /*Multiplce Choice and Checkboxes */
                            else if (questionType == 'multiple_choice' || questionType == 'checkbox') {
                                question_text = value[`${questionID}-question`];
                                let { options, option_keywords } = q;

                                // Iterate over the options
                                for (const option of options) {
                                    let keywordID = `${questionID}-${processID(option)}-keywords`
                                    let optionID = `${questionID}-${processID(option)}`
                                    let optionValue = value[optionID]
                                    let keywordValue = value[keywordID]

                                    // Check if the option is not empty and the keywords are empty
                                    if (optionValue && optionValue.trim() != '' && (!keywordValue || keywordValue.trim() == '')) {
                                        // If we don't already have the keyword in the current keyword dictionary
                                        if (!currentKeyWordDict[keywordID]) {
                                            // Create a new promise for this keyword generation and push it to the array
                                            optionPromises.push(
                                                checkKeywordsAndSave(optionValue, keywordDict).then(keywordArray => {
                                                    keywordValue = keywordArray.join(' ');
                                                    keywordValue = processKeywords(keywordValue);
                                                    setCurrentKeywordDict({
                                                        ...currentKeyWordDict,
                                                        [keywordID]: keywordValue
                                                    });

                                                    return { keywordID, keywordValue }; // Return the new keyword and its ID as the promise result
                                                })
                                            );
                                        }
                                    }
                                }
                            }

                            else if (questionType == 'dropdown') {
                                question_text = value[`${questionID}-question`];

                                let optionListID = `${questionID}-options`
                                let optionListValue = value[optionListID]
                                let optionList = value[optionListID].split('\n')

                                let keywordID = `${questionID}-option-keywords`
                                let keywordValue = value[keywordID]
                                let keywordList = value[keywordID].split('\n');
                                // console.log('dropdown keywordValue', keywordValue)
                                if (keywordValue.split('\n').length == optionListValue.split('\n').length) {
                                    // console.log('valid keyword list, same length', keywordValue.split('\n').length)
                                }
                                else {
                                    let optionPromises = [];
                                    // console.log('invalid keyword list, different length', keywordValue.split('\n').length, optionListValue.split('\n').length)
                                    for (const option of optionList) {
                                        // Push each promise into the array
                                        optionPromises.push(checkKeywordsAndSave(option, keywordDict));
                                    }

                                    // Wait for all the promises to complete
                                    Promise.all(optionPromises)
                                        .then(optionKeywordsList => {
                                            let newKeywordList = optionKeywordsList.map(optionKeywords => {
                                                optionKeywords = optionKeywords.join(' ')
                                                optionKeywords = processKeywords(optionKeywords);
                                                return optionKeywords;
                                            });
                                            // console.log('newKeywordList', newKeywordList)
                                            setValue(keywordID, newKeywordList.join('\n'))
                                        })
                                        .catch(error => {
                                            console.error(error);
                                            setLoading(false);
                                        });
                                }
                            }

                            else if (questionType == 'matrix') {
                                question_text = value[`${questionID}-question`];
                                let optionKeywords = [];
                                let { options, columns_options, option_keywords, column_option_keywords } = q;
                                if (!options) {
                                    options = []
                                }
                                if (!columns_options) {
                                    columns_options = []
                                }

                                let matrixPromises = [];

                                /*Row Options */
                                for (const option of options) {
                                    let keywordID = `${questionID}-${processID(option)}-keywords`
                                    let optionID = `${questionID}-${processID(option)}`
                                    let optionValue = value[optionID]
                                    let keywordValue = value[keywordID]
                                    if (optionValue && optionValue.trim() != '') { //if option is not empty
                                        if (!keywordValue || keywordValue.trim() == '') { //if keywords are empty
                                            //Generate keywords based on conditions
                                            if (!currentKeyWordDict[keywordID]) {
                                                // Push each promise into the array
                                                matrixPromises.push(
                                                    checkKeywordsAndSave(optionValue, keywordDict).then(keywordArray => {
                                                        keywordValue = keywordArray.join(' ')
                                                        keywordValue = processKeywords(keywordValue);
                                                        setCurrentKeywordDict({ ...currentKeyWordDict, [keywordID]: keywordValue })

                                                        return { keywordID, keywordValue };
                                                    })
                                                );
                                            }
                                        }
                                    }
                                }

                                /*Column Options */
                                for (const option of columns_options) {
                                    let keywordID = `${questionID}-${processID(option)}-keywords`
                                    let optionID = `${questionID}-${processID(option)}`
                                    let optionValue = value[optionID]
                                    let keywordValue = value[keywordID]
                                    if (optionValue && optionValue.trim() != '') { //if option is not empty
                                        if (!keywordValue || keywordValue.trim() == '') { //if keywords are empty
                                            //Generate keywords based on conditions
                                            if (!currentKeyWordDict[keywordID]) {
                                                // Push each promise into the array
                                                matrixPromises.push(
                                                    checkKeywordsAndSave(optionValue, keywordDict).then(keywordArray => {
                                                        keywordValue = keywordArray.join(' ')
                                                        keywordValue = processKeywords(keywordValue);
                                                        setCurrentKeywordDict({ ...currentKeyWordDict, [keywordID]: keywordValue })

                                                        return { keywordID, keywordValue };
                                                    })
                                                );
                                            }
                                        }
                                    }
                                }

                                // Wait for all the promises to complete
                                Promise.all(matrixPromises)
                                    .then(matrixKeywords => {
                                        for (const { keywordID, keywordValue } of matrixKeywords) {
                                            setValue(keywordID, keywordValue)
                                        }
                                    })
                                    .catch(error => {
                                        console.error(error);
                                        setLoading(false);
                                    });
                            }

                            // Check if the keywords need to be generated
                            if (!keywords || keywords.trim() == '') {
                                if (!currentKeyWordDict[`${questionID}-keywords`]) {
                                    // console.log('keywords', keywords, question_text);
                                    // Create a new promise for this keyword generation and push it to the array
                                    promises.push(
                                        checkKeywordsAndSave(question_text, keywordDict).then(keywordArray => {
                                            keywords = keywordArray.join(' ');
                                            while (Object.values(currentKeyWordDict).includes(keywords)) {
                                                let acronym = getAcronym(question_text);
                                                keywords += ` ${acronym.slice(0, 4)}`;
                                            }
                                            keywords = processKeywords(keywords);
                                            // console.log('processKeywords', keywords);
                                            setCurrentKeywordDict({
                                                ...currentKeyWordDict,
                                                [`${questionID}-keywords`]: keywords,
                                            });
                                            // console.log(question_text, 'final keywords', keywords);

                                            const newQuestion = JSON.parse(JSON.stringify(q));
                                            newQuestion.keywords = keywords;

                                            return newQuestion; // Return the new question as the promise result

                                        })
                                    );
                                }
                            } else {
                                // console.log('valid keywords', keywords);
                                setValue(`${questionID}-keywords`, keywords)

                            }

                        }
                        // Handle all the promises at once
                        Promise.all(promises)
                            .then((newQuestions) => {
                                // Once all promises are resolved, update your question list and stop loading
                                // setCurrentQuestionList([...newQuestions]);
                                // keywords = newQuestions.keywords;
                                // setValue(`${questionID}-keywords`, keywords)
                                for (const newQuestion of newQuestions) {
                                    const { questionID, keywords } = newQuestion;
                                    // console.log('newQuestion', newQuestion)

                                    setValue(`${questionID}-keywords`, keywords);
                                    // console.log('setValue', `${questionID}-keywords`, keywords)
                                }
                                setTimeout(() => {
                                    setLoading(false); // Set loading to false when the request is complete.
                                }, 1000);
                            })
                            .catch((error) => {
                                // Handle any errors
                                console.error(error);
                                setLoading(false);
                            });
                        // Handle the option keyword promises separately
                        Promise.all(optionPromises)
                            .then((results) => {
                                for (const result of results) {
                                    setValue(result.keywordID, result.keywordValue);
                                }
                                setTimeout(() => {
                                    setLoading(false); // Set loading to false when the request is complete.
                                }, 1000);
                            })
                            .catch((error) => {
                                // Handle any errors
                                console.error(error);
                                setLoading(false);
                            });

                        // const newQuestion = JSON.parse(JSON.stringify(q))
                        // newQuestion.keywords = keywords;
                        // currentQuestionListCopy.push(newQuestion)
                        // setTimeout(() => {
                        //     setLoading(false); // Set loading to false when the request is complete.
                        // }, 1000);
                    }
                    // setCurrentQuestionList([...currentQuestionListCopy])

                }
            }

        })
        return () => subscription.unsubscribe();
    }, [watch, currentQuestionList]);
    useEffect(() => {
        console.log('currentQuestionList', currentQuestionList)
    }, [currentQuestionList])

    // formLibrary.map(form => {
    //     if (form.formID != formID)
    //         allPrerequisites[form.formID] = form.formTitle
    //     // form.formID: form.formTitle
    // })
    // console.log(formID)

    useEffect(() => {
        let formTitle = 'Untitled Form';
        let formDomain = 'Common';
        let allowedInstitutions = [];
        let excludedInstitutions = [];
        let allowedRoles = []
        let prerequisites = []
        let nextForm = []
        let startDateRequiredServer = false
        let endDateRequiredServer = false;
        let startDateServer = ''
        let endDateServer = ''
        let formPrefix = 'N/A'
        let formPrefix2 = 'N/A'

        let displayNameServer = ''
        if (formData) {
            if (formData.formTitle) {
                formTitle = formData.formTitle
            }
            else if (formData.form_questions[0].question_type == 'form_title')
                formTitle = formData.form_questions[0].question_text

            allowedInstitutions = formData.allowedInstitutions ? formData.allowedInstitutions : []
            excludedInstitutions = formData.excludedInstitutions ? formData.excludedInstitutions : []
            formDomain = formData.formDomain ? formData.formDomain : 'Common'
            allowedRoles = formData.allowedRoles ? formData.allowedRoles : []
            startDateRequiredServer = formData.startDateRequired ? formData.startDateRequired : false
            endDateRequiredServer = formData.endDateRequired ? formData.endDateRequired : false
            startDateServer = formData.startDate ? formData.startDate : ''
            endDateServer = formData.endDate ? formData.endDate : ''
            formPrefix = formData.formPrefix ? formData.formPrefix : 'N/A'
            formPrefix2 = formData.formPrefix2 ? formData.formPrefix2 : 'N/A'


            // console.log(allowedRoles)


            setValue('formTitle', formTitle)
            setValue('formDomain', formDomain)
            setValue('allowedInstitutions', allowedInstitutions)
            setValue('excludedInstitutions', excludedInstitutions)
            setValue('allowedRoles', [...allowedRoles])

            setValue('currentNextForm', [...nextForm])
            setValue('startDateRequired', startDateRequiredServer)
            setValue('startDate', startDateServer)
            setValue('endDateRequired', endDateRequiredServer)
            setValue('endDate', endDateServer)
            setValue('formPrefix', formPrefix)
            setValue('formPrefix2', formPrefix2)
            setCurrentFormDomain(formDomain);

            setCurrentAllowedInstitutions([...allowedInstitutions]);
            setCurrentExcludedInstitutions([...excludedInstitutions]);
            setCurrentAllowedRoles([...allowedRoles])
            setCurrentNextForm([...nextForm])

            console.log('formData', formData)


            let count = 0
            formData.form_questions.forEach(question => {
                if (!notQuestionTypes.includes(question.question_type)) {
                    count += 1
                }
            })
            // setNumberOfQuestions(count);
        }
    }, [formData, formLibrary])
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button variant={errors['allowedInstitutions'] && currentAllowedInstitutions.length <= 0 ? 'outline-danger' : 'outline-info'}
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}>{children}
            &#x25bc;</Button>
    ));
    function writeToFirebase(data, merge = false) {
        console.log(data)
        setDoc(doc(db, "form_library", data.formID), data, { merge: merge })
            .then(() => {
                // setFormID(data.formID);
                if (merge == false || formAction != 'edit') {
                    console.log(`Form ${data.formID} written to Firebase successfully`)
                    alert(`Form ${data.formID} written to Firebase successfully`)


                }
                else {
                    console.log(`Form ${data.formID} revised successfully`)
                    alert(`Form ${data.formID} revised successfully`)

                }

                setFormURL(`/forms/${data.formID}`)

                // setFileProcessedSuccessfully(Date.now());
            }).catch(err => {
                console.log(err)
                alert(err)
                // setFileProcessedSuccessfully(Date.now());

            });

    }


    const handleUpload = (data, e) => {
        e.preventDefault();
        console.log('submitted data', data)

        let customValidationPassed = true;
        console.log('currentQuestionList', currentQuestionList)

        currentQuestionList.forEach(question => {
            const { questionID, question_type: questionType } = question
            // const questionID = question.questionID
            // const questionType = question.question_type
            if (questionType == 'short_answer' || questionType == 'long_answer'
                || questionType == 'date' || questionType == 'section_header'
                || questionType == 'email' || questionType == 'phone_number'
                || questionType == 'plain_text' || questionType == 'sign_date' || questionType == 'file_upload') {
                if (data[questionID].trim() == '') {
                    console.log('failed 1')
                    setError(questionID, { type: 'custom', message: 'Cannot be empty!' })
                    customValidationPassed = false;
                }


                if (isKeywordApplicable(questionType)) {
                    let keywordID = `${questionID}-keywords`
                    console.log('keywordID', keywordID)
                    if (!data[keywordID] || data[keywordID].trim() == '') {
                        setError(`${questionID}-keywords`, { type: 'custom', message: 'Keywords cannot be empty!' })
                    }
                }


            }
            else if (questionType == 'multiple_choice' || questionType == 'checkbox') {
                if (data[`${questionID}-question`] == '') {
                    console.log(`${questionID}-question`)
                    console.log('failed 2')

                    // setError(`${questionID}-question`, { type: 'custom', message: 'Cannot be empty!' })
                    setError(`${questionID}-question`, { type: "focus" }, { shouldFocus: true });
                    customValidationPassed = false;
                }


                let keywordID = `${questionID}-keywords`
                console.log('keywordID', keywordID)
                if (!data[keywordID] || data[keywordID].trim() == '') {
                    setError(`${questionID}-keywords`, { type: 'custom', message: 'Keywords cannot be empty!' })
                    customValidationPassed = false;
                }


                if (currentQuestionList.length > 0) {
                    currentQuestionList.forEach(item => {
                        console.log('in multiple choice', item);
                        if (item.questionID == questionID) {
                            const rawOptionIDs = item.options
                            const optionVals = []
                            rawOptionIDs.forEach(rOptionID => {
                                let optionID = `${questionID}-${processID(rOptionID)}`
                                console.log('optionID', optionID)
                                console.log('optionID Data', data[optionID])
                                const val = data[optionID]
                                // optionVals.push(data[val])
                                if (val == '' || !val) {
                                    console.log("INVALID!")
                                    console.log(data)
                                    setError(`${questionID}-${optionID}`, { type: 'custom', message: 'Cannot be empty!' })
                                    customValidationPassed = false;
                                }

                                let keywordID = `${optionID}-keywords`
                                console.log('keywordID', keywordID)
                                if (!data[keywordID] || data[keywordID].trim() == '') {
                                    console.log('empty keyword checkbox', keywordID)
                                    setError(keywordID, { type: 'custom', message: 'Keywords cannot be empty!' })
                                    customValidationPassed = false;

                                }

                            })
                        }
                    })
                }

            }
            else if (questionType == 'matrix') {
                console.log('validating matrix creation')
                if (data[`${questionID}-question`] == '') {
                    console.log(`${questionID}-question`)
                    console.log('matrix validation failed 1')

                    // setError(`${questionID}-question`, { type: 'custom', message: 'Cannot be empty!' })
                    setError(`${questionID}-question`, { type: "focus" }, { shouldFocus: true });
                    customValidationPassed = false;
                }
                let keywordID = `${questionID}-keywords`
                console.log('keywordID', keywordID)
                if (!data[keywordID] || data[keywordID].trim() == '') {
                    setError(`${questionID}-keywords`, { type: 'custom', message: 'Keywords cannot be empty!' })
                    customValidationPassed = false;
                }
                if (currentQuestionList.length > 0) {
                    currentQuestionList.forEach(item => {
                        // console.log(item);
                        if (item.questionID == questionID) {
                            const rowOptionIDs = item.options
                            const columnOptionIDs = item.columns_options
                            rowOptionIDs.forEach(optionID => {
                                console.log('row optionID: ', `${questionID}-${processID(optionID)}`)
                                console.log('row optionID Data: ', data[`${questionID}-${processID(optionID)}`])
                                const val = data[`${questionID}-${processID(optionID)}`]
                                // optionVals.push(data[val])
                                if (val == '' || !val) {
                                    console.log("INVALID ROW!")
                                    console.log(data)
                                    setError(`${questionID}-${optionID}`, { type: 'custom', message: 'Cannot be empty!' })
                                    customValidationPassed = false;
                                }

                                /*Validate Row Keywords */

                                let keywordID = `${questionID}-${processID(optionID)}-keywords`
                                let processedOptionID = `${questionID}-${processID(optionID)}`
                                let optionValue = data[processedOptionID]
                                let keywordValue = data[keywordID]
                                if (optionValue && optionValue.trim() != '') { //if option is not empty
                                    if (!keywordValue || keywordValue.trim() == '') { //if keywords are empty
                                        setError(keywordID, { type: 'custom', message: 'Keywords cannot be empty!' })
                                        customValidationPassed = false;

                                    }
                                }
                            })
                            columnOptionIDs.forEach(optionID => {
                                console.log('column optionID: ', `${questionID}-${processID(optionID)}`)
                                console.log('column optionID Data: ', data[`${questionID}-${processID(optionID)}`])
                                const val = data[`${questionID}-${processID(optionID)}`]
                                // optionVals.push(data[val])
                                if (val == '' || !val) {
                                    console.log("INVALID COLUMN!")
                                    console.log(data)
                                    setError(`${questionID}-${optionID}`, { type: 'custom', message: 'Cannot be empty!' })
                                    customValidationPassed = false;
                                }
                                /*Validate Column Keywords */

                                let keywordID = `${questionID}-${processID(optionID)}-keywords`
                                let processedOptionID = `${questionID}-${processID(optionID)}`
                                let optionValue = data[processedOptionID]
                                let keywordValue = data[keywordID]
                                if (optionValue && optionValue.trim() != '') { //if option is not empty
                                    if (!keywordValue || keywordValue.trim() == '') { //if keywords are empty
                                        setError(keywordID, { type: 'custom', message: 'Keywords cannot be empty!' })
                                        customValidationPassed = false;

                                    }
                                }
                            })
                        }
                    })
                }
            }
            else if (questionType == 'dropdown') {
                const prompt = data[`${questionID}-question`];
                if (!prompt || prompt == '') {
                    setError(`${questionID}-question`, { type: 'custom', message: 'Cannot be empty!' })
                    customValidationPassed = false;

                }


                let keywordID = `${questionID}-keywords`
                console.log('keywordID', keywordID)
                if (!data[keywordID] || data[keywordID].trim() == '') {
                    setError(keywordID, { type: 'custom', message: 'Keywords cannot be empty!' })
                    customValidationPassed = false;
                }


                let optionListID = `${questionID}-options`
                let optionListValue = data[optionListID]
                let optionList = data[optionListID].split('\n')

                let optionKeywordID = `${questionID}-option-keywords`
                let keywordValue = data[optionKeywordID]
                let keywordList = data[optionKeywordID].split('\n');
                console.log('dropdown keywordValue', keywordValue)
                if (!optionListValue || optionListValue.replaceAll(' ') == '') {
                    customValidationPassed = false;
                    setError(`${questionID}-options`, { type: 'custom', message: 'Cannot be empty!' })
                }
                if (keywordValue.split('\n').length != optionListValue.split('\n').length) {
                    customValidationPassed = false;
                    setError(optionKeywordID, { type: 'custom', message: 'Keywords not matching Option List!' })
                }
            }


        })
        if (customValidationPassed) {
            console.log('PASS!');
            let structuredItems = [{
                questionID: processID(formLongName),
                // question_text: data.formTitle,
                question_text: formLongName,
                question_type: 'form_title',

            }]
            const allQuestionIDs = []
            currentQuestionList.forEach(item => {
                let { questionID, question_type: questionType, parent_questionID, keywords } = item;
                // const questionType = item.question_type;
                // const questionID = item.questionID;
                parent_questionID = item.parent_questionID ? item.parent_questionID : null;
                if (!keywords) {
                    keywords = null;
                }
                let parent_conditions = item.parent_conditions ? item.parent_conditions : null;
                if (parent_conditions) {
                    const newParent_Conditions = JSON.parse(JSON.stringify(parent_conditions));
                    for (let [p_qID, p_cond_arr] of Object.entries(parent_conditions)) {
                        console.log('parent_condition', p_qID, p_cond_arr);
                        if (p_cond_arr[0].includes("New-Option")) {
                            const processed_condition_val = data[p_cond_arr[0]]
                            console.log('processed_condition_val', processed_condition_val)
                            newParent_Conditions[p_qID] = [`${p_qID}-${processID(processed_condition_val)}`, processed_condition_val]
                        }
                    }
                    console.log('newParent_Conditions', newParent_Conditions)
                    parent_conditions = newParent_Conditions
                }
                if (questionType == 'short_answer' || questionType == 'long_answer'
                    || questionType == 'date' || questionType == 'section_header'
                    || questionType == 'email' || questionType == 'phone_number'
                    || questionType == 'plain_text' || questionType == 'sign_date' || questionType == 'file_upload') {
                    const val = data[item.questionID];
                    let description = '';
                    if (data[item.questionID + '-description-parent']) {
                        description = data[item.questionID + '-description']
                    }
                    keywords = data[`${questionID}-keywords`] ? data[`${questionID}-keywords`] : null
                    if (val) {
                        let structuredItem = {
                            question_type: questionType,
                            questionID: questionID,
                            question_text: val,
                            required: data[`${questionID}-required`] ? true : false,
                            encrypted: data[`${questionID}-encrypted`] ? true : false,
                            parent_questionID,
                            parent_conditions,
                            question_description: description,
                            keywords,
                        }
                        structuredItems.push(structuredItem)
                    }
                }
                else if (questionType == 'multiple_choice' || questionType == 'checkbox') {
                    const val = data[item.questionID + '-question'];
                    let description = '';
                    if (data[item.questionID + '-description-parent']) {
                        description = data[item.questionID + '-description']
                    }
                    keywords = data[`${questionID}-keywords`] ? data[`${questionID}-keywords`] : null

                    if (val) {
                        // console.log('item.options', item.options)
                        // console.log(getValues())
                        const options = []
                        const requiredOptions = []
                        const option_keywords = []
                        const field_annotations = {}
                        if (description) {
                            field_annotations[`${item.questionID}`] = description;
                        }
                        item.options.forEach((option, index) => {
                            let optionID = `${item.questionID}-${processID(option)}`
                            let optionValue = data[optionID]
                            options.push(optionValue)
                            option_keywords.push(data[`${optionID}-keywords`])
                            if (questionType == 'checkbox') {
                                const requiredValue = data[`${optionID}-required`]
                                if (requiredValue)
                                    requiredOptions.push(optionValue)

                            }

                            /*Process ID for values */
                            let option_description = data[`${optionID}-description`] && data[`${optionID}-description-parent`] == true ? data[`${optionID}-description`] : null;
                            if (option_description) {
                                let processedOptionValue = processID(optionValue);

                                field_annotations[`${questionID}-${processedOptionValue}`] = option_description
                            }
                        })
                        // console.log('real options', options)


                        let structuredItem = {
                            question_type: questionType,
                            questionID: questionID,
                            question_text: val,
                            options,
                            parent_questionID,
                            parent_conditions,
                            question_description: description,
                            keywords,
                            option_keywords,
                            field_annotations
                        }
                        if (questionType == 'checkbox') {
                            structuredItem['requiredOptions'] = requiredOptions;
                        }

                        structuredItems.push(structuredItem)
                    }
                }
                else if (questionType == 'matrix') {

                    const val = data[questionID + '-question'];
                    let description = null;
                    if (data[questionID + '-description-parent'] == true) {
                        description = data[questionID + '-description']
                    }
                    const keywords = data[`${questionID}-keywords`] ? data[`${questionID}-keywords`] : null
                    const field_annotations = {}
                    if (description) {
                        field_annotations[`${questionID}`] = description;
                    }
                    if (val) {
                        let structuredQuestionID = processID(val)
                        let count = 2
                        while (allQuestionIDs.includes(structuredQuestionID)) {
                            structuredQuestionID += `-${count}`
                            count += 1
                        }
                        allQuestionIDs.push(structuredQuestionID)


                        console.log(item.options, item.columns_options)

                        const options = []
                        const columnsOptions = []
                        const option_keywords = []
                        const column_option_keywords = []

                        item.options.forEach((option, index) => {
                            const optionID = `${questionID}-${processID(option)}`
                            const optionValue = data[optionID]
                            options.push(optionValue)
                            option_keywords.push(data[`${optionID}-keywords`])

                            /*Process ID for values */
                            let option_description = data[`${optionID}-description`] && data[`${optionID}-description-parent`] == true ? data[`${optionID}-description`] : null;

                            if (option_description) {
                                let processedOptionValue = processID(optionValue);
                                field_annotations[`${questionID}-${processedOptionValue}`] = option_description
                            }
                        })
                        item.columns_options.forEach((option, index) => {
                            const optionID = `${item.questionID}-${processID(option)}`
                            const optionValue = data[optionID]

                            let column_description = data[`${optionID}-description`] && data[`${optionID}-description-parent`] == true ? data[`${optionID}-description`] : null;

                            columnsOptions.push(data[optionID])
                            column_option_keywords.push(data[`${optionID}-keywords`])

                            if (column_description) {
                                let processedOptionValue = processID(optionValue);
                                field_annotations[`${questionID}-${processedOptionValue}`] = column_description
                            }
                        })

                        let structuredItem = {
                            question_type: questionType,
                            questionID: questionID,
                            question_text: val,
                            keywords,
                            options,
                            option_keywords,
                            columns_options: columnsOptions,
                            column_option_keywords,
                            parent_questionID,
                            parent_conditions,
                            question_description: description,
                            field_annotations
                        }

                        structuredItems.push(structuredItem)
                    }
                }
                else if (questionType == 'dropdown') {
                    console.log('item', item)
                    const prompt = data[`${questionID}-question`];
                    let keywords = data[`${questionID}-keywords`] ? data[`${questionID}-keywords`] : null
                    let optionListID = `${questionID}-options`
                    let optionListValue = data[optionListID]
                    let optionList = data[optionListID].split('\n')

                    let keywordID = `${questionID}-option-keywords`
                    let keywordValue = data[keywordID]
                    let keywordList = data[keywordID].split('\n');
                    // if (!prompt || prompt == '') {
                    //     setError(`${questionID}-question`, { type: 'custom', message: 'Cannot be empty!' })
                    // }
                    let description = '';
                    if (data[item.questionID + '-description-parent']) {
                        description = data[item.questionID + '-description']
                    }



                    let options = optionList

                    let option_keywords = keywordList
                    let structuredItem = {
                        question_type: questionType,
                        questionID: questionID,
                        question_text: prompt,
                        keywords,
                        options,
                        option_keywords,
                        question_description: description,
                        parent_questionID,
                        parent_conditions,
                        required: data[`${questionID}-required`] ? true : false,

                    }
                    structuredItems.push(structuredItem)
                }
                else if (questionType == 'conditional') {
                    const prompt = data[`${questionID}-question`];

                    if (!prompt || prompt == '') {
                        setError(`${questionID}-question`, { type: 'custom', message: 'Cannot be empty!' })
                    }
                    let structuredQuestionID = processID(prompt)
                    let count = 2
                    while (allQuestionIDs.includes(structuredQuestionID)) {
                        structuredQuestionID += `-${count}`
                        count += 1
                    }
                    allQuestionIDs.push(structuredQuestionID)
                    let structuredItem = {
                        question_type: questionType,
                        questionID,
                        question_text: prompt,
                        parent_questionID,
                        parent_conditions,
                    }
                    structuredItems.push(structuredItem)

                }

            })

            let status = 'unpublished'
            if (userData) {
                if (isHubLead(userData.atype)) {
                    status = 'unpublished'
                    if (formAction == 'edit') {
                        status = formData ? formData.status : 'unpublished'
                    }
                }
                if (isCampusLead(userData.atype)) {
                    status = 'awaiting-approval'
                }
            }

            let finalFormStructure = {
                allowedInstitutions: data.allowedInstitutions ? data.allowedInstitutions : [],
                excludedInstitutions: data.excludedInstitutions ? data.excludedInstitutions : [],
                allowedRoles: data.allowedRoles,
                lastEdited: serverTimestamp(),
                createdAt: serverTimestamp(),
                formDomain: data.formDomain,
                formFormat: 'Web',
                formID: data.formID,
                formPrefix: data.formPrefix,
                formPrefix2: data.formPrefix2,
                form_questions: structuredItems,
                formTitle: data.formTitle,
                formLongName: formLongName,
                userID: user ? user.uid : 'anonymous',
                status: status,
                startDateRequired: data['startDateRequired'],
                endDateRequired: data['endDateRequired'],
                startDate: data.startDateRequired ? data.startDate : '',
                endDate: data.endDateRequired ? data.endDate : '',

            }
            console.log('finalFormStructure', finalFormStructure);
            writeToFirebase(finalFormStructure, true)
        }
        else {
            console.log('FAILED!')
            setSubmitAttempted(true);
        } //

    }
    register('formDomain', { required: true })
    register('allowedInstitutions', { required: true })
    register('allowedRoles', { required: true })
    register('currentNextForm', { required: true })
    const formTitle = removeSpecialCharacters(watch('formTitle'))
    // const formTitle = 'hello'

    const formPrefix = watch('formPrefix')
    const formPrefix2 = watch('formPrefix2')
    const allowedInstitutions = watch('allowedInstitutions')
    const formDomain = watch('formDomain')
    const [formLongName, setFormLongName] = useState('')
    useEffect(() => {
        // console.log('formLibrary', formLibrary)
        if (formLibrary && formLibrary.length > 0) {
            let displayName = ''
            if (formPrefix != 'N/A' && formPrefix != '') {
                let count = 1
                let valid = false
                let formIncrement = `${formPrefix}${count}`
                if (formLibrary && formLibrary.length > 0) {
                    // console.log('formLibrary', formLibrary)
                    const allFormTitles = formLibrary.map(form => {
                        // console.log(form)

                        if (form.displayName) {

                            return form.displayName

                        }
                        else if (form.formTitle) {

                            return form.formTitle


                        }
                        else if (form.form_questions && form.form_questions[0].question_type == 'form_title') {
                            const formName = form.form_questions[0].question_text
                            return formName

                        }
                    })
                    const allFormIDs = formLibrary.map(form => {
                        return form.formID;
                    })
                    // console.log('allFormTitles', allFormTitles)
                    // console.log(formIncrement)

                    while (true) {
                        let included = false;
                        for (let form of formLibrary) {
                            let title = '';
                            let formID = form.formID;
                            if (form.displayName) {

                                title = form.displayName

                            }
                            else if (form.formTitle) {

                                title = form.formTitle


                            }
                            else if (form.form_questions && form.form_questions[0].question_type == 'form_title') {
                                const formName = form.form_questions[0].question_text
                                title = formName

                            }
                            if (title.includes(formIncrement)) {
                                included = true
                                if (formID == editFormID) { //No need to increment of the form is in edit mode and same prefix.
                                    // console.log(form, count)
                                    included = false;
                                }
                                break;
                            }

                        }

                        if (!included) {
                            break;
                        }

                        count += 1
                        formIncrement = `${formPrefix}${count}`
                    }

                    displayName += `${formIncrement}_`
                }

            }
            //FIX HERE!
            if (formDomain == 'Specific' && allowedInstitutions && allowedInstitutions.length > 0) {
                console.log('allowedInstitutions', allowedInstitutions)
                let allAcronyms = allowedInstitutions.map(institution => {
                    const acronym = institution.match(/\([^\)]+\)/g)[0].replace(/\(|\)/g, "")
                    // console.log(acronym)//.replace(/\(|\)/g, "")
                    return acronym
                })
                // console.log('allAcronyms', allAcronyms)
                if (allAcronyms.length > 0)
                    displayName += `${allAcronyms.join('_')}_`

            }
            if (formPrefix2 != 'N/A' && formPrefix2 != '') {
                displayName += `${formPrefix2}`
            }
            if (formTitle)
                displayName += ` ${formTitle}`
            setFormLongName(displayName)
        }
    }, [formLibrary, formTitle, formPrefix, formPrefix2, formDomain, allowedInstitutions])

    const [currentAllowedInstitutions, setCurrentAllowedInstitutions] = useState([])
    const [currentExcludedInstitutions, setCurrentExcludedInstitutions] = useState([])

    const [currentFormDomain, setCurrentFormDomain] = useState('Common')
    const [currentNextForm, setCurrentNextForm] = useState([])

    useEffect(() => {
        setValue('formDomain', currentFormDomain);
    }, [currentFormDomain])
    useEffect(() => {
        // setValue('formDomain', currentFormDomain);
        console.log('currentAllowedRoles', currentAllowedRoles)
    }, [currentAllowedRoles])
    const buttonOutlineStyle = { borderColor: theme.highlightColor, color: theme.highlightColor }
    const buttonFillStyle = { borderColor: theme.highlightColor, backgroundColor: theme.highlightColor, color: 'white' }
    const [tempItems, setTempItems] = useState([]);
    useEffect(() => {
        console.log('tempItems: ', tempItems)
    }, [tempItems])
    useEffect(() => {
        // console.log('currentQuestionList: ', currentQuestionList)
    }, [currentQuestionList])
    useEffect(() => {
        if (userData)
            console.log('userData: ', userData)
    }, [userData])


    const [structuredFormQuestions, setStructuredFormQuestions] = useState([]);



    return <div>
        {/* <p>Form Title: {formTitle}</p> */}
        {/* <img src="https://i.ibb.co/S5JKMT4/SOAR.png" alt="SOAR" border="0" width="120" height="90"></img> */}
        <ReactTooltip backgroundColor={theme.highlightColor} />
        <RoleValidationComponent
            // requiredRoles={['student']}
            requiredRoles={['portal-admin', 'hub-lead', 'campus-lead']}
            redirect={false}
            setUserExternal={setUser}
            setUserDataExternal={setUserData} />

        {renderPage ? <div className="card m-3 border-light">

            <div className="row card-body">
                {formAction == 'edit' && <div>
                    <h6 className="float-end">
                        Last Edited: <i className={isBlinking ? 'blinking' : ''} style={isBlinking ? { backgroundColor: "limegreen" } : { fontWeight: 'bold' }}>
                            {lastUpdatedDateString ? lastUpdatedDateString : 'Never'}
                        </i>
                    </h6>
                </div>}
                {formURL && <div >
                    <Button className='float-end mx-2' variant='success'
                        href={`${formURL}`}> <AiOutlineRead style={{ marginBottom: 1, fontSize: 16 }} /> Form Preview
                    </Button>


                </div>}


                <h2>Form Overview {formID && <span>(ID: <i>{formID}</i>)</span>} </h2>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(handleUpload)}>
                        {/* <form onSubmit={handleRegistration}> */}

                        <div className="row">
                            <Input
                                name="formID"
                                label="Form ID"
                                className="mb-3 col-md-2"
                                placeholder={newFormID}
                                defaultValue={newFormID}
                                instructions={formAction == 'edit' ? 'Form ID cannot be altered when revising a form.' : 'Tips: a short and easy URL to access'}
                                disabled={formAction == 'edit'}
                                required={true}
                            />
                            <Select
                                name={'formPrefix'}
                                label={'Prefix'}
                                className="mb-3 col-md-1"
                                options={formPrefixes}
                                // required={true}

                                defaultValue={formPrefixes[0]}
                                instructions={'Specify form access type. Form numbering will be incremented automatically.'}
                            />
                            <Select
                                name={'formPrefix2'}
                                label={'Prefix 2'}
                                className="mb-3 col-md-3"
                                options={formPrefixes2}
                                // required={true}

                                defaultValue={formPrefixes2[0]}
                            // instructions={instructions}
                            />

                            <Input
                                name="formTitle"
                                label="Form Name"
                                className="mb-3 col-md-6"
                                required={true}
                                instructions={'Please do not enter special symbols.'}
                            />

                            {/* <Input
                                name="lastName"
                                label="Last Name"
                                className="mb-3 col-xl-6"
                            /> */}
                            <p>Display Name: <b>{formLongName}</b></p>
                            <p>Number of Questions: {numberOfQuestions}</p>
                            <Dropdown className=''
                                onSelect={(eventKey, event) => {
                                    if (!currentAllowedRoles.includes(eventKey)) {
                                        setValue('allowedRoles', [...currentAllowedRoles, eventKey]);
                                        setCurrentAllowedRoles([...currentAllowedRoles, eventKey])
                                    }
                                }}>
                                <Dropdown.Toggle variant={errors['allowedRoles'] && currentAllowedRoles.length <= 0 ? 'outline-danger' : ''}
                                    className={errors['allowedRoles'] && currentAllowedRoles.length <= 0 ? '' : 'button-outline-theme'}
                                    id="dropdown-basic">
                                    Specify Users
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {Object.keys(allRoles).map(role => {
                                        return <Dropdown.Item eventKey={role}>{allRoles[role]}</Dropdown.Item>
                                    })}
                                </Dropdown.Menu>
                                <AiFillQuestionCircle style={{
                                    marginLeft: '2px', marginBottom: '3px',
                                    color: theme.highlightColor, fontSize: '17px'
                                }} data-tip={'Specify which account type can access this form.'} />

                            </Dropdown>
                            <div className="my-2 col-md-10">
                                {Object.keys(allRoles).map(role => {

                                    if (currentAllowedRoles.includes(role)) {
                                        return <Button className="mx-2" variant='secondary'>{allRoles[role]}<AiFillCloseCircle style={{
                                            marginLeft: '5px', marginBottom: '3px',
                                            color: 'white', fontSize: '17px'
                                        }} onClick={() => {
                                            let allowedRoles = currentAllowedRoles
                                            var index = allowedRoles.indexOf(role);
                                            if (index !== -1) {

                                                allowedRoles.splice(index, 1);
                                                console.log(allowedRoles)
                                                setValue('allowedRoles', [...allowedRoles])
                                                setCurrentAllowedRoles([...allowedRoles])
                                            }
                                        }} /> </Button>
                                    }
                                    return <></>

                                })}

                                <div className="is-invalid">

                                </div>
                                <div className="invalid-feedback mb-2">
                                    {errors['allowedRoles'] && currentAllowedRoles.length <= 0 ?
                                        errors['allowedRoles'].message : ''}
                                </div>
                            </div>
                            <Dropdown className='mb-3'
                                onSelect={(eventKey, event) => { setCurrentFormDomain(eventKey) }}>
                                <Dropdown.Toggle className='button-outline-theme' id="dropdown-basic">
                                    Form Domain  {currentFormDomain ? `(${currentFormDomain}) ` : ' '}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey='Common'>Common</Dropdown.Item>
                                    <Dropdown.Item eventKey='Specific' >Specific</Dropdown.Item>
                                </Dropdown.Menu>
                                <AiFillQuestionCircle style={{
                                    marginLeft: '2px', marginBottom: '3px',
                                    color: theme.highlightColor, fontSize: '17px'
                                }} data-tip={'Common: Available to all institutions (except ones listed in the "Exclude an Institution" below).\nSpecific: available to one or more certain institutions.'} />

                            </Dropdown>
                            {currentFormDomain == 'Common' && <div>
                                <Dropdown className='col-md-12  mx-2'
                                    onSelect={(eventKey, event) => {
                                        setCurrentExcludedInstitutions([...currentExcludedInstitutions, eventKey])
                                        setValue('excludedInstitutions', [...currentExcludedInstitutions, eventKey]);
                                        setCurrentEventKey(eventKey)
                                    }}>
                                    <Dropdown.Toggle variant='outline-warning' id="dropdown-custom-components">
                                        Exclude an Institution: {currentExcludedInstitutions.length <= 0 && "None"}
                                    </Dropdown.Toggle>
                                    <AiFillQuestionCircle style={{
                                        marginLeft: '2px', marginBottom: '3px',
                                        color: '#ffcc00', fontSize: '17px'
                                    }} data-tip={'The institutions specified here will not be able to see the form on their main page.'} />


                                    <Dropdown.Menu as={CustomMenu} >
                                        {institutionsList.map(institute => {
                                            if (!currentExcludedInstitutions || !currentExcludedInstitutions.includes(institute))
                                                return <Dropdown.Item eventKey={institute} active={institute == currentEventKey}>{institute}</Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="is-invalid">
                                    {/* <div className="invalid-feedback">hello22</div> */}

                                </div>
                                <div className="invalid-feedback mb-2">{errors['institution'] && !currentEventKey ? errors['institution'].message : ''}</div>
                                <div className="mb-2 col-md-6">
                                    {institutionsList && institutionsList.map(institution => {
                                        const displayStyle = currentExcludedInstitutions.includes(institution) ? 'block' : 'none'

                                        return <Button style={{ display: displayStyle }} className="mx-4" variant='secondary'>{institution}<AiFillCloseCircle style={{
                                            marginLeft: '5px', marginBottom: '3px',
                                            color: 'white', fontSize: '17px'
                                        }} onClick={() => {
                                            let excludedInstitutions = currentExcludedInstitutions
                                            var index = excludedInstitutions.indexOf(institution);
                                            if (index !== -1) {

                                                excludedInstitutions.splice(index, 1);
                                                console.log(excludedInstitutions)
                                                setValue('excludedInstitutions', [...excludedInstitutions])
                                                setCurrentExcludedInstitutions([...excludedInstitutions])
                                            }
                                        }} /> </Button>
                                    })}
                                    {/* <Button className="mx-1" variant='secondary'>Institution 1 <AiFillCloseCircle style={{
                                        marginLeft: '2px', marginBottom: '3px',
                                        color: 'white', fontSize: '17px'
                                    }} /> </Button>
                                    <Button className="mx-1" variant='secondary'>Institution 2 </Button> */}
                                    <div className="is-invalid">
                                        {/* <div className="invalid-feedback">hello22</div> */}

                                    </div>
                                    <div className="invalid-feedback mb-2">{errors['excludedInstitutions'] && currentExcludedInstitutions.length <= 0 ? errors['excludedInstitutions'].message : ''}</div>
                                </div>
                            </div>}


                            {currentFormDomain == 'Specific' && <div>
                                <Dropdown className='col-md-12  mx-2'
                                    onSelect={(eventKey, event) => {
                                        setCurrentAllowedInstitutions([...currentAllowedInstitutions, eventKey])
                                        setValue('allowedInstitutions', [...currentAllowedInstitutions, eventKey]);
                                        setCurrentEventKey(eventKey)
                                    }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        Add an Institution
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu} >
                                        {institutionsList.map(institute => {
                                            if (!currentAllowedInstitutions || !currentAllowedInstitutions.includes(institute))
                                                return <Dropdown.Item eventKey={institute} active={institute == currentEventKey}>{institute}</Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="is-invalid">
                                    {/* <div className="invalid-feedback">hello22</div> */}

                                </div>
                                <div className="invalid-feedback mb-2">{errors['institution'] && !currentEventKey ? errors['institution'].message : ''}</div>
                                <div className="mb-2 col-md-6">
                                    {institutionsList.map(institution => {
                                        const displayStyle = currentAllowedInstitutions.includes(institution) ? 'block' : 'none'

                                        return <Button style={{ display: displayStyle }} className="mx-4" variant='secondary'>{institution}<AiFillCloseCircle style={{
                                            marginLeft: '5px', marginBottom: '3px',
                                            color: 'white', fontSize: '17px'
                                        }} onClick={() => {
                                            let allowedInstitutions = currentAllowedInstitutions
                                            var index = allowedInstitutions.indexOf(institution);
                                            if (index !== -1) {

                                                allowedInstitutions.splice(index, 1);
                                                console.log(allowedInstitutions)
                                                setValue('allowedInstitutions', [...allowedInstitutions])
                                                setCurrentAllowedInstitutions([...allowedInstitutions])
                                            }
                                        }} /> </Button>
                                    })}
                                    {/* <Button className="mx-1" variant='secondary'>Institution 1 <AiFillCloseCircle style={{
                                        marginLeft: '2px', marginBottom: '3px',
                                        color: 'white', fontSize: '17px'
                                    }} /> </Button>
                                    <Button className="mx-1" variant='secondary'>Institution 2 </Button> */}
                                    <div className="is-invalid">
                                        {/* <div className="invalid-feedback">hello22</div> */}

                                    </div>
                                    <div className="invalid-feedback mb-2">{errors['allowedInstitutions'] && currentAllowedInstitutions.length <= 0 ? errors['allowedInstitutions'].message : ''}</div>
                                </div>
                            </div>}


                            {formAction == 'edit' && editFormID && <FormOrderTracker formID={editFormID} formLongName={formLongName} />}
                            <h4>Date </h4>

                            <div className='row'>
                                <div className='col-md-4 form-check mx-3'>

                                    <label>Start Date</label>

                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'startDateRequired'}
                                        // value={true}
                                        value={'startDateRequired'}
                                        // defaultChecked={defaultCheckValue}
                                        {...register('startDateRequired')}
                                    />
                                    {startDateRequired && <Input
                                        name={'startDate'}
                                        type="date"
                                        label={'Pick a date'}
                                        required={true}
                                        // className="mb-3 col-md-4"
                                        defaultValue={format(todayDate, "yyyy-mm-dd")}
                                        instructions={'Form is officially open on this date.'}
                                    />}



                                </div>
                                <div className='col-md-4 form-check'>
                                    <label>End Date</label>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'endDateRequired'}
                                        // value={true}
                                        value={'endDateRequired'}
                                        // defaultChecked={defaultCheckValue}
                                        {...register('endDateRequired')}
                                    />
                                    {endDateRequired && <Input
                                        name={'endDate'}
                                        type="date"
                                        label={'Pick a date'}
                                        // className="mb-3 col-md-4"
                                        required={true}
                                        defaultValue={format(tomorrowDate, "yyyy-mm-dd")}
                                        instructions={'Form will be closed for submissions after this date.'}
                                    />}
                                </div>
                            </div>






                            <Row style={{ marginTop: 10 }}>
                                <Col md='12' style={{ textAlign: 'center' }}>
                                    <hr></hr>
                                    <h3>Question Lists</h3>
                                    <p className='text-muted'>Current Total of Questions: {currentQuestionList.length}</p>

                                </Col>


                            </Row>

                            <DragNDropList
                                currentQuestionList={currentQuestionList}
                                setTempItems={setCurrentQuestionList}
                                setStructuredFormQuestions={setStructuredFormQuestions}
                                latestRetrievedForm={formData}
                                formAction={formAction}
                            />
                        </div>
                        {formAction == 'edit' && loading && <Row style={{ marginTop: 5 }}>
                            {/* <Col md='12'><SpinningCircles
                                    stroke="#000000"
                                    fill={theme.highlightColor}
                                    speed={1}
                                    width="50px"
                                    style={{ margin: "auto" }}
                                /></Col> */}
                            <Col md='12' style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <Bars stroke="#000000"
                                    fill={theme.highlightColor}
                                    speed={1}
                                    width="100%"
                                    height='200px'
                                    style={{ margin: "auto" }} />
                                <b style={{ fontSize: 18 }}><i>Loading Form Data...</i></b>
                                {<p className="text-secondary">If your form is long, please be patient.</p>}
                            </Col>

                        </Row>}
                        <div className="row">
                            <div className="col-md-3">
                                <div className='my-2'>
                                    {/* {questionObj.question_text} */}
                                    <Dropdown className=''
                                        onSelect={(eventKey, event) => {
                                            addNewQuestion(eventKey);
                                            // if (!currentAllowedRoles.includes(eventKey)) {
                                            //     setValue('allowedRoles', [...currentAllowedRoles, eventKey]);
                                            //     setCurrentAllowedRoles([...currentAllowedRoles, eventKey])
                                            // }
                                        }}>
                                        <Dropdown.Toggle variant={errors['currentQuestionList'] && currentQuestionList.length <= 0 ? 'outline-danger' : ''}
                                            className={errors['allowedRoles'] && currentQuestionList.length <= 0 ? '' : 'button-outline-theme'}
                                            id="dropdown-basic">
                                            Add a Question
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {Object.keys(allQuestionTypes).map(qType => {
                                                return <Dropdown.Item eventKey={qType}>{allQuestionTypes[qType][0]}</Dropdown.Item>
                                            })}
                                        </Dropdown.Menu>
                                        <AiFillQuestionCircle style={{
                                            marginLeft: '2px', marginBottom: '3px',
                                            color: theme.highlightColor, fontSize: '17px'
                                        }} data-tip={'Specify which question type you would like to add.'} />

                                    </Dropdown>
                                </div>
                                {/* <Button >Add a question</Button> */}

                            </div>
                        </div>
                        <Row>
                            <Col className='form-check'>
                                <label style={{ fontWeight: generateKeywordsOnSubmit ? 'bold' : 'initial' }}>Generate Speech-out Keywords Automatically For Missing Fields</label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={'generateKeywordsOnSubmit'}
                                    // value={true}
                                    value={'generateKeywordsOnSubmit'}
                                    // defaultChecked={defaultCheckValue}
                                    {...register('generateKeywordsOnSubmit')}
                                />
                                <p><i>Note: Dropdown keywords will be overwritten if invalid. </i></p>
                                {currentQuestionList.length > 15 && <p className="text-danger"> WARNING : Your form is long. This feature will take a longer time if a form is longer than 15 questions. Only click ONCE to generate.
                                    <br></br>You might see an unresponsive pop-up on your browser while it's generating. Please click "Wait" and be patient for a few minutes.</p>}
                            </Col>

                        </Row>

                        <div className="row d-flex justify-content-center my-3">

                            <div className="form-group">
                                <button type="submit" className={`btn button-fill-theme mr-1`}>
                                    {formAction == 'edit' ? 'Revise and Save' : 'Upload New Form'}
                                </button>
                                {/* <Button className='mx-2' variant='outline-secondary' onClick={() => {
                                    // setFormID(null)
                                    // setLatestUnuploadedForm(null);
                                    // fileInputRef.current.value = "";
                                }}>Cancel </Button> */}

                                {formURL && <Button className='float-end mx-2' variant='success' href={`${formURL}`}>

                                    <AiOutlineRead style={{ marginBottom: 1, fontSize: 16 }} /> Form Preview </Button>}



                            </div>
                        </div>
                        {formAction == 'edit' && <div>
                            <h6 className="float-end ">
                                Last Edited: <i className={isBlinking ? 'blinking' : ''} style={isBlinking ? { backgroundColor: "limegreen" } : { fontWeight: 'bold' }}>
                                    {lastUpdatedDateString ? lastUpdatedDateString : 'Never'}
                                </i>
                            </h6>
                        </div>}
                        <small className="text-muted">
                            By clicking the 'Upload' button, you confirm that you accept
                            our Terms of use and Privacy Policy.
                        </small>
                    </form>
                    <Modal show={showRefresh} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Database Update Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            There are new changes to this form in the database. Would you like you to refresh the page?
                            {mostRecentFormData && <div>
                                <p><br></br>
                                    Last Updated: <b>{lastUpdatedDateString}</b></p>
                                {/* {mostRecentFormData.userID == userData.userID && <p>Edited By: You</p>} */}
                            </div>}
                            <p className='text-danger'><br></br><i>
                                Warning: You will lose your current progress if you refresh. Please select Continue Editing if you would like to keep the progress.
                            </i>
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-warning" onClick={handleRefresh}>
                                Refresh
                            </Button>
                            <Button className={`btn button-fill-theme mr-1`} onClick={handleClose}>
                                Continue editing form
                            </Button>

                        </Modal.Footer>
                    </Modal>
                </FormProvider>
            </div>
        </div> : <h2>FORM NOT FOUND!</h2>
        }
    </div>
}

