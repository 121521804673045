import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { BarCharts } from './BarCharts';
import { LineChart } from './LineCharts';
import { db } from "../Firebase/firebasedb";
import { doc, setDoc, getDoc, orderBy, serverTimestamp, collection, query, where, onSnapshot, getDocs } from "firebase/firestore";
import { PieChart } from "./PieChart";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Input, RadioGroup, Textarea, Checkbox, CheckboxFollowup } from "../Components";
import { BivariableCharts } from "./BivariableCharts";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { CustomMenu, FormLibrary, RoleValidationComponent, containsSpecialCharacters, generateRandomAnswersBulk, generateRandomNumber, getRGB, notQuestionTypes, removeSpecialCharacters } from "../Automatic Forms/Utils";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { AiFillCloseCircle, AiFillQuestionCircle, AiFillSave } from "react-icons/ai";
import theme from "../Theme/theme";
import { colorFamily, getRandomColor } from "../Fixed Sources/colorScales";
import ReactTooltip from "react-tooltip";
import { NavBar } from "../Components/NavBar";
import { allRoles, isCampusLead, isHubLead, isPortalAdmin } from "../Fixed Sources/accountTypes";
import { BsClock, BsCloudCheck, BsCloudSlash, BsFillArchiveFill } from "react-icons/bs";
import { RadarChart } from "./RadarChart";


export const ChartEdit = ({ userData }) => {
    console.log('userData', userData)
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Cannot be empty!')
            .test('No Special Character', 'Your chart cannot contain special characters!', (result) => {
                if (containsSpecialCharacters(result)) {
                    return false;
                }
                return true;
            }),
        description: Yup.string().required('Cannot be empty!'),
        allowedInstitutions: Yup.array().test("Required", "Please choose at least one institution to share the form with!", (result) => {
            // console.log(result);
            if (currentFormDomain == 'Specific') {
                if (!result || result.length <= 0)
                    return false;
            }
            return true;
        }),
        allowedRoles: Yup.array().test("Required", 'At least one account type must be specified!', (result) => {
            // console.log(currentAllowedRoles)
            if (!result || result.length <= 0) {
                return false;
            }
            return true;
        }),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const methods = useForm(formOptions);
    const { handleSubmit, reset, watch, register, setError, setValue, getValues, formState: { errors } } = methods;
    const [chartData, setChartData] = useState(null);
    const [currentSelectedChart, setCurrentSelectedChart] = useState("Bar Chart");
    const [currentColor, setCurrentColor] = useState(getRandomColor())
    // const [title, setChartTitle] = useState(null);
    const title = watch('title');
    const description = watch('description');
    const [institutionsList, setInstitutionsList] = useState([]);
    const [currentAllowedRoles, setCurrentAllowedRoles] = useState([])
    const [currentAllowedInstitutions, setCurrentAllowedInstitutions] = useState([])
    const [currentExcludedInstitutions, setCurrentExcludedInstitutions] = useState([])
    const [currentEventKey, setCurrentEventKey] = useState(null)
    const [currentFormDomain, setCurrentFormDomain] = useState('Common')
    const [currentStatus, setCurrentStatus] = useState('unpublished')
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalSuccess, setModalSuccess] = useState(false);
    const changeChartStatus = (newStatus) => {
        setCurrentStatus(newStatus); // Or however you update the chart's status in your state
        // Put any other code you need to execute when the status changes here
    }
    const handleOpenModal = (message) => {
        setModalMessage(message);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalSuccess(false);
    };

    const handleConfirmModal = (newStatus) => {
        let submittedData = getValues();
        if (newStatus == 'awaiting-approval' || newStatus == 'published') {
            submittedData = {
                ...chartData,
                ...submittedData,

                status: newStatus,
                createdAt: new Date(),
                createdBy: userData.userID,
                chartType: currentSelectedChart,
                color: currentColor,
                title: title,
            }
        }
        else {
            submittedData = {
                // ...submittedData,
                status: newStatus,
            }
        }
        console.log('submittedData', submittedData)
        writeToFirebase(submittedData);
        // setCurrentStatus(newStatus);
        setShowModal(false);
    };
    const [clickedButton, setClickedButton] = useState(null);

    const handleButtonClick = (buttonName) => {
        setClickedButton(buttonName);
    };
    const writeToFirebase = (data) => {
        const docRef = doc(db, "chart_library", chartID);
        console.log('saving...', data);

        setDoc(docRef, data, { merge: true }).then(() => {
            // alert(`${chartID} written to Firebase successfully.`)
            setModalSuccess(true);
            handleOpenModal(`Chart ${chartID} updated to Firebase successfully.`)
        });
    }

    const formatStatus = (status) => {
        return status
            .split('-')  // Split the status into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
            .join(' ');  // Combine the words back into a single string, separated by spaces
    }
    register('formDomain', { required: true })
    register('allowedInstitutions', { required: true })
    register('allowedRoles', { required: true })

    const StatusComponent = ({ status }) => {
        let color = 'red'
        let icon = <BsCloudCheck />
        if (status) {
            if (status.toLowerCase() == 'published') {
                color = 'green'
            }
            else if (status.toLowerCase() == 'unpublished') {
                color = '#C41E3A'
                icon = <BsCloudSlash />
            }
            else if (status.toLowerCase() == 'awaiting-approval') {
                color = theme.warningColor
                icon = <BsClock />
            }
            else if (status.toLowerCase() == 'archived') {
                color = 'gray'
                icon = <BsFillArchiveFill style={{ marginBottom: 4 }} />
            }

            status = status.toUpperCase();
            const inputStyle = { color, fontWeight: 'bold' }
            return (
                < span
                    style={{
                        textAlign: "center",
                        cursor: "pointer",
                        // lineHeight: "normal"
                        ...inputStyle
                    }}>
                    {status} {icon}

                </span>
            );
            return <></>
        }

    }


    const { chartID } = useParams();
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button variant={errors['allowedInstitutions'] && currentAllowedInstitutions.length <= 0 ? 'outline-danger' : 'outline-info'}
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}>{children}
            &#x25bc;</Button>
    ));


    const handleUpload = (data, e) => {
        e.preventDefault();
        console.log('submitted data', data)
        let { status } = data;
        console.log('clickedButton', clickedButton)
        if (clickedButton === "publish") {
            if (status === 'unpublished') {
                if (isCampusLead(userData.atype) || isHubLead(userData.atype)) {
                    handleOpenModal('You do not have permission to publish a chart. Are you sure you want to push the chart for approval?')
                }
                else if (isPortalAdmin(userData.atype)) {
                    handleOpenModal('Are you sure you want to publish the chart?')

                }
            }
            else if (status === 'awaiting-approval') {
                if (isPortalAdmin(userData.atype)) {
                    // console.log('here')
                    handleOpenModal('Are you sure you want to publish the chart?')
                }
            }
            else if (status === 'published') {
                if (isCampusLead(userData.atype) || isHubLead(userData.atype)) {
                    handleOpenModal('You do not have permission to publish a chart. Are you sure you want to push the chart for approval?')
                }
                else if (isPortalAdmin(userData.atype)) {
                    handleOpenModal('Are you sure you want to revise and publish the chart?')

                }
            }
        } else if (clickedButton === "unpublish") {
            handleOpenModal('Are you sure you want to unpublish the chart?')
        }
    }
    console.log(errors);
    useEffect(() => {
        const docRef = doc(db, "information_sources", 'institutions');
        const unsub1 = onSnapshot(docRef, (docSnap) => {
            if (docSnap.exists()) {
                const data = docSnap.data();
                setInstitutionsList(data['institution_list'])
            } else {

            }
        });
        const docRef2 = doc(db, "chart_library", chartID);

        const unsubscribe = onSnapshot(docRef2, (doc) => {
            if (doc.exists()) {
                console.log('firebase chartData()', doc.data())
                setChartData(doc.data());
                const remoteChartData = doc.data();
                if (remoteChartData) {
                    setModalSuccess(false);
                    const { formDomain, excludedInstitutions, allowedInstitutions, allowedRoles, chartType, color, title, description, status } = remoteChartData;
                    if (chartType) {
                        setCurrentSelectedChart(chartType);
                    }
                    if (color) {
                        setCurrentColor(color)
                    }
                    if (title) {
                        // setChartTitle(title)
                        setValue('title', title)
                    }
                    if (description) {
                        // setChartTitle(title)
                        setValue('description', description)
                    }
                    if (status) {
                        setCurrentStatus(status);
                    }
                    if (formDomain) {
                        setCurrentFormDomain(formDomain)
                    }
                    if (allowedInstitutions) {
                        setCurrentAllowedInstitutions(allowedInstitutions)
                    }
                    if (excludedInstitutions) {
                        setCurrentExcludedInstitutions(excludedInstitutions)
                    }
                    if (allowedRoles) {
                        setCurrentAllowedRoles(allowedRoles);
                    }
                    // setValue('formDomain', formDomain);
                    // setValue('allowedInstitutions', allowedInstitutions);

                }
            } else {
                console.log("No such document!");
            }
        });

        // Clean up the onSnapshot listener when the component is unmounted
        return () => unsubscribe();
    }, [chartID, db]);

    useEffect(() => {

    }, [chartData])
    useEffect(() => {
        setValue('formDomain', currentFormDomain);
    }, [currentFormDomain])
    useEffect(() => {
        setValue('allowedRoles', currentAllowedRoles);
    }, [currentAllowedRoles])
    useEffect(() => {
        setValue('status', currentStatus);
    }, [currentStatus])
    useEffect(() => {
        setValue('allowedInstitutions', [...currentAllowedInstitutions]);
    }, [currentAllowedInstitutions])
    useEffect(() => {
        setValue('excludedInstitutions', [...currentExcludedInstitutions]);
    }, [currentExcludedInstitutions])
    useEffect(() => {
        if (title && chartData) {
            chartData.title = title;
            chartData.description = description ? description : '';

            setChartData({ ...chartData });
            // setValue('title', title);
            // setValue('title', title)
        }
    }, [title, description])
    useEffect(() => {
        if (currentColor && chartData) {
            console.log('currentColor', currentColor, chartData)
            chartData.color = currentColor;
            setChartData({ ...chartData });
        }
    }, [currentColor])
    const ChartWithDescription = ({ description, ChartComponent, chartData }) => {
        return (
            <>
                <p className='text-center'>Description: <i>{description}</i></p>
                <ChartComponent chartData={chartData} />
            </>
        );
    };

    const RenderSelectedChart = ({ chartType, chartData }) => {
        const { description = '' } = chartData;

        const chartMappings = {
            'Pie Chart': PieChart,
            'Bar Chart': BarCharts,
            'Line Chart': LineChart,
            'Radar Chart': RadarChart
        };

        const SelectedChartComponent = chartMappings[chartType];

        if (SelectedChartComponent) {
            return <ChartWithDescription description={description} ChartComponent={SelectedChartComponent} chartData={chartData} />;
        }

        return <></>;
    };
    if (!chartData) {
        return <div>Loading {chartID} - {title}...</div>;
    }

    return (
        <div>
            <br></br>
            <hr></hr>
            <ReactTooltip />
            {/* Your other publishing settings components... */}
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleUpload)}>
                    <details className='detail-summary' open>
                        <summary style={{ fontSize: 20 }}>Chart Preview</summary>
                        {chartData && <Row>
                            <Col md='3' style={{ textAlign: 'center', alignItems: 'center', display: 'flex' }}>


                            </Col>
                            <Col md='5' >
                                <Row >
                                    <Col md='4' className='center-text-container' >
                                        <label htmlFor={'chart-name'} className={`form-label-inline`}>
                                            Enter Chart Name:
                                        </label>
                                    </Col>
                                    <Col md='8'>
                                        <div>
                                            <input
                                                id='chart-name'
                                                type={'text'}
                                                className={'form-control '}
                                                // placeholder={'Chart Name Here...'}
                                                // onChange={(event) => {
                                                //     setChartTitle(event.target.value);
                                                // }}
                                                placeholder={title}
                                                defaultValue={title}
                                                {...register('title')}

                                            />


                                        </div>
                                        <div className="is-invalid">

                                        </div>
                                        <div className="invalid-feedback mb-2">
                                            {errors[`title`] &&
                                                errors[`title`].message}
                                        </div>
                                    </Col>

                                </Row>
                                <Row className='mt-2'>
                                    <Col md='4' className='center-text-container' >
                                        <label htmlFor={'chart-description'} className={`form-label-inline`}>
                                            Description:
                                        </label>
                                    </Col>
                                    <Col md='8'>
                                        <div>
                                            <textarea
                                                id='chart-description'
                                                type={'text'}
                                                rows="4"
                                                className={'form-control '}
                                                placeholder={'Chart Description Here...'}
                                                // onChange={(event) => {
                                                //     setChartDescription(event.target.value);
                                                // }}
                                                {...register('description')}

                                            />
                                            <div className="is-invalid">

                                            </div>
                                            <div className="invalid-feedback mb-2">
                                                {errors[`description-${chartID}`] &&
                                                    errors[`description-${chartID}`].message}
                                            </div>
                                            <div className="is-invalid">

                                            </div>
                                            <div className="invalid-feedback mb-2">
                                                {errors[`description`] &&
                                                    errors[`description`].message}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>


                            </Col>
                            <Col md='3'>
                                {currentSelectedChart != 'Pie Chart' && <div className='col-md-3' style={{ textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                                    <Dropdown onSelect={(eventKey, event) => {
                                        console.log('EventKey', eventKey)
                                        setCurrentColor(getRGB(eventKey))
                                    }}>
                                        <Dropdown.Toggle variant='outline-secondary'>
                                            Chart Color: <div class="square-family" style={{ backgroundColor: currentColor }}></div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {colorFamily.map(color => {
                                                return <Dropdown.Item eventKey={color} active={currentColor == color}>
                                                    <div class="square-family" style={{ backgroundColor: color }}></div>
                                                </Dropdown.Item>
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>}
                            </Col>
                            <Col md='1' >

                                {/* <AiFillSave
                        style={{ color: '#474d4a', fontSize: 30, cursor: 'pointer', marginLeft: 4 }}
                        onClick={() => {
                            writeToFirebase(chartData);
                        }}
                        data-tip='Save chart to database' /> */}
                            </Col>
                        </Row>}
                        {/* <BarCharts chartData={chartData} /> */}
                        <hr></hr>
                        <RenderSelectedChart chartType={currentSelectedChart} chartData={chartData} />
                    </details>

                    <Row>
                        {/* <h4 className='text-center'>Chart ID: {chartID}</h4> */}
                        <div class="line">
                            <div class="line-before"></div>
                            <span><h4>Publishing Settings (Chart ID: {chartID})</h4></span>
                            <div class="line-after"></div>
                        </div>
                    </Row>
                    <Row className='mb-2'>
                        <Col md='2'>
                            <Dropdown className=''
                                onSelect={(eventKey, event) => {
                                    if (!currentAllowedRoles.includes(eventKey)) {
                                        setValue('allowedRoles', [...currentAllowedRoles, eventKey]);
                                        setCurrentAllowedRoles([...currentAllowedRoles, eventKey])
                                    }
                                }}>
                                <Dropdown.Toggle variant={errors['allowedRoles'] && currentAllowedRoles.length <= 0 ? 'outline-danger' : ''}
                                    className={errors['allowedRoles'] && currentAllowedRoles.length <= 0 ? '' : 'button-outline-theme'}
                                    id="dropdown-basic">
                                    Specify Users
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {Object.keys(allRoles).map(role => {
                                        if (currentAllowedRoles.includes(role)) {
                                            return <Dropdown.Item eventKey={role} disabled={true}>{allRoles[role]} (Already Selected)</Dropdown.Item>
                                        }
                                        return <Dropdown.Item eventKey={role}>{allRoles[role]}</Dropdown.Item>

                                    })}
                                </Dropdown.Menu>
                                <AiFillQuestionCircle style={{
                                    marginLeft: '2px', marginBottom: '3px',
                                    color: theme.highlightColor, fontSize: '17px'
                                }} data-tip={'Specify which account type can access this form.'} />

                            </Dropdown>
                        </Col>
                        <Col md='10'>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {Object.keys(allRoles).map(role => {

                                    if (currentAllowedRoles.includes(role)) {
                                        return <Button className="mx-2" variant='secondary' >{allRoles[role]}

                                            <AiFillCloseCircle style={{
                                                marginLeft: '5px', marginBottom: '3px',
                                                color: 'white', fontSize: '17px'
                                            }} onClick={() => {
                                                let allowedRoles = currentAllowedRoles
                                                var index = allowedRoles.indexOf(role);
                                                if (index !== -1) {

                                                    allowedRoles.splice(index, 1);
                                                    console.log(allowedRoles)
                                                    setValue('allowedRoles', [...allowedRoles])
                                                    setCurrentAllowedRoles([...allowedRoles])
                                                }
                                            }} /> </Button>
                                    }
                                    return <></>

                                })}
                            </div>


                            <div className="is-invalid">

                            </div>
                            <div className="invalid-feedback mb-2">
                                {errors['allowedRoles'] && currentAllowedRoles.length <= 0 ?
                                    errors['allowedRoles'].message : ''}
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col md='3'>
                            <Dropdown className='mb-3'
                                onSelect={(eventKey, event) => { setCurrentFormDomain(eventKey) }}>
                                <Dropdown.Toggle className='button-outline-theme' id="dropdown-basic">
                                    Chart Domain  {currentFormDomain ? `(${currentFormDomain}) ` : ' '}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey='Common'>Common</Dropdown.Item>
                                    <Dropdown.Item eventKey='Specific' >Specific</Dropdown.Item>
                                </Dropdown.Menu>
                                <AiFillQuestionCircle style={{
                                    marginLeft: '2px', marginBottom: '3px',
                                    color: theme.highlightColor, fontSize: '17px'
                                }} data-tip={'Common: Available to all institutions (except ones listed in the "Exclude an Institution" below).\nSpecific: available to one or more certain institutions.'} />

                            </Dropdown>
                        </Col>
                        <Col md='9'>
                            {currentFormDomain == 'Common' && <div>
                                <Dropdown
                                    onSelect={(eventKey, event) => {
                                        setCurrentExcludedInstitutions([...currentExcludedInstitutions, eventKey])
                                        setValue('excludedInstitutions', [...currentExcludedInstitutions, eventKey]);
                                        // setCurrentEventKey(eventKey)
                                    }}>
                                    <Dropdown.Toggle variant='outline-warning' id="dropdown-custom-components">
                                        Exclude an Institution: {currentExcludedInstitutions.length <= 0 && "None"}
                                    </Dropdown.Toggle>
                                    <AiFillQuestionCircle style={{
                                        marginLeft: '2px', marginBottom: '3px',
                                        color: '#ffcc00', fontSize: '17px'
                                    }} data-tip={'The institutions specified here will not be able to see the chart on their main page.'} />


                                    <Dropdown.Menu as={CustomMenu} >
                                        {institutionsList.map(institute => {
                                            if (!currentExcludedInstitutions || !currentExcludedInstitutions.includes(institute))
                                                return <Dropdown.Item eventKey={institute}>{institute}</Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="is-invalid">
                                    {/* <div className="invalid-feedback">hello22</div> */}

                                </div>
                                <div className="invalid-feedback mb-2">{errors['institution'] && !currentEventKey ? errors['institution'].message : ''}</div>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {institutionsList && institutionsList.map(institution => {
                                        const displayStyle = currentExcludedInstitutions.includes(institution) ? 'block' : 'none'

                                        return <Button style={{ display: displayStyle, margin: 2, padding: '2px 10px' }} variant='secondary'>{institution}<AiFillCloseCircle style={{
                                            marginLeft: '5px', marginBottom: '3px',
                                            color: 'white', fontSize: '17px'
                                        }} onClick={() => {
                                            let excludedInstitutions = currentExcludedInstitutions
                                            var index = excludedInstitutions.indexOf(institution);
                                            if (index !== -1) {

                                                excludedInstitutions.splice(index, 1);
                                                console.log(excludedInstitutions)
                                                setValue('excludedInstitutions', [...excludedInstitutions])
                                                setCurrentExcludedInstitutions([...excludedInstitutions])
                                            }
                                        }} /> </Button>
                                    })}
                                    {/* <Button className="mx-1" variant='secondary'>Institution 1 <AiFillCloseCircle style={{
                                        marginLeft: '2px', marginBottom: '3px',
                                        color: 'white', fontSize: '17px'
                                    }} /> </Button>
                                    <Button className="mx-1" variant='secondary'>Institution 2 </Button> */}
                                    <div className="is-invalid">
                                        {/* <div className="invalid-feedback">hello22</div> */}

                                    </div>
                                    <div className="invalid-feedback mb-2">{errors['excludedInstitutions'] && currentExcludedInstitutions.length <= 0 ? errors['excludedInstitutions'].message : ''}</div>
                                </div>
                            </div>}
                            {currentFormDomain == 'Specific' && <div>
                                <Dropdown className=''
                                    onSelect={(eventKey, event) => {
                                        setCurrentAllowedInstitutions([...currentAllowedInstitutions, eventKey])
                                        setValue('allowedInstitutions', [...currentAllowedInstitutions, eventKey]);
                                        setCurrentEventKey(eventKey)
                                    }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        Add an Institution
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu} >
                                        {institutionsList.map(institute => {
                                            if (!currentAllowedInstitutions || !currentAllowedInstitutions.includes(institute))
                                                return <Dropdown.Item
                                                    eventKey={institute}

                                                >{institute}</Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="is-invalid">
                                    {/* <div className="invalid-feedback">hello22</div> */}

                                </div>
                                <div className="invalid-feedback mb-2">{errors['institution'] && !currentEventKey ? errors['institution'].message : ''}</div>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {institutionsList.map(institution => {
                                        const displayStyle = currentAllowedInstitutions.includes(institution) ? 'block' : 'none'

                                        return <Button style={{ display: displayStyle, fontSize: "13px", margin: 2, padding: "2px 10px" }} variant='secondary'>{institution}
                                            <AiFillCloseCircle style={{
                                                marginLeft: '5px', marginBottom: '3px',
                                                color: 'white', fontSize: '17px',

                                            }} onClick={() => {
                                                let allowedInstitutions = currentAllowedInstitutions
                                                var index = allowedInstitutions.indexOf(institution);
                                                if (index !== -1) {

                                                    allowedInstitutions.splice(index, 1);
                                                    console.log(allowedInstitutions)
                                                    setValue('allowedInstitutions', [...allowedInstitutions])
                                                    setCurrentAllowedInstitutions([...allowedInstitutions])
                                                }
                                            }} /> </Button>
                                    })}
                                    <div className="invalid-feedback mb-2">{errors['allowedInstitutions'] && currentAllowedInstitutions.length <= 0 ? errors['allowedInstitutions'].message : ''}</div>
                                </div>
                            </div>}
                        </Col>
                    </Row>


                    <hr></hr>
                    <Row>
                        <Col md='4' style={{ marginLeft: 3, display: 'flex', alignItems: 'center', fontSize: 18 }}>

                            <label>Current Chart Status: <StatusComponent status={currentStatus} /></label>
                        </Col>

                        <Col md='4' style={{ textAlign: 'center' }}>
                            {currentStatus === "published" && <div>
                                <Button type='submit' size="lg" className="button-fill-theme mx-2" onClick={() => handleButtonClick("publish")}>
                                    Revise and Publish
                                </Button>
                                <Button type='submit' size="lg" variant="secondary" onClick={() => handleButtonClick("unpublish")}>
                                    Unpublish
                                </Button>
                            </div>

                            }

                            {currentStatus === "unpublished" &&
                                <Button type='submit' className="button-fill-theme"
                                    onClick={() => handleButtonClick("publish")} size="lg"
                                >
                                    Publish
                                </Button>
                            }
                            {currentStatus === "awaiting-approval" &&
                                <div>
                                    <Button type='submit' size="lg" className="button-fill-theme mx-2" onClick={() => handleButtonClick("publish")}>
                                        Publish
                                    </Button>
                                    <Button type='submit' size="lg" variant="secondary" onClick={() => handleButtonClick("unpublish")}>
                                        Unpublish
                                    </Button>
                                </div>
                            }

                            <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{modalSuccess ? 'Success' : 'Confirm Action'}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {modalMessage}
                                    <br></br><br></br>
                                    {modalMessage.includes('unpublish') && <>

                                        <p className='text-muted'><i>Note: Unpublishing will not save your current changes. Only publishing will save the changes to the database.</i></p>
                                    </>}
                                </Modal.Body>
                                <Modal.Footer>
                                    {modalSuccess ? (
                                        <Button variant="primary" onClick={handleCloseModal}>
                                            Ok
                                        </Button>
                                    ) : (
                                        <>
                                            <Button variant="secondary" onClick={handleCloseModal}>
                                                Cancel
                                            </Button>
                                            <Button variant="primary" onClick={() => {
                                                let newStatus = 'published';
                                                if (modalMessage.includes('unpublish'))
                                                    newStatus = 'unpublished';
                                                else if (modalMessage.includes('approval')) newStatus = 'awaiting-approval';
                                                handleConfirmModal(newStatus);
                                            }}>
                                                Confirm
                                            </Button>

                                        </>
                                    )}

                                </Modal.Footer>
                            </Modal>
                        </Col>


                    </Row>

                </form>
            </FormProvider>
        </div >
    );
};
