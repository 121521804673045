import React, { useEffect, useState } from "react";
import {
    getAuth,
    onAuthStateChanged,
} from "firebase/auth";
import LoginForm from "./LoginForm";
export const ErrorPage = ({ error = 404 }) => {
    const [user, setUser] = useState(null);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                // const uid = user.uid;
                // ...
                setUser(user);
            } else {
                setUser(false)
            }
        });
        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        console.log('user in parent', user)
    }, [user])

    return <div>
        <RenderComponent error={error} user={user} />
    </div>
}

const RenderComponent = ({ error, user }) => {
    console.log('user', user)
    if (user) {
        if (user.uid) {
            if (error == 500) {
                return <div className="text-center mt-2 d-flex flex-column justify-content-center align-items-center">
                    <h2 >
                        500: Permission Not Satisfied
                    </h2>
                    <p>Please contact your portal administrator for more information.</p>
                </div >
            }
            return <div>
                <h2 className="text-center">404: Page Not Found</h2>
            </div>
        }

    }
    if (user == false) {
        return <LoginForm />
    }
    return <div>
        <h2 className="text-center">404: Page Not Found</h2>
    </div>
}