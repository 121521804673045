import React, { useState, useEffect } from "react";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { ConvertRGBtoRGBA, random_rgb, random_rgba } from "../Automatic Forms/Utils";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

export const data = {
    labels: ["Thing 1", "Thing 2", "Thing 3", "Thing 4", "Thing 5", "Thing 6"],
    datasets: [
        {
            label: "# of Votes",
            data: [2, 15, 30, 5, 2, 3],
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1
        }
    ]
};

export function RadarChart({ chartData, chartRef }) {
    const [displayChartData, setDisplayChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },

        },
        maintainAspectRatio: false
    })
    const randomColor = random_rgb();
    const randomAlpha = (Math.random() * (0.4 - 0.8) + 0.4).toFixed(1)
    const randomBackgroundColor = ConvertRGBtoRGBA(randomColor, 0.4)
    const randomBorderColor = ConvertRGBtoRGBA(randomColor, 1)
    useEffect(() => {
        if (chartData) {
            console.log('Radar Chart Data: ', chartData)
            let rawData = chartData.data;
            // let sortedArray = sortArray(rawData);
            // let sortedLabels = sortedArray.map(item => item[0])
            // let sortedValues = sortedArray.map(item => item[1])
            let { color, alpha } = chartData;
            let borderColor = randomBackgroundColor;
            let backgroundColor = randomBackgroundColor;

            if (!alpha) {
                alpha = 0.4;
            }
            if (color) {
                borderColor = ConvertRGBtoRGBA(color, 1)
                backgroundColor = ConvertRGBtoRGBA(color, 0.4)
            }
            if (rawData) {
                const labels = Object.keys(rawData)

                const processedData = {
                    labels,
                    datasets: [
                        {
                            label: chartData.title,
                            data: Object.values(rawData),
                            // backgroundColor: "rgba(255, 99, 132, 0.2)",
                            // borderColor: "rgba(255, 99, 132, 1)",
                            borderColor,
                            backgroundColor,

                            borderWidth: 1
                        },
                        // {
                        //     label: 'Dataset 2',
                        //     data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                        //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        // },
                    ]
                }
                console.log('Radar Processed Chart Data', processedData)
                setDisplayChartData(processedData)
                setChartOptions({
                    ...chartOptions, plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: chartData.title,
                        },
                    },
                })

            }
            // console.log(sortedLabels, sortedValues)

        }

    }, [chartData])
    return <div>
        {displayChartData && <Radar
            height={chartData.dimension ? chartData.dimension.height : 400}
            width={chartData.dimension ? chartData.dimension.width : 400}
            options={chartOptions} data={displayChartData} ref={chartRef} id='mainChart' />}
    </div>
}
