import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LoginForm from "./Forms/LoginForm";
import RegisterForm from "./Forms/RegisterForm";
import MentorForm from "./Forms/MentorForm";
import ParticipationForm from "./Forms/ParticipationForm";
import UnderstandingForm from "./Forms/UnderstandingForm";
import TranscriptForm from "./Forms/TranscriptForm";
import InstitutionForm from "./Forms/InstitutionForm";
import Home from "./Components/Home";
import DashboardStudent from "./Components/DashboardStudent";
import DashboardFaculty from "./Components/DashboardFaculty";
import DashboardAdmin from "./Components/DashboardAdmin";
import Header from "./Components/Header";
import FormRendering from "./Automatic Forms/FormRendering";
import CSVUpload from "./Automatic Forms/CSVUpload";
import Forms from "./Automatic Forms/DefaultFormsPage";
import ForgotPassword from "./Forms/ForgotPassword";
import { Button, Container, Modal, Nav } from "react-bootstrap";
import SampleTable from './Automatic Forms/SampleTable'
import Dictaphone from "./SimpleChatbot/Dictaphone";
import {
  // isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";
import { ChartRendering } from './Automatic Charts/ChartRendering'
import DefaultCharts from "./Automatic Charts/DefaultChartPage";
import EditProfilePage from "./Forms/EditProfilePage";
import DefaultHomePage from './Components/DefaultHomePage'
import { ReadMe } from "./Components/ReadMe";
import { EditUserData } from "./Components/EditUserData";
import { ChatGPT } from "./Chatbot/ChatGPT"
import { ChartEdit } from "./Automatic Charts/ChartEdit";
import { ChartRenderingUser } from "./Automatic Charts/ChartRenderingUser";
import { NavBar, ProfileIcon } from "./Components/NavBar";
import { ErrorPage } from "./Forms/ErrorPage";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import Sidebar from 'react-bootstrap-sidebar-menu';
import "./Forms/styles.scss";
import Layout from "./Forms/Layout";
import { Navbar } from "react-bootstrap";
import Main from "./Forms/Main";
import soar from "./img/soar.png";
import { doc, onSnapshot } from "@firebase/firestore";
import { allRolesCompat, isCampusLead, isHubLead, isPortalAdmin, isStudentOrMentor } from "./Fixed Sources/accountTypes";
import { FaUserEdit } from "react-icons/fa";
import { AiFillFund, AiOutlineLogout } from "react-icons/ai";
import { db } from "./Firebase/firebasedb";
import { BsFileArrowDownFill, BsFillFileArrowUpFill, BsLayoutTextWindow, BsWindow, BsWindowFullscreen } from "react-icons/bs";
import { BiEdit, BiPlus } from "react-icons/bi";
import theme from "./Theme/theme";
import styled from "styled-components";
const RecommendedIcon = styled.div`
    display: inline-block;

    &:after {
        content: "RECOMMENDED";
        font-size: 0.5em;
        vertical-align: super;
        color: ${theme.highlightColor};
    }
`;

const V1Icon = styled.div`
    display: inline-block;

    &:after {
        content: "v1";
        font-size: 0.6em;
        vertical-align: super;
        color: ${theme.highlightColor};
    }
`;
const V2Icon = styled.div`
    display: inline-block;

    &:after {
        content: "v2";
        font-size: 0.6em;
        vertical-align: super;
        color: ${theme.highlightColor};
    }
`;
const RenderComponent = ({ user }) => {
  const navigate = useNavigate();
  console.log('user', user)
  const [error, setError] = useState(null);
  useEffect(() => {
    if (user == false) {
      const shouldShowError = location.pathname !== '/login' && location.pathname !== '/' && location.pathname !== '/register';


      if (shouldShowError) {
        navigate('/login');
        setError({ message: 'You must log in first!' })

      }
      // console.log('navigate')
    }
  }, [user])
  const location = useLocation();


  return <Container fluid className="shadow p-3 mb-5 bg-white rounded"
  // style={{ paddingLeft: 30, paddingRight: 30 }}
  >
    <>

      {/* <Header /> */}

      <Routes>
        <Route path="/" element={<DefaultHomePage user={user} />} />
        {/* <Route path="/home" element={<DefaultHomePage />}>
          </Route> */}
        <Route path="/login" element={<LoginForm error={error} />} />
        <Route path="/readme" element={<ReadMe />} >
          <Route path=":action" element={<ReadMe />} />
        </Route>


        {/* <Route path="/register" element={<RegisterForm />}> */}

        {/* </Route> */}
        <Route path="/edit-profile" element={<EditProfilePage />} />
        <Route path="/mentorform" element={<MentorForm />} />
        <Route path="/transcriptform" element={<TranscriptForm />} />
        <Route path="/understandingform" element={<UnderstandingForm />} />
        <Route path="/participationform" element={<ParticipationForm />} />
        <Route path="/institutionform" element={<InstitutionForm />} />

        {/* <Route path="/sample-form" element={<FormRendering />} /> */}
        <Route path="/csvupload" element={<CSVUpload />} />
        <Route path="/sampleTable" element={<SampleTable />} />
        <Route path="/forms" element={<Forms />}>
          <Route path=":formId" element={<FormRendering />}>
            <Route path=":action" element={<Forms />}>
            </Route>
          </Route>
        </Route>
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/dictaphone" element={<Dictaphone />} />
        {/* <Route path="/charts" element={<DefaultCharts />} >
            <Route path=":formId" element={<ChartRendering />} />
          </Route> */}
        <Route path="/charts" element={<DefaultCharts />} >
          <Route path=":chartID" element={<ChartRenderingUser />}>
            <Route path=":action" element={<DefaultCharts />} />
          </Route>

        </Route>
        <Route path="/manage-users" element={<EditUserData />} />
        <Route path="/chatgpt" element={<ChatGPT />} />


        <Route path="*" element={<ErrorPage error={404} />} />


      </Routes>
    </>
    {/* )} */}
  </Container>

}
function App() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const [userData, setUserData] = useState(null);
  const theme = "light";
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
        setUser(false)
      }
    });
    return () => unsubscribe();
  }, [auth]);
  const [showLogOut, setShowLogOut] = useState(false);

  const handleClose = () => setShowLogOut(false);
  const handleShow = () => setShowLogOut(true);
  const logOut = () => {
    setShowLogOut(false);
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log("Signed out successfully!");
        if (location.pathname == '/login')
          window.location.reload();
        else {
          navigate('/login')
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          let role = data.atype
          let roleFull = 'Administrator'
          let userRoleFull = 'Student'
          if (role != '') {
            data.userRoleFull = allRolesCompat[role] ? allRolesCompat[role] : "Student"
          }
          setUserData(data);

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          // setRedirectDestination("/");
          //   setShouldRedirect(true);
        }
      });
    }
  }, [user]);


  useEffect(() => {
    if (isAndroid) {
      const url = "https://umkc.app.box.com/s/y2k6x53kb5o90umxnfsinu2mdr0rh0yv";

      window.location.replace(url);
    } else if (isIOS) {
      // window.location.replace("https://apps.apple.com/us/app/soar-ai/id1621354278");

      setTimeout(() => {
        window.location.replace(
          "https://apps.apple.com/us/app/soar-ai/id1621354278"
        );
      }, 10000);
    } else {
      // window.location.replace("http:localhost:3000");
    }
  }, []);

  return (
    <Layout>
      {user && userData && <Navbar variant={theme} bg={theme} expand="lg" className="py-0 shadow-sm align-items-center">
        <Container fluid>

          <Navbar.Brand href="/" className="fs-6 d-flex align-items-center">
            <img
              src={soar}
              width="65"
              height="30"
              className="d-inline-block align-top mr-2"
              alt="SOAR logo"
            />
            <V2Icon><span style={{ marginLeft: 10, fontSize: 17 }}>Surmounting Obstacles for Academic Resilience </span></V2Icon>

          </Navbar.Brand>

          <Navbar.Collapse id="responsive-navbar-nav" className="fs-6 justify-content-center">
            <Nav className="me-auto">
              {/* <Nav.Link href="/">Home</Nav.Link> */}
              <Nav.Link href="/readme">README</Nav.Link>
              <Nav.Link href="/charts" target="_blank" rel="noopener noreferrer">
                Chart Dashboard
              </Nav.Link>
              {/* <Nav.Link href="https://soar-ai.com" target="_blank" rel="noopener noreferrer">
                <V1Icon><b>Production Version </b></V1Icon>
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
          <Nav className="d-flex align-items-center justify-content-center">
            <Navbar.Text className="d-flex align-items-center">
              {isStudentOrMentor(userData.atype) && userData.nickname && userData.nickname != '' ?
                <span><b>{userData.nickname}</b> - {userData.userRoleFull}
                  <ProfileIcon userData={userData} />
                  <i>({userData.institution})</i></span>
                : <span><b>{userData.firstName} {userData.lastName}</b> - {`${userData.userRoleFull} `}
                  <ProfileIcon userData={userData} />
                  <i>
                    ({userData.institution})
                  </i></span>
              }
            </Navbar.Text>
            <Button className='mx-2' variant="warning" href='/edit-profile' >
              <FaUserEdit style={{ marginRight: 2, marginBottom: 4, fontSize: 16 }} />

              Edit Profile
            </Button>
            <Button variant="danger" onClick={handleShow}>
              <AiOutlineLogout style={{ marginRight: 2, marginBottom: 2, fontSize: 16 }} />

              Log Out
            </Button>
          </Nav>


        </Container>
      </Navbar>
      }

      {user && userData && <Sidebar variant={theme} bg={theme} expand="lg" defaultExpanded={true}>
        <Sidebar.Collapse getScrollValue={500}>
          <Sidebar.Header>
            <Sidebar.Brand className="fs-6 d-flex align-items-center justify-content-center"><img
              /* src="https://tapdintostem.org/wp-content/uploads/2021/12/12142021TAPDINTOSTEM-LOGO-Low-Res-336799.jpg" */
              src={soar}
              width="60"
              height="25"
              // className="d-inline-block align-top"
              alt="SOAR logo"
            />
              {/* <span style={{ marginLeft: 3, fontSize: 13 }}>Surmounting Obstacles for Academic Resilience</span> */}
              <span style={{ marginLeft: 10, fontSize: 18, color: 'gray' }}>Overview</span>

            </Sidebar.Brand>
            <Sidebar.Toggle />
          </Sidebar.Header>
          <Sidebar.Body>

            <Sidebar.Nav>
              {(isPortalAdmin(userData.atype) || isHubLead(userData.atype) || isCampusLead(userData.atype)) && <Sidebar.Nav.Link target="_blank" rel="noopener noreferrer"
                eventKey="readme_edit" href='/readme/edit' active={location.pathname === '/readme/edit'} >
                <Sidebar.Nav.Icon><BiEdit style={{ fontSize: 21, marginBottom: 4 }} /></Sidebar.Nav.Icon>
                <Sidebar.Nav.Title>Edit README</Sidebar.Nav.Title>
              </Sidebar.Nav.Link>}
              {(isPortalAdmin(userData.atype)) &&
                <Sidebar.Sub eventKey={0}>
                  <Sidebar.Sub.Toggle>
                    <Sidebar.Nav.Icon></Sidebar.Nav.Icon>
                    <Sidebar.Nav.Title>User Management</Sidebar.Nav.Title>
                  </Sidebar.Sub.Toggle>
                  <Sidebar.Sub.Collapse>
                    <Sidebar.Nav>
                      {isPortalAdmin(userData.atype) && <Sidebar.Nav.Link target="_blank" rel="noopener noreferrer"
                        eventKey="manage-users" href='/manage-users' active={location.pathname === '/manage-users'} >
                        <Sidebar.Nav.Icon><BsWindow style={{ fontSize: 17, marginBottom: 4 }} /></Sidebar.Nav.Icon>
                        <Sidebar.Nav.Title>User Dashboard</Sidebar.Nav.Title>
                      </Sidebar.Nav.Link>}

                      <Sidebar.Nav.Link
                        target="_blank" rel="noopener noreferrer" eventKey="user_roster" active={false}
                        href="https://soar-lzlb5fvlsa-uc.a.run.app/getUsers">
                        <Sidebar.Nav.Icon><BsFileArrowDownFill style={{ fontSize: 20, marginBottom: 2 }} /></Sidebar.Nav.Icon>
                        <Sidebar.Nav.Title>Download User Roster (.CSV)</Sidebar.Nav.Title>
                      </Sidebar.Nav.Link>
                    </Sidebar.Nav>
                  </Sidebar.Sub.Collapse>
                </Sidebar.Sub>
              }
              <Sidebar.Sub eventKey={0}>
                <Sidebar.Sub.Toggle>
                  <Sidebar.Nav.Icon></Sidebar.Nav.Icon>
                  <Sidebar.Nav.Title>Form Management</Sidebar.Nav.Title>
                </Sidebar.Sub.Toggle>
                <Sidebar.Sub.Collapse>
                  <Sidebar.Nav>
                    <Sidebar.Nav.Link target="_blank" rel="noopener noreferrer" eventKey="form_dashboard"
                      active={location.pathname === '/'} href='/'>
                      <Sidebar.Nav.Icon><BsLayoutTextWindow style={{ fontSize: 18, marginBottom: 4 }} /></Sidebar.Nav.Icon>
                      <Sidebar.Nav.Title>Form Dashboard</Sidebar.Nav.Title>
                    </Sidebar.Nav.Link>
                    {(isPortalAdmin(userData.atype) || isHubLead(userData.atype) || isCampusLead(userData.atype)) && <Sidebar.Nav.Link
                      target="_blank" rel="noopener noreferrer" eventKey="forms_create"
                      active={location.pathname === '/forms/create'} href='/forms/create'>
                      <Sidebar.Nav.Icon><BiPlus style={{ fontSize: 20, marginBottom: 2 }} /></Sidebar.Nav.Icon>
                      <Sidebar.Nav.Title ><RecommendedIcon>Create New Forms </RecommendedIcon></Sidebar.Nav.Title>
                    </Sidebar.Nav.Link>}
                    {(isPortalAdmin(userData.atype) || isHubLead(userData.atype) || isCampusLead(userData.atype)) && <Sidebar.Nav.Link
                      target="_blank" rel="noopener noreferrer" eventKey="csvupload"
                      active={location.pathname === '/csvupload'} href='/csvupload'>
                      <Sidebar.Nav.Icon><BsFillFileArrowUpFill style={{ fontSize: 21, marginBottom: 2 }} /></Sidebar.Nav.Icon>
                      <Sidebar.Nav.Title>Upload A Form (CSV/Excel)</Sidebar.Nav.Title>
                    </Sidebar.Nav.Link>}
                  </Sidebar.Nav>
                </Sidebar.Sub.Collapse>
              </Sidebar.Sub>
              <Sidebar.Sub eventKey={0}>
                <Sidebar.Sub.Toggle>
                  <Sidebar.Nav.Icon></Sidebar.Nav.Icon>
                  <Sidebar.Nav.Title>Chart Visualizations</Sidebar.Nav.Title>
                </Sidebar.Sub.Toggle>
                <Sidebar.Sub.Collapse>
                  <Sidebar.Nav>
                    <Sidebar.Nav.Link target="_blank" rel="noopener noreferrer" eventKey="chart_viz"
                      active={location.pathname === '/charts'} href='/charts'>
                      <Sidebar.Nav.Icon><AiFillFund style={{ fontSize: 21, marginBottom: 4 }} /></Sidebar.Nav.Icon>
                      <Sidebar.Nav.Title>Chart Dashboard</Sidebar.Nav.Title>
                    </Sidebar.Nav.Link>
                    {isPortalAdmin(userData.atype) && <Sidebar.Nav.Link
                      target="_blank" rel="noopener noreferrer" eventKey="chart_create"
                      active={location.pathname === '/charts/create'} href='/charts/create'>
                      <Sidebar.Nav.Icon><BiPlus style={{ fontSize: 21, marginBottom: 4 }} /></Sidebar.Nav.Icon>
                      <Sidebar.Nav.Title>Create New Charts</Sidebar.Nav.Title>
                    </Sidebar.Nav.Link>}
                  </Sidebar.Nav>
                </Sidebar.Sub.Collapse>
              </Sidebar.Sub>
            </Sidebar.Nav>
          </Sidebar.Body>
        </Sidebar.Collapse>
      </Sidebar>}
      <Main>
        <RenderComponent user={user} />
      </Main>
      <Modal show={showLogOut} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Logout Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you would like to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={logOut}>
            Yes!
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );


  /* <TestSet /> */
}


export default App;
