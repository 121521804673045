import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, ButtonGroup, Card, Col, Modal, Row } from "react-bootstrap";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { doc, getDoc, setDoc, where, getDocs, orderBy, onSnapshot, serverTimestamp, query, collection, deleteDoc } from "firebase/firestore";
import { db } from "../Firebase/firebasedb";
import { BsArrowUpRight, BsArrowUpRightSquare, BsBoxArrowInLeft, BsClock, BsCloudCheck, BsCloudSlash, BsCloudSlashFill, BsEyeglasses, BsFileEarmarkZipFill, BsFillArchiveFill, BsFillArrowRightSquareFill, BsFillCloudArrowUpFill, BsJournalArrowDown, BsJournalArrowUp, BsPencilSquare, BsThreeDotsVertical, BsWindowSplit } from "react-icons/bs";
import { AiFillClone, AiFillQuestionCircle, AiFillEdit, AiOutlineDelete, AiOutlineDownload, AiFillCopy, AiFillDelete, AiOutlineArrowUp, AiOutlineClose, AiOutlineThunderbolt, AiFillAppstore, AiFillThunderbolt, AiOutlinePlusCircle } from "react-icons/ai";
import { allRolesCompat, equivalentRoleDict, isCampusLead, isHubLead, isPortalAdmin } from '../Fixed Sources/accountTypes';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import Dropdown from 'react-bootstrap/Dropdown';
import theme from "../Theme/theme";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { FaCloudUploadAlt, FaDownload, FaEdit, FaExternalLinkAlt } from "react-icons/fa";
import { deleteChartPermanent, makeid } from '../Automatic Forms/Utils';

const ChartTable = ({ userData, chartLibraryRemote, chartListRemote, }) => {
    const chartLibrary = chartLibraryRemote;
    const role = userData ? userData.atype : 'campus-lead'
    const columns = [
        {
            dataField: "chartID", text: "Chart ID",
            filter: textFilter(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '110px', textAlign: 'center', wordBreak: 'break-word' };
            },
            editable: false,
            formatter: chartIDFormatter,
        },

        {
            dataField: "title", text: "Chart Title", filter: textFilter(),
            sort: true,
            editable: false,
            // headerStyle: (colum, colIndex) => {
            //     return { width: '250px', textAlign: 'center', wordBreak: 'break-word' };
            // },
        },

        {
            dataField: "chartType", text: "Chart Type", filter: textFilter(),
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: '140px', textAlign: 'center', wordBreak: 'break-word' };
            },
        },
        {
            dataField: 'status',
            text: 'Status',
            filter: textFilter(),
            sort: true,
            editable: false,
            formatter: statusFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '140px', textAlign: 'center', wordBreak: 'break-word' };
            },
        },
        {
            dataField: 'createdAtString',
            text: 'Created At',
            // filter: dateFilter(),
            sort: true,
            editable: false,
            sortFunc: (a, b, order, dataField) => {
                // console.log(new Date(b) - new Date(a))
                if (order === 'asc') {
                    return new Date(b) - new Date(a);
                }
                return new Date(a) - new Date(b); // desc
            },
            headerStyle: (colum, colIndex) => {
                return { width: '130px', textAlign: 'center', wordBreak: 'break-word' };
            },
        },
        {
            dataField: 'allowedRolesString',
            text: 'Allowed Roles',
            filter: textFilter(),
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: '140px', textAlign: 'center', wordBreak: 'break-word' };
            },

        },
        {
            dataField: 'allowedInstitutionsString',
            text: 'Allowed Institutions',
            filter: textFilter(),
            sort: true,
            editable: false,


        },

    ];
    const [show, setShow] = useState(false);
    const [currentChart, setCurrentChart] = useState(null);
    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
    };
    const [showClone, setShowClone] = useState(false);
    const handleShowClone = () => {
        setShowClone(true);
    };
    const handleCloseClone = () => {
        setShowClone(false);
    };
    const [showArchive, setShowArchive] = useState(false);
    const handleShowArchive = () => {
        setShowArchive(true);
    };
    const handleCloseArchive = () => {
        setShowArchive(false);
    };
    const publishChart = async (chartID, status) => {
        await setDoc(doc(db, "chart_library", chartID), { status: status }, { merge: true })
        console.log(`Chart ${chartID} is now ${status}`);
        setCurrentStatusMessage(`Chart ${chartID} is now ${status}`)
        handleShowStatusModal(true);
    }
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [currentStatusMessage, setCurrentStatusMessage] = useState('');

    const handleCloseStatusModal = () => setShowStatusModal(false);
    const handleShowStatusModal = () => setShowStatusModal(true);

    const deleteChart = async (chartID) => {
        handleCloseArchive();

        if (chartID && userData) {
            // await deleteDoc(doc(db, "chart_library", chartID))
            let docSnapshot = await getDoc(doc(db, "chart_library", chartID));
            let formData = {}
            if (docSnapshot.data()) {
                // formData = docSnapshot.data()
                // formData = { ...formData, status: 'unpublished', deletedAt: serverTimestamp(), deletedBy: userData.userID, deletedByFull: userData.email }
                // console.log('deleted formData', formData, ' by ', userData.email)
                await setDoc(doc(db, "chart_library", chartID), { status: 'archived' }, { merge: true })
                // await deleteDoc(doc(db, "chart_library", chartID))
                // alert(`Chart ${chartID} is now moved to Archived folder!`);
                setCurrentStatusMessage(`Chart ${chartID} is now moved to Archived folder!`)
                handleShowStatusModal(true);
            }

        }

    }
    function statusFormatter(cell, row, rowIndex, formatExtraData) {
        // console.log('row', row)
        let color = 'red'
        let icon = <BsCloudCheck />
        let status = row.status.replace('-', ' ');
        if (status.toLowerCase() == 'published') {
            color = 'green'
        }
        else if (status.toLowerCase() == 'unpublished') {
            color = '#C41E3A'
            icon = <BsCloudSlash />
        }
        else if (status.toLowerCase() == 'awaiting approval') {
            color = theme.warningColor
            icon = <BsClock />
        }
        else if (status.toLowerCase() == 'archived') {
            color = 'gray'
            icon = <BsFillArchiveFill style={{ marginBottom: 4 }} />
        }
        status = status.toUpperCase();
        const inputStyle = { color, fontWeight: 'bold' }
        return (
            < div
                style={{
                    textAlign: "center",
                    cursor: "pointer",
                    // lineHeight: "normal"
                    ...inputStyle
                }}>
                {status} {icon}

            </div>
        );
    }
    function chartIDFormatter(cell, row, rowIndex, formatExtraData) {
        console.log('row', row)

        let chartID = row.chartID
        const status = row.status;


        return (
            < div
                style={{
                    textAlign: "center",
                    cursor: "pointer",
                }}>
                <Dropdown>
                    <Dropdown.Toggle size='sm' variant="outline-secondary" id="dropdown-basic">
                        {/* <Link to={`/forms/${chartID}`} target="_blank" rel="noopener noreferrer">{chartID}</Link> */}
                        {chartID}
                        <BsThreeDotsVertical style={{ marginBottom: 3 }} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {status != 'archived' && <div>
                            <Dropdown.Header>Shared Actions <AiFillAppstore style={{ marginLeft: 2, marginBottom: 4, fontSize: 15 }} /> </Dropdown.Header>
                            <Dropdown.Item as="a" href={`/charts/${chartID}`} target="_blank" rel="noopener noreferrer">
                                Open Chart <FaExternalLinkAlt style={{ marginLeft: 2, marginBottom: 5, fontSize: 15 }} />
                            </Dropdown.Item>

                            <Dropdown.Item as="a" href={`/charts/${chartID}/edit`} target="_blank" rel="noopener noreferrer">
                                Edit Chart <FaEdit style={{ marginLeft: 2, marginBottom: 3, fontSize: 16 }} />
                            </Dropdown.Item>

                            <Dropdown.Item onClick={() => {
                                setCurrentChart(row)
                                setShowClone(true)
                            }}>Clone Chart <AiFillCopy style={{ marginLeft: 2, marginBottom: 3, fontSize: 16 }} /></Dropdown.Item>
                            {(isCampusLead(role) || isHubLead(role)) && <div>
                                {status == 'unpublished' && <Dropdown.Item onClick={() => { publishChart(row.chartID, 'awaiting-approval') }}>Push To Admin <AiOutlineArrowUp style={{ marginLeft: 2, marginBottom: 3, fontSize: 16 }} /></Dropdown.Item>}
                                {status == 'published' && <Dropdown.Item disabled>Chart Already Pushed</Dropdown.Item>}
                                {status == 'awaiting-approval' && <Dropdown.Item disabled >Pending Approval</Dropdown.Item>}
                                {status == 'awaiting-approval' && <Dropdown.Item onClick={() => { publishChart(row.chartID, 'unpublished') }}>Cancel Push <AiOutlineClose style={{ marginLeft: 2, marginBottom: 3, fontSize: 16 }} /></Dropdown.Item>}
                            </div>}

                            {(isPortalAdmin(role) || isHubLead(role)) && <div>
                                <Dropdown.Divider />
                                <Dropdown.Header>Additional Actions <AiFillThunderbolt style={{ marginLeft: 2, marginBottom: 3, fontSize: 15, color: '#FCAE1E' }} /> </Dropdown.Header>
                                {/* <Dropdown.Item onClick={() => { console.log('Edit Chart') }}><CSVLink style={{ textDecoration: 'none', color: 'black' }} data={convertToArray(chartID)} filename={`${row.formLongName}.csv`}>
                                    Download Chart (CSV)</CSVLink>
                                    <FaDownload style={{ marginLeft: 2, marginBottom: 4, fontSize: 15 }} />
                                </Dropdown.Item>

                                <Dropdown.Item onClick={() => { exportChartResponsesToArray(chartID, `${row.formLongName}_responses.csv`) }}>Export Responses (CSV) <FaDownload style={{ marginLeft: 2, marginBottom: 4, fontSize: 15 }} /></Dropdown.Item> */}

                            </div>}
                            {isPortalAdmin(role) && <div>
                                {status == 'unpublished' && <Dropdown.Item onClick={() => { publishChart(row.chartID, 'published') }}>Publish <FaCloudUploadAlt style={{ marginLeft: 2, marginBottom: 3, fontSize: 15 }} /></Dropdown.Item>}
                                {status == 'published' && <Dropdown.Item onClick={() => { publishChart(row.chartID, 'unpublished') }}>Unpublish <BsCloudSlashFill style={{ marginLeft: 2, marginBottom: 3, fontSize: 15 }} /></Dropdown.Item>}
                                {status == 'awaiting-approval' && <Dropdown.Item onClick={() => { publishChart(row.chartID, 'published') }}>Approve and Publish <FaCloudUploadAlt style={{ marginLeft: 2, marginBottom: 3, fontSize: 15 }} /></Dropdown.Item>}
                                {status == 'awaiting-approval' && <Dropdown.Item onClick={() => { publishChart(row.chartID, 'unpublished') }}>
                                    Reject and Unpublish <BsCloudSlashFill style={{ marginLeft: 2, marginBottom: 3, fontSize: 15 }} />
                                </Dropdown.Item>}
                                <Dropdown.Item
                                    onClick={() => {
                                        setCurrentChart(row)
                                        handleShowArchive();
                                    }
                                    }>Archive Chart <BsJournalArrowDown style={{ marginLeft: 2, marginBottom: 4, fontSize: 15 }} /></Dropdown.Item>

                            </div>}
                        </div>}
                        {status == 'archived' && <div>
                            {isPortalAdmin(role) && <div>
                                <Dropdown.Item
                                    onClick={() => {
                                        console.log('recover ', chartID)
                                        publishChart(chartID, 'unpublished')
                                    }}>
                                    Recover <BsFillArrowRightSquareFill style={{ marginLeft: 2, marginBottom: 3, fontSize: 15, color: 'green' }}></BsFillArrowRightSquareFill>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    setCurrentChart(row)
                                    setShow(true)
                                }
                                }>Delete Chart Permanently <AiFillDelete style={{ marginLeft: 2, marginBottom: 4, fontSize: 15 }} /></Dropdown.Item>


                            </div>}
                        </div>}


                    </Dropdown.Menu>
                </Dropdown>
            </div >
        );
    }
    const cloneChart = async (chartID) => {
        console.log('Cloning ', chartID)
        console.log('chartLibrary', chartLibrary)
        if (chartLibrary) {

            if (chartID in chartLibrary) {
                const chartMetadata = chartLibrary[chartID]
                let cloneData = JSON.parse(JSON.stringify(chartMetadata))
                const { title } = cloneData;
                const newChartID = makeid(6)
                let newChartTitle = title + ' (Cloned)'

                let allowedRoles = cloneData.allowedRoles;
                let compatAllowedRoles = []
                let compatAllowedRolesString = []
                allowedRoles.forEach(role => {
                    const equivRole = equivalentRoleDict[role]
                    if (!compatAllowedRoles.includes(equivRole)) {
                        compatAllowedRoles.push(equivRole)
                        if (equivRole in allRolesCompat)
                            compatAllowedRolesString.push(allRolesCompat[equivRole])
                    }

                })

                // if (cloneData.startDate) {
                //     cloneData.startDate = parseMultiple(cloneData.startDate);
                // }
                // if (cloneData.endDate) {
                //     cloneData.endDate = parseMultiple(cloneData.endDate);
                // }
                let newData = {

                    createdAt: serverTimestamp(),
                    lastEdited: serverTimestamp(),
                    chartID: newChartID,
                    title: newChartTitle,
                    userID: userData.userID,
                    status: 'unpublished',
                    allowedRoles: compatAllowedRoles,
                    allowedRolesString: compatAllowedRolesString
                }
                cloneData = { ...cloneData, ...newData }
                console.log('Original Data:', chartMetadata)
                console.log('Cloned Data: ', cloneData)

                setDoc(doc(db, "chart_library", newChartID), cloneData).then(() => {
                    // alert('Chart has been cloned successfully!')
                    handleCloseClone(true)
                    setCurrentStatusMessage(`Chart ${chartID} has been cloned successfully!`)
                    handleShowStatusModal(true);
                })

            }

            // handleCloseClone(true)

        }


    }
    useEffect(() => {
        if (showStatusModal === true) {
            if (showStatusModal) {
                document.body.style.overflow = 'unset';
            }

            const timer = setTimeout(() => {
                setShowStatusModal(false);
            }, 2000);
            // This will clear the timeout if the component is unmounted before the timeout finishes
            return () => {
                clearTimeout(timer);
                // Reset the body overflow when the modal is hidden
                document.body.style.overflow = 'auto';
            };
        }
    }, [showStatusModal])
    return (
        <div>
            <BootstrapTable
                bootstrap4
                keyField='id'
                data={chartListRemote}
                columns={columns}
                filter={filterFactory()}
                pagination={paginationFactory()} />

            <Modal
                size="lg"
                show={showClone}
                onHide={handleCloseClone}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Chart Clone Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Would you like to save the change?  </h5>
                    {currentChart && <Card style={{ width: '100%' }}>
                        <Card.Body>

                            <Card.Title>{currentChart.title ? currentChart.title : 'N/A'}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">ID: {currentChart.chartID}</Card.Subtitle>
                            <Card.Text>
                                Created at: <b>{currentChart.createdAtString}</b> <br></br>
                            </Card.Text>

                        </Card.Body>
                        <Card.Footer className="text-muted">Note: This action is irreversible</Card.Footer>
                    </Card>}


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        handleCloseClone();
                    }}>
                        No
                    </Button>
                    <Button variant="info" onClick={() => {
                        if (currentChart) {
                            cloneChart(currentChart.chartID);
                        }
                    }}>Yes, proceed.</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                size="lg"
                show={showArchive}
                onHide={handleCloseArchive}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Chart Archive Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Would you like to archive this chart?  </h5>
                    {/* {currentForm &&
                    <div>
                        <h6>Email: {currentForm.email}</h6>
                        <h6>Name: {currentForm.firstName} - {currentForm.lastName}</h6>
                        <h6>Nickname: {currentForm.nickname}</h6>
                    </div>} */}
                    {currentChart && <Card style={{ width: '100%' }}>
                        <Card.Body>

                            <Card.Title>{currentChart.title ? currentChart.title : 'N/A'}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">ID: {currentChart.chartID}</Card.Subtitle>
                            <Card.Text>
                                Created at: <b>{currentChart.createdAtString}</b> <br></br>
                            </Card.Text>

                        </Card.Body>
                        <Card.Footer className="text-muted">Note: You can later view this chart in the Archived Charts section.</Card.Footer>
                    </Card>}

                    {/* <h6>Creator Email: {creatorEmail}</h6> */}
                    {/* <i>Note: You can recover this by clicking on <Button size='sm' className='mx-1' variant="danger" disabled>
                    View Recently Deleted Forms
                </Button></i> on the main page. */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseArchive}>
                        Cancel
                    </Button>
                    <Button variant="dark" onClick={() => {
                        if (currentChart) {
                            deleteChart(currentChart.chartID)
                        }
                    }}>Yes, I would like to archive this chart.</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Chart Deletion Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Would you like to delete this chart?  </h5>
                    {/* {currentForm &&
                    <div>
                        <h6>Email: {currentForm.email}</h6>
                        <h6>Name: {currentForm.firstName} - {currentForm.lastName}</h6>
                        <h6>Nickname: {currentForm.nickname}</h6>
                    </div>} */}
                    {currentChart && <Card style={{ width: '100%' }}>
                        <Card.Body>

                            <Card.Title>{currentChart.title ? currentChart.title : 'N/A'}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">ID: {currentChart.chartID}</Card.Subtitle>
                            <Card.Text>
                                Created at: <b>{currentChart.createdAtString}</b> <br></br>
                            </Card.Text>

                        </Card.Body>
                        <Card.Footer className="text-muted">Note: This action is irreversible</Card.Footer>
                    </Card>}

                    {/* <h6>Creator Email: {creatorEmail}</h6> */}
                    {/* <i>Note: You can recover this by clicking on <Button size='sm' className='mx-1' variant="danger" disabled>
                    View Recently Deleted Forms
                </Button></i> on the main page. */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => {
                        if (currentChart) {
                            const callbackFunction = () => {
                                handleClose();
                                setCurrentStatusMessage(`Chart ${currentChart.chartID} deleted successfully!`)
                                handleShowStatusModal();
                            }
                            deleteChartPermanent(currentChart.chartID, callbackFunction)
                        }
                    }}>Yes, I would like to delete this chart.</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showStatusModal} onHide={handleCloseStatusModal} size="sm"
                backdrop={false}
                dialogClassName="modal-right">
                <Modal.Header closeButton>
                    <Modal.Title>{currentStatusMessage}</Modal.Title>
                </Modal.Header>
            </Modal>
        </div>
    );
}
export const ChartManagementDashboard = ({ userData }) => {
    const [chartList, setChartList] = useState([]);
    const [chartLibrary, setChartLibrary] = useState({});
    const [studentChartList, setStudentChartList] = useState([])
    const [facultyChartList, setFacultyChartList] = useState([])
    const [campusLeadChartList, setCampusLeadChartList] = useState([])
    const [hubleadChartList, setHubLeadChartList] = useState([])
    // const [chartLibrary, setFormLibrary] = useState({})
    const [archivedChartList, setArchivedChartList] = useState([])
    const [invalidChartList, setInvalidChartList] = useState([])

    // const [archivedFormLibrary, setArchivedFormLibrary] = useState({})

    useEffect(() => {
        const chartLibraryCollection = collection(db, "chart_library");

        // This will set up the real-time listener
        const unsubscribe = onSnapshot(chartLibraryCollection, snapshot => {
            const chartData = snapshot.docs.map(doc => {
                const data = doc.data();
                let { createdAt, allowedInstitutions, allowedRoles, formDomain } = data;
                let createdAtString = 'N/A'
                let allowedInstitutionsString = 'N/A'
                let allowedRolesString = 'N/A'
                if (createdAt) {
                    createdAtString = createdAt.toDate().toDateString()
                    let createdTime = createdAt.toDate().toLocaleTimeString()
                    createdAtString = createdAtString + ', ' + createdTime
                }

                // let allowedRolesString = 'None'

                if (allowedRoles) {
                    if (allowedRoles.length > 0) {
                        let tempList = []

                        allowedRoles.forEach(role => {
                            const equivRole = equivalentRoleDict[role]

                            if (equivRole in allRolesCompat)
                                tempList.push(allRolesCompat[equivRole])
                        })
                        tempList = [...new Set(tempList)];

                        allowedRolesString = tempList.join(', ')
                        // console.log('allowedRolesString', allowedRolesString)



                    }
                }

                if (formDomain) {
                    allowedInstitutionsString = formDomain == 'Common' ? 'Common' : 'Specific: ' + allowedInstitutions.join(', ')
                }
                return {
                    ...data,
                    createdAtString,
                    id: doc.id,
                    allowedRolesString,
                    allowedInstitutionsString,
                    formDomain,
                }
            });
            const chartLibraryRemote = {}
            setChartList(chartData);
            chartData.forEach(data => {
                chartLibraryRemote[data.chartID] = data;
            })
            setChartLibrary({ ...chartLibraryRemote });
            console.log('chartLibraryRemote', chartLibraryRemote)
        });

        // Clean up the listener on component unmount
        return () => unsubscribe();

    }, []);
    useEffect(() => {
        let studentCList = []
        let facultyCList = []
        let campusLeadCList = []
        let hubLeadCList = []
        let invalidCList = []
        let archivedCList = []
        chartList.forEach(chartMetadata => {
            const allowedRoles = chartMetadata.allowedRoles;
            const { status } = chartMetadata;

            if (allowedRoles) {
                if (status != 'archived') {
                    if (allowedRoles.includes('student') || allowedRoles.includes('student-mentor')) {
                        studentCList.push(chartMetadata);
                    }
                    if (allowedRoles.includes('faculty')) {
                        facultyCList.push(chartMetadata);
                    }
                    if (allowedRoles.includes('campus-lead')) {
                        campusLeadCList.push(chartMetadata);
                    }
                    if (allowedRoles.includes('hub-lead')) {
                        hubLeadCList.push(chartMetadata);
                    }
                }
                else {
                    archivedCList.push(chartMetadata);
                }

            }
            else {
                invalidCList.push(chartMetadata);
            }
        })
        setStudentChartList(studentCList)
        setFacultyChartList(facultyCList)
        setCampusLeadChartList(campusLeadCList)
        setHubLeadChartList(hubLeadCList)
        setArchivedChartList(archivedCList)
        setInvalidChartList(invalidCList);


    }, [chartList])

    return <div>
        <br></br>
        <Row>
            <Col md='3'>
            </Col>
            <Col md='6' className="d-flex justify-content-center align-items-center">
                <Dropdown as={ButtonGroup}>
                    <Button variant="outline-secondary" disabled>
                        <h2 style={{ color: 'black', fontWeight: 'bold' }}>Admin Chart Dashboard</h2>
                    </Button>

                    <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />

                    <Dropdown.Menu>
                        <Dropdown.Item
                            as="a"
                            href="/charts/create"
                            target="_blank"
                            rel="noopener noreferrer" // This is for security reasons when using target="_blank"
                        >Create a new chart <AiOutlinePlusCircle className='mb-1'
                            style={{ fontSize: 17 }} /></Dropdown.Item>
                        {(isCampusLead(userData.atype) || isHubLead(userData.atype)) && <Dropdown.Item
                            as="a"
                            href="/charts"
                            target="_blank"
                            rel="noopener noreferrer" // This is for security reasons when using target="_blank"
                        >Personal chart dashboard <BsWindowSplit style={{ fontSize: 17, marginBottom: 2 }} /></Dropdown.Item>}
                        {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item> */}
                        {/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>

        </Row>

        <details className='my-details' style={{ borderColor: '#4285F4' }}>
            <summary className='detail-summary'><b>Student Charts ({studentChartList.length})</b></summary>
            <ChartTable userData={userData}
                chartLibraryRemote={chartLibrary}
                chartListRemote={studentChartList}
            />
        </details>

        <details className='my-details' style={{ borderColor: '#00C853' }}>
            <summary className='detail-summary'><b>Faculty Charts ({facultyChartList.length})</b></summary>
            <ChartTable userData={userData}
                chartLibraryRemote={chartLibrary}
                chartListRemote={facultyChartList}
            />
        </details>
        <details className='my-details' style={{ borderColor: '#FF8800' }}>
            <summary className='detail-summary'><b>Campus Lead Charts ({campusLeadChartList.length})</b></summary>
            <ChartTable userData={userData}
                chartLibraryRemote={chartLibrary}
                chartListRemote={campusLeadChartList}
            />
        </details>
        <details className='my-details' style={{ borderColor: '#800080' }}>
            <summary className='detail-summary'><b>Hub Lead Charts ({hubleadChartList.length})</b></summary>
            <ChartTable userData={userData} chartLibraryRemote={chartLibrary} chartListRemote={hubleadChartList} />
        </details>
        <details className='my-details' style={{ borderColor: 'gray' }}>
            <summary className='detail-summary'><b>Archived Charts ({archivedChartList.length})</b></summary>
            <ChartTable userData={userData} chartLibraryRemote={chartLibrary} chartListRemote={archivedChartList} />
        </details>
        {invalidChartList.length > 0 && <details className='my-details' style={{ borderColor: 'red' }}>
            <summary className='detail-summary'><b>Invalid Charts ({invalidChartList.length})</b></summary>
            <ChartTable userData={userData} chartLibraryRemote={chartLibrary} chartListRemote={invalidChartList} />
        </details>}

    </div>
}


