import { useEffect, useState, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { validationSchema_t } from "../Components/Validations";
import { ChatbotTranscript } from "../Chatbot/Chatbots";
import { Modal, Button } from "react-bootstrap";

// import TestSet from "../Components/TestSet";
import {
  Input,
  Textarea,
  RadioGroup,
  Select,
  // Checkbox,
} from "../Components";
import { yupResolver } from "@hookform/resolvers/yup";
import "bootstrap/dist/css/bootstrap.min.css";
import { db } from "../Firebase/firebasedb";
import {
  collection,
  getDoc,
  doc,
  addDoc,
  setDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const schoolOptions = [
  "Auburn University",
  "Auburn University at Montgomery",
  "Alabama State University",
  "Tuskegee University",
  "University of Missouri-Kansas City",
  "Little Priest Tribal College",
  "University of Wisconsin-Milwaukee",
  "Wichita State University ",
  "Northern Arizona University",
  "University of Nevada, Reno",
  "Columbus State University",
  "Troy University",
  "Middle Tennessee State University",
  "University of Hawaii, Manoa",
  "Northern Marianas College",
  "University of Hawaii-Kapi’olani Community College",
  "The University of Missouri, Kansas City",
  "The University of Tennessee, Knoxville",
  "University of Washington",
  "San Diego University ",
  "University of Alaska, Anchorage",
  "Southern Union State Community College",
  "The Ohio State University",
  "Gallaudet University",
  "Landmark College",
  "Purdue University",
];

const academicyearOptions = [
  { label: "First Year", value: "freshman" },
  { label: "Sophomore", value: "sophomore" },
  { label: "Junior", value: "junior" },
  { label: "Senior", value: "senior" },
  { label: "Graduate", value: "graduate" },
];

const sessionOptions = [
  { label: "Fall Semester", value: "fall" },
  { label: "Spring Semester", value: "spring" },
  { label: "Summer Session", value: "summer" },
  { label: "Autumn Quarter", value: "q1" },
  { label: "Winter Quarter", value: "q2" },
  { label: "Spring Quarter", value: "q3" },
  { label: "Summer Quarter", value: "q4" },
];

const degreeOptions = [
  { label: "Associate's", value: "associates" },
  { label: "Bachelor's", value: "bachelors" },
  { label: "Master's", value: "masters" },
  { label: "Doctor's", value: "doctors" },
];

const outcomeOptions = [
  { label: "Associate's", value: "associates" },
  { label: "Bachelor's", value: "bachelors" },
  { label: "Master's", value: "masters" },
  { label: "Doctor's", value: "doctors" },
  { label: "Post-doctoral Fellowship", value: "postdoc" },
  { label: "Don’t know", value: "unknown" },
];

const TranscriptForm = () => {
  const auth = getAuth();
  const [userData, setUserData] = useState(null);
  const [user, setUser] = useState(null);
  const [transcriptData, setTranscriptData] = useState(null);

  const storage = getStorage();

  const formOptions = { resolver: yupResolver(validationSchema_t) };
  const methods = useForm(formOptions);
  const { handleSubmit, reset, watch } = methods;
  const ifIntern = watch("ifIntern");
  const ifResearch = watch("ifResearch");
  // const ifDifferent = watch("ifDifferent");
  const [botSubmit, setBotSubmit] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectDestination, setRedirectDestination] = useState("/");
  const [submitSuccessfully, setSubmitSuccessfully] = useState(false);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
        setRedirectDestination("/");
        setShouldRedirect(true);
      }
    });
    return () => unsubscribe();
  }, [auth]);
  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          setUserData(data);

          // setUserData(data);
          if (data.atype === "administrator") {
            setRedirectDestination("/dashboardadmin");
            setShouldRedirect(true);
          } else if (data.atype === "faculty") {
            setRedirectDestination("/dashboardfaculty");
            setShouldRedirect(true);
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setRedirectDestination("/dashboardstudent");
          setShouldRedirect(true);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const colTrans = collection(db, "transcripts");
      const q = query(
        colTrans,
        where("studentID", "==", user.uid),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      getDocs(q)
        .then((snapshot) => {
          const x = snapshot.docs[0].data();
          setTranscriptData(x);
        })
        .catch((err) => {
          setTranscriptData(true);
          console.log(err);
        });
    }
  }, [user]);

  useEffect(() => {
    console.log(botSubmit);
    if (botSubmit) {
      console.log("TRUE!!!");
      thisform.current &&
        thisform.current.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      // setBotSubmit(false);
    }
  }, [botSubmit]);

  const navigate = useNavigate();
  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectDestination);
    }
  }, [shouldRedirect, redirectDestination, navigate]);
  useEffect(() => {
    if (submitSuccessfully) {
      // setShow(true);
    }
  }, [submitSuccessfully]);
  function onSubmit(data, event) {
    console.log(event);
    event.preventDefault();
    // display form data on success
    console.log(data);
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
    // Add a new document with a generated id.
    writeToFirebase(data);
    // 'file' comes from the Blob or File API

    // navigate("/dashboard");
    return false;
  }

  function writeToFirebase(data) {
    if (!data.session) {
      data.session = "";
    }
    if (!data.degree) {
      data.degree = "";
    }
    if (!ifIntern) {
      data.hours = "";
      data.company = "";
      data.pay = "";
      data.work = "";
    }
    if (!ifResearch) {
      data.topic = "";
      data.faculty = "";
      data.summary = "";
    }

    addDoc(collection(db, "transcripts"), {
      createdplatfrom: "WebApp",
      studentID: user.uid,
      createdAt: serverTimestamp(),
      firstName: data.firstName,
      lastName: data.lastName,
      school: data.school,
      schoolType: "university",
      // year: "unknown",
      academicYear: data.academicyear,
      typeOfSemester: data.session,
      degree: data.degree,
      major: data.major,
      minor: data.minor,
      outcome: data.outcome,
      monthAnticipatedGraduateDate: data.degreeComplete,
      yearAnticipatedGraduateDate: data.degreeComplete,
      accomplishmentsThisSemester: data.accomplishments,
      completedInternship: data.ifIntern,
      internshipCompany: data.company,
      internshipTotalHours: data.hours,
      internshipPayInDollars: data.pay,
      internshipSummary: data.work,
      completedResearch: data.ifResearch,
      researchTopic: data.topic,
      facultyMentor: data.faculty,
      researchSummary: data.summary,
    }).then((docSnapshot) => {
      console.log("Document written with ID: ", docSnapshot.id);
      if (data.transcriptupload.length > 0) {
        var filepath = "transcripts/unnamed_transcript.pdf";
        if (userData != null) {
          // filepath = "transcripts/" + docRef.id + "/transcript.pdf"
          filepath = "transcripts/" + docSnapshot.id + "/transcript.pdf";
        }
        const storageRef = ref(storage, filepath);
        // console.log(data.transcriptupload[0])
        uploadBytes(storageRef, data.transcriptupload[0]).then((snapshot) => {
          console.log("Uploaded a blob or file!");
          console.log(snapshot);
          console.log(snapshot.metadata.fullPath);
          if (userData != null) {
            setDoc(
              doc(db, "transcripts", docSnapshot.id),
              { transcriptStorageURL: snapshot.metadata.fullPath },
              { merge: true }
            ).then(() => {
              setSubmitSuccessfully(true);
              handleShow();
            });
          }
        });
      } else {
        handleShow();
      }
    });
  }
  const thisform = useRef();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    navigate("/dashboardstudent");
  };
  const handleShow = () => {
    setShow(true);
    // navigate('/dashboard');
  };

  // usePrompt("Are you sure you want to leave?", true);

  return (
    transcriptData && (
      <div className="container">
        {console.log("TranscriptData", transcriptData)}

        <div className="card m-3">
          <h5 className="card-header">
            Semester Transcript and Progress Update Form
          </h5>
          <div className="card-body">
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Submission Successful!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Your transcript information has been uploaded successfully!
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} ref={thisform}>
                <div className="row">
                  <Input
                    name="firstName"
                    label="First Name"
                    className="mb-3 col-md-3"
                    /* inputClass="form-control" */
                    defaultValue={transcriptData.firstName}
                  />
                  <Input
                    name="lastName"
                    label="Last Name"
                    className="mb-3 col-md-3"
                    defaultValue={transcriptData.lastName}
                  />
                  <Select
                    name="school"
                    label="University/College"
                    className="mb-3 col-md-6"
                    options={schoolOptions}
                    defaultValue={transcriptData.school}
                  />
                </div>
                <div className="row">
                  <Input
                    name="major"
                    label="Major"
                    className="mb-3 col"
                    defaultValue={transcriptData.major}
                  />
                  <Input
                    name="minor"
                    label="Minor"
                    className="mb-3 col"
                    defaultValue={transcriptData.minor}
                  />
                  <RadioGroup
                    label="Degree Type"
                    name="degree"
                    options={degreeOptions}
                    checked={transcriptData.degree}
                  />
                  <Input
                    name="degreeComplete"
                    label="Anticipated Graduation Date"
                    type="month"
                    className="mb-3 col"
                    defaultValue={transcriptData.monthAnticipatedGraduateDate}
                  />
                  {/* <Input */}
                  {/*   name="year" */}
                  {/*   label="Year of Semester" */}
                  {/*   type="year" */}
                  {/*   className="mb-3 col" */}
                  {/* /> */}
                </div>
                <div className="row">
                  <RadioGroup
                    label="Academic Standing"
                    name="academicyear"
                    options={academicyearOptions}
                    checked={transcriptData.academicYear}
                  />

                  <RadioGroup
                    label="Current School Session"
                    name="session"
                    options={sessionOptions}
                    checked={transcriptData.typeOfSemester}
                  />
                  <RadioGroup
                    label="Expected Education Outcome"
                    name="outcome"
                    options={outcomeOptions}
                    checked={transcriptData.degree}
                  />
                </div>
                <div className="row">
                  <Input
                    name="transcriptupload"
                    label="Upload your transcript"
                    className="mb-3"
                    inputClass="form-control"
                    type="file"
                  />
                </div>
                <div className="row">
                  <Textarea
                    name="accomplishments"
                    label="Accomplishments this semester"
                    className="mb-3 col-12"
                    defaultValue={transcriptData.accomplishmentsThisSemester}
                  />
                </div>

                <div className="row">
                  <div>
                    <Input
                      name="ifIntern"
                      label="Completed Internship"
                      type="checkbox"
                      className="form-check col"
                      labelClass="form-check-label"
                      inputClass="form-check-input"
                      /* inputPlaceholder="Initials" */
                      defaultChecked={transcriptData.completedInternship}
                    />
                  </div>
                </div>
                {(!!ifIntern || transcriptData.completedInternship) && (
                  <>
                    <div className="row">
                      <Input
                        name="company"
                        label="Company/Organization"
                        className="mb-3 col-4"
                        defaultValue={transcriptData.internshipCompany}
                      />
                      <Input
                        name="hours"
                        label="Number of Hours in Total"
                        className="mb-3 col-4"
                        defaultValue={transcriptData.internshipTotalHours}
                      />
                      <Input
                        name="pay"
                        label="Pay"
                        className="mb-3 col-4"
                        defaultValue={transcriptData.internshipPayInDollars}
                      />
                    </div>
                    <div className="row">
                      <Textarea
                        name="work"
                        label="What did you do?"
                        className="mb-3 col-12"
                        defaultValue={transcriptData.internshipSummary}
                      />
                    </div>
                  </>
                )}

                <div className="row">
                  <div>
                    <Input
                      name="ifResearch"
                      label="Completed Undergraduate Research"
                      type="checkbox"
                      className="form-check col"
                      labelClass="form-check-label"
                      inputClass="form-check-input"
                      defaultChecked={transcriptData.completedResearch}
                    />
                  </div>
                </div>
                {(!!ifResearch || transcriptData.completedResearch) && (
                  <>
                    <div className="row">
                      <Input
                        name="topic"
                        label="Topic"
                        className="mb-3 col-8"
                        defaultValue={transcriptData.researchTopic}
                      />
                      <Input
                        name="faculty"
                        label="Faculty Mentor"
                        className="mb-3 col-4"
                        defaultValue={transcriptData.facultyMentor}
                      />
                    </div>
                    <div className="row">
                      <Textarea
                        name="summary"
                        label="Summary of your work"
                        className="mb-3 col-12"
                        defaultValue={transcriptData.researchSummary}
                      />
                    </div>
                    <div className="row">
                      <Input
                        name="researchupload"
                        label="Upload files"
                        className="mb-3"
                        inputClass="form-control"
                        type="file"
                      />
                    </div>
                  </>
                )}

                <div className="mb-3">
                  <button
                    type="submit"
                    disabled={botSubmit}
                    // onClick={() => handleSubmit(onSubmit)}
                    className="btn btn-primary"
                  >
                    {botSubmit ? "Already submitted by bot!" : "Submit"}
                  </button>
                  <button
                    type="button"
                    onClick={() => reset()}
                    className="btn btn-secondary"
                  >
                    Reset
                  </button>
                </div>
              </form>
              <ChatbotTranscript
                validationSchema={validationSchema_t}
                setFormEnd={setBotSubmit}
              />
            </FormProvider>
          </div>
        </div>
      </div>
    )
  );
};
export default TranscriptForm;
