// import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { format, parse } from "date-fns";
import ChatBot from "../SimpleChatbot/ChatBot";
import { enableAutoTTS } from "enable-auto-tts";
import { useFormContext } from "react-hook-form";
import { ThemeProvider } from "styled-components";
import { Update } from "./Update";
import {
  vals1,
  vals2,
  vals3,
  vals_intern,
  vals_research,
  vals_transcript,
  vals_understand,
  vals_participation,
  vals_awareness,
  vals_project,
  vals_institute,
  keys1,
  keys2,
  keys3,
  keys_intern,
  keys_research,
  keys_transcript,
  keys_understand,
  keys_awareness,
  keys_participation,
  keys_project,
  keys_institute,
  labels_mentor,
  labels_transcript,
  labels_participate,
  labels_institute,
  Steps,
  ModifySteps,
} from "./StepGenerate";

import Review from "./Review";

import {
  MediaPermissionsError,
  // MediaPermissionsErrorType,
  // requestAudioPermissions,
  // requestVideoPermissions,
  requestMediaPermissions,
} from "mic-check";

const theme = {
  background: "#f5f8fb",
  fontFamily: "monospace",
  headerBgColor: "#6e48aa",
  headerFontColor: "#fff",
  headerFontSize: "16px",
  botBubbleColor: "#6E48AA",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

// const theme = {
//   background: "#f5f8fb",
//   fontFamily: "monospace",
//   headerBgColor: "#7ca9f4",
//   headerFontColor: "#fff",
//   headerFontSize: "15px",
//   botBubbleColor: "#7ca9f4",
//   botFontColor: "#fff",
//   userBubbleColor: "#fff",
//   userFontColor: "#4a4a4a"
// };

const config = {
  width: "400px",
  height: "500px",
  floating: true,
};

const triggerSelect = (stepN, stepY, usevalue = false) => {
  const out = ({ value }) =>
    value &&
    ["n", "nn", "nnn", "no", "nope", "", "false"].includes(value.toLowerCase())
      ? stepN
      : usevalue
      ? value.toLowerCase() + "-update"
      : stepY;
  // console.log(x);
  // console.log(value);
  return out;
};

// const checkSpeechSynthesis = (
//   setSpeechSynthesis,
//   stepN,
//   stepY,
//   usevalue = false
// ) => {
//   // const out = ({ value }) =>
//   //   value &&
//   //   ["n", "nn", "nnn", "no", "nope", "", "false"].includes(value.toLowerCase())
//   //     ? stepN
//   //     : usevalue
//   //       ? value.toLowerCase() + "-update"
//   //       : stepY;
//   const out = ({ value }) => {
//     console.log(value);
//     if (value) {
//       if (
//         ["n", "nn", "nnn", "no", "nope", "", "false"].includes(
//           value.toLowerCase()
//         )
//       ) {
//         setSpeechSynthesis({ enable: false, lang: "en" });

//         return stepN;
//       } else {
//         console.log("SPEECH PLEASE!");
//         if (usevalue) {
//           return value.toLowerCase() + "-update";
//         }
//         setSpeechSynthesis({ enable: true, lang: "en" });
//         // setFloating(false)

//         return stepY;
//       }
//     }
//     setSpeechSynthesis({ enable: false, lang: "en" });

//     return stepN;
//   };
//   // console.log(x);
//   // console.log(value);
//   return out;
// };

export const ChatbotMentor = ({ validationSchema, setFormEnd, navigate }) => {
  const {
    // formState: { errors },
    setValue,
  } = useFormContext();
  const [speechSynthesis, setSpeechSynthesis] = useState({
    enable: false,
    lang: "en",
  });

  const speechSwitch = () => {
    setSpeechSynthesis({ enable: !speechSynthesis.enable, lang: "en" });
    console.log(speechSynthesis);
  };

  // const [floating, setFloating] = useState(true);
  const handleEnd = ({ steps, values }) => {
    // console.log(steps);
    // console.log(values);
    console.log("BOT FORM END!");
    setFormEnd(true);
    // navigate("/dashboard");
    // ref.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };
  useEffect(() => {
    console.log("SPEECH UPDATE!");
    console.log(speechSynthesis);
  }, [speechSynthesis]);
  const handleUpdate = (previousStep) => {
    const { id, value } = previousStep;
    if (id === "email") {
      setValue(id, value.replace(" at ", "@").replace(/ /g, ""));
    } else if (id === "dob" && /[A-Za-z]/.test(value)) {
      const newval = parse(value, "MMMM do yyyy", new Date());
      setValue(id, format(newval, "yyyy-MM-dd"));
      // console.log(format(newval, "yyyy-MM-dd"));
    } else if (id === "degreeComplete" && /[A-Za-z]/.test(value)) {
      const newval = parse(value, "MMMM yyyy", new Date());
      setValue(id, format(newval, "yyyy-MM"));
    } else if (
      (id.includes("condition") ||
        id.includes("race") ||
        id.includes("educassit") ||
        id.includes("accessibility")) &&
      ["n", "nn", "nnn", "no", "nope"].includes(value.toLowerCase())
    ) {
      setValue(id, false);
    } else {
      if ((id.includes(".other") || id.includes(".federal")) && value !== "") {
        console.log(id.match(/.+\./) + "check" + id.slice(-3));
        setValue(id.match(/.+\./) + "check" + id.slice(-3), true);
      }
      setValue(id, value);
    }
  };

  const part1_length = keys1.length + keys2.length;
  const part2_length = keys3.length;
  // remove the validation for email step
  const steps1 = Steps(
    [...keys1, ...keys2],
    {
      ...vals1,
      ...vals2,
    },
    handleUpdate,
    validationSchema
  );
  const steps2 = Steps(
    keys3,
    vals3,
    handleUpdate,
    validationSchema,
    part1_length + 1
  );
  const modifysteps = ModifySteps(
    part1_length + part2_length + 1,
    labels_mentor
  );

  requestMediaPermissions({ audio: true, video: false })
    .then(() => {})
    .catch((err: MediaPermissionsError) => {});

  return (
    <ThemeProvider theme={theme}>
      {/* {console.log([...keys1, ...keys2])} */}
      {/* {console.log({...vals1, ...vals2})} */}
      {/* {console.log(part1_length + part2_length + 1 )} */}
      {console.log(modifysteps)}
      {enableAutoTTS()}
      <ChatBot
        recognitionEnable={true}
        handleEnd={handleEnd}
        speechSynthesis={speechSynthesis}
        speechSwitch={speechSwitch}
        {...config}
        steps={[
          // {
          //   id: "greeting",
          //   message:
          //     "Please type anything to start the conversation.",
          //   trigger: "prelude"
          // },
          // {
          //   id: 'prelude',
          //   user: true,
          //   trigger: 'speech_synthesis_question'
          // },
          {
            id: "init",
            message: "Please press Enter/Return key to continue.",
            trigger: "init_user",
          },
          {
            id: "init_user",
            user: true,
            trigger: "privacy",
          },
          /* { */
          /*   id: "speech_synthesis_question", */
          /*   message: "Would you like to turn on the speech synthesis?", */
          /*   trigger: "user_answer", */
          /* }, */
          /* { */
          /*   id: "user_answer", */
          /*   user: true, */
          /*   trigger: checkSpeechSynthesis( */
          /*     setSpeechSynthesis, */
          /*     "privacy", */
          /*     "privacy" */
          /*   ), */
          /* }, */
          {
            id: "privacy",
            message:
              "This form collects information about participants of the TAPDINTO-STEM program. Access to the data will be restricted to authorized personnel of the program. The data collected will only be reported in aggregation to offer maximal protection of your privacy. Please continue.",
            trigger: "0",
          },
          ...steps1,

          {
            id: part1_length,
            message: "Is your permanent address different from above?",
            trigger: "ifDifferent",
          },
          {
            id: "ifDifferent",
            /* options: [ */
            /*   { value: true, label: "Yes", trigger: "ifDifferent_u" }, */
            /*   { value: false, label: "No", trigger: "48" } */
            /* ] */
            user: true,
            trigger: triggerSelect(
              part1_length + part2_length - 2,
              "ifDifferent_u"
            ),
          },
          {
            id: "ifDifferent_u",
            component: <Update handleUpdate={handleUpdate} />,
            replace: true,
            trigger: part1_length + 1,
          },
          ...steps2,

          {
            id: part1_length + part2_length + 1,
            component: (
              <Review
                keys={[...keys1, ...keys2, ...keys3]}
                labels={labels_mentor}
              />
            ),
            /* asMessage: true, */
            trigger: "check",
          },
          {
            id: "check",
            message: "Great! Check out your summary",
            trigger: "update",
          },
          {
            id: "update",
            message: "Would you like to update some field?",
            trigger: "update-select",
          },
          {
            id: "update-select",
            user: true,
            trigger: triggerSelect("confirm-question", "update-yes"),
          },
          {
            id: "update-yes",
            message:
              "What field would you like to update? Please use the exact label in the table,",
            trigger: "confirm-update",
          },
          {
            id: "confirm-update",
            user: true,
            trigger: triggerSelect("confirm-question", "", true),
            /* validator */
          },

          ...modifysteps,

          {
            id: "confirm-question",
            message:
              "Please review the form and press Submit Now whenever you are ready.",
            trigger: "confirm-answer",
          },
          {
            id: "confirm-answer",
            options: [{ value: "y", label: "Submit Now", trigger: "final" }],
          },
          {
            id: "final",
            message: "Thanks! Your data was submitted successfully!",
            end: true,
          },
        ]}
      />
    </ThemeProvider>
  );
};

export const ChatbotUnderstanding = ({
  validationSchema,
  setFormEnd,
  navigate,
  list_items,
}) => {
  requestMediaPermissions({ audio: true, video: false })
    .then(() => {})
    .catch((err: MediaPermissionsError) => {});

  const {
    // formState: { errors },
    setValue,
  } = useFormContext();

  const [speechSynthesis, setSpeechSynthesis] = useState({
    enable: false,
    lang: "en",
  });

  const speechSwitch = () => {
    setSpeechSynthesis({ enable: !speechSynthesis.enable, lang: "en" });
    console.log(speechSynthesis);
  };

  const handleEnd = ({ steps, values }) => {
    console.log(steps);
    console.log(values);
    console.log("BOT FORM END!");
    setFormEnd(true);
    // ref.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  const handleUpdate = (previousStep) => {
    const { id, value } = previousStep;
    if (["n", "nn", "nnn", "no", "nope"].includes(value.toLowerCase())) {
      setValue(id, false);
    } else {
      setValue(id, true);
    }
  };

  const steps = Steps(
    [...keys_understand],
    {
      ...vals_understand,
    },
    handleUpdate,
    validationSchema
  );

  return (
    <ThemeProvider theme={theme}>
      {/* {console.log([...keys1, ...keys2])} */}
      {/* {console.log({...vals1, ...vals2})} */}
      {/* {console.log(part1_length + part2_length + 1 )} */}
      <ChatBot
        recognitionEnable={true}
        handleEnd={handleEnd}
        speechSynthesis={speechSynthesis}
        speechSwitch={speechSwitch}
        {...config}
        steps={[
          {
            id: "init",
            message: "Please press Enter/Return key to continue.",
            trigger: "init_user",
          },
          {
            id: "init_user",
            user: true,
            trigger: "privacy",
          },
          {
            id: "privacy",
            message:
              "Please initial your understanding of each statement below and sign in the space provided below to indicate that you understand what data and information will be requested from you while participating in the TAPDINTO-STEM program.",
            trigger: "0",
          },
          ...steps,
          {
            id: keys_understand.length,
            message:
              "Thank you. Please press Submit Now whenever you are ready.",
            trigger: "confirm-answer",
          },
          {
            id: "confirm-answer",
            options: [{ value: "y", label: "Submit Now", trigger: "final" }],
          },
          {
            id: "final",
            message: "Thanks! Your data was submitted successfully!",
            end: true,
          },
        ]}
      />
    </ThemeProvider>
  );
};

export const ChatbotTranscript = ({
  validationSchema,
  setFormEnd,
  navigate,
}) => {
  requestMediaPermissions({ audio: true, video: false })
    .then(() => {})
    .catch((err: MediaPermissionsError) => {});

  const {
    // formState: { errors },
    setValue,
  } = useFormContext();
  const [speechSynthesis, setSpeechSynthesis] = useState({
    enable: false,
    lang: "en",
  });

  const speechSwitch = () => {
    setSpeechSynthesis({ enable: !speechSynthesis.enable, lang: "en" });
    console.log(speechSynthesis);
  };

  const handleEnd = ({ steps, values }) => {
    console.log("All steps: ", steps);
    // console.log(values);
    console.log("BOT FORM END!");
    setFormEnd(true);
    // navigate("/dashboard");
    // ref.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  const handleUpdate = (previousStep) => {
    const { id, value } = previousStep;
    if (id === "degreeComplete" && /[A-Za-z]/.test(value)) {
      const newval = parse(value, "MMMM yyyy", new Date());
      setValue(id, format(newval, "yyyy-MM"));
    } else if (id === "session" && value) {
      let newvalue;
      switch (value.toLowerCase()) {
        case "fall semester":
          newvalue = "fall";
          break;
        case "spring semester":
          newvalue = "spring";
          break;
        case "summer session":
          newvalue = "summer";
          break;
        case "autumn quarter":
          newvalue = "q1";
          break;
        case "winter quarter":
          newvalue = "q2";
          break;
        case "spring quarter":
          newvalue = "q3";
          break;
        case "summer quarter":
          newvalue = "q4";
          break;
        default:
      }
      setValue(id, newvalue);
    } else if (
      id.includes("if") &&
      ["n", "nn", "nnn", "no", "nope", "", "false", undefined].includes(
        value.toLowerCase()
      )
    ) {
      setValue(id, false);
    } else {
      setValue(id, value);
    }
    // console.log(value);
  };

  const part1_length = keys_transcript.length;
  const part2_length = keys_intern.length;
  const part3_length = keys_research.length;
  const steps1 = Steps(
    keys_transcript,
    vals_transcript,
    handleUpdate,
    validationSchema
  );
  const steps2 = Steps(
    keys_intern,
    vals_intern,
    handleUpdate,
    validationSchema,
    part1_length + 1
  );
  const steps3 = Steps(
    keys_research,
    vals_research,
    handleUpdate,
    validationSchema,
    part1_length + part2_length + 2
  );
  const modifysteps = ModifySteps("review", labels_transcript);

  return (
    <ThemeProvider theme={theme}>
      {/* {console.log(keys_intern)} */}
      {/* {console.log(steps1)} */}
      {/* {console.log(steps2)} */}
      {/* {console.log(steps3)} */}
      {/* {console.log(modifysteps)} */}
      <ChatBot
        recognitionEnable={true}
        handleEnd={handleEnd}
        speechSynthesis={speechSynthesis}
        speechSwitch={speechSwitch}
        {...config}
        steps={[
          {
            id: "init",
            message: "Please press Enter/Return key to continue.",
            trigger: "init_user",
          },
          {
            id: "init_user",
            user: true,
            trigger: "0",
          },
          /* { */
          /*   id: "privacy", */
          /*   message: */
          /*     "This form collects information about participants of the TAPDINTO-STEM program. Access to the data will be restricted to authorized personnel of the program. The data collected will only be reported in aggregation to offer maximal protection of your privacy. Please type anything to continue.", */
          /*   trigger: "0" */
          /* }, */
          ...steps1,
          {
            id: part1_length,
            message: "Please upload your transcript",
            trigger: "transcriptupload",
          },
          {
            id: "transcriptupload",
            component: (
              <div>
                <label htmlFor="transcriptupload">Upload your transcript</label>
                <input
                  type="file"
                  id="transcriptupload"
                  placeholder="form-control-file"
                />
              </div>
            ),
            /* asMessage: true, */
            trigger: "ifIntern_q",
          },

          {
            id: "ifIntern_q",
            message: "Have you completed any internship?",
            trigger: "ifIntern",
          },
          {
            id: "ifIntern",
            user: true,
            trigger: triggerSelect(
              part1_length + part2_length + 1,
              "ifIntern_u"
            ),
          },
          {
            id: "ifIntern_u",
            component: <Update handleUpdate={handleUpdate} />,
            replace: true,
            trigger: part1_length + 1,
          },
          ...steps2,
          {
            id: part1_length + part2_length + 1,
            message: "Have you completed any undergraduate research?",
            trigger: "ifResearch",
          },
          {
            id: "ifResearch",
            user: true,
            trigger: triggerSelect("review", "ifResearch_u"),
          },
          {
            id: "ifResearch_u",
            component: <Update handleUpdate={handleUpdate} />,
            replace: true,
            trigger: part1_length + part2_length + 2,
          },

          ...steps3,

          {
            id: part1_length + part2_length + part3_length + 2,
            message: "Please upload related file",
            trigger: "researchupload",
          },
          {
            id: "researchupload",
            component: (
              <div>
                <label htmlFor="researchupload">Upload your file</label>
                <input
                  type="file"
                  id="researchupload"
                  placeholder="form-control-file"
                />
              </div>
            ),
            /* asMessage: true, */
            trigger: "review",
          },

          {
            id: "review",
            component: (
              <Review
                keys={[...keys_transcript, ...keys_intern, ...keys_research]}
                labels={labels_transcript}
              />
            ),
            /* asMessage: true, */
            trigger: "check",
          },
          {
            id: "check",
            message: "Great! Check out your summary",
            trigger: "update",
          },
          {
            id: "update",
            message: "Would you like to update some field?",
            trigger: "update-select",
          },
          {
            id: "update-select",
            user: true,
            trigger: triggerSelect("confirm-question", "update-yes"),
          },
          {
            id: "update-yes",
            message:
              "What field would you like to update? Please use the exact label in the table",
            trigger: "confirm-update",
          },
          {
            id: "confirm-update",
            user: true,
            validator: (value) => {
              if (
                !Object.values(labels_transcript)
                  .map((x) => x.toLowerCase())
                  .includes(value.toLowerCase())
              ) {
                return "Use the exact label";
              }
              return true;
            },
            trigger: triggerSelect("confirm-question", "", true),
            /* validator */
          },

          ...modifysteps,

          {
            id: "confirm-question",
            message: "Please press Submit Now whenever you are ready.",
            trigger: "confirm-answer",
          },
          {
            id: "confirm-answer",
            options: [{ value: "y", label: "Submit Now", trigger: "final" }],
          },
          {
            id: "final",
            message: "Thanks! Your data was submitted successfully!",
            end: true,
          },
        ]}
      />
    </ThemeProvider>
  );
};

export const ChatbotParticipation = ({
  validationSchema,
  setFormEnd,
  navigate,
}) => {
  requestMediaPermissions({ audio: true, video: false })
    .then(() => {})
    .catch((err: MediaPermissionsError) => {});

  const {
    // formState: { errors },
    setValue,
  } = useFormContext();

  const [speechSynthesis, setSpeechSynthesis] = useState({
    enable: false,
    lang: "en",
  });

  const speechSwitch = () => {
    setSpeechSynthesis({ enable: !speechSynthesis.enable, lang: "en" });
    console.log(speechSynthesis);
  };

  const handleEnd = ({ steps, values }) => {
    console.log(steps);
    console.log(values);
    console.log("BOT FORM END!");
    setFormEnd(true);
    // ref.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  const handleUpdate = (previousStep) => {
    const { id, value } = previousStep;
    if (
      ["n", "nn", "nnn", "no", "nope", "", "false", undefined].includes(
        value.toLowerCase()
      )
    ) {
      setValue(id, false);
    } else if (/^\d+$/g.test(value)) {
      setValue(id, Number(value).toString());
    } else {
      setValue(id, value);
    }
  };

  const part1_length = keys_awareness.length;
  const part2_length = keys_participation.length;
  const part3_length = keys_project.length;

  const steps1 = Steps(
    [...keys_awareness],
    {
      ...vals_awareness,
    },
    handleUpdate,
    validationSchema
  );

  const steps2 = Steps(
    [...keys_participation, ...keys_project],
    {
      ...vals_participation,
      ...vals_project,
    },
    handleUpdate,
    validationSchema,
    part1_length + 1
  );

  const modifysteps = ModifySteps(
    part1_length + part2_length + part3_length + 1,
    labels_participate
  );

  return (
    <ThemeProvider theme={theme}>
      {/* {console.log([...keys1, ...keys2])} */}
      {/* {console.log({...vals1, ...vals2})} */}
      {/* {console.log(part1_length + part2_length + part3_length + 1)} */}
      {/* {console.log([...steps1, ...steps2])} */}
      <ChatBot
        recognitionEnable={true}
        handleEnd={handleEnd}
        speechSynthesis={speechSynthesis}
        speechSwitch={speechSwitch}
        {...config}
        steps={[
          {
            id: "init",
            message: "Please press Enter/Return key to continue.",
            trigger: "init_user",
          },
          {
            id: "init_user",
            user: true,
            trigger: "privacy",
          },
          {
            id: "privacy",
            message:
              "Please specify the extent to which you are aware of each of the following. If a service does not apply to you, please leave blank. Use the following scale to respond to the awareness questions: 1 = not aware or not applicable, 2 = some awareness, 3 = slightly aware, 4 = aware, 5 = very aware.",
            trigger: "0",
          },
          ...steps1,
          {
            id: part1_length,
            message:
              "Please specify the extent to which you have participated in each of the following. If a service does not apply to you, please leave blank. Use the following scale to respond to the participation questions: 1 = did not participate, 2 = did not help me, 3 = somewhat helped me, 4 = mostly helped me, 5 = definitely helped me. ",
            trigger: part1_length + 1,
          },

          ...steps2,

          {
            id: part1_length + part2_length + part3_length + 1,
            component: (
              <Review
                keys={[
                  ...keys_awareness,
                  ...keys_participation,
                  ...keys_project,
                ]}
                labels={labels_participate}
              />
            ),
            /* asMessage: true, */
            trigger: "check",
          },
          {
            id: "check",
            message: "Great! Check out your summary",
            trigger: "update",
          },
          {
            id: "update",
            message: "Would you like to update some field?",
            trigger: "update-select",
          },
          {
            id: "update-select",
            user: true,
            trigger: triggerSelect("confirm-question", "update-yes"),
          },
          {
            id: "update-yes",
            message:
              "What field would you like to update? Please use the exact label in the table",
            trigger: "confirm-update",
          },
          {
            id: "confirm-update",
            user: true,
            validator: (value) => {
              if (
                !Object.values(labels_participate)
                  .map((x) => x.toLowerCase())
                  .includes(value.toLowerCase())
              ) {
                return "Use the exact label";
              }
              return true;
            },
            trigger: triggerSelect("confirm-question", "", true),
            /* validator */
          },

          ...modifysteps,
          {
            id: "confirm-question",
            message: "Please press Submit Now whenever you are ready.",
            trigger: "confirm-answer",
          },

          {
            id: "confirm-answer",
            options: [{ value: "y", label: "Submit Now", trigger: "final" }],
          },
          {
            id: "final",
            message: "Thanks! Your data was submitted successfully!",
            end: true,
          },
        ]}
      />
    </ThemeProvider>
  );
};

export const ChatbotInstitute = ({
  validationSchema,
  setFormEnd,
  navigate,
}) => {
  requestMediaPermissions({ audio: true, video: false })
    .then(() => {})
    .catch((err: MediaPermissionsError) => {});

  const {
    // formState: { errors },
    setValue,
  } = useFormContext();

  const [speechSynthesis, setSpeechSynthesis] = useState({
    enable: false,
    lang: "en",
  });

  const speechSwitch = () => {
    setSpeechSynthesis({ enable: !speechSynthesis.enable, lang: "en" });
    console.log(speechSynthesis);
  };

  const handleEnd = ({ steps, values }) => {
    console.log(steps);
    console.log(values);
    console.log("BOT FORM END!");
    setFormEnd(true);
    // ref.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  const handleUpdate = (previousStep) => {
    const { id, value } = previousStep;
    if (
      ["n", "nn", "nnn", "no", "nope", "", "false", undefined].includes(
        value.toLowerCase()
      )
    ) {
      setValue(id, false);
    } else setValue(id, value);
  };

  const part1_length = keys_institute.length;

  const steps1 = Steps(
    [...keys_institute],
    {
      ...vals_institute,
    },
    handleUpdate,
    validationSchema
  );

  const modifysteps = ModifySteps(part1_length, labels_institute);

  return (
    <ThemeProvider theme={theme}>
      {/* {console.log([...keys1, ...keys2])} */}
      {/* {console.log({...vals1, ...vals2})} */}
      {/* {console.log(part1_length + part2_length + part3_length + 1)} */}
      {console.log([...steps1])}
      {enableAutoTTS()}
      <ChatBot
        recognitionEnable={true}
        handleEnd={handleEnd}
        speechSynthesis={speechSynthesis}
        speechSwitch={speechSwitch}
        {...config}
        steps={[
          {
            id: "init",
            message: "Please press Enter/Return key to continue.",
            trigger: "init_user",
          },
          {
            id: "init_user",
            user: true,
            trigger: "privacy",
          },
          {
            id: "privacy",
            message: "Thank you for your support!",
            trigger: "0",
          },
          ...steps1,

          {
            id: part1_length,
            component: (
              <Review keys={[...keys_institute]} labels={labels_institute} />
            ),
            /* asMessage: true, */
            trigger: "check",
          },
          {
            id: "check",
            message: "Great! Check out your summary",
            trigger: "update",
          },
          {
            id: "update",
            message: "Would you like to update some field?",
            trigger: "update-select",
          },
          {
            id: "update-select",
            user: true,
            trigger: triggerSelect("confirm-question", "update-yes"),
          },
          {
            id: "update-yes",
            message:
              "What field would you like to update? Please use the exact label in the table",
            trigger: "confirm-update",
          },
          {
            id: "confirm-update",
            user: true,
            validator: (value) => {
              if (
                !Object.values(labels_participate)
                  .map((x) => x.toLowerCase())
                  .includes(value.toLowerCase())
              ) {
                return "Use the exact label";
              }
              return true;
            },
            trigger: triggerSelect("confirm-question", "", true),
            /* validator */
          },

          ...modifysteps,
          {
            id: "confirm-question",
            message: "Please press Submit Now whenever you are ready.",
            trigger: "confirm-answer",
          },

          {
            id: "confirm-answer",
            options: [{ value: "y", label: "Submit Now", trigger: "final" }],
          },
          {
            id: "final",
            message: "Thanks! Your data was submitted successfully!",
            end: true,
          },
        ]}
      />
    </ThemeProvider>
  );
};
