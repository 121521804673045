import { formatDate, processID, generateRandomNicknames, makeid, RoleValidationComponent, restoreForm, deleteFormsPermanent, generateRandomNumber, parseMultiple } from "../Automatic Forms/Utils"
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { NavBar } from "./NavBar"
import './Input.css'
import { db } from "../Firebase/firebasedb";
import { doc, getDoc, setDoc, where, getDocs, orderBy, onSnapshot, serverTimestamp, query, collection, deleteDoc } from "firebase/firestore";
import { allRolesRegular, allRoles, isHubLead, allRolesReverse, allRolesCompat, isPortalAdmin, isCampusLead, equivalentRoleDict } from "../Fixed Sources/accountTypes";
import Dropdown from 'react-bootstrap/Dropdown';
import { Button, Card, Modal } from "react-bootstrap";
import { BsArrowUpRight, BsArrowUpRightSquare, BsBoxArrowInLeft, BsClock, BsCloudCheck, BsCloudSlash, BsCloudSlashFill, BsEyeglasses, BsFileEarmarkZipFill, BsFillArchiveFill, BsFillArrowRightSquareFill, BsFillCloudArrowUpFill, BsJournalArrowDown, BsJournalArrowUp, BsPencilSquare, BsThreeDotsVertical } from "react-icons/bs";
import { AiFillClone, AiFillQuestionCircle, AiFillEdit, AiOutlineDelete, AiOutlineDownload, AiFillCopy, AiFillDelete, AiOutlineArrowUp, AiOutlineClose, AiOutlineThunderbolt, AiFillAppstore, AiFillThunderbolt } from "react-icons/ai";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { faker } from '@faker-js/faker';
import { institutionsList } from "../Fixed Sources/institutions"
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { base64Decode, base64Encode } from "@firebase/util";
import './component_styles.css'
import theme from "../Theme/theme";
import { CSVLink } from "react-csv";
import { FaCloudUploadAlt, FaDownload, FaEdit, FaExternalLinkAlt } from "react-icons/fa";

export const FormTableManager = ({ userData }) => {
    const [formList, setFormList] = useState([])
    const [studentFormList, setStudentFormList] = useState([])
    const [facultyFormList, setFacultyFormList] = useState([])
    const [campusLeadFormList, setCampusLeadFormList] = useState([])
    const [hubleadFormList, setHubLeadFormList] = useState([])


    const [formLibrary, setFormLibrary] = useState({})
    const [archivedFormList, setArchivedFormList] = useState([])
    const [archivedFormLibrary, setArchivedFormLibrary] = useState({})
    useEffect(() => {
        // const q = query(collection(db, "Users"));
        const formUploadRef = collection(db, "form_library");
        const q = query(formUploadRef, orderBy("createdAt", "desc"))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const users = {};
            const remoteList = [];
            let index = 0
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                let { startDate, endDate, createdAt, formLongName, formTitle, formPrefix, formPrefix2 } = data;
                if (!formLongName) {
                    if (formTitle) {
                        data.formLongName = formTitle;
                        if (formPrefix2 && formPrefix2 != '' && formPrefix2 != 'N/A') {
                            data.formLongName = `${formPrefix2} ` + data.formLongName
                        }
                        if (formPrefix && formPrefix != '' && formPrefix != 'N/A') {
                            data.formLongName = `${formPrefix}_` + data.formLongName
                        }

                    }

                }
                console.log('startDate', startDate)

                startDate = parseMultiple(startDate);
                endDate = parseMultiple(endDate);

                console.log('startDate', startDate)
                // assuming date is in format YYYY/MM/DD
                if (startDate && startDate.trim() != '') {
                    let [year, month, day] = startDate.split("-").map(Number);
                    console.log('year', year, 'month', month, 'day', day, startDate)

                    startDate = new Date(year, month - 1, day).toDateString();

                    startDate = startDate != 'Invalid Date' ? startDate : "N/A"
                }
                else {
                    startDate = "N/A"
                }
                if (endDate && startDate.trim() != '') {
                    let [year, month, day] = endDate.split("-").map(Number);
                    endDate = new Date(year, month - 1, day).toDateString()
                    endDate = endDate != 'Invalid Date' ? endDate : "N/A"

                }
                else {
                    endDate = 'N/A'
                }

                createdAt = data.createdAt.toDate().toDateString()
                let createdTime = data.createdAt.toDate().toLocaleTimeString()
                createdAt = createdAt + ', ' + createdTime

                let allowedRolesString = 'None'
                const allowedRoles = data.allowedRoles;

                if (allowedRoles.length > 0) {
                    let tempList = []
                    // console.log('allowedRoles', allowedRoles)
                    allowedRoles.forEach(role => {
                        const equivRole = equivalentRoleDict[role]

                        if (equivRole in allRolesCompat)
                            tempList.push(allRolesCompat[equivRole])
                    })
                    tempList = [...new Set(tempList)];

                    allowedRolesString = tempList.join(', ')
                    if (data.formID == 'iphQ0fnG') {
                        console.log('allowedRolesString', allowedRolesString)
                    }
                }
                const formDomain = data.formDomain;
                const allowedInstitutions = data.allowedInstitutions;

                let allowedInstitutionsString = formDomain == 'Common' ? 'Common' : 'Specific: ' + allowedInstitutions.join(', ')
                data = {
                    ...data,
                    id: index + 1,
                    startDate,
                    endDate,
                    createdAt,
                    allowedRolesString,
                    allowedInstitutionsString
                }
                formLibrary[data.formID] = data;
                setFormLibrary({ ...formLibrary })
                remoteList.push(data)
                index += 1;
            });
            setFormList(remoteList)
        });

        const formUploadRef2 = collection(db, "form_library_recently_deleted");
        const q2 = query(formUploadRef2, orderBy("createdAt", "desc"))
        const unsubscribe2 = onSnapshot(q2, (querySnapshot) => {
            const users = {};
            const remoteList = [];
            let index = 0
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                let { startDate, endDate, createdAt } = data;
                console.log('startDate', startDate)

                startDate = parseMultiple(startDate);
                endDate = parseMultiple(endDate);

                console.log('startDate', startDate)
                // assuming date is in format YYYY/MM/DD
                if (startDate && startDate.trim() != '') {
                    let [year, month, day] = startDate.split("-").map(Number);
                    console.log('year', year, 'month', month, 'day', day, startDate)

                    startDate = new Date(year, month - 1, day).toDateString();

                    startDate = startDate != 'Invalid Date' ? startDate : "N/A"
                }
                else {
                    startDate = "N/A"
                }
                if (endDate && startDate.trim() != '') {
                    let [year, month, day] = endDate.split("-").map(Number);
                    endDate = new Date(year, month - 1, day).toDateString()
                    endDate = endDate != 'Invalid Date' ? endDate : "N/A"

                }
                else {
                    endDate = 'N/A'
                }

                createdAt = data.createdAt.toDate().toDateString()
                let createdTime = data.createdAt.toDate().toLocaleTimeString()
                createdAt = createdAt + ', ' + createdTime

                let allowedRolesString = 'None'
                const allowedRoles = data.allowedRoles;

                if (allowedRoles.length > 0) {
                    let tempList = []

                    allowedRoles.forEach(role => {
                        const equivRole = equivalentRoleDict[role]

                        if (equivRole in allRolesCompat)
                            tempList.push(allRolesCompat[equivRole])
                    })
                    tempList = [...new Set(tempList)];

                    allowedRolesString = tempList.join(', ')
                    // console.log('allowedRolesString', allowedRolesString)



                }
                const formDomain = data.formDomain;
                const allowedInstitutions = data.allowedInstitutions;

                let allowedInstitutionsString = formDomain == 'Common' ? 'Common' : 'Specific: ' + allowedInstitutions.join(', ')
                data = {
                    ...data,
                    id: index + 1,
                    startDate,
                    endDate,
                    createdAt,
                    allowedRolesString,
                    allowedInstitutionsString,
                    status: 'archived'
                }
                archivedFormLibrary[data.formID] = data;
                setArchivedFormLibrary({ ...archivedFormLibrary })
                remoteList.push(data)
                index += 1;
            });
            setArchivedFormList(remoteList)
        });
    }, [])
    useEffect(() => {
        let studentFList = []
        let facultyFList = []
        let campusLeadFList = []
        let hubLeadFList = []


        formList.forEach(formMetadata => {
            const allowedRoles = formMetadata.allowedRoles;
            if (allowedRoles.includes('student') || allowedRoles.includes('student-mentor')) {
                studentFList.push(formMetadata);
            }
            if (allowedRoles.includes('faculty')) {
                facultyFList.push(formMetadata);
            }
            if (allowedRoles.includes('campus-lead')) {
                campusLeadFList.push(formMetadata);
            }
            if (allowedRoles.includes('hub-lead')) {
                hubLeadFList.push(formMetadata);
            }
        })
        setStudentFormList(studentFList)
        setFacultyFormList(facultyFList)
        setCampusLeadFormList(campusLeadFList)
        setHubLeadFormList(hubLeadFList)


    }, [formList])

    return <div>
        <details className='my-details' style={{ borderColor: '#4285F4', }}>
            <summary className='detail-summary'><b>Student Forms ({studentFormList.length})</b></summary>
            <FormTable userData={userData}
                formLibraryRemote={formLibrary}
                formListRemote={studentFormList}
            />
        </details>

        <details className='my-details' style={{ borderColor: '#00C853' }}>
            <summary className='detail-summary'><b>Faculty Forms ({facultyFormList.length})</b></summary>
            <FormTable userData={userData}
                formLibraryRemote={formLibrary}
                formListRemote={facultyFormList}
            />
        </details>
        <details className='my-details' style={{ borderColor: '#FF8800' }}>
            <summary className='detail-summary'><b>Campus Lead Forms ({campusLeadFormList.length})</b></summary>
            <FormTable userData={userData}
                formLibraryRemote={formLibrary}
                formListRemote={campusLeadFormList}
            />
        </details>
        <details className='my-details' style={{ borderColor: '#800080' }}>
            <summary className='detail-summary'><b>Hub Lead Forms ({hubleadFormList.length})</b></summary>
            <FormTable userData={userData}
                formLibraryRemote={formLibrary}
                formListRemote={hubleadFormList}
            />
        </details>
        <details className='my-details' style={{ borderColor: 'gray' }}>
            <summary className='detail-summary'><b>Archived Forms ({archivedFormList.length})</b></summary>
            <FormTable userData={userData}
                formLibraryRemote={archivedFormLibrary}
                formListRemote={archivedFormList}
                targetStatus='archived'
            />
        </details>

    </div>
}
export const FormTable = ({ userData, formLibraryRemote, formListRemote, targetStatus = 'available' }) => {
    const [valid, setValid] = useState(false)
    const role = userData ? userData.atype : 'campus-lead'
    // const [formList, setFormList] = useState([])
    // const [formLibrary, setFormLibrary] = useState({})
    let formLibrary = formLibraryRemote;
    // console.log('formList', formLibraryRemote)
    let formList = formListRemote;
    // if (targetRole) {
    //     formListRemote.forEach(formMetadata => {
    //         const allowedRoles = formMetadata.allowedRoles;
    //         if (allowedRoles.includes(targetRole)) {
    //             formList.push(formMetadata);
    //         }
    //     })
    // }
    // else {
    //     formList = formListRemote
    // }
    function naturalCompare(a, b) {
        // Extract substring before underscore if it exists, otherwise use the entire string
        const aBeforeUnderscore = a.includes('_') ? a.split('_')[0] : a;
        const bBeforeUnderscore = b.includes('_') ? b.split('_')[0] : b;

        // Regular expression to match groups of digits or non-digits
        const re = /(\d+)|(\D+)/g;

        // Break the substrings into chunks of digits and non-digits
        const aMatch = aBeforeUnderscore.match(re);
        const bMatch = bBeforeUnderscore.match(re);

        // Compare the chunks
        for (let i = 0; i < Math.min(aMatch.length, bMatch.length); i++) {
            if (/\d/.test(aMatch[i]) && /\d/.test(bMatch[i])) {
                const numCompare = parseInt(aMatch[i], 10) - parseInt(bMatch[i], 10);
                if (numCompare !== 0) return numCompare;
            } else {
                const lexCompare = aMatch[i].localeCompare(bMatch[i]);
                if (lexCompare !== 0) return lexCompare;
            }
        }

        return aMatch.length - bMatch.length;
    }

    const items = ["STU1_data", "STU10Z", "STU9_info", "STU2", "STU10_data", "ABC"];
    items.sort(naturalCompare);

    console.log(items); // Output: [ 'ABC', 'STU1_data', 'STU2', 'STU9_info', 'STU10Z', 'STU10_data' ]

    const columns = [
        {
            dataField: 'formID',
            text: 'Form ID',

            filter: textFilter(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '130px', textAlign: 'center', wordBreak: 'break-word' };
            },
            editable: false,
            formatter: formIDFormatter,

            // style: { fontSize: 9 }

        },
        {
            dataField: 'formLongName',
            text: 'Form Name',
            filter: textFilter(),
            sort: true,
            editable: false,
            sortFunc: (a, b, order, dataField) => {
                // let randomNum = Math.floor(generateRandomNumber(0, 100));
                // let randomNum = a.length - b.length;
                // let randomNum = a.trim().localeCompare(b.trim());
                let randomNum = -naturalCompare(a.trim(), b.trim());

                console.log(a, b, randomNum)

                // console.log(dataField)
                if (order === 'asc') {
                    return randomNum
                }
                // console.log(a > b)
                randomNum = naturalCompare(a.trim(), b.trim());


                return randomNum

            }
        },
        {
            dataField: 'status',
            text: 'Status',
            filter: textFilter(),
            sort: true,
            editable: false,
            formatter: statusFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '140px', textAlign: 'center', wordBreak: 'break-word' };
            },
        },
        {
            dataField: 'startDate',
            text: 'Start Date',
            filter: dateFilter(),
            sort: true,
            editable: false

        },
        {
            dataField: 'endDate',
            text: 'End Date',
            filter: dateFilter(),
            sort: true,
            editable: false
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            // filter: dateFilter(),
            sort: true,
            editable: false,
            sortFunc: (a, b, order, dataField) => {
                // console.log(new Date(b) - new Date(a))
                if (order === 'asc') {
                    return new Date(b) - new Date(a);
                }
                return new Date(a) - new Date(b); // desc
            },
            headerStyle: (colum, colIndex) => {
                return { width: '130px', textAlign: 'center', wordBreak: 'break-word' };
            },
        },
        {
            dataField: 'allowedRolesString',
            text: 'Allowed Roles',
            filter: textFilter(),
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: '140px', textAlign: 'center', wordBreak: 'break-word' };
            },

        },
        {
            dataField: 'allowedInstitutionsString',
            text: 'Allowed Institutions',
            filter: textFilter(),
            sort: true,
            editable: false,


        },

        // {
        //     dataField: 'lastName',
        //     text: 'Last Name',

        //     filter: textFilter(),
        //     sort: true,

        // }, {
        //     dataField: 'nickname',
        //     text: 'Nickname',
        //     sort: true,
        //     filter: textFilter(),

        // },
        // {
        //     dataField: 'atype',
        //     text: 'Role',
        //     sort: true,
        //     filter: textFilter(),
        //     editor: {
        //         type: Type.SELECT,
        //         options: [{
        //             value: 'Student',
        //             label: 'Student'
        //         },
        //         {
        //             value: 'Faculty/Staff',
        //             label: 'Faculty/Staff'
        //         }, {
        //             value: 'Campus/Hub Lead',
        //             label: 'Campus/Hub Lead'
        //         }, {
        //             value: 'Hub Lead Admin',
        //             label: 'Hub Lead Admin'
        //         }]
        //     }
        // },
        // {
        //     dataField: 'institution',
        //     text: 'Institution',
        //     sort: true,
        //     filter: textFilter(),
        //     editor: {
        //         type: Type.SELECT,
        //         getOptions: (setOptions, { row, column }) => {
        //             //   console.log(`current editing row id: ${row.id}`);
        //             //   console.log(`current editing column: ${column.dataField}`);
        //             let dropDownList = []
        //             institutionsList.forEach(inst => {
        //                 dropDownList.push({
        //                     value: inst,
        //                     label: inst
        //                 })
        //             })
        //             return dropDownList;
        //         }
        //     }

        // },
        // {
        //     dataField: "action",
        //     text: "Action",
        //     sort: false,
        //     formatter: rankFormatter,
        //     headerAttrs: { width: 100 },
        //     editable: false,
        //     // attrs: { width: 50, class: "EditRow" } 
        // }
    ];
    const rowStyle = (row, rowIndex) => {
        return { overflowWrap: 'break-word' };
    };
    function generateObjects(n) {
        let objects = [];
        for (let i = 0; i < n; i++) {
            let randomIndex = Math.floor(Math.random() * institutionsList.length);
            let randomIndex2 = Math.floor(Math.random() * Object.keys(allRoles).length);
            let obj = {
                id: i + 1,
                formID: makeid(8),
                formTitle: faker.lorem.lines(1),
                status: 'Published',
                // firstName: faker.person.firstName(),
                // lastName: faker.person.lastName(),
                // nickname: generateRandomNicknames(false),
                // institution: institutionsList[randomIndex],
                // atype: Object.values(allRoles)[randomIndex2]
            };
            objects.push(obj);
        }
        console.log(objects)
        return objects;

    }
    // const [formList, setFormList] = useState(generateObjects(20))
    // const [formLibrary, setFormLibrary] = useState({})
    const [formResponsesCSVData, setFormResponsesCSVData] = useState(null)
    const csvInstance = useRef();
    function convertToArray(formID) {

        let exportData = [
            ["question_type", "question_text", "options", 'instructions'],
        ];
        if (formID in formLibrary) {
            const formMetadata = formLibrary[formID]
            let formTitle = ''
            let formLongName = ''
            if (formMetadata.formID == formID) {

                formMetadata.form_questions.forEach(q => {
                    const options = q.options ? q.options.join('||') : ''
                    const instructions = q.instructions ? q.instructions : ''
                    exportData.push([q.question_type, q.question_text, options, instructions])
                })
                // console.log('exportData', exportData)
            }
        }


        return exportData

    }
    async function exportFormResponsesToArray(formID, formName) {
        // formID = '7bjTAtC5'
        const formSubmissionRef = collection(db, "automatic_form_submissions");
        const q2 = query(formSubmissionRef, where("formID", "==", formID));
        const querySnapshot = await getDocs(q2)

        console.log(querySnapshot.size)

        let answerDict = {};
        let questionList = [];
        let rawData = [];
        const excludedQuestionTypes = ['form_title', 'plain_text', 'section_header', 'sign_date'];
        let exportData = []
        let params = null
        var filteredArray = [];
        var questionHeaderRow = [];

        if (formID in formLibrary) {
            const formMetadata = formLibrary[formID]
            if (formMetadata.formID == formID) {
                questionList = formMetadata['form_questions'];
            }

        }
        console.log('questionList', questionList);
        questionList.forEach(q => {
            const param = q['questionID']
            const questionText = q['question_text']
            if (param != 'formID' && !excludedQuestionTypes.includes(q['question_type'])) {
                // console.log('q', q)
                filteredArray.push(param)

                if (q.question_type == 'matrix') {
                    // console.log('q.options', q.options);

                    questionHeaderRow = questionHeaderRow.concat(q.options);
                }
                else {
                    questionHeaderRow.push(questionText);
                }

            }
        })
        filteredArray = [...filteredArray, 'createdAt', 'userID', 'formSubmissionID',]
        questionHeaderRow = [...questionHeaderRow, 'createdAt', 'userID', 'formSubmissionID']

        exportData.push(questionHeaderRow)
        querySnapshot.forEach((docSnapShot) => {

            const data = docSnapShot.data();

            rawData.push(data);
            // console.log('data', data)
            if (!data.userID.includes('automatic-bot')) {
                // console.log(data)
                let responses = []
                filteredArray.forEach(param => {
                    if (param == 'createdAt') {
                        let resp = data[param]
                        const createdDate = resp.toDate().toDateString()
                        const createdTime = resp.toDate().toLocaleTimeString('en-US');
                        responses.push(`${createdDate}, ${createdTime}`)
                    }
                    else {
                        // console.log('param', param)
                        // console.log('data[param]', data[param])
                        if (data[param] !== null && data[param] !== undefined) {
                            if (typeof data[param] === 'object') {
                                const paramObj = data[param]
                                let result = []

                                if (param.includes('matrix')) {

                                    for (const [key, value] of Object.entries(paramObj)) {
                                        questionHeaderRow.forEach(header => {
                                            // console.log('key', key)
                                            // console.log('processID(header)', processID(header))
                                            if (processID(header) == key) {
                                                responses.push(value)
                                            }
                                        })
                                    }
                                }
                                else {

                                    for (const [key, value] of Object.entries(paramObj)) {
                                        // console.log(key, value);
                                        if (value != false) {
                                            result.push(value)
                                        }
                                    }
                                    responses.push(result.join(', '))

                                }

                            }
                            else {
                                responses.push(data[param])
                            }
                        }
                        else {
                            // console.log(param, data[param])
                            responses.push("N/A")
                        }

                    }
                })
                exportData.push(responses)
            }
        })
        console.log('exportData', exportData)
        setFormResponsesCSVData({
            data: exportData,
            filename: formName
        })
        return exportData

    }
    useEffect(() => {
        if (formResponsesCSVData && csvInstance.current && csvInstance.current.link) {
            setTimeout(() => {
                csvInstance.current.link.click();
                setFormResponsesCSVData(null);
            });
        }
    }, [formResponsesCSVData]);
    const publishForm = async (formID, status) => {
        await setDoc(doc(db, "form_library", formID), { status: status }, { merge: true })
        console.log(`Form ${formID} is now ${status}`);
    }
    function statusFormatter(cell, row, rowIndex, formatExtraData) {
        // console.log('row', row)
        let color = 'red'
        let icon = <BsCloudCheck />
        let status = row.status.replace('-', ' ');
        if (status.toLowerCase() == 'published') {
            color = 'green'
        }
        else if (status.toLowerCase() == 'unpublished') {
            color = '#C41E3A'
            icon = <BsCloudSlash />
        }
        else if (status.toLowerCase() == 'awaiting approval') {
            color = theme.warningColor
            icon = <BsClock />
        }
        else if (status.toLowerCase() == 'archived') {
            color = 'gray'
            icon = <BsFillArchiveFill style={{ marginBottom: 4 }} />
        }
        status = status.toUpperCase();
        const inputStyle = { color, fontWeight: 'bold' }
        return (
            < div
                style={{
                    textAlign: "center",
                    cursor: "pointer",
                    // lineHeight: "normal"
                    ...inputStyle
                }}>
                {status} {icon}

            </div>
        );
    }
    function formIDFormatter(cell, row, rowIndex, formatExtraData) {
        // console.log('row', row)

        let formID = row.formID
        const status = row.status;


        return (
            < div
                style={{
                    textAlign: "center",
                    cursor: "pointer",
                }}>
                <Dropdown>
                    <Dropdown.Toggle size='sm' variant="outline-secondary" id="dropdown-basic">
                        {/* <Link to={`/forms/${formID}`} target="_blank" rel="noopener noreferrer">{formID}</Link> */}
                        {formID}
                        <BsThreeDotsVertical style={{ marginBottom: 3 }} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {status != 'archived' && <div>
                            <Dropdown.Header>Shared Actions <AiFillAppstore style={{ marginLeft: 2, marginBottom: 4, fontSize: 15 }} /> </Dropdown.Header>
                            <Dropdown.Item
                                as="a"
                                href={`/forms/${formID}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none', color: 'black' }}
                            >
                                Open Form <FaExternalLinkAlt style={{ marginLeft: 2, marginBottom: 5, fontSize: 15 }} />
                            </Dropdown.Item>

                            <Dropdown.Item
                                as="a"
                                href={`/forms/${formID}/edit`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none', color: 'black' }}
                            >
                                Edit Form <FaEdit style={{ marginLeft: 2, marginBottom: 3, fontSize: 16 }} />
                            </Dropdown.Item>

                            <Dropdown.Item onClick={() => {
                                setCurrentForm(row)
                                setShowClone(true)
                            }}>Clone Form <AiFillCopy style={{ marginLeft: 2, marginBottom: 3, fontSize: 16 }} /></Dropdown.Item>
                            {(isCampusLead(role) || isHubLead(role)) && <div>
                                {status == 'unpublished' && <Dropdown.Item onClick={() => { publishForm(row.formID, 'awaiting-approval') }}>Push To Admin <AiOutlineArrowUp style={{ marginLeft: 2, marginBottom: 3, fontSize: 16 }} /></Dropdown.Item>}
                                {status == 'published' && <Dropdown.Item disabled>Form Already Pushed</Dropdown.Item>}
                                {status == 'awaiting-approval' && <Dropdown.Item disabled >Pending Approval</Dropdown.Item>}
                                {status == 'awaiting-approval' && <Dropdown.Item onClick={() => { publishForm(row.formID, 'unpublished') }}>Cancel Push <AiOutlineClose style={{ marginLeft: 2, marginBottom: 3, fontSize: 16 }} /></Dropdown.Item>}
                            </div>}

                            {(isPortalAdmin(role) || isHubLead(role)) && <div>
                                <Dropdown.Divider />
                                <Dropdown.Header>Additional Actions <AiFillThunderbolt style={{ marginLeft: 2, marginBottom: 3, fontSize: 15, color: '#FCAE1E' }} /> </Dropdown.Header>
                                <Dropdown.Item onClick={() => { console.log('Edit Form') }}><CSVLink style={{ textDecoration: 'none', color: 'black' }} data={convertToArray(formID)} filename={`${row.formLongName}.csv`}>
                                    Download Form (CSV)</CSVLink>
                                    <FaDownload style={{ marginLeft: 2, marginBottom: 4, fontSize: 15 }} />
                                </Dropdown.Item>

                                <Dropdown.Item onClick={() => { exportFormResponsesToArray(formID, `${row.formLongName}_responses.csv`) }}>Export Responses (CSV) <FaDownload style={{ marginLeft: 2, marginBottom: 4, fontSize: 15 }} /></Dropdown.Item>

                            </div>}
                            {isPortalAdmin(role) && <div>
                                {status == 'unpublished' && <Dropdown.Item onClick={() => { publishForm(row.formID, 'published') }}>Publish <FaCloudUploadAlt style={{ marginLeft: 2, marginBottom: 3, fontSize: 15 }} /></Dropdown.Item>}
                                {status == 'published' && <Dropdown.Item onClick={() => { publishForm(row.formID, 'unpublished') }}>Unpublish <BsCloudSlashFill style={{ marginLeft: 2, marginBottom: 3, fontSize: 15 }} /></Dropdown.Item>}
                                {status == 'awaiting-approval' && <Dropdown.Item onClick={() => { publishForm(row.formID, 'published') }}>Approve and Publish <FaCloudUploadAlt style={{ marginLeft: 2, marginBottom: 3, fontSize: 15 }} /></Dropdown.Item>}
                                {status == 'awaiting-approval' && <Dropdown.Item onClick={() => { publishForm(row.formID, 'unpublished') }}>
                                    Reject and Unpublish <BsCloudSlashFill style={{ marginLeft: 2, marginBottom: 3, fontSize: 15 }} />
                                </Dropdown.Item>}
                                <Dropdown.Item
                                    onClick={() => {
                                        setCurrentForm(row)
                                        handleShowArchive();
                                    }
                                    }>Archive Form <BsJournalArrowDown style={{ marginLeft: 2, marginBottom: 4, fontSize: 15 }} /></Dropdown.Item>

                            </div>}
                        </div>}
                        {status == 'archived' && <div>
                            {isPortalAdmin(role) && <div>
                                <Dropdown.Item
                                    onClick={() => {
                                        console.log('recover ', formID)
                                        restoreForm(formID, userData, null)
                                    }}>
                                    Recover <BsFillArrowRightSquareFill style={{ marginLeft: 2, marginBottom: 3, fontSize: 15, color: 'green' }}></BsFillArrowRightSquareFill>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    setCurrentForm(row)
                                    setShow(true)
                                }
                                }>Delete Form Permanently <AiFillDelete style={{ marginLeft: 2, marginBottom: 4, fontSize: 15 }} /></Dropdown.Item>


                            </div>}
                        </div>}


                    </Dropdown.Menu>
                </Dropdown>
            </div >
        );
    }
    // useEffect(() => {
    //     // const q = query(collection(db, "Users"));
    //     const formUploadRef = collection(db, "form_library");
    //     const q = query(formUploadRef, orderBy("createdAt", "desc"))
    //     const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //         const users = {};
    //         const remoteList = [];
    //         let index = 0
    //         querySnapshot.forEach((doc) => {
    //             let data = doc.data();
    //             // let startDate = new Date(data.startDate).toLocaleDateString()
    //             let startDate = new Date(data.startDate).toDateString()
    //             startDate = startDate != 'Invalid Date' ? startDate : "N/A"

    //             let endDate = new Date(data.endDate).toDateString()
    //             endDate = endDate != 'Invalid Date' ? endDate : "N/A"

    //             let createdAt = data.createdAt.toDate().toDateString()
    //             let createdTime = data.createdAt.toDate().toLocaleTimeString()
    //             createdAt = createdAt + ', ' + createdTime

    //             let allowedRolesString = 'None'
    //             const allowedRoles = data.allowedRoles;
    //             if (allowedRoles.length > 0) {
    //                 let tempList = []
    //                 console.log('allowedRoles', allowedRoles)
    //                 allowedRoles.forEach(role => {
    //                     if (role in allRolesCompat)
    //                         tempList.push(allRolesCompat[role])
    //                 })
    //                 allowedRolesString = tempList.join(', ')

    //             }
    //             const formDomain = data.formDomain;
    //             const allowedInstitutions = data.allowedInstitutions;

    //             let allowedInstitutionsString = formDomain == 'Common' ? 'Common' : 'Specific: ' + allowedInstitutions.join(', ')
    //             data = {
    //                 ...data,
    //                 id: index + 1,
    //                 startDate,
    //                 endDate,
    //                 createdAt,
    //                 allowedRolesString,
    //                 allowedInstitutionsString
    //             }
    //             formLibrary[data.formID] = data;
    //             setFormLibrary({ ...formLibrary })
    //             remoteList.push(data)
    //             index += 1;
    //         });
    //         setFormList(remoteList)
    //         // setFormListInitial(JSON.parse(JSON.stringify(remoteList)))

    //     });
    // }, [])
    const [show, setShow] = useState(false);
    const [currentForm, setCurrentForm] = useState(null);
    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
    };
    const [showClone, setShowClone] = useState(false);
    const handleShowClone = () => {
        setShowClone(true);
    };
    const handleCloseClone = () => {
        setShowClone(false);
    };
    const [showArchive, setShowArchive] = useState(false);
    const handleShowArchive = () => {
        setShowArchive(true);
    };
    const handleCloseArchive = () => {
        setShowArchive(false);
    };
    const deleteForm = async (formID) => {
        handleCloseArchive();

        if (formID && userData) {
            // await deleteDoc(doc(db, "form_library", formID))
            let docSnapshot = await getDoc(doc(db, "form_library", formID));
            let formData = {}
            if (docSnapshot.data()) {
                formData = docSnapshot.data()
                formData = { ...formData, status: 'unpublished', deletedAt: serverTimestamp(), deletedBy: userData.userID, deletedByFull: userData.email }
                console.log('deleted formData', formData, ' by ', userData.email)

                await setDoc(doc(db, "form_library_recently_deleted", formID), formData, { merge: true })
                await deleteDoc(doc(db, "form_library", formID))
                alert(`Form ${formID} is now moved to Archived folder!`);
            }

        }

    }
    const cloneForm = async (formID) => {
        console.log('Cloning ', formID)
        if (formLibrary) {

            if (formID in formLibrary) {
                const formMetadata = formLibrary[formID]
                let cloneData = JSON.parse(JSON.stringify(formMetadata))
                const newFormID = makeid(8)
                let newFormLongName = ''
                let newFormTitle = ''
                if (formMetadata.displayName) {
                    newFormLongName = formMetadata.displayName + ' (Cloned)'
                }
                else if (formMetadata.formLongName) {
                    newFormLongName = formMetadata.formLongName + ' (Cloned)'
                }
                else if (formMetadata.formTitle) {
                    newFormTitle = newFormLongName.formTitle + ' (Cloned)'
                    newFormTitle = formMetadata.formTitle + ' (Cloned)'
                }
                else if (formMetadata.form_questions && formMetadata.form_questions[0].question_type == 'form_title') {
                    const formName = formMetadata.form_questions[0].question_text
                    newFormLongName = formName + ' (Cloned)'
                    newFormTitle = formName + ' (Cloned)'
                }
                let allowedRoles = cloneData.allowedRoles;
                let compatAllowedRoles = []
                let compatAllowedRolesString = []
                allowedRoles.forEach(role => {
                    const equivRole = equivalentRoleDict[role]
                    if (!compatAllowedRoles.includes(equivRole)) {
                        compatAllowedRoles.push(equivRole)
                        if (equivRole in allRolesCompat)
                            compatAllowedRolesString.push(allRolesCompat[equivRole])
                    }

                })

                if (cloneData.startDate) {
                    cloneData.startDate = parseMultiple(cloneData.startDate);
                }
                if (cloneData.endDate) {
                    cloneData.endDate = parseMultiple(cloneData.endDate);
                }
                let newData = {
                    createdAt: serverTimestamp(),
                    lastEdited: serverTimestamp(),
                    formID: newFormID,
                    formLongName: newFormLongName,
                    formTitle: newFormTitle,
                    userID: userData.userID,
                    status: 'unpublished',
                    currentPrerequisites: [],
                    currentPrerequisitesFull: [],
                    currentNextForm: [],
                    currentNextFormFull: [],
                    allowedRoles: compatAllowedRoles,
                    allowedRolesString: compatAllowedRolesString
                }
                let newFormQuestions = cloneData.form_questions
                newFormQuestions[0].question_text = newFormQuestions[0].question_text + ' (Cloned)'
                cloneData = { ...cloneData, ...newData }
                console.log('Original Data:', formMetadata)
                console.log('Cloned Data: ', cloneData)

                setDoc(doc(db, "form_library", newFormID), cloneData).then(() => {
                    alert('Form has been cloned successfully!')
                    handleCloseClone(true)
                })

            }

            // handleCloseClone(true)

        }


    }

    return <div>

        {/* <NavBar setUserDataExternal={setUserData} /> */}
        {/* <RoleValidationComponent requiredRoles={['hub-lead-admin', 'hub-lead']}
            redirect={true}
            setValidExternal={setValid} /> */}
        {formList && <div><h6>Total Records: {formList.length}</h6></div>}
        <BootstrapTable
            bootstrap4
            keyField='id'
            data={formList}
            columns={columns}
            filter={filterFactory()}
            pagination={paginationFactory()}
            rowStyle={rowStyle}
            cellEdit={cellEditFactory({
                mode: 'click',
                blurToClone: true,
                beforeCloneCell: (oldValue, newValue, row, column) => {
                    // alert('beforeCloneCell')
                    if (oldValue != newValue) {
                        handleShowClone();
                        row.oldValue = oldValue;
                        row.newValue = newValue;
                        row.column = column;
                        setCurrentForm(row)
                    }

                    // setFormList([...formListInitial])
                },
                // afterCloneCell: (oldValue, newValue, row, column) => { console.log('row', row, 'column', column) }
            })}
            rowClasses="small-font-table"
            headerClasses="header-font-table"

        />


        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Form Deletion Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Would you like to delete this form?  </h5>
                {/* {currentForm &&
                    <div>
                        <h6>Email: {currentForm.email}</h6>
                        <h6>Name: {currentForm.firstName} - {currentForm.lastName}</h6>
                        <h6>Nickname: {currentForm.nickname}</h6>
                    </div>} */}
                {currentForm && <Card style={{ width: '100%' }}>
                    <Card.Body>

                        <Card.Title>{currentForm.formLongName ? currentForm.formLongName : currentForm.formTitle}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">ID: {currentForm.formID}</Card.Subtitle>
                        <Card.Text>
                            Created at: <b>{currentForm.createdAt}</b> <br></br>
                        </Card.Text>

                    </Card.Body>
                    <Card.Footer className="text-muted">Note: This action is irreversible</Card.Footer>
                </Card>}

                {/* <h6>Creator Email: {creatorEmail}</h6> */}
                {/* <i>Note: You can recover this by clicking on <Button size='sm' className='mx-1' variant="danger" disabled>
                    View Recently Deleted Forms
                </Button></i> on the main page. */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => {
                    if (currentForm) {
                        deleteFormsPermanent(currentForm.formID, handleClose)
                    }
                }}>Yes, I would like to delete this form.</Button>
            </Modal.Footer>
        </Modal>
        <Modal
            size="lg"
            show={showArchive}
            onHide={handleCloseArchive}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Form Archive Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Would you like to archive this form?  </h5>
                {/* {currentForm &&
                    <div>
                        <h6>Email: {currentForm.email}</h6>
                        <h6>Name: {currentForm.firstName} - {currentForm.lastName}</h6>
                        <h6>Nickname: {currentForm.nickname}</h6>
                    </div>} */}
                {currentForm && <Card style={{ width: '100%' }}>
                    <Card.Body>

                        <Card.Title>{currentForm.formLongName ? currentForm.formLongName : currentForm.formTitle}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">ID: {currentForm.formID}</Card.Subtitle>
                        <Card.Text>
                            Created at: <b>{currentForm.createdAt}</b> <br></br>
                        </Card.Text>

                    </Card.Body>
                    <Card.Footer className="text-muted">Note: You can later view this form in the Archived Forms section.</Card.Footer>
                </Card>}

                {/* <h6>Creator Email: {creatorEmail}</h6> */}
                {/* <i>Note: You can recover this by clicking on <Button size='sm' className='mx-1' variant="danger" disabled>
                    View Recently Deleted Forms
                </Button></i> on the main page. */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseArchive}>
                    Cancel
                </Button>
                <Button variant="dark" onClick={() => {
                    if (currentForm) {
                        deleteForm(currentForm.formID)
                    }
                }}>Yes, I would like to archive this form.</Button>
            </Modal.Footer>
        </Modal>
        <Modal
            size="lg"
            show={showClone}
            onHide={handleCloseClone}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Form Clone Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Would you like to save the change?  </h5>
                {currentForm && <Card style={{ width: '100%' }}>
                    <Card.Body>

                        <Card.Title>{currentForm.formLongName ? currentForm.formLongName : currentForm.formTitle}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">ID: {currentForm.formID}</Card.Subtitle>
                        <Card.Text>
                            Created at: <b>{currentForm.createdAt}</b> <br></br>
                        </Card.Text>

                    </Card.Body>
                    <Card.Footer className="text-muted">Note: This action is irreversible</Card.Footer>
                </Card>}


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    handleCloseClone();
                }}>
                    No
                </Button>
                <Button variant="info" onClick={() => {
                    if (currentForm) {
                        cloneForm(currentForm.formID);
                    }
                }}>Yes, proceed.</Button>
            </Modal.Footer>
        </Modal>
        {formResponsesCSVData ?
            <CSVLink
                data={formResponsesCSVData.data}
                filename={formResponsesCSVData.filename}
                ref={csvInstance}
            />
            : undefined}
    </div >
}