import { Input, RadioGroup, Textarea, Checkbox, CheckboxFollowup, Select, ConditionalPreview } from "../Components";
import { Matrix, MatrixPreview } from "../Components/MatrixQuestion";
import CurvedArrow from "react-curved-arrow";
import React, { useState, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { BsFillPlusCircleFill } from 'react-icons/bs';
import PhoneInput from 'react-phone-number-input'
import { processID } from "./Utils";
import 'react-phone-number-input/style.css'
import { Dropdown } from "../Components/Dropdown";
import { Collapse } from "react-bootstrap";
import { base64Decode } from "@firebase/util";
const highlightColor = '#6E48AA'
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}
export const ShortAnswer = ({ q,
    currentFormDisplayQuestion,
    latestRetrievedForm,
}) => {
    let inputStyle = {}
    let labelStyle = {}

    if (currentFormDisplayQuestion) {
        // console.log(currentFormDisplayQuestion.questionID);
        // console.log(q.questionID);
        if (currentFormDisplayQuestion.questionID === q.questionID) {
            inputStyle = { border: `2px dashed ${highlightColor}` }
            labelStyle = { color: highlightColor, fontWeight: 'bold' }
        }
    }
    let autofilledValue = '';
    if (latestRetrievedForm) {
        // console.log(latestRetrievedForm)
        // console.log(latestRetrievedForm[`${q['questionID']}`])
        autofilledValue = latestRetrievedForm[`${q['questionID']}`]

        if (q.encrypted) {
            autofilledValue = base64Decode(latestRetrievedForm[`${q['questionID']}`])

        }


    }
    // console.log(q.questionID)


    if (q["question_type"] === "short_answer") {
        const instructions = q.question_description ? q.question_description : null

        return <div>
            <Input
                style={inputStyle}
                labelStyle={labelStyle}
                name={q['questionID']}
                label={q['question_text']}
                className="mb-3 col-md-6"
                defaultValue={autofilledValue}
                instructions={instructions}
                required={q.required}
                encrypted={q.encrypted}
                disabled={q.readonly}
            />
        </div>
    }
}
export const LongAnswer = ({ q, currentFormDisplayQuestion, latestRetrievedForm }) => {
    let inputStyle = {}
    let labelStyle = {}

    if (currentFormDisplayQuestion) {
        // console.log(currentFormDisplayQuestion.questionID);
        // console.log(q.questionID);
        if (currentFormDisplayQuestion.questionID === q.questionID) {
            inputStyle = { border: `2px dashed ${highlightColor}` }
            labelStyle = { color: highlightColor, fontWeight: 'bold' }

        }
    }
    let autofilledValue = '';
    if (latestRetrievedForm) {
        // console.log(latestRetrievedForm)
        // console.log(latestRetrievedForm[`${q['questionID']}`])
        autofilledValue = latestRetrievedForm[`${q['questionID']}`]
        if (q.encrypted) {
            autofilledValue = base64Decode(latestRetrievedForm[`${q['questionID']}`])

        }
    }
    if (q["question_type"] === "long_answer") {
        const instructions = q.question_description ? q.question_description : null
        // console.log(q['questionID'], q['question_text'])
        return <div>
            <Textarea
                style={inputStyle}
                labelStyle={labelStyle}
                name={q['questionID']}
                label={q['question_text']}
                className="mb-3 col-md-4"
                required={q.required}
                defaultValue={autofilledValue}
                instructions={instructions}
                encrypted={q.encrypted}
                disabled={q.readonly}



            />
        </div>
    }
}
export const DateInput = ({ q, currentFormDisplayQuestion, latestRetrievedForm }) => {
    let inputStyle = {}
    let labelStyle = {}

    if (currentFormDisplayQuestion) {
        // console.log(currentFormDisplayQuestion.questionID);
        // console.log(q.questionID);
        if (currentFormDisplayQuestion.questionID === q.questionID) {
            inputStyle = { border: `2px dashed ${highlightColor}` }
            labelStyle = { color: highlightColor, fontWeight: 'bold' }

            // console.log(inputStyle);
        }
    }
    let autofilledValue = '';
    if (latestRetrievedForm) {
        // console.log(latestRetrievedForm)
        // console.log(latestRetrievedForm[`${q['questionID']}`])
        autofilledValue = latestRetrievedForm[`${q['questionID']}`]
        // console.log(autofilledValue)
    }
    if (q.question_type === 'date') {
        const instructions = q.question_description ? q.question_description : null
        return <div>
            <Input
                labelStyle={labelStyle}
                style={inputStyle}
                name={q.questionID}
                type="date"
                label={q.question_text}
                className="mb-3 col-md-4"
                defaultValue={autofilledValue}
                instructions={instructions}
                required={q.required}
                disabled={q.readonly}



            />
        </div>
    }
}
export const MultipleChoice = ({ q, currentFormDisplayQuestion, latestRetrievedForm, q_family }) => {
    let inputStyle = {}
    const {
        register,
        watch,
        formState: { errors }
    } = useFormContext();
    if (currentFormDisplayQuestion) {
        // console.log(currentFormDisplayQuestion.questionID);
        // console.log(q.questionID);
        if (currentFormDisplayQuestion.questionID === q.questionID) {
            inputStyle = { color: highlightColor, fontWeight: 'bold' }
            // console.log(inputStyle);
        }
    }
    let options = []
    q['options'].forEach((option, i) => {
        options.push({ label: option, value: option })
    })
    // console.log('options', options)
    const instructions = q.question_description ? q.question_description : null
    // console.log('q', q)

    const [parentChecked, setParentChecked] = useState(watch(q.questionID));
    const parentConditions = [];
    q_family.forEach(followup_q => {
        if (followup_q.parent_conditions) {
            if (Object.keys(followup_q.parent_conditions).length > 0) {
                const parentConditionValue = followup_q.parent_conditions[q.questionID][1];
                // console.log('parentConditionValue', parentConditionValue)
                parentConditions.push(parentConditionValue);
            }
        }
    })


    useEffect(() => {
        if (latestRetrievedForm) {
            setParentChecked(latestRetrievedForm[q.questionID])
        }
        const subscription = watch((value, { name, type }) => {
            if (name == q.questionID)
                setParentChecked(watch(name))

        })
        return () => subscription.unsubscribe();
    }, [watch]);
    useEffect(() => {
        // console.log('parentChecked', parentChecked)
    }, [parentChecked]);

    return <>
        <RadioGroup
            style={inputStyle}
            className="col-sm-12 col-md-10 col-lg-10 mb-3"
            label={q['question_text']}
            name={q['questionID']}
            options={options}
            latestRetrievedForm={latestRetrievedForm}
            instructions={instructions}
            disabled={q.readonly}
            q={q}
        />
        {
            Object.keys(q_family).length > 0 && parentConditions.includes(parentChecked) && <Collapse in={parentChecked}>
                <div className="row conditional-container">

                    {q_family.map(followup_q => {

                        // console.log('followup_q.parent_questionID', followup_q.parent_questionID, 'q.questionID', q.questionID)
                        if (followup_q.parent_questionID == q.questionID) {
                            // console.log('followup_q', followup_q)
                            // console.log('followup_q.parent_conditions & Object.keys(followup_q.parent_conditions).length > 0', followup_q.parent_conditions && Object.keys(followup_q.parent_conditions).length > 0)
                            if (followup_q.parent_conditions) {
                                if (Object.keys(followup_q.parent_conditions).length > 0) {
                                    const parentConditionValue = followup_q.parent_conditions[q.questionID][1];
                                    // console.log('parentConditionValue', parentConditionValue)
                                    if (parentChecked == parentConditionValue) {
                                        const independentQ = JSON.parse(JSON.stringify(followup_q));
                                        independentQ.parent_questionID = "";
                                        return <AutomaticQuestion
                                            q={independentQ}
                                            currentFormDisplayQuestion={currentFormDisplayQuestion}
                                            latestRetrievedForm={latestRetrievedForm}
                                        />
                                    }
                                }
                            }


                        }

                    })}

                </div>
            </Collapse>
        }

    </>


}
export const CheckBox = ({ q, currentFormDisplayQuestion, latestRetrievedForm }) => {
    let inputStyle = {}
    if (currentFormDisplayQuestion) {
        // console.log(currentFormDisplayQuestion.questionID);
        // console.log(q.questionID);
        if (currentFormDisplayQuestion.questionID === q.questionID) {
            inputStyle = { color: highlightColor, fontWeight: 'bold' }
            // console.log(inputStyle);
        }
    }

    let options = []
    q['options'].forEach((option, i) => {
        options.push({ name: option })
    })
    // console.log(options)
    const instructions = q.question_description ? q.question_description : null
    return <Checkbox
        style={inputStyle}
        label={q['question_text']}
        groupname={q['questionID']}
        options={options}
        requiredOptions={q.requiredOptions ? q.requiredOptions : []}
        className="col-sm-12 col-md-10 col-lg-10 mb-3"
        latestRetrievedForm={latestRetrievedForm}
        instructions={instructions}
        disabled={q.readonly}
        q={q}

    />

}
export const DropdownInput = ({ q, currentFormDisplayQuestion, latestRetrievedForm }) => {
    let inputStyle = {}
    if (currentFormDisplayQuestion) {
        // console.log(currentFormDisplayQuestion.questionID);
        // console.log(q.questionID);
        if (currentFormDisplayQuestion.questionID === q.questionID) {
            inputStyle = { color: highlightColor, fontWeight: 'bold' }
            // console.log(inputStyle);
        }
    }

    let options = []
    q['options'].forEach((option, i) => {
        options.push(option)
    })
    // console.log(options)
    const instructions = q.question_description ? q.question_description : null
    const required = q.required ? q.required : false
    let autofilledValue = '';
    if (latestRetrievedForm) {
        // console.log(latestRetrievedForm)
        // console.log(latestRetrievedForm[`${q['questionID']}`])
        autofilledValue = latestRetrievedForm[`${q['questionID']}`]
    }
    else {
        // autofilledValue = q['options'] && q['options'].length > 0 ? q['options'][0] : null
        autofilledValue = null;
    }

    return <Select
        name={q.questionID}
        label={q.question_text}
        className="mb-3 col-md-6"
        options={q['options']}
        defaultValue={autofilledValue}
        instructions={instructions}
        required={required}
        disabled={q.readonly}
    />
}
export const CheckBoxFollowUp = ({ q_family, currentFormDisplayQuestion, latestRetrievedForm }) => {
    let inputStyle = {}
    if (currentFormDisplayQuestion) {
        // console.log(currentFormDisplayQuestion.questionID);
        // console.log(q.questionID);
        if (currentFormDisplayQuestion.questionID === q_family[0].questionID) {
            inputStyle = { color: highlightColor, fontWeight: 'bold' }
            // console.log(inputStyle);
        }
    }
    const instructions = q_family[0].instructions ? q_family[0].instructions : null


    console.log('q_family', q_family);
    return <CheckboxFollowup
        style={inputStyle}
        className="col-sm-12 col-md-6 col-lg-6 mb-3"
        questions={q_family}
        currentFormDisplayQuestion={currentFormDisplayQuestion}
        latestRetrievedForm={latestRetrievedForm}
        instructions={instructions}

    />
    // return <ConditionalPreview
    //     style={inputStyle}
    //     className="col-sm-12 col-md-6 col-lg-6 mb-3"
    //     questions={q_family}
    //     currentFormDisplayQuestion={currentFormDisplayQuestion}
    //     latestRetrievedForm={latestRetrievedForm}
    //     instructions={instructions}

    // />
}
export const RatingBubble = ({ q,
    currentFormDisplayQuestion,
    latestRetrievedForm }) => {
    const specialOptions = ['Other, please specify:']
    const maxRange = q.maxRange;
    const options = Array.from({ length: maxRange }, (x, i) => {
        return { label: i + 1, value: i + 1 }
    })
    const instructions = q.instructions ? q.instructions : null


    // console.log(q.options);
    const [statementOptions, setStatementOptions] = useState(q.options);
    const [currentOtherCount, setCurrentOtherCount] = useState(0);
    useEffect(() => {
        console.log(statementOptions)
    }, [statementOptions, currentOtherCount])
    const addOption = () => {
        const newOption = `Other, please specify (${currentOtherCount + 1}): `
        setCurrentOtherCount(currentOtherCount + 1)
        statementOptions.push(newOption)
        setStatementOptions(statementOptions)
    }
    return <div className="row">

        <b data-tip={instructions} style={{ marginBottom: 15 }} >{q.question_text}</b>
        {
            statementOptions.map((option, i) => {
                // console.log((q.question_text + '.' + option).replace(/,[/]/g,'-'))
                let inputStyle = {}
                if (currentFormDisplayQuestion) {
                    // console.log(option)
                    // console.log(currentFormDisplayQuestion.question_text)
                }
                if (currentFormDisplayQuestion && ((q.questionID + '.' + processID(option)) === currentFormDisplayQuestion.questionID)) {
                    inputStyle = { color: highlightColor, fontWeight: 'bold' }
                }
                let name = q.questionID + '.' + processID(option)
                let label = option
                if (option.includes('Other,')) {
                    label = 'Other, please specify: '
                }
                return <div className="row">
                    <RadioGroup
                        style={inputStyle}
                        label={label}
                        name={name}
                        className="mb-3 row"
                        labelclass="col-5 col-sm-6 col-md-8 col-lg-7 col-xl-8"
                        optionclass="col-7 col-sm-6 col-md-4 col-lg-5 col-xl-4"
                        options={options}
                        latestRetrievedForm={latestRetrievedForm}
                    />
                </div>
            })
        }
        <div>

            <p>Add another option
                <button style={{ border: 'hidden' }}
                    onClick={addOption}>
                    <BsFillPlusCircleFill style={{ color: highlightColor, fontSize: "1.5em" }} /></button> </p>
        </div>

    </div>
}
export const FileUpload = ({ q,
    waitAction = false,
    setBotTriggerNext = null,
    latestRetrievedForm = null,
    currentFormDisplayQuestion }) => {
    const {
        register,
        watch,
        formState: { errors }
    } = useFormContext();
    const arrowColor = highlightColor
    const [attached, setAttached] = useState(false);
    const [reuse, setReuse] = useState(true);
    let showHighlight = false;

    if (currentFormDisplayQuestion) {
        console.log(currentFormDisplayQuestion)
        if (currentFormDisplayQuestion.questionID == q.questionID) {
            showHighlight = true;
        }
    }
    console.log(errors)

    let inputStyle = { border: "1px solid limegreen" }
    let labelStyle = { color: 'limegreen', fontWeight: 'bold' }
    if (attached) {
        inputStyle = { border: "1px solid limegreen" }
        labelStyle = { color: 'limegreen', fontWeight: 'bold' }
    }
    else if (waitAction && !attached && showHighlight) {
        inputStyle = { border: `2px dashed ${arrowColor}` }
        labelStyle = { color: highlightColor, fontWeight: 'bold' }

    }
    else {
        inputStyle = {}
        labelStyle = {}
    }
    let bucketPath = '';
    let previousFilename = '';
    if (latestRetrievedForm) {
        let fileUploadInfo = latestRetrievedForm[`${q.questionID}`]
        console.log(latestRetrievedForm)
        if (fileUploadInfo && fileUploadInfo.uploadSuccess) {
            bucketPath = fileUploadInfo.storageURL
            previousFilename = bucketPath.split('/').pop();

        }

    }
    useEffect(() => {
        if (latestRetrievedForm) {

        }
        const subscription = watch((value, { name, type }) => {
            // console.log(value, name, type);
            if (name && name.includes('reuse')) {
                if (value[name] === true) {
                    setReuse(true)
                }
                else {
                    setReuse(false)
                }
            }
        })
        return () => subscription.unsubscribe();
    }, [watch]);
    const instructions = q.instructions ? q.instructions : null

    return <div>
        {!attached && previousFilename != '' &&
            <div>
                <b> <u> Note:</u> </b>You have uploaded <b>{previousFilename}</b> previously.
                <Input
                    // labelStyle={style}
                    name={q.questionID + '-reused'}
                    label={`Re-use ${previousFilename}`}
                    type="checkbox"
                    className="form-check col"
                    labelClass="form-check-label"
                    inputClass="form-check-input"
                    defaultChecked={true}
                // required={q.required}
                />
            </div>}
        {(!reuse || previousFilename == '') && <div>
            {waitAction && !attached && <div className="from" style={{ height: "70px" }} />}

            {waitAction && !attached && showHighlight &&
                <CurvedArrow dynamicUpdate={true} color={highlightColor}
                    fromSelector=".from" toSelector=".to"
                    middleY={40} fromOffsetX={100} toOffsetX={0}
                    fromOffsetY={10} toOffsetY={10} />}
            <div className='to'>
                <Input

                    labelStyle={labelStyle}
                    style={inputStyle}
                    name={q.questionID}
                    label={q.question_text}
                    className="mb-3"
                    inputClass="form-control"
                    type="file"
                    onChange={(e) => {
                        const { target } = e
                        if (target.value.length > 0) {
                            console.log('attached')
                            setAttached(true);
                            setBotTriggerNext(true);

                        } else {
                            console.log('not attached')
                        }
                    }}
                    instructions={instructions}
                    required={q.required}


                />
            </div>
        </div>}
        {/* <div className="text-danger">{errors[q.questionID]?.message}</div> */}


    </div>

}
export const PhoneNumberInput = ({ q,
    currentFormDisplayQuestion,
    latestRetrievedForm }) => {
    let inputStyle = {}
    let labelStyle = {}

    if (currentFormDisplayQuestion) {
        // console.log(currentFormDisplayQuestion.questionID);
        // console.log(q.questionID);
        if (currentFormDisplayQuestion.questionID === q.questionID) {
            inputStyle = { border: `2px dashed ${highlightColor}` }
            labelStyle = { color: highlightColor, fontWeight: 'bold' }

        }
    }
    let autofilledValue = '';

    if (latestRetrievedForm) {
        // console.log(latestRetrievedForm)
        // console.log(latestRetrievedForm[`${q['questionID']}`])
        autofilledValue = latestRetrievedForm[`${q['questionID']}`]
    }
    const instructions = q.instructions ? q.instructions : null

    return <Input
        labelStyle={labelStyle}
        style={inputStyle}
        name={q.questionID}
        type="tel"
        label={q.question_text}
        className="mb-3 col-md-4"
        inputPlaceholder={'xxx-xxx-xxxx'}
        defaultValue={autofilledValue}
        instructions={instructions}
        required={q.required}

    />
}
export const EmailInput = ({ q,
    currentFormDisplayQuestion,
    latestRetrievedForm }) => {
    let inputStyle = {}
    let labelStyle = {}

    if (currentFormDisplayQuestion) {
        // console.log(currentFormDisplayQuestion.questionID);
        // console.log(q.questionID);
        if (currentFormDisplayQuestion.questionID === q.questionID) {
            inputStyle = { border: `2px dashed ${highlightColor}` }
            labelStyle = { color: highlightColor, fontWeight: 'bold' }

        }
    }
    let autofilledValue = '';

    if (latestRetrievedForm) {
        // console.log(latestRetrievedForm)
        // console.log(latestRetrievedForm[`${q['questionID']}`])
        autofilledValue = latestRetrievedForm[`${q['questionID']}`]
    }
    const instructions = q.instructions ? q.instructions : null

    return <Input
        labelStyle={labelStyle}
        style={inputStyle}
        name={q.questionID}
        type="email"
        label={q.question_text}
        className="mb-3 col-md-4"
        inputPlaceholder={'jane.doe@gmail.com'}
        required={q.required}

        defaultValue={autofilledValue}
        instructions={instructions}

    />
}
export const PlainText = ({ q }) => {
    const textToDisplay = q.question_text ? q.question_text : ''
    return <div style={{ marginBottom: "10px", whiteSpace: 'pre-line' }}>
        <p>{textToDisplay}</p>
    </div>
}
export const SignDate = ({ q }) => {
    const textToDisplay = q.question_text ? q.question_text : ''
    const todayDate = new Date()
    return <div style={{ marginBottom: "10px" }}>
        <p>{textToDisplay} : <b>{todayDate.toLocaleDateString('en-US')}</b></p>
    </div>
}
export const SectionHeader = ({ q }) => {
    const textToDisplay = q.question_text ? q.question_text : ''
    return <div style={{ marginBottom: "10px" }}>
        <b>{textToDisplay}</b>
    </div>
}
export const FormTitle = ({ q }) => {
    const textToDisplay = q.question_text ? q.question_text : ''
    return <div style={{ marginBottom: "10px", letterSpacing: "0.03px", wordBreak: "break-word" }}>
        <h1><i></i> {textToDisplay} {q.readonly && <i>(Read-only)</i>}</h1>
    </div>
}

export const AutomaticQuestion = ({ q,
    formQuestions = [],
    waitAction = false,
    setBotTriggerNext = null,
    currentFormDisplayQuestion = null,
    latestRetrievedForm = null }) => {
    // console.log('q', q)
    if (!q.questionID) {
        q.questionID = processID(q.question_text); //no comma or periods. in IDs or keys;
    }
    if (q['parent_questionID'] == "" || !q.parent_questionID) { //not belongs to the follow up family
        if (q["question_type"] === "short_answer") {
            return <ShortAnswer q={q}
                currentFormDisplayQuestion={currentFormDisplayQuestion}
                latestRetrievedForm={latestRetrievedForm} />;
        }
        else if (q.question_type == 'phone_number') {
            return <PhoneNumberInput q={q}
                currentFormDisplayQuestion={currentFormDisplayQuestion}
                latestRetrievedForm={latestRetrievedForm} />;
        }
        else if (q.question_type == 'email') {
            return <EmailInput q={q}
                currentFormDisplayQuestion={currentFormDisplayQuestion}
                latestRetrievedForm={latestRetrievedForm} />;
        }
        else if (q["question_type"] === "long_answer") {
            return <LongAnswer q={q} currentFormDisplayQuestion={currentFormDisplayQuestion} latestRetrievedForm={latestRetrievedForm} />;
        }
        else if (q["question_type"] === "multiple_choice") {
            // console.log('conditionalll')
            let q_family = []
            formQuestions.forEach(question => {
                // console.log(question)
                if (question.parent_questionID === q.questionID) {
                    q_family.push(question);
                }
            })
            // console.log('q_family', q_family)
            return <MultipleChoice q={q}
                currentFormDisplayQuestion={currentFormDisplayQuestion}
                latestRetrievedForm={latestRetrievedForm}
                q_family={q_family} />;
        }
        else if (q["question_type"] === "checkbox") {
            return <CheckBox q={q} currentFormDisplayQuestion={currentFormDisplayQuestion} latestRetrievedForm={latestRetrievedForm} />;

        }
        else if (q["question_type"] === "dropdown") {
            return <DropdownInput q={q} currentFormDisplayQuestion={currentFormDisplayQuestion} latestRetrievedForm={latestRetrievedForm} />

        }
        else if (q["question_type"] === "date") {
            return <DateInput q={q} currentFormDisplayQuestion={currentFormDisplayQuestion} latestRetrievedForm={latestRetrievedForm} />;

        }
        else if (q['question_type'] === 'conditional') {
            // console.log('conditionalll')
            let q_family = [q]
            formQuestions.forEach(question => {
                // console.log(question)
                if (question.parent_questionID === q.questionID) {
                    q_family.push(question);
                }
            })
            return <CheckBoxFollowUp q_family={q_family} currentFormDisplayQuestion={currentFormDisplayQuestion} latestRetrievedForm={latestRetrievedForm} />;
            // return <ConditionalPreview q_family={q_family} />;

        }
        else if (q.question_type === 'matrix') {
            return <Matrix q={q} latestRetrievedForm={latestRetrievedForm} />
            // return <MatrixPreview q={q} />


        }
        else if (q.question_type === 'file_upload') {
            return <FileUpload q={q}
                waitAction={waitAction}
                setBotTriggerNext={setBotTriggerNext}
                latestRetrievedForm={latestRetrievedForm}
                currentFormDisplayQuestion={currentFormDisplayQuestion}
            />
        }
        else if (q.question_type === 'section_header') {
            return <SectionHeader q={q} />
        }
        else if (q.question_type === 'form_title') {
            return <FormTitle q={q} />
        }
        else if (q.question_type === 'plain_text') {
            return <PlainText q={q} />
        }
        else if (q.question_type === 'sign_date') {
            return <SignDate q={q} />
        }
    }
    else { //belongs to follow up family
        // console.log('not supported', q)
        return <></>
    }
    return <div></div>;
}