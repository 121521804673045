const Review = ({ steps, keys, labels }) => {
  return (
    <div style={{ width: "100%" }}>
      {/* {console.log(obj2)} */}
      <h5>Summary</h5>
      <table className="table">
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {keys.map(
            key =>
              steps[key] && (
                <tr key={key}>
                  <td>{labels[key]}</td>
                  <td>{steps[key].value}</td>
                  {/* {console.log(steps[key])} */}
                </tr>
              )
          )}
          {/* {keys2.map(key => */}
          {/*   obj2[key.match(/\w+/)].push( */}
          {/*     ["n", "nn", "nnn", "no", "nope"].includes( */}
          {/*       steps[key].value.toLowerCase() */}
          {/*     ) */}
          {/*   ) */}
          {/* )} */}
          {/* {console.log(obj2)} */}

          {/* {keys3.map( */}
          {/*   key => */}
          {/*     steps[key] && ( */}
          {/*       <tr> */}
          {/*         <td>{labels3[key]}</td> */}
          {/*         <td>{steps[key].value}</td> */}
          {/*       </tr> */}
          {/*     ) */}
          {/* )} */}
        </tbody>
      </table>
    </div>
  );
};

export default Review;
