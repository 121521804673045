import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import '../Components/Input.css'
import ReactTooltip from 'react-tooltip';
import { AiFillQuestionCircle, AiOutlineLock } from "react-icons/ai";
import theme from '../Theme/theme'
import { countableQuestionTypes, encryptableQuestionTypes, requirableQuestionTypes } from "./Utils";
import { FieldAnnotation } from "../Components";
import { Col, Row } from "react-bootstrap";

export const FileUploadInput = ({
    q,
    labelStyle,
    name,
    label,
    className,
    type,
    labelClass,
    inputClass = "form-control",
    inputPlaceholder,
    instructions,
    defaultCheckValue,
    defaultEncryptValue,
    questionType,
    ...rest
}) => {
    const { watch,
        register,
        formState: { errors }
    } = useFormContext();
    // let labelStyle = {}
    // if ({...rest}.style && Object.keys({...rest}.style).length > 0 ) {
    //   labelStyle = {color: 'aqua', fontWeight: 'bold'}
    // }
    // console.log('q in fileuploadinput', q)
    const highlightColor = theme.highlightColor
    const normalizeInput = (value) => {
        // console.log(value)
        // return nothing if no value
        if (!value) return value;

        // only allows 0-9 inputs
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;
        let formattedString = ''
        if (value.length > 0) {

            // returns: "x", "xx", "xxx"
            if (cvLength < 4) return currentValue;

            // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

            // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
            formattedString = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
        return formattedString
    };
    if (type == 'tel') {
        // console.log(rest.defaultValue,  normalizeInput(rest.defaultValue))
        rest.defaultValue = normalizeInput(rest.defaultValue)
    }
    const [phoneNum, setPhoneNum] = useState(rest.defaultValue)
    useEffect(() => {
        const passedName = name
        const passedType = type;
        const subscription = watch((value, { name, type }) => {
            // console.log(passedName)
            // console.log(value, name, type);

            if (passedName == name && passedType == 'tel') {
                // console.log(normalizeInput(value[name]))
                setPhoneNum(normalizeInput(value[name]))
            }
        })
        return () => subscription.unsubscribe();
    }, [watch]);
    // const parent_questionID = q.parent_questionID? q.parent_questionID: null;
    // if (parent_questionID) {
    //     console.log('parent_questionID', parent_questionID)
    // }


    return (
        <div className={className}>
            <ReactTooltip backgroundColor={highlightColor} />

            <label htmlFor={name} style={errors[name] ? { color: 'red', fontWeight: 'bold' } : labelStyle} className={`form-label ${labelClass}`}>
                {label}
                {instructions && <AiFillQuestionCircle style={{ marginLeft: '2px', marginBottom: '3px', color: highlightColor, fontSize: '17px' }} data-tip={instructions} />}
            </label>

            <div className='form-check'>
                {type == 'tel' &&
                    <input
                        type={'tel'}
                        className={`${inputClass} ${errors[name] ? "is-invalid" : ""}`}
                        id={name}
                        placeholder={inputPlaceholder}
                        value={phoneNum}
                        {...register(name)}
                        {...rest}
                        autoFocus
                    />}
                {questionType != 'plain_text' && <input
                    type={type || "text"}
                    className={`${inputClass} ${errors[name] ? "is-invalid" : ""}`}
                    id={name}
                    placeholder={inputPlaceholder}
                    {...register(name)}
                    {...rest}
                // autoFocus
                />}
                {questionType == 'plain_text' && <textarea
                    type={type || "text"}
                    style={{ minHeight: '200px' }}
                    className={`form-control ${inputClass} ${errors[name] ? "is-invalid" : ""}`}
                    id={name}
                    placeholder={inputPlaceholder}
                    {...register(name)}
                    {...rest}

                />}
                <div className="invalid-feedback">{errors[name]?.message}</div>
            </div>

            <FieldAnnotation question={q} />

            {requirableQuestionTypes.includes(questionType) && <div className='form-check form-check-inline'>
                {/* hello */}
                <label>Required</label>

                <input
                    className="form-check-input col form-check-inline"
                    type="checkbox"
                    id={name + '-required'}
                    // value={true}
                    value={name + '-required'}
                    defaultChecked={defaultCheckValue}
                    {...register(name + '-required')}
                />
                <AiFillQuestionCircle style={{ marginLeft: '2px', marginBottom: '3px', color: highlightColor, fontSize: '17px' }} data-tip={'Users must upload a file for this question in order to submit successfully.'} />

            </div>}
            {encryptableQuestionTypes.includes(questionType) && <div className='form-check form-check-inline'>
                <label>Encrypted</label>

                <input
                    className="form-check-input"
                    type="checkbox"
                    id={name + '-encrypted'}
                    // value={true}
                    value={name + '-encrypted'}
                    defaultChecked={defaultEncryptValue}
                    {...register(name + '-encrypted')}
                />
                <AiOutlineLock style={{ marginLeft: '2px', marginBottom: '3px', color: 'green', fontSize: '17px' }}
                    data-tip={'Answers to this question will be securely encrypted in the database.'} />
            </div>}
            {
                Object.keys(countableQuestionTypes).includes(questionType) && <Row style={{ margin: "5 0 5 0" }}>
                    <Col md='2' className='center-text-container' style={{ marginLeft: 2 }}>Speech-out Keyword(s):</Col>
                    <Col md='8' style={{ paddingLeft: 0 }}>
                        <div>


                            <div className='form-check'>

                                <input
                                    type={"text"}
                                    className={`${inputClass} ${errors[name + '-keywords'] ? "is-invalid" : ""}`}
                                    id={name + '-keywords'}
                                    placeholder={'Sample Keywords'}
                                    defaultValue={q.keywords ? q.keywords : ''}
                                    {...register(name + '-keywords')}
                                />
                                <div className="invalid-feedback">{errors[name + '-keywords']?.message}</div>

                            </div>
                        </div>
                    </Col>
                    {/* yo */}
                </Row>
                // <div>
                //     <label>Speech-out Keywords</label>

                //     <div className='form-check'>

                //         <input
                //             type={"text"}
                //             className={`${inputClass} ${errors[name + '-keywords'] ? "is-invalid" : ""}`}
                //             id={name + '-keywords'}
                //             placeholder={'Sample Keywords'}
                //             defaultValue={q.keywords ? q.keywords : ''}
                //             {...register(name + '-keywords')}
                //         />
                //         <div className="invalid-feedback">{errors[name + '-keywords']?.message}</div>

                //     </div>
                // </div>

            }





        </div>
    );
};
const SpeechOutKeywords = ({ name }) => {

}