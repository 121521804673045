import React, { useEffect, useState, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { validationSchema_i } from "../Components/Validations";
import { ChatbotInstitute } from "../Chatbot/Chatbots";
import { InputGroups, Select, Checkbox, RadioGroup } from "../Components";
import { yupResolver } from "@hookform/resolvers/yup";
import { db } from "../Firebase/firebasedb";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  serverTimestamp,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Modal, Button } from "react-bootstrap";

const schoolOptions = [
  "Auburn University",
  "Auburn University at Montgomery",
  "Alabama State University",
  "Tuskegee University",
  "University of Missouri-Kansas City",
  "Little Priest Tribal College",
  "University of Wisconsin-Milwaukee",
  "Wichita State University ",
  "Northern Arizona University",
  "University of Nevada, Reno",
  "Columbus State University",
  "Troy University",
  "Middle Tennessee State University",
  "University of Hawaii, Manoa",
  "Northern Marianas College",
  "University of Hawaii-Kapi’olani Community College",
  "The University of Tennessee, Knoxville",
  "University of Washington",
  "San Diego University ",
  "University of Alaska, Anchorage",
  "Southern Union State Community College",
  "The Ohio State University",
  "Gallaudet University",
  "Landmark College",
  "Purdue University",
];

const additionOptions = [
  { name: "nstudent", label: "Total Number of Students (degree seeking)" },
  {
    name: "nstudentStem",
    label: "Total Number of STEM Students (degree seeking)",
  },
  {
    name: "nstudentDisable",
    label: "Total Number of Students with Disabilities (degree seeking)",
  },
  {
    name: "nstudentStemDisable",
    label: "Total Number of STEM Students with Disabilities (degree seeking)",
  },
];

const latinoOptions = [
  { label: "Hispanic or Latino", name: "latino" },
  { label: "Not Hispanic or Latino", name: "nonlatino" },
];

const raceOptions = [
  { name: "native", label: "American Indian or Alaska Native" },
  { name: "asian", label: "Asian" },
  { name: "black", label: "Black or African American" },
  { name: "islander", label: "Native Hawaiian or Other Pacific Islander" },
  { name: "arab", label: "Middle Eastern or North African" },
  { name: "white", label: "White" },
  { name: "multiple", label: "Multiple races" },
  { name: "na", label: "Race Not reported" },
];

const serviceOptions = [
  { name: "Accommodation Letters to faculty" },
  { name: "Accommodations Assessment" },
  { name: "Campus authority for accommodation implementation" },
  { name: "Test/assignment proctoring" },
  { name: "Process for developing formal accommodation plans" },
  { name: "Academic advising" },
  { name: "Career advising" },
  { name: "Student Organization sponsorship" },
  { name: "Faculty consultations" },
  { name: "Student satisfaction surveys" },
  { name: "External work with local high schools" },
  { name: "External work with local rehabilitation agencies" },
  { name: "External work with local community service providers" },
  { name: "Other services provided to students" },
];

const conditionOptions = [
  { name: "brain", label: "Acquired/Traumatic Brain Injury" },
  { name: "add", label: "ADD/ADHD" },
  { name: "blind", label: "Blind/Visual Impairment" },
  { name: "deaf", label: "Deaf/Hard-of-Hearing" },
  { name: "learning", label: "Learning Disability" },
  { name: "orthopedic", label: "Physical/Orthopedic/Mobility Disability" },
  { name: "psycho", label: "Psychological/Psychiatric" },
  { name: "speech", label: "Speech Impairment" },
  { name: "medical", label: "Systemic Health/Medical" },
  { name: "OtherS", label: "Other (Please Specify)" },
  { name: "OtherN", label: "Other (Number)" },
];

const officeOptions = [
  { name: "Stand-alone department" },
  {
    name: "Part of Diversity, Access, and/or Equity department",
  },
  { name: "Part of Counseling department" },
  { name: "Part of Other Student Affairs department" },
  { name: "Part of Other Campus Division (Please Specify)" },
];

const fteOptions = [
  { value: "1", label: "0.1 - 0.9" },
  { value: "2", label: "1.0 – 1.9" },
  { value: "3", label: "2.0 – 2.9" },
  { value: "4", label: "3.0 – 3.9" },
  { value: "5", label: "4.0 – 4.9" },
  { value: "6", label: "Over 5" },
];

const referOptions = [
  { value: "faculty", label: "Faculty" },
  { value: "staff", label: "Staff" },
  { value: "self", label: "Student self-referral" },
];

const InstitutionForm = () => {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  // const [userData, setUserData] = useState(null)
  const [institutionData, setInstitutionData] = useState(null);

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectDestination, setRedirectDestination] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
        setRedirectDestination("/");
        setShouldRedirect(true);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          // setUserData(data);
          if (data.atype === "student") {
            setRedirectDestination("/dashboardstudent");
            setShouldRedirect(true);
          } else if (data.atype === "faculty") {
            setRedirectDestination("/dashboardfaculty");
            setShouldRedirect(true);
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setRedirectDestination("/dashboardadmin");
          setShouldRedirect(true);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const colTrans = collection(db, "InstitutionalForm");
      const q = query(
        colTrans,
        where("institutionID", "==", user.uid),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      getDocs(q)
        .then((snapshot) => {
          const x = snapshot.docs[0].data();
          setInstitutionData(x);
        })
        .catch((err) => {
          setInstitutionData(true);
          console.log(err);
        });
    }
  }, [user]);

  const navigate = useNavigate();
  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectDestination);
    }
  }, [shouldRedirect, redirectDestination, navigate]);

  const formOptions = { resolver: yupResolver(validationSchema_i) };
  const methods = useForm(formOptions);
  const { handleSubmit, reset } = methods;

  const [botSubmit, setBotSubmit] = useState(false);
  // const [isChecked, setIsChecked] = useState(false);

  // const handleOnCheck = () => {
  //   setIsChecked(!isChecked);
  // };
  useEffect(() => {
    console.log(botSubmit);
    if (botSubmit) {
      console.log("TRUE!!!");
      thisform.current &&
        thisform.current.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      // setBotSubmit(false);
    }
  }, [botSubmit]);

  function onSubmit(data) {
    // display form data on success
    console.log(data);
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
    writeToFirebase(data);
    return false;
  }

  function writeToFirebase(data) {
    addDoc(collection(db, "InstitutionalForm"), {
      createdplatfrom: "WebApp",
      institutionID: user.uid,
      createdAt: serverTimestamp(),
      institutionalName: data.school,
      totalStudents: data.nstudent,
      totalSTEMStudents: data.nstudentStem,
      totalStudentsWithDis: data.nstudentDisable,
      totalSTEMStudentsWithDis: data.nstudentStemDisable,
      totalStudentsHispanic: data.latino,
      totalStudentsNotHispanic: data.nonlatino,
      totalRaceAmericanIndian: data.native,
      totalRaceAsian: data.asian,
      totalRaceBlack: data.black,
      totalRaceHawaiian: data.islander,
      totalRaceMiddleEastern: data.arab,
      totalRaceWhite: data.white,
      totalRaceMultiple: data.multiple,
      totalRaceNotReported: data.na,
      totalStudentsHispanicWithDis: data.latinoDisable,
      totalStudentsNotHispanicWithDis: data.nonlatinoDisable,
      totalRaceAmericanIndianWithDis: data.nativeDisable,
      totalRaceAsianWithDis: data.asianDisable,
      totalRaceBlackWithDis: data.blackDisable,
      totalRaceHawaiianWithDis: data.islanderDisable,
      totalRaceMiddleEasternWithDis: data.arabDisable,
      totalRaceWhiteWithDis: data.whiteDisable,
      totalRaceMultipleWithDis: data.multipleDisable,
      totalRaceNotReportedWithDis: data.naDisable,
      services: data.service,
      // servicesOther: data.service.other,
      // : data.brain,
      // : data.add,
      // : data.blind,
      // : data.deaf,
      // : data.learning,
      // : data.orthopedic,
      // : data.psycho,
      // : data.speech,
      // : data.medical,
      // : data.OtherS,
      // : data.OtherN,
      // : data.brainStem,
      // : data.addStem,
      // : data.blindStem,
      // : data.deafStem,
      // : data.learningStem,
      // : data.orthopedicStem,
      // : data.psychoStem,
      // : data.speechStem,
      // : data.medicalStem,
      // : data.OtherSStem,
      // : data.OtherNStem,
      // : data.office,
      // : data.office.other,
      // : data.fte,
      // : data.refer
    }).then((dataSnapshot) => {
      console.log("Document written with ID: ", dataSnapshot.id);
      handleShow();
    });
  }

  const thisform = useRef();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    navigate("/dashboardadmin");
  };
  const handleShow = () => {
    setShow(true);
  };

  // usePrompt("Are you sure you want to leave?", true);

  return (
    institutionData && (
      <div className="container">
        <div className="card m-3">
          <h5 className="card-header">
            Institutional Research Data form IPEDs+
          </h5>
          <div className="card-body">
            <p className="card-text mb-3">{/* placeholder */}</p>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Submission Successful!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Your form has been submitted successfully!
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} ref={thisform}>
                <div className="row">
                  <Select
                    name="school"
                    label="University/College"
                    className="mb-3 col-md-6 fw-bold"
                    options={schoolOptions}
                    defaultValue={institutionData.institutionalName}
                  />
                </div>
                <div className="row">
                  <div className="fw-bold">Additional institutional data</div>
                  <InputGroups
                    options={additionOptions}
                    className="mb-3 col-md-6"
                    /* defaultValue={institutionData} */
                  />
                </div>
                <div className="row">
                  <div className="fw-bold">
                    Number of degree seeking students by ethnicity
                  </div>
                  <InputGroups
                    options={latinoOptions}
                    className="mb-3 col-md-6"
                  />
                </div>
                <div className="row">
                  <div className="fw-bold">
                    Race - Enter number of degree seeking students
                  </div>
                  <InputGroups
                    options={raceOptions}
                    className="mb-3 col-md-4"
                  />
                </div>
                <div className="row">
                  <div className="fw-bold">
                    Ethnicity of students with disabilities – enter number of
                    degree seeking students
                  </div>
                  <InputGroups
                    options={latinoOptions}
                    className="mb-3 col-md-6"
                    suffix="Disable"
                  />
                </div>
                <div className="row">
                  <div className="fw-bold">
                    Race of students with disabilities – enter number of degree
                    seeking students
                  </div>
                  <InputGroups
                    options={raceOptions}
                    className="mb-3 col-md-4"
                    suffix="Disable"
                  />
                </div>
                <div className="row">
                  <div className="fw-bold">
                    Services provided by the Institution’s Disability/Access
                    office (Please check all that apply){" "}
                  </div>
                  <Checkbox
                    label=""
                    groupname="service"
                    options={serviceOptions}
                    className="col-sm-12 col-md-12 col-lg-8 mb-3"
                  />
                </div>

                <div className="row">
                  <div className="fw-bold">
                    Number of Students who Identify with each condition{" "}
                  </div>
                  <InputGroups
                    options={conditionOptions}
                    className="mb-3 col-md-3"
                  />
                </div>
                <div className="row">
                  <div className="fw-bold">
                    Number of Students in STEM Degree Seeking programs who
                    Identify with Each Condition{" "}
                  </div>
                  <InputGroups
                    options={conditionOptions}
                    className="mb-3 col-md-3"
                    suffix="Stem"
                  />
                </div>

                <div className="row">
                  <div className="fw-bold">
                    Disability/Access Office Reporting Line: Office Structure
                  </div>
                  <Checkbox
                    label=""
                    groupname="office"
                    options={officeOptions}
                    className="col-sm-12 col-md-12 col-lg-8 mb-3"
                  />
                </div>
                <div className="row">
                  <div className="fw-bold">
                    How many FTE are in your department that primarily serve
                    students with disabilities?{" "}
                  </div>
                  <RadioGroup label="" name="fte" options={fteOptions} />
                </div>
                <div className="row">
                  <div className="fw-bold">
                    Where do you get most of your student referrals for
                    accommodations?
                  </div>
                  <RadioGroup label="" name="refer" options={referOptions} />
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    disabled={botSubmit}
                    // onClick={() => handleSubmit(onSubmit)}
                    className="btn btn-primary mr-1"
                  >
                    {botSubmit ? "Already submitted by bot!" : "Submit"}
                  </button>
                  <button
                    type="button"
                    onClick={() => reset()}
                    className="btn btn-secondary"
                  >
                    Reset
                  </button>
                </div>
              </form>
              <ChatbotInstitute
                validationSchema={validationSchema_i}
                setFormEnd={setBotSubmit}
                navigate={navigate}
              />
            </FormProvider>
          </div>
        </div>
      </div>
    )
  );
};
export default InstitutionForm;
