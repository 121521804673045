import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { Modal, Button, Row, Col } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { db } from "../Firebase/firebasedb";
import {
  doc, setDoc, getDocs, onSnapshot,
  serverTimestamp, query, where, collection, orderBy, getDoc,
  deleteDoc
} from "firebase/firestore";
import {
  BsArrowUpRight, BsArrowUpRightSquare,
  BsBoxArrowInLeft, BsClock, BsCloudCheck,
  BsCloudSlash, BsCloudSlashFill, BsFillArchiveFill, BsFillArrowRightSquareFill, BsFillCloudArrowUpFill, BsJournalArrowDown, BsJournalArrowUp, BsPencilSquare, BsThreeDotsVertical
} from "react-icons/bs";

import AvailableForms from "./AvailableForms";
import { allRoles, allRolesCompat, isStudentOrMentor } from "../Fixed Sources/accountTypes";
import { AiFillCheckCircle, AiFillClockCircle, AiFillEyeInvisible, AiFillLock } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Bars } from "react-loading-icons";
import theme from "../Theme/theme";
import { checkDateRangeStatus } from "../Automatic Forms/Utils";

export default function DashboardStudent({ role }) {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  let displayName = '';
  if (isStudentOrMentor(role)) {
    if (userData && userData.nickname && userData.nickname != '') {
      displayName = userData.nickname
    }
  }
  else {
    if (userData)
      displayName = `${userData.firstName} ${userData.lastName}`
  }
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
      }
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          setUserData(data);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
    }
  }, [user]);
  return (
    <>

      <main>
        {/* <h2>Surmounting Obstacles for Academic Resilience </h2> */}
        <h3 className="mt-3 text-center">
          {user && userData && `Welcome to SOAR,  ${displayName}!`}
        </h3>
        <p></p>
        {user && userData && <AvailableForms userID={user.uid} accountType={userData.atype} userData={userData} />}
        {user && userData && <UserSubmissions userID={user.uid} userData={userData} />}
      </main>
    </>
  );
}
const UserSubmissions = ({ userID, userData }) => {
  const [submissions, setSubmissions] = useState(null);
  const [forms, setForms] = useState({});

  useEffect(() => {
    const formsCollection = collection(db, 'form_library');
    const archivedFormsCollection = collection(db, 'form_library_recently_deleted');

    // Listen for updates to the forms
    const unsubscribeForms = onSnapshot(formsCollection, (querySnapshot) => {
      let formsData = {};
      querySnapshot.forEach((docSnapshot) => {
        formsData[docSnapshot.id] = docSnapshot.data();
      });
      setForms(formsData);
    });

    // Listen for updates to the archived forms
    const unsubscribeArchivedForms = onSnapshot(archivedFormsCollection, (querySnapshot) => {
      let archivedFormsData = {};
      querySnapshot.forEach((docSnapshot) => {
        archivedFormsData[docSnapshot.id] = docSnapshot.data();
        archivedFormsData[docSnapshot.id].status = 'archived';
      });
      // Merge archived forms data into the forms data
      setForms((prevState) => ({ ...prevState, ...archivedFormsData }));
    });

    // Cleanup function
    return () => {
      unsubscribeForms();
      unsubscribeArchivedForms();
    };
  }, []);
  const submissionQuery = query(
    collection(db, 'automatic_form_submissions'),
    where('userID', '==', userID),
    orderBy('createdAt', 'desc')
  );


  useEffect(() => {
    // ... (rest of your code)

    // Subscribe to the snapshot
    const unsubscribe = onSnapshot(submissionQuery, (submissionSnapshots) => {
      // Get form data for each submission, and add it to an array
      let allSubmissions = [];
      for (const submissionDoc of submissionSnapshots.docs) {
        const submissionData = submissionDoc.data();

        // Merge form data
        const formData = forms[submissionData.formID];
        if (formData) {
          const { formLongName, endDate, startDate, status } = formData;
          submissionData.formLongName = formLongName; // or any other fields you want to merge
          let createdAt = submissionData.createdAt.toDate().toDateString();
          let createdTime = submissionData.createdAt.toDate().toLocaleTimeString();
          submissionData.status = status;
          submissionData.startDate = startDate ? startDate : null;
          submissionData.endDate = endDate ? endDate : null;
          submissionData.createdAt = createdAt + ', ' + createdTime;
          allSubmissions.push(submissionData);
        }


      }

      // Remove duplicates (keep only the latest submission for each formID)
      const uniqueSubmissions = Array.from(new Set(allSubmissions.map(s => s.formID)))
        .map(formID => allSubmissions.find(s => s.formID === formID));

      setSubmissions(uniqueSubmissions);
    });

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, [userID, forms]);

  // Subscribe to real-time updates on 'form_library' collection




  const linkFormatter = (cell, row) => {
    const { formID, status, formLongName, startDate, endDate } = row;
    // replace "#" with the link you need, it could depend on row data
    let expireStatus = checkDateRangeStatus(startDate, endDate);
    if (status === 'published') {
      // console.log('row', row)

      if (expireStatus === 'Open') {
        return <Link to={`/forms/${formID}`} target="_blank" rel="noopener noreferrer">Edit Response</Link>
      }
    }
    return <Link to={`/forms/${formID}/readonly`} target="_blank" rel="noopener noreferrer">View Response</Link>
  }
  function statusFormatter(cell, row, rowIndex, formatExtraData) {
    // console.log('row', row)
    let color = 'red'
    let icon = <AiFillCheckCircle style={{ marginBottom: 3 }} />
    let cursor = 'pointer'
    let { status } = row
    if (status) {
      status = row.status.replace('-', ' ');
    }
    else {
      status = 'N/A'
    }
    let { startDate, endDate } = row

    if (status.toLowerCase() == 'published') {
      status = 'open'
      color = 'green'
      let expireStatus = checkDateRangeStatus(startDate, endDate);
      console.log('row', row)

      console.log('expireStatus', expireStatus);
      if (expireStatus == 'Not yet open') {
        status = 'LOCKED'
        color = 'darkred'
        icon = <AiFillLock style={{ marginBottom: 3 }} />
      }
      else if (expireStatus == 'Not yet open' || expireStatus == 'Expired') {
        status = expireStatus
        color = 'darkred'
        icon = <AiFillClockCircle style={{ marginBottom: 3 }} />
      }
    }
    else if (status.toLowerCase() == 'unpublished') {
      status = 'closed'
      // color = '#C41E3A'
      color = 'gray'

      icon = <AiFillEyeInvisible style={{ marginBottom: 3 }} />


    }
    // else if (status.toLowerCase() == 'awaiting approval') {
    //   color = theme.warningColor
    //   icon = <BsClock />
    // }
    else if (status.toLowerCase() == 'archived') {
      color = 'gray'
      status = 'closed'
      // icon = <AiFillEyeInvisible style={{ marginBottom: 4 }} />
      icon = <AiFillEyeInvisible style={{ marginBottom: 3 }} />

      cursor = 'not-allowed'

    }
    else {
      status = 'closed'
      // color = '#C41E3A'
      color = 'gray'
      icon = <AiFillEyeInvisible style={{ marginBottom: 3 }} />
      cursor = 'not-allowed'

    }
    status = status.toUpperCase();
    const inputStyle = { color, fontWeight: 'bold', fontSize: 13, cursor }
    return (
      < div
        style={{
          textAlign: "center",
          marginTop: 1,
          // lineHeight: "normal"
          ...inputStyle

        }}>
        {status} {icon}

      </div>
    );
  }
  const columns = [
    {
      dataField: 'formLongName',
      text: 'Form Name',
      sort: true,
      filter: textFilter(),
      // formatter: linkFormatter, // add this line
    },
    {
      dataField: 'createdAt',
      text: 'Submitted Date',
      sort: true,
      sortFunc: (a, b, order, dataField) => {
        // console.log('dataField', dataField)
        if (order === 'asc') {
          return new Date(b) - new Date(a);
        }
        return new Date(a) - new Date(b); // desc
      },
      headerStyle: (colum, colIndex) => {
        return { width: '220px', textAlign: 'center', wordBreak: 'break-word' };
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      filter: textFilter(),
      formatter: statusFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '110px', textAlign: 'center', wordBreak: 'break-word' };
      },
    },
    {
      dataField: 'action',
      text: '',
      // sort: true,
      // filter: textFilter(),
      formatter: linkFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '130px', textAlign: 'center', wordBreak: 'break-word' };
      },
    }
  ];
  return (
    <div>
      <details className='' open>
        <summary className='detail-summary' style={{ fontSize: "1.75rem" }}>History of Completed Forms</summary>
        {submissions && <div>
          {submissions.length > 0 && <BootstrapTable
            bootstrap4
            keyField='formID'
            data={submissions}
            columns={columns}
            filter={filterFactory()}
            pagination={paginationFactory()}
            // cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
            rowClasses="small-font-table"
            headerClasses="header-font-table"
          />}
          {submissions.length == 0 && <h4 style={{ textAlign: 'center' }}>No submissions available.</h4>}
        </div>} {!submissions && <Row style={{ marginTop: 5 }}>
          {/* <Col md='12'><SpinningCircles
                stroke="#000000"
                fill={theme.highlightColor}
                speed={1}
                width="50px"
                style={{ margin: "auto" }}
            /></Col> */}
          <Col md='12' style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Bars stroke="#000000"
              fill={theme.highlightColor}
              speed={1}
              width="100%"
              height='200px'
              style={{ margin: "auto" }} />
            <b style={{ fontSize: 18 }}><i>Loading Form History...</i></b>
            {/* {<p className="text-secondary">If your form is long, please be patient.</p>} */}
          </Col>

        </Row>}
      </details>

    </div>
  );
};
