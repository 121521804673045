import React, { useState, useEffect, useRef } from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { ConvertRGBtoRGBA, random_rgb, random_rgba } from "../Automatic Forms/Utils";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//     labels,
//     datasets: [
//         {
//             label: 'Dataset 1',
//             data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//             backgroundColor: 'rgba(255, 99, 132, 0.5)',
//         },
//         {
//             label: 'Dataset 2',
//             data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//             backgroundColor: 'rgba(53, 162, 235, 0.5)',
//         },
//     ],
// };

export const BarCharts = ({ chartData, chartRef }) => {
    // console.log('chartRef', chartRef)
    // useEffect(() => {
    //     console.log('chartRef', chartRef)
    // }, [chartRef])
    const [displayChartData, setDisplayChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    })
    const sortArray = (rawDict) => {
        // Create items array
        if (rawDict) {
            var items = Object.keys(rawDict).map(function (key) {
                return [key, rawDict[key]];
            });

            // Sort the array based on the second element
            items.sort(function (first, second) {
                return second[1] - first[1];
            });

            // Create a new array with only the first 5 items
            return items.slice(0, 10);
        }
        return [];

    }
    const randomColor = random_rgb();
    const randomAlpha = (Math.random() * (0.4 - 0.8) + 0.4).toFixed(1)
    const randomBackgroundColor = ConvertRGBtoRGBA(randomColor, 0.4)
    const randomBorderColor = ConvertRGBtoRGBA(randomColor, 1)

    useEffect(() => {
        if (chartData) {
            // console.log(chartData)
            let rawData = chartData.data;
            let sortedArray = sortArray(rawData);
            let sortedLabels = sortedArray.map(item => item[0])
            let sortedValues = sortedArray.map(item => item[1])
            let { color, alpha } = chartData;
            let borderColor = randomBackgroundColor;
            let backgroundColor = randomBackgroundColor;

            if (!alpha) {
                alpha = 0.4;
            }
            if (color) {
                borderColor = ConvertRGBtoRGBA(color, 1)
                backgroundColor = ConvertRGBtoRGBA(color, 0.4)
            }
            // console.log(sortedLabels, sortedValues)
            const labels = sortedLabels
            const processedData = {
                labels,
                datasets: [
                    {
                        label: chartData.title,
                        data: sortedValues,
                        borderColor,
                        backgroundColor
                    },
                    // {
                    //     label: 'Dataset 2',
                    //     data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                    //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    // },
                ]
            }
            // console.log('processedData', processedData)
            setDisplayChartData(processedData)
            setChartOptions({
                ...chartOptions, plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: chartData.title,
                    },
                },
            })

        }

    }, [chartData])
    return <div>
        {displayChartData && <Bar options={chartOptions} data={displayChartData} ref={chartRef} id='mainChart' />}
    </div>
}
