import React, { useState, useEffect, useRef } from "react";
import { db } from "../Firebase/firebasedb";
import { doc, setDoc, getDoc, orderBy, serverTimestamp, collection, query, where, onSnapshot, getDocs } from "firebase/firestore";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { Input, RadioGroup, Textarea, Checkbox, CheckboxFollowup } from "../Components";
import { BivariableCharts } from "./BivariableCharts";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { CustomMenu, extractYear, FormLibrary, generateRandomAnswersBulk, generateRandomNumber, isPositiveInteger, notQuestionTypes } from "../Automatic Forms/Utils";
import { Card, Col, Collapse, Modal, Row } from "react-bootstrap";
import { AiFillCloseCircle, AiOutlineDown, AiOutlinePlusCircle, AiOutlineUp } from "react-icons/ai";
import theme from "../Theme/theme";
import { RandomDefaultCharts } from "./RandomDefaultCharts";
import 'react-slideshow-image/dist/styles.css';
import "./automatic_chart.css"
import { Slide } from 'react-slideshow-image';
import { useSearchParams } from "react-router-dom";
import { allRoles } from "../Fixed Sources/accountTypes";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
        variant="outline-secondary" // This variant gives a light gray outline
        style={{ color: 'black', borderColor: 'lightgray' }} // Font color set to lightgray
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children} &#x25bc;
    </Button>
));

const CustomToggleChartSelection = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-secondary"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
function EditableBox({ setNumberOfDefaultCharts }) {

    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(5);
    const maxValue = 15;
    const ref = useRef(null);
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setIsEditing(false);
            setValue(ref.current.innerText);
            let currentValue = ref.current.innerText;
            if (isPositiveInteger(currentValue) !== true) {
                currentValue = 5
            }
            else {
                if (currentValue > maxValue) {
                    currentValue = maxValue
                }
                else {
                    currentValue = ref.current.innerText
                }
            }
            setValue(currentValue);
            setNumberOfDefaultCharts(currentValue);
        }
    };

    const handleClick = () => {
        setIsEditing(true);
    };

    const handleChange = (event) => {
        console.log(event.target.innerText)
        // setValue(event.target.innerText);
    };
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsEditing(false);
            setValue(ref.current.innerText);
            let currentValue = ref.current.innerText;
            if (isPositiveInteger(currentValue) !== true) {
                currentValue = 5
            }
            else {
                if (currentValue > maxValue) {
                    currentValue = maxValue
                }
                else {
                    currentValue = ref.current.innerText
                }
            }
            setValue(currentValue);
            setNumberOfDefaultCharts(currentValue);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    useEffect(() => {
        // console.log('new Value', value)
    }, [value])

    return (
        <div>


            <h6>Number of AI-Generated Charts (Min: 5, Max: 15):
                <span
                    ref={ref}
                    className={`editable-box${isEditing ? '--editing' : ''}`}
                    // style={{ marginLeft: 2, paddingLeft: 10, paddingRight: 10 }}
                    onClick={handleClick}
                    contentEditable={isEditing}
                    onInput={handleChange}
                    onKeyDown={handleKeyDown}
                >
                    {value}
                </span>
                {/* {value} */}
            </h6>
            <i style={{ fontSize: 12 }}>Note: AI-generated charts will be different upon refreshing the page.</i>

        </div>
    );
}

const LinkedFormsComponent = ({ formId,
    formLibrary,
    primaryFormSubmissionData,
    setLinked,
    setMergedSubmissionDataExternal }) => {
    const [currentLinkedForms, setCurrentLinkedForms] = useState([]);
    const [currentLinkedVariables, setCurrentLinkVariables] = useState({});
    const [allStoredSubmissions, setAllStoreSubmissions] = useState({});
    const [currentLinkedSubmissions, setCurrentLinkedSubmissions] = useState({});
    const notQuestionTypesExtended = notQuestionTypes.concat(['file_upload']) //no visualization for file_upload for now
    const [mergedFormSubmissionData, setMergedFormSubmissionData] = useState([]);



    useEffect(() => {
        if (currentLinkedForms.length > 0) {
            console.log('currentLinkedForms', currentLinkedForms)
        }
    }, [currentLinkedForms])
    const {
        register,
        watch,
        formState: { errors },
        getValues,
        setError,
        clearErrors,
    } = useFormContext();
    useEffect(() => {

        if (currentLinkedVariables && Object.keys(currentLinkedVariables).length > 0) {
            console.log('currentLinkedVariables (Child)', currentLinkedVariables)
            let mergedInfo = []

            for (const [form_id, link_vars] of Object.entries(currentLinkedVariables)) {
                let linkedInfo = {}
                if (!currentLinkedSubmissions[form_id]) { //info not exists yet

                }
                else {
                    const secondaryFormSubmissionData = currentLinkedSubmissions[form_id]
                    console.log(`Link Info Exists - ${form_id} : `, currentLinkedSubmissions[form_id]);

                    if (primaryFormSubmissionData) {
                        console.log("Primary Info: ", primaryFormSubmissionData);
                        const primaryVariable = link_vars['primaryVariable']
                        const secondaryVariable = link_vars['secondaryVariable']
                        primaryFormSubmissionData.forEach(prim_formData => {
                            secondaryFormSubmissionData.forEach(sec_formData => {
                                if (prim_formData[primaryVariable] == sec_formData[secondaryVariable]) {
                                    mergedInfo.push({ ...sec_formData, ...prim_formData });
                                }
                            })
                        })
                        console.log(`merged Info ${formId} (${primaryVariable}) - ${form_id} (${secondaryVariable}): `, mergedInfo)
                        setLinked(true);
                        setMergedFormSubmissionData([...mergedInfo]);



                    }
                }
            }

        }
        else {
            setLinked(false);
        }
    }, [currentLinkedVariables, currentLinkedSubmissions])
    useEffect(() => {
        if (mergedFormSubmissionData.length > 0) {
            setMergedSubmissionDataExternal([...mergedFormSubmissionData]);

        }
        else {
            setMergedSubmissionDataExternal([]);
        }
    }, [mergedFormSubmissionData])

    useEffect(() => {
        ///Linked Form Submission Data (Real Database) !!!///
        //  const q2 = query(formSubmissionRef, where("formID", "==", form_id));


        // getDocs(q2)
        //     .then((querySnapshot) => {
        //         console.log(querySnapshot.size)
        //         let answerDict = {};
        //         let rawData = [];
        //         const allKeys = [];

        //         querySnapshot.forEach((docSnapShot) => {
        //             const data = docSnapShot.data();
        //             rawData.push(data);
        //         })
        //         setAllStoreSubmissions({
        //             ...allStoredSubmissions,
        //             [form_id]: rawData
        //         });
        //     })
        //     .catch((error) => {
        //         console.log("Error getting documents: ", error);
        //     });

        /* Synthesized Data */
        if (currentLinkedForms.length > 0) {

            currentLinkedForms.forEach(form_id => {
                if (!allStoredSubmissions[form_id]) {
                    const randomNumber = generateRandomNumber(70, 200)

                    generateRandomAnswersBulk(form_id, randomNumber).then(randomResponsesArray => {
                        console.log(`randomResponsesArray - ${form_id}`, randomResponsesArray)
                        setAllStoreSubmissions({
                            ...allStoredSubmissions,
                            [form_id]: randomResponsesArray
                        });
                    })
                }

            });
        }
        else {
            setLinked(false);

        }

    }, [currentLinkedForms])
    useEffect(() => {
        console.log('allStoredSubmissions', allStoredSubmissions)
        const linkedInfo = {}
        currentLinkedForms.forEach(linkedFormID => {
            let startDate = getValues(`startDate-${linkedFormID}`)
            let endDate = getValues(`endDate-${linkedFormID}`)
            console.log(startDate, endDate)
            let [startDateTemp, endDateTemp] = validateDate(startDate, endDate, linkedFormID)
            if (startDateTemp && endDateTemp) {
                console.log('date-qualified linked form', linkedFormID)
                if (allStoredSubmissions[linkedFormID]) { //structure linked forms by date
                    let rawInfo = allStoredSubmissions[linkedFormID]
                    let filteredRawInfo = []
                    rawInfo.forEach(data => {
                        let createdAt = new Date(1900, 1, 1);
                        if (data.createdAt) {
                            if (typeof data.createdAt.toDate == 'function') {
                                createdAt = data.createdAt.toDate()
                            }
                            else if (data.createdAt instanceof Date) {
                                createdAt = data.createdAt;
                            }
                        }
                        if (createdAt > startDateTemp && createdAt <= endDateTemp) {
                            filteredRawInfo.push(data)
                        }
                    })
                    linkedInfo[linkedFormID] = filteredRawInfo
                }
            }
        })
        setCurrentLinkedSubmissions({ ...linkedInfo });


    }, [allStoredSubmissions, currentLinkedForms])
    useEffect(() => {
        if (currentLinkedSubmissions && Object.keys(currentLinkedSubmissions).length > 0) {
            console.log('currentLinkedSubmissions (Child)', currentLinkedSubmissions)
        }
        else {
            console.log('currentLinkedSubmissions (Child): no linked form!')
        }

    }, [currentLinkedSubmissions])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            console.log('watch:', value)
            const linkedInfo = {}

            currentLinkedForms.forEach(linkedFormID => {
                let startDate = value[`startDate-${linkedFormID}`]
                let endDate = value[`endDate-${linkedFormID}`]
                console.log(startDate, endDate)
                let [startDateTemp, endDateTemp] = validateDate(startDate, endDate, linkedFormID)
                if (startDateTemp && endDateTemp) {
                    console.log('date-qualified linked form', linkedFormID)
                    if (allStoredSubmissions[linkedFormID]) { //structure linked forms by date
                        let rawInfo = allStoredSubmissions[linkedFormID]
                        let filteredRawInfo = []
                        rawInfo.forEach(data => {
                            const createdAt = data.createdAt ? data.createdAt.toDate() : new Date(1900, 1, 1);
                            if (createdAt > startDateTemp && createdAt <= endDateTemp) {
                                filteredRawInfo.push(data)
                            }
                        })
                        linkedInfo[linkedFormID] = filteredRawInfo
                    }
                }


            })
            setCurrentLinkedSubmissions({ ...linkedInfo });
        })



        return () => subscription.unsubscribe();
    }, [watch, allStoredSubmissions, currentLinkedForms]);

    const validateDate = (startDate, endDate, formID) => {
        let startDateTemp = startDate;
        let endDateTemp = endDate;
        if (startDate == '') {
            startDateTemp = new Date(1900, 1, 1);
        }
        else {
            startDateTemp = new Date(startDate);
        }
        if (endDate == '') {
            endDateTemp = todayDate;
        }
        else {
            endDateTemp = new Date(endDate);
        }
        if (startDateTemp > todayDate) {
            setError(`startDate-${formID}`, { type: 'custom', message: 'Start date must be before today!' });
        }
        else if (endDateTemp > todayDate) {
            setError(`endDate-${formID}`, { type: 'custom', message: 'End date must be before today!' });
        }
        else if (startDateTemp >= endDateTemp) {
            setError(`startDate-${formID}`, { type: 'custom', message: 'Start Date must be before End Date!' });
        }

        else {
            clearErrors(`startDate-${formID}`);
            clearErrors(`endDate-${formID}`);
            return [startDateTemp, endDateTemp]
        }
        return [false, false]
    }

    if (formLibrary.length > 0) {
        let mainFormVariables = ['userID']
        let mainFormData = {}
        formLibrary.map(formData => {
            if (formData.formID == formId) {
                mainFormData = formData
                let formQuestions = formData['form_questions']
                // console.log('main formQuestions', formQuestions)
                formQuestions.forEach(q => {
                    if (q.questionID) {
                        if (!notQuestionTypesExtended.includes(q.question_type))
                            mainFormVariables.push(q.questionID);
                    }
                })
            }
        })
        console.log('mainFormVariables', mainFormVariables)

        return <div>
            <Dropdown
                onSelect={(eventKey, event) => {

                    setCurrentLinkedForms([...currentLinkedForms, eventKey])
                }}>
                {/* <Dropdown.Toggle variant='outline-primary' className='mb-1'>
                    Link Another Form
                </Dropdown.Toggle> */}

                <Dropdown.Menu as={CustomMenu} >
                    {formLibrary.map(formData => {
                        const formID = formData.formID
                        const formTitle = formData.formLongName ? formData.formLongName : formData.formTitle
                        if (formID != formId && !currentLinkedForms.includes(formID)) {
                            return <Dropdown.Item eventKey={formID}>
                                {formTitle}
                            </Dropdown.Item>
                        }


                    })}
                </Dropdown.Menu>
            </Dropdown>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {
                    currentLinkedForms.map((formID, index) => {
                        let formTitle = ''
                        let secondaryVariables = ['userID']
                        let localFormData = {}
                        {
                            formLibrary.map(formData => {
                                const localformID = formData.formID
                                if (localformID == formID) {
                                    let formQuestions = formData['form_questions']
                                    localFormData = formData
                                    formTitle = formData.formLongName ? formData.formLongName : formData.formTitle

                                    formQuestions.forEach(q => {
                                        if (q.questionID) {
                                            if (!notQuestionTypesExtended.includes(q.question_type))
                                                secondaryVariables.push(q.questionID);
                                        }
                                    })
                                    console.log('secondary formQuestionIDs', secondaryVariables)
                                }


                            })
                        }
                        let currentSelectedSecondaryVar = 'None'
                        let currentSelectedPrimaryVar = 'None'

                        if (currentLinkedVariables[formID]) {
                            let currentLinkInfo = currentLinkedVariables[formID]
                            currentSelectedPrimaryVar = currentLinkInfo['primaryVariable'] ? currentLinkInfo['primaryVariable'] : 'None'
                            currentSelectedSecondaryVar = currentLinkInfo['secondaryVariable'] ? currentLinkInfo['secondaryVariable'] : 'None'
                        }
                        let numberOfRecords = 0
                        if (allStoredSubmissions[formID]) {
                            numberOfRecords = allStoredSubmissions[formID].length;
                        }
                        return <Card style={{
                            width: '20rem',
                            flex: "1",
                            margin: 2,
                        }}
                        >
                            <Card.Header
                                // style={{ display: 'flex' }}
                                as="h5" >Linked Form {`${index + 1}`}

                                <AiFillCloseCircle style={{
                                    color: 'gray',
                                    float: 'right',
                                    verticalAlign: 'top',
                                    position: 'relative',
                                    top: "10%",
                                }} onClick={() => {
                                    let linkedForms = currentLinkedForms
                                    var index = currentLinkedForms.indexOf(formID);
                                    if (index !== -1) {

                                        const removedFormID = linkedForms.splice(index, 1);
                                        // console.log(linkedForms)
                                        // setValue('linkedForms', [...linkedForms])
                                        if (currentLinkedVariables[removedFormID]) {
                                            console.log('removedFormID', removedFormID)
                                            delete currentLinkedVariables[removedFormID]
                                            if (currentLinkedVariables) {
                                                console.log('remaining Linked Variables', currentLinkedVariables)
                                                setCurrentLinkVariables({ ...currentLinkedVariables })
                                            }
                                        }
                                        setCurrentLinkedForms([...linkedForms])
                                    }
                                }} />




                            </Card.Header>
                            <Card.Body>
                                <Card.Title>{formTitle}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">ID: {formID} - # of Records : {numberOfRecords}</Card.Subtitle>
                                <div className='row'>
                                    <div className='col-sm-4 col-md-3 col-lg-4 form-check mb-1'>
                                        <Input
                                            name={`startDate-${formID}`}
                                            type="date"
                                            label={'Start Date'}
                                            required={true}
                                            // className="mb-3 col-md-4"
                                            // defaultValue={format(todayDate)}
                                            instructions={'Start date of responses.'}
                                        />
                                    </div>
                                    <div className=' col-sm-4 col-md-3 col-lg-4 form-check mx-3 mb-1'>
                                        <Input
                                            name={`endDate-${formID}`}
                                            type="date"
                                            label={'End Date'}
                                            // className="mb-3 col-md-4"
                                            required={true}
                                            // defaultValue={format(new Date(), "yyyy-mm-dd")}
                                            instructions={'End date of responses.'}
                                        />
                                    </div>
                                </div>
                                <Card.Text>
                                    Link By:
                                </Card.Text>

                                {/* First Drop Down */}
                                <Dropdown
                                    onSelect={(eventKey, event) => {
                                        const primaryVariable = eventKey
                                        let currentLinkInfo = {}
                                        if (currentLinkedVariables[formID]) {
                                            currentLinkInfo = currentLinkedVariables[formID]
                                            currentLinkInfo['primaryVariable'] = primaryVariable
                                        }
                                        else {
                                            currentLinkInfo = {
                                                primaryVariable: primaryVariable
                                            }
                                        }

                                        setCurrentLinkVariables({
                                            ...currentLinkedVariables,
                                            [formID]: currentLinkInfo
                                        })
                                    }}>
                                    <Dropdown.Toggle variant='outline-primary'>
                                        Main-Form Variable: <b>{currentSelectedPrimaryVar}</b>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu} >
                                        {mainFormVariables.map(variable => {
                                            return <Dropdown.Item eventKey={variable}>
                                                {variable}
                                            </Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* Second Drop Down */}
                                <Dropdown className='mt-1'
                                    onSelect={(eventKey, event) => {
                                        const secondaryVariable = eventKey
                                        let currentLinkInfo = {}
                                        if (currentLinkedVariables[formID]) {
                                            currentLinkInfo = currentLinkedVariables[formID]
                                            currentLinkInfo['secondaryVariable'] = secondaryVariable
                                        }
                                        else {
                                            currentLinkInfo = {
                                                secondaryVariable: secondaryVariable
                                            }
                                        }

                                        setCurrentLinkVariables({
                                            ...currentLinkedVariables,
                                            [formID]: currentLinkInfo
                                        })
                                    }}>
                                    <Dropdown.Toggle variant='outline-secondary'>
                                        Linked-Form Variable: <b>{currentSelectedSecondaryVar}</b>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu} >
                                        {secondaryVariables.map(secondaryVar => {
                                            return <Dropdown.Item eventKey={secondaryVar}>
                                                {secondaryVar}
                                            </Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>

                            </Card.Body>
                        </Card>
                    })
                }
            </div >


        </div >
    }
    return <></>

}
const todayDate = new Date();
const formSubmissionRef = collection(db, "automatic_form_submissions");
function useAllUsersGroupedByAttributes() {
    const [usersByInstitution, setUsersByInstitution] = useState({});
    const [usersByAtype, setUsersByAtype] = useState({});

    useEffect(() => {
        const fetchAllUsers = async () => {
            const userCollection = collection(db, 'Users');  // Using the db instance
            const snapshot = await getDocs(userCollection);

            const usersDictByInstitution = {};
            const usersDictByAtype = {};

            snapshot.forEach(doc => {
                const userData = doc.data();
                const institution = userData.institution;
                const atype = userData.atype;

                // Group by Institution
                if (!usersDictByInstitution[institution]) {
                    usersDictByInstitution[institution] = [];
                }
                usersDictByInstitution[institution].push({
                    ...userData,
                    userID: doc.id
                });

                // Group by Atype (role)
                if (!usersDictByAtype[atype]) {
                    usersDictByAtype[atype] = [];
                }
                usersDictByAtype[atype].push({
                    ...userData,
                    userID: doc.id
                });
            });

            setUsersByInstitution(usersDictByInstitution);
            setUsersByAtype(usersDictByAtype);
        };

        fetchAllUsers();
    }, []);  // Empty dependency array ensures this runs once when the component mounts

    return [usersByInstitution, usersByAtype];
}



export function ChartDashboard() {

    const validationSchema = Yup.object().shape({
        startDate: Yup.string().test('start date vs end date comparison',
            'Start date cannot be after end date', (result) => {
                const date1 = new Date(startDate);
                const date2 = new Date(endDate);
                console.log('startDate', startDate);

                console.log('endDate', endDate);
                // console.log('startDateRequired', startDateRequired)
                if (startDate == "" && endDate == "")
                    return true
                return date1 < date2;
            }),
        endDate: Yup.string().test('start date vs end date comparison',
            'End date cannot be before Start date!', (result) => {
                const date1 = new Date(startDate);
                const date2 = new Date(endDate);

                // console.log(endDate);
                // console.log(startDate)
                if (startDate == "" && endDate == "")
                    return true

                return date1 < date2;
            }),
        chartTitle: Yup.string().required(),
        chartDescription: Yup.string().required(),
    })
    const formOptions = { resolver: yupResolver(validationSchema) };
    const methods = useForm(formOptions);
    const { handleSubmit, reset, watch, register, setError, clearErrors, setValue, getValues, formState: { errors } } = methods;
    console.log('errors', errors)
    const startDate = watch('startDate');

    const endDate = watch('endDate');
    const onSubmit = (data) => console.log(data);
    //Drop-down
    const [chartTitle, setChartTitle] = useState(null);
    const [currentEventKey, setCurrentEventKey] = useState(null)
    const [latestAnswerDict, setLatestAnswerDict] = useState(null)
    const [formData, setFormData] = useState(null);
    const [primaryFormSubmissionData, setPrimaryFormSubmissionData] = useState(null);
    const [retrievedTime, setRetrievedTime] = useState(null);
    const [primaryFormSubmissionDataFiltered, setPrimaryFormSubmissionDataFiltered] = useState([]);

    const [formSubmissionData, setFormSubmissionData] = useState(null);

    const [allVariables, setAllVariables] = useState([]);

    const [currentLinkedSubmissions, setCurrentLinkSubmissions] = useState({});
    const [linked, setLinked] = useState(false);

    const [currentCustomCharts, setCurrentCustomCharts] = useState([]);
    const [customChartCount, setCustomChartCount] = useState(0);
    const [customChartRefs, setCustomChartRefs] = useState([]);


    const [searchParams, setSearchParams] = useSearchParams();
    const dataMode = searchParams.get('dataMode') ? searchParams.get('dataMode') : 'real'
    const excludedKeys = ['userID', 'formID', 'formSubmissionID', 'createdAt']
    const formFilters = {
        'Vv1KJw6H': {
            'excludedKeys': ['First-Name', 'Middle-Name', 'Last-Name', 'Street-Address', 'City', 'Email', 'Telephone', 'Call-Type', 'Call-Number',
                'Gender', 'Telephone', 'US-Citizen', "US-Permanent-Resident", 'Zipcode'],
        },

    }
    console.log('dataMode:', dataMode)
    useEffect(() => {
        if (!linked) {
            setLatestAnswerDict(null);
        }
    }, [linked])

    const [formId, setFormId] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [currentChartLayout, setCurrentChartLayout] = useState([])
    const [numberOfDefaultCharts, setNumberOfDefaultCharts] = useState(5)
    // Slide transition properties
    const indicators = (index) => (<div className="indicator"></div>);
    const slideProperties = {
        duration: 5000,
        transitionDuration: 500,
        infinite: true,
        // indicators: { indicators },
        arrows: true,

    };
    const [open, setOpen] = useState(false);

    const [currentAllowedRoles, setCurrentAllowedRoles] = useState([])
    const [institutionsList, setInstitutionsList] = useState([]);
    const [currentAllowedInstitutions, setCurrentAllowedInstitutions] = useState([])
    const [usersByInstitution, usersByAtype] = useAllUsersGroupedByAttributes();
    console.log('usersByInstitution', usersByInstitution)

    useEffect(() => {
        const docRef = doc(db, "information_sources", 'institutions');
        const unsub1 = onSnapshot(docRef, (docSnap) => {
            if (docSnap.exists()) {
                const data = docSnap.data();
                setInstitutionsList(data['institution_list'])
            } else {

            }
        });
    }, [])
    useEffect(() => {
        if (formId) {
            const formRef = doc(db, "form_library", formId)
            const unsubscribe1 = onSnapshot(formRef, (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    console.log('formData', data);
                    setFormData({ ...data });
                }

            })
            // /Instead, use synthesized data!
            if (dataMode == 'synthetic') {
                const randomNumber = generateRandomNumber(70, 200)
                const generate = generateRandomAnswersBulk(formId, randomNumber).then(randomResponsesArray => {
                    console.log('randomResponsesArray', randomResponsesArray)
                    setPrimaryFormSubmissionData(randomResponsesArray);

                });
            }
            else {
                console.log('using real submission data!');

                const q = query(formSubmissionRef, where("formID", "==", formId));

                const querySnapshot = getDocs(q).then((querySnapshot) => {
                    console.log(querySnapshot.size)
                    let answerDict = {};
                    let rawData = [];
                    const allKeys = [];
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        const data = doc.data();
                        rawData.push(data);
                    });
                    const newDate = new Date();
                    setRetrievedTime(newDate);
                    setPrimaryFormSubmissionData(rawData);
                });

            }

        }





    }, [formId])
    useEffect(() => {
        if (chartTitle) {
            // console.log(chartData)
            setChartData({
                ...chartData,
                title: chartTitle,

                // labels: Object.keys(aggregatedData),
                // values: Object.values(aggregatedData)
            })
        }
    }, [chartTitle])
    useEffect(() => {
        if (chartData) {
            // console.log(chartData)
        }
    }, [chartData])
    const validateDate = (startDate, endDate) => {
        let startDateTemp = startDate;
        let endDateTemp = endDate;
        if (startDate == '') {
            startDateTemp = new Date(1900, 1, 1);
        }
        else {
            startDateTemp = new Date(startDate);
        }
        if (endDate == '') {
            endDateTemp = todayDate;
        }
        else {
            endDateTemp = new Date(endDate);
        }
        if (startDateTemp > todayDate) {
            setError('startDate', { type: 'custom', message: 'Start date must be before today!' });
        }
        else if (endDateTemp > todayDate) {
            setError('endDate', { type: 'custom', message: 'End date must be before today!' });
        }
        else if (startDateTemp >= endDateTemp) {
            setError('startDate', { type: 'custom', message: 'Start Date must be before End Date!' });
        }

        else {
            clearErrors('startDate');
            clearErrors('endDate');
            return [startDateTemp, endDateTemp]
        }
        return [false, false]
    }
    function getAllowedUserIDs(usersByInstitution, usersByAtype, currentAllowedInstitutions, currentAllowedRoles) {
        let allowedUserIDs = [];

        if (!currentAllowedInstitutions.length && !currentAllowedRoles.length) {
            // Both are empty, return all user IDs
            // Object.values(usersByInstitution).forEach(userList => {
            //     const userIDsFromInstitution = userList.map(user => user.userID);
            //     allowedUserIDs = [...allowedUserIDs, ...userIDsFromInstitution];
            // });
            // return [...new Set(allowedUserIDs)];
            return [];
        }

        if (!currentAllowedRoles.length) { // Only institutions are specified
            currentAllowedInstitutions.forEach(institution => {
                if (usersByInstitution[institution]) {
                    const userIDsFromInstitution = usersByInstitution[institution].map(user => user.userID);
                    allowedUserIDs = [...allowedUserIDs, ...userIDsFromInstitution];
                }
            });
        } else if (!currentAllowedInstitutions.length) { // Only roles are specified
            currentAllowedRoles.forEach(role => {
                if (usersByAtype[role]) {
                    const userIDsFromRole = usersByAtype[role].map(user => user.userID);
                    allowedUserIDs = [...allowedUserIDs, ...userIDsFromRole];
                }
            });
        } else {  // Both are specified
            currentAllowedInstitutions.forEach(institution => {
                if (usersByInstitution[institution]) {
                    usersByInstitution[institution].forEach(user => {
                        if (currentAllowedRoles.includes(user.atype)) {
                            allowedUserIDs.push(user.userID);
                        }
                    });
                }
            });
        }

        return [...new Set(allowedUserIDs)];  // Remove duplicates for safety
    }


    const processSpecialConditions = (formId, key, value) => {
        // console.log('formId key value', formId, key, value)
        if (formId == 'Vv1KJw6H') {
            if (key.toLowerCase().includes('date-of-birth')) {
                // console.log('value', extractYear(value))
                return extractYear(value)
            }
        }
        return value
    }

    useEffect(() => {
        let answerDict = {};
        const allKeys = [];
        let filteredData = [];
        if (primaryFormSubmissionData) {
            console.log("start date", startDate);
            console.log("end date", endDate);
            // let startDateTemp = startDate;
            // let endDateTemp = endDate;
            let [startDateTemp, endDateTemp] = validateDate(startDate, endDate)
            if (startDateTemp && endDateTemp) {
                primaryFormSubmissionData.forEach((data) => {
                    let createdAt = new Date(1900, 1, 1);
                    if (data.createdAt) {
                        if (typeof data.createdAt.toDate == 'function') {
                            createdAt = data.createdAt.toDate()
                        }
                        else if (data.createdAt instanceof Date) {
                            createdAt = data.createdAt;
                        }
                    }
                    if (createdAt > startDateTemp && createdAt <= endDateTemp) {
                        filteredData.push(data)
                    }


                })
            }
            else {
                filteredData = primaryFormSubmissionData
            }
            /*Filter by institution and by atype */
            // const allUsersIDoriginal = filteredData.map(data => data.userID)
            // console.log('allUsersIDoriginal', allUsersIDoriginal)
            let filteredData2 = []
            const allAllowedUserIDs = getAllowedUserIDs(usersByInstitution, usersByAtype, currentAllowedInstitutions, currentAllowedRoles);
            console.log('allAllowedUserIDs', allAllowedUserIDs)

            if (allAllowedUserIDs.length > 0) {
                filteredData.forEach((data) => {
                    const { userID } = data
                    if (allAllowedUserIDs.includes(userID)) {
                        filteredData2.push(data)
                    }

                })
            }
            else {
                filteredData2 = filteredData

            }
            filteredData = filteredData2
            const exclusiveIDs = []
            // allUsersIDoriginal.forEach(uID => {
            //     if (!allAllowedUserIDs.includes(uID)) {
            //         exclusiveIDs.push(uID)
            //     }
            // })
            // console.log('exclusiveIDs', exclusiveIDs)

            setPrimaryFormSubmissionDataFiltered([...filteredData])


        }

    }, [primaryFormSubmissionData, startDate, endDate, currentAllowedInstitutions, currentAllowedRoles])
    useEffect(() => {
        if (linked) {
            console.log('has linked form!', currentLinkedSubmissions)
        }
        else {
            console.log('no linked form!')
            let answerDict = {};
            // const allKeys = [];
            setFormSubmissionData([...primaryFormSubmissionDataFiltered])
        }

    }, [primaryFormSubmissionDataFiltered, linked])
    useEffect(() => {
        if (formSubmissionData && formSubmissionData.length > 0) {
            console.log('formSubmissionData', formSubmissionData)
            console.log('formId', formId)

            let answerDict = {};
            let specialFilters = formFilters[formId] ? formFilters[formId] : null
            let combinedExcludedKeys = excludedKeys

            if (specialFilters) {
                if (specialFilters['excludedKeys']) {
                    combinedExcludedKeys = combinedExcludedKeys.concat(specialFilters['excludedKeys']);
                    console.log('combinedExcludedKeys', combinedExcludedKeys)

                }
            }
            formSubmissionData.forEach((data) => {
                console.log('Object.keys(data)', Object.keys(data));
                for (const [key, value] of Object.entries(data)) {
                    if (!combinedExcludedKeys.includes(key)) {
                        if (typeof value === 'string' || value instanceof String) {
                            let processedValue = processSpecialConditions(formId, key, value)
                            if (key in answerDict) {
                                answerDict[key].push(processedValue);
                            }
                            else {
                                answerDict[key] = [processedValue];
                            }
                        }
                        else {
                            if (typeof value === 'object') {
                                // console.log(value)
                                if (value != null) {
                                    for (const [key2, value2] of Object.entries(value)) {
                                        // console.log(key2)
                                        if (key2.includes('checkOther') === false && value2 !== '' && value2 !== false) {
                                            if (key in answerDict) {
                                                answerDict[key].push(value2);
                                            }
                                            else {
                                                answerDict[key] = [value2];
                                            }
                                        }

                                    }
                                }

                            }
                        }
                    }



                }
                // console.log('setLatestAnswerDict', setLatestAnswerDict)
                setLatestAnswerDict({ ...answerDict });
            })
        }
    }, [formSubmissionData, formId])
    useEffect(() => {
        if (allVariables.length > 0) {
            // console.log(allVariables)
            if (currentEventKey == null) {
                setCurrentEventKey(allVariables[0]);
            }
        }
    }, [allVariables, currentEventKey])
    useEffect(() => {
        if (currentEventKey && latestAnswerDict) {
            console.log('currentEventKey', currentEventKey)
            const mainKey = currentEventKey

            let aggregatedData = {}
            if (latestAnswerDict[mainKey]) {
                const uniqueValues = new Set(latestAnswerDict[mainKey])

                uniqueValues.forEach(val => {
                    aggregatedData[val] = 0

                })
                latestAnswerDict[mainKey].forEach(val => {
                    aggregatedData[val] += 1
                })

                setChartData({
                    title: mainKey,
                    data: aggregatedData,
                    // labels: Object.keys(aggregatedData),
                    // values: Object.values(aggregatedData)
                })
            }
            else {
                console.log('latestAnswerDict[mainKey]', latestAnswerDict[mainKey])
            }


        }
        const defaultChartLayout = generateRandomChartNames(latestAnswerDict, numberOfDefaultCharts)
        setCurrentChartLayout(defaultChartLayout)
        console.log('defaultChartLayout', defaultChartLayout)
    }, [currentEventKey, latestAnswerDict, numberOfDefaultCharts])
    useEffect(() => {
        // console.log('numberOfDefaultCharts', numberOfDefaultCharts)
    }, [numberOfDefaultCharts])
    const supportedCharts = ['Bar Chart', 'Pie Chart', 'Line Chart', 'Radar Chart', 'Map Chart']
    const generateRandomChartNames = (latestAnswerDict, numCharts = 5) => {
        var defaultLayouts = ['Bar Chart', 'Pie Chart', 'Line Chart', 'Radar Chart']
        var mapSupported = false;
        if (latestAnswerDict) {
            const potentialMapKeys = ['State']
            let mapKeys = []

            console.log("latestAnswerDict", latestAnswerDict);
            let allKeys = Object.keys(latestAnswerDict);
            allKeys.sort();
            allKeys.forEach(variable => {
                if (potentialMapKeys.includes(variable)) {
                    mapKeys.push(variable);
                }
            })
            if (mapKeys.length > 0) {
                mapSupported = true;
                defaultLayouts.push('Map Chart')
            }

        }
        if (defaultLayouts.length < numCharts) {
            const remainingNum = numCharts - defaultLayouts.length;
            for (let i = 1; i <= remainingNum; i++) {
                let randomChartName = supportedCharts[Math.floor(Math.random() * supportedCharts.length)]
                while (mapSupported == false && randomChartName == 'Map Chart') {
                    randomChartName = supportedCharts[Math.floor(Math.random() * supportedCharts.length)]
                }
                defaultLayouts.push(randomChartName)
            }

        }
        return defaultLayouts
    }
    const [formLibrary, setFormLibrary] = useState([]);
    useEffect(() => {
        console.log('formLibrary', formLibrary)
    }, [formLibrary])
    useEffect(() => {
        console.log('customChartRefs', customChartRefs)
    }, [customChartRefs])

    const [showStatusModal, setShowStatusModal] = useState(false);
    const [currentStatusMessage, setCurrentStatusMessage] = useState('');
    const handleCloseStatusModal = () => {
        setShowStatusModal(false);
    }
    const handleShowStatusModal = (message) => {
        setShowStatusModal(true);
        setCurrentStatusMessage(message);
    }
    useEffect(() => {
        if (showStatusModal === true) {
            if (showStatusModal) {
                document.body.style.overflow = 'unset';
            }

            const timer = setTimeout(() => {
                setShowStatusModal(false);
            }, 4000);
            // This will clear the timeout if the component is unmounted before the timeout finishes
            return () => {
                clearTimeout(timer);
                // Reset the body overflow when the modal is hidden
                document.body.style.overflow = 'auto';
            };
        }
    }, [showStatusModal])

    return <div>
        <div className='row' style={{
            display: 'flex',
            justifyContent: 'center',
            // width: 'fit-content',
            alignContent: 'center',
            textAlign: 'center'
        }}>
            {dataMode == 'synthetic' ? <div className='col-md-12' style={{ width: 'fit-content' }}>
                <h3 style={{ borderRadius: '20px', padding: 10, fontStyle: "italic", backgroundColor: theme.highlightColor, color: 'white' }}>
                    Disclaimer: The data here is randomly generated for development purposes. <br></br>Real responses will be connected in the future once design is finalized.
                </h3>
            </div> :
                <div className='col-md-12' style={{ width: 'fit-content' }}>
                    <h5 style={{ borderRadius: '20px', padding: 10, fontStyle: "italic", backgroundColor: 'green', color: 'white' }}>
                        Data retrieved at: {retrievedTime ? <b>{retrievedTime.toLocaleString()}</b> : <b>N/A</b>}
                    </h5>
                </div>
            }
        </div>
        <FormLibrary setFormLibraryExternal={setFormLibrary} accountType={'hub-lead-admin'} publishedOnly={false} />

        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md='12'>
                        <Dropdown
                            onSelect={(eventKey, event) => {
                                setFormId(eventKey);
                            }}>
                            <Dropdown.Toggle variant='outline-secondary' className='mb-1 w-100'>
                                {!formData && "- Select Form -"}
                                {formData && <b>{formData.formLongName}</b>}

                            </Dropdown.Toggle>

                            <Dropdown.Menu as={CustomMenu} >
                                {formLibrary.map(formData => {
                                    const formID = formData.formID
                                    const formTitle = formData.formLongName ? formData.formLongName : formData.formTitle

                                    return <Dropdown.Item eventKey={formID}>
                                        {formTitle}
                                    </Dropdown.Item>



                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>

                {/* <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Label>Select Form</Form.Label>
                    <Form.Select
                        className="mb-1"
                        onChange={(e) => {
                            setFormId(e.target.value);
                        }}
                    >
                        <option value="" disabled selected>- Select Form -</option>
                        {formLibrary.map(formData => {
                            const formID = formData.formID;
                            const formTitle = formData.formLongName ? formData.formLongName : formData.formTitle;

                            return (
                                <option key={formID} value={formID}>
                                    {formTitle}
                                </option>
                            );
                        })}
                    </Form.Select>
                </Form.Group> */}
                <LinkedFormsComponent formLibrary={formLibrary}
                    formId={formId}
                    setMergedSubmissionDataExternal={setFormSubmissionData}
                    primaryFormSubmissionData={primaryFormSubmissionData}
                    setLinked={setLinked} />
                {
                    formId && <div>
                        <details className>
                            <summary style={{ fontSize: 25, marginBottom: 3 }}>Data Filter</summary>

                            <Row>
                                <Col md='5'>
                                    <Row>
                                        <Col md='5'>
                                            <Input
                                                name={'startDate'}
                                                type="date"
                                                label={'Start Date'}
                                                required={true}
                                                instructions={'Start date of responses.'}
                                            />
                                        </Col>
                                        <Col md='1'>
                                            <label></label>
                                            <Row className="d-flex justify-content-center mb-2" style={{ fontSize: 30 }}>
                                                -
                                            </Row>
                                        </Col>
                                        <Col md='5'>
                                            <Input
                                                name={'endDate'}
                                                type="date"
                                                label={'End Date'}
                                                required={true}
                                                instructions={'End date of responses.'}
                                            />

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='4'>
                                            <Dropdown className=''
                                                onSelect={(eventKey, event) => {
                                                    if (!currentAllowedRoles.includes(eventKey)) {
                                                        // setValue('allowedRoles', [...currentAllowedRoles, eventKey]);
                                                        setCurrentAllowedRoles([...currentAllowedRoles, eventKey])
                                                    }
                                                }}>
                                                <Dropdown.Toggle as={CustomToggle}
                                                    className={errors['allowedRoles'] && currentAllowedRoles.length <= 0 ? '' : 'button-outline-theme'}
                                                    id="dropdown-basic">
                                                    Specify Users
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {Object.keys(allRoles).map(role => {
                                                        if (currentAllowedRoles.includes(role)) {
                                                            return <Dropdown.Item eventKey={role} disabled={true}>{allRoles[role]} (Already Selected)</Dropdown.Item>
                                                        }
                                                        return <Dropdown.Item eventKey={role}>{allRoles[role]}</Dropdown.Item>

                                                    })}
                                                </Dropdown.Menu>
                                                {/* <AiFillQuestionCircle style={{
                                                    marginLeft: '2px', marginBottom: '3px',
                                                    color: theme.highlightColor, fontSize: '17px'
                                                }} data-tip={'Specify which account type can access this form.'} /> */}

                                            </Dropdown>

                                        </Col>
                                        <Col md='8'>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {Object.keys(allRoles).map(role => {

                                                    if (currentAllowedRoles.includes(role)) {
                                                        return <Button style={{ fontSize: "13px", margin: 2, padding: "2px 10px" }} variant='secondary'>
                                                            {allRoles[role]}

                                                            <AiFillCloseCircle style={{
                                                                marginLeft: '5px', marginBottom: '3px',
                                                                color: 'white', fontSize: '17px'
                                                            }} onClick={() => {
                                                                let allowedRoles = currentAllowedRoles
                                                                var index = allowedRoles.indexOf(role);
                                                                if (index !== -1) {

                                                                    allowedRoles.splice(index, 1);
                                                                    console.log(allowedRoles)
                                                                    // setValue('allowedRoles', [...allowedRoles])
                                                                    setCurrentAllowedRoles([...allowedRoles])
                                                                }
                                                            }} /> </Button>
                                                    }
                                                    return <></>

                                                })}
                                            </div>


                                            <div className="is-invalid">

                                            </div>
                                            <div className="invalid-feedback mb-2">
                                                {errors['allowedRoles'] && currentAllowedRoles.length <= 0 ?
                                                    errors['allowedRoles'].message : ''}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md='1'><div className="vertical-line"></div></Col>
                                <Col md='6'>
                                    <label className='mb-2'>Filter by institutions: </label>
                                    <Dropdown className=''
                                        onSelect={(eventKey, event) => {
                                            setCurrentAllowedInstitutions([...currentAllowedInstitutions, eventKey])
                                            // setValue('allowedInstitutions', [...currentAllowedInstitutions, eventKey]);
                                            // setCurrentEventKey(eventKey)
                                        }}>
                                        <Dropdown.Toggle
                                            as={CustomToggle}
                                            // id="dropdown-custom-components"
                                            style={{ borderColor: 'lightgray', color: 'lightgray' }}
                                        >
                                            Add an Institution
                                        </Dropdown.Toggle>


                                        <Dropdown.Menu as={CustomMenu} >
                                            {institutionsList.map(institute => {
                                                if (!currentAllowedInstitutions || !currentAllowedInstitutions.includes(institute))
                                                    return <Dropdown.Item
                                                        eventKey={institute}

                                                    >{institute}</Dropdown.Item>
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {institutionsList.map(institution => {
                                            const displayStyle = currentAllowedInstitutions.includes(institution) ? 'block' : 'none'

                                            return <Button style={{ display: displayStyle, fontSize: "13px", margin: 2, padding: "2px 10px" }} variant='secondary'>{institution}
                                                <AiFillCloseCircle style={{
                                                    marginLeft: '5px', marginBottom: '3px',
                                                    color: 'white', fontSize: '17px',

                                                }} onClick={() => {
                                                    let allowedInstitutions = currentAllowedInstitutions
                                                    var index = allowedInstitutions.indexOf(institution);
                                                    if (index !== -1) {

                                                        allowedInstitutions.splice(index, 1);
                                                        console.log(allowedInstitutions)
                                                        // setValue('allowedInstitutions', [...allowedInstitutions])
                                                        setCurrentAllowedInstitutions([...allowedInstitutions])
                                                    }
                                                }} /> </Button>
                                        })}
                                        {/* <div className="invalid-feedback mb-2">{errors['allowedInstitutions'] && currentAllowedInstitutions.length <= 0 ? errors['allowedInstitutions'].message : ''}</div> */}
                                    </div>

                                </Col>
                            </Row>

                        </details>
                        <hr></hr>
                        {formData &&
                            <Row>
                                <Col md='12' style={{ textAlign: 'center' }}>
                                    <h2>{formData.formLongName ? formData.formLongName : formData.formTitle}
                                    </h2>
                                    {primaryFormSubmissionData && !primaryFormSubmissionDataFiltered && <div>
                                        <h4>Total Unfiltered Original Records: <b>{primaryFormSubmissionData.length}</b> </h4>
                                        {/* 
                                        {primaryFormSubmissionDataFiltered &&
                                            <i>Total Filtered Records: <b>{primaryFormSubmissionDataFiltered.length}</b></i>
                                        } */}
                                    </div>
                                    }

                                    {primaryFormSubmissionDataFiltered && <div>
                                        <h4>Total Filtered Original Records: <b>{primaryFormSubmissionDataFiltered.length}</b> </h4>

                                        {primaryFormSubmissionData &&
                                            <i>Total original (unfiltered) records: <b>{primaryFormSubmissionData.length}</b></i>
                                        }
                                    </div>
                                    }

                                </Col>
                                {/* <div className='col-md-2'>
                                {formLibrary.length > 0 && <Dropdown>
                                    <Dropdown.Toggle>
                                        View Another Form
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu} >
                                        {formLibrary.map(formData => {
                                            const formID = formData.formID
                                            const formTitle = formData.formLongName ? formData.formLongName : formData.formTitle
                                            return <Dropdown.Item href={`/charts/${formID}`}>
                                                <Link to={`/charts/${formID}`} target="_blank" rel="noopener noreferrer"
                                                    style={{ textDecoration: "none", color: 'black' }}
                                                >
                                                    {formTitle}
                                                </Link>
                                            </Dropdown.Item>

                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>}
                            </div> */}

                            </Row>
                        }

                        <Row>
                            <Col md='12' className="d-flex justify-content-center align-items-center">
                                <div className="line">

                                    <Button
                                        onClick={() => setOpen(!open)}
                                        aria-controls="AI-collapse-text"
                                        aria-expanded={open}
                                        variant="link"

                                    >

                                        <h4>AI-Generated Charts </h4>
                                        {open ? <AiOutlineUp style={{ marginBottom: 2, marginLeft: 5 }} /> : <AiOutlineDown style={{ marginBottom: 2, marginLeft: 5 }} />}
                                    </Button>


                                </div>
                            </Col>
                            <Col md='12'>
                                <Collapse in={open}>
                                    <div id="AI-collapse-text">
                                        <EditableBox setNumberOfDefaultCharts={setNumberOfDefaultCharts} />
                                        <Slide indicators={indicators} {...slideProperties} >
                                            {currentChartLayout.map((layout, index) => {
                                                return (
                                                    <div className='' key={index}>
                                                        <RandomDefaultCharts
                                                            latestAnswerDict={latestAnswerDict}
                                                            formData={formData}
                                                            rawData={formSubmissionData}
                                                            requestedChartType={layout}
                                                            handleShowStatusModal={handleShowStatusModal}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </Slide>
                                    </div>
                                </Collapse>
                            </Col>
                        </Row>


                        {(linked || startDate != '' || endDate != '') && formSubmissionData && <h5>Number of Filtered/Linked Records: <b>{formSubmissionData.length}</b></h5>}



                        {/* <div class="wrapper">
                            {currentChartLayout.map((layout, index) => {

                                return <div className=''>
                                    <RandomDefaultCharts
                                        latestAnswerDict={latestAnswerDict}
                                        formData={formData}
                                        rawData={formSubmissionData}
                                        requestedChartType={layout}
                                    />
                                </div>



                            })}
                        </div> */}

                        {/* <div className=''>
                            <RandomDefaultCharts
                                latestAnswerDict={latestAnswerDict}
                                formData={formData}
                                rawData={formSubmissionData}
                                requestedChartType={'Bar Chart'}
                            />
                        </div> */}

                        {/* <div>
                            <iframe src={"https://soar-lzlb5fvlsa-uc.a.run.app/corr/" + formId}
                                height={"500"}
                                width={"100%"} />
                        </div>
                        <>
                            <b>Note: </b>
                            <div>
                                Positive numbers indicate positive correlation between 2 variables.
                                Negative numbers indicate negative correlation between 2 variables.
                                0 indicates NO correlation. <br></br>
                                The <i>further</i> away the correlation coefficient is from zero, the <i>stronger</i> the relationship between the two variables.



                            </div>
                        </> */}
                        <div class="line">
                            <div class="line-before"></div>
                            <span><h4 style={{}}>Expert-Generated Charts</h4></span>
                            <div class="line-after"></div>
                        </div>
                        <Button variant='outline-success' onClick={() => {
                            console.log('additional charts: ' + (customChartCount + 1))
                            setCustomChartCount(customChartCount + 1);
                            const newRef = React.createRef();
                            setCustomChartRefs([...customChartRefs, newRef])
                        }}>
                            <AiOutlinePlusCircle className='mb-1'
                                style={{ fontSize: 17, marginRight: 3 }} />
                            Create Additional Charts
                        </Button>

                        {formSubmissionData && customChartRefs.map((cRef, index) => (
                            <div ref={cRef} className='custom-chart-container'  >
                                <span className='float-end'><AiFillCloseCircle className='float-end' style={{
                                    color: 'gray', fontSize: '22px', cursor: 'pointer'
                                }} onClick={() => {
                                    console.log('delete ' + index)
                                    const deleteRef = customChartRefs.splice(index, 1)[0]; //delete the option
                                    console.log('deleteRef', deleteRef)

                                    deleteRef.current.style.display = "none";
                                    console.log('newCustomChartRefs', customChartRefs)
                                    setCustomChartRefs([...customChartRefs]);


                                }} /></span>

                                <BivariableCharts
                                    latestAnswerDict={latestAnswerDict}
                                    formData={formData}
                                    rawData={formSubmissionData}
                                    handleShowStatusModal={handleShowStatusModal}
                                />

                            </div>
                        ))

                        }



                    </div>
                }


            </form>
        </FormProvider>
        <Modal show={showStatusModal} onHide={handleCloseStatusModal} size="sm"
            backdrop={false}
            dialogClassName="modal-right">
            <Modal.Header closeButton>
                <Modal.Title>{currentStatusMessage}</Modal.Title>
            </Modal.Header>
        </Modal>
    </div>
}
