import { isAccessAllowed } from "../Automatic Forms/Utils";
import React, { useState, useEffect, useRef } from "react";
import { doc, setDoc, getDoc, orderBy, deleteDoc, serverTimestamp, collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../Firebase/firebasedb";
import { allRolesCompat, equivalentRoleDict, isCampusLead, isHubLead, isStudentOrMentor } from "../Fixed Sources/accountTypes";
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap";
import { Slide } from "react-slideshow-image";
import { RenderSelectedChart } from './ChartRenderingUser'
import { BsWindowFullscreen } from "react-icons/bs";
export const ChartDashboardStudent = ({ userData }) => {
    const [chartList, setChartList] = useState([]);
    const [validChartList, setValidChartList] = useState([]);

    const [chartLibrary, setChartLibrary] = useState({});
    // Slide transition properties
    const indicators = (index) => (<div className="indicator"></div>);
    const slideProperties = {
        duration: 5000,
        transitionDuration: 500,
        infinite: true,
        // indicators: { indicators },
        arrows: true,

    };
    const [forceRender, setForceRender] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setForceRender(r => !r); // toggles state to force re-render
        }, 100);

        return () => clearTimeout(timer); // clear timeout if component is unmounted
    }, []);

    // const [archivedFormLibrary, setArchivedFormLibrary] = useState({})
    console.log('userData', userData)
    useEffect(() => {
        const chartLibraryCollection = collection(db, "chart_library");

        // This will set up the real-time listener
        const unsubscribe = onSnapshot(chartLibraryCollection, snapshot => {
            const chartData = snapshot.docs.map(doc => {
                const data = doc.data();
                let { createdAt, allowedInstitutions, allowedRoles, formDomain } = data;
                let createdAtString = 'N/A'
                let allowedInstitutionsString = 'N/A'
                let allowedRolesString = 'N/A'
                if (createdAt) {
                    createdAtString = createdAt.toDate().toDateString()
                    let createdTime = createdAt.toDate().toLocaleTimeString()
                    createdAtString = createdAtString + ', ' + createdTime
                }

                // let allowedRolesString = 'None'

                if (allowedRoles) {
                    if (allowedRoles.length > 0) {
                        let tempList = []

                        allowedRoles.forEach(role => {
                            const equivRole = equivalentRoleDict[role]

                            if (equivRole in allRolesCompat)
                                tempList.push(allRolesCompat[equivRole])
                        })
                        tempList = [...new Set(tempList)];

                        allowedRolesString = tempList.join(', ')
                        // console.log('allowedRolesString', allowedRolesString)



                    }
                }

                if (formDomain) {
                    allowedInstitutionsString = formDomain == 'Common' ? 'Common' : 'Specific: ' + allowedInstitutions.join(', ')
                }
                return {
                    ...data,
                    createdAtString,
                    id: doc.id,
                    allowedRolesString,
                    allowedInstitutionsString,
                    formDomain,
                }
            });
            const chartLibraryRemote = {}
            setChartList(chartData);
            chartData.forEach(data => {
                chartLibraryRemote[data.chartID] = data;
            })
            setChartLibrary({ ...chartLibraryRemote });
            console.log('chartLibraryRemote', chartLibraryRemote)
        });

        // Clean up the listener on component unmount
        return () => unsubscribe();

    }, []);
    useEffect(() => {
        let validList = []
        chartList.forEach(chartMetadata => {
            if (isAccessAllowed(chartMetadata, userData) === true) {
                validList.push(chartMetadata)
            }
        })
        setValidChartList(validList);

    }, [chartList])
    useEffect(() => {
        if (validChartList.length > 0) {
            console.log('validChartList', validChartList)
        }

    }, [validChartList])

    return <div>
        <br></br>
        <h2 className='text-center' style={{ marginBottom: 10 }}>Chart Dashboard ({validChartList.length})</h2>
        {/* {isStudentOrMentor(userData.atype) || userData.atype == 'faculty' ? <h2 className='text-center' style={{ marginBottom: 10 }}>Chart Dashboard ({validChartList.length})</h2>
            : <Row className='mb-2'>
                <Col md='3'>
                </Col>
                <Col md='6' className="d-flex justify-content-center align-items-center">
                    <Dropdown as={ButtonGroup}>
                        <Button variant="outline-secondary" disabled>
                            <h2 style={{ color: 'black', fontWeight: 'bold' }}>Chart Dashboard</h2>
                        </Button>

                        <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />

                        <Dropdown.Menu>
                      
                            <Dropdown.Item
                                as="a"
                                href="/charts/dashboard"
                                target="_blank"
                                rel="noopener noreferrer" // This is for security reasons when using target="_blank"
                            >Admin chart dashboard <BsWindowFullscreen style={{ fontSize: 17, marginBottom: 2, marginLeft: 2 }} /></Dropdown.Item>
                       
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>

            </Row>
        } */}

        <Row>

            <hr></hr>
            {validChartList.length > 0 && <Slide indicators={indicators} {...slideProperties} >
                {validChartList.map((chartMetadata, index) => {
                    const { chartType } = chartMetadata
                    return (
                        <div className='' key={index}>
                            <RenderSelectedChart chartType={chartType} chartData={chartMetadata} />
                        </div>
                    );
                })}
            </Slide>}
        </Row>
    </div>
}