import React, { useEffect, useState, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { validationSchema_u } from "../Components/Validations";
import { ChatbotUnderstanding } from "../Chatbot/Chatbots";
// import TestSet from "../Components/TestSet";
import { vals_understand } from "../Chatbot/StepGenerate";

import { Input } from "../Components";
import { yupResolver } from "@hookform/resolvers/yup";
import { db } from "../Firebase/firebasedb";
import { collection, addDoc, getDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Modal, Button } from "react-bootstrap";

const list_items = Object.entries(vals_understand).map((item) => ({
  name: item[0],
  value: item[1],
}));

const UnderstandingForm = () => {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  // const [userData, setUserData] = useState(null)

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectDestination, setRedirectDestination] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
        setRedirectDestination("/");
        setShouldRedirect(true);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          // setUserData(data);
          if (data.atype === "administrator") {
            setRedirectDestination("/dashboardadmin");
            setShouldRedirect(true);
          } else if (data.atype === "faculty") {
            setRedirectDestination("/dashboardfaculty");
            setShouldRedirect(true);
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setRedirectDestination("/dashboardstudent");
          setShouldRedirect(true);
        }
      });
    }
  }, [user]);
  const navigate = useNavigate();
  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectDestination);
    }
  }, [shouldRedirect, redirectDestination, navigate]);
  const formOptions = { resolver: yupResolver(validationSchema_u) };
  const methods = useForm(formOptions);
  const { handleSubmit, reset } = methods;

  const [botSubmit, setBotSubmit] = useState(false);
  // const [isChecked, setIsChecked] = useState(false);

  // const handleOnCheck = () => {
  //   setIsChecked(!isChecked);
  // };
  useEffect(() => {
    console.log(botSubmit);
    if (botSubmit) {
      console.log("TRUE!!!");
      thisform.current &&
        thisform.current.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      // setBotSubmit(false);
    }
  }, [botSubmit]);
  function onSubmit(data) {
    // display form data on success
    console.log(data);
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
    writeToFirebase(data);
    return false;
  }
  function writeToFirebase(data) {
    addDoc(collection(db, "understandingForm"), {
      checkedOne: data.initOne,
      checkedTwo: data.initTwo,
      checkedThree: data.initThree,
      checkedFour: data.initFour,
      checkedFive: data.initFive,
      checkedSix: data.initSix,
      checkedSeven: data.initSeven,
      checkedEight: data.initEight,
      checkedNine: data.initNine,
      // checkedTen: false,
    }).then((docSnapShot) => {
      console.log("Document written with ID: ", docSnapShot.id);
      handleShow();
    });
  }

  const thisform = useRef();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    navigate("/dashboardstudent");
  };
  const handleShow = () => {
    setShow(true);
  };

  // usePrompt("Are you sure you want to leave?", true);

  return (
    <div className="container">
      <div className="card m-3">
        <h5 className="card-header">Student Understanding Form</h5>
        <div className="card-body">
          <p className="card-text fw-bold mb-4">
            Please initial your understanding of each statement below and sign
            in the space provided below to indicate that you understand what
            data and information will be requested from you while participating
            in the TAPDINTO-STEM program.
          </p>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Submission Successful!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Your form has been submitted successfully!</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} ref={thisform}>
              {list_items.map((item) => (
                <Input
                  name={item.name}
                  key={item.name}
                  label={item.value}
                  type="checkbox"
                  className="form-check mb-3"
                  labelClass="form-check-label"
                  inputClass="form-check-input"
                  /* inputPlaceholder="Initials" */
                />
              ))}
              <div className="form-group">
                <button
                  type="submit"
                  disabled={botSubmit}
                  // onClick={() => handleSubmit(onSubmit)}
                  className="btn btn-primary mr-1"
                >
                  {botSubmit ? "Already submitted by bot!" : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={() => reset()}
                  className="btn btn-secondary"
                >
                  Reset
                </button>
              </div>
            </form>
            <ChatbotUnderstanding
              validationSchema={validationSchema_u}
              setFormEnd={setBotSubmit}
              navigate={navigate}
            />
          </FormProvider>
        </div>
      </div>
    </div>
  );
};
export default UnderstandingForm;
