import { useEffect } from "react";

export const Update = ({ previousStep, handleUpdate }) => {
  useEffect(() => handleUpdate(previousStep), [previousStep, handleUpdate]);
  return false;
};

export const TestUpdate = ({ previousStep }) => {
  useEffect(() => console.log(previousStep), [previousStep]);
  return false;
};
