export const colorScales = [
    [ //green
        "#094F29",
        "#0A6921",
        "#1A8828",
        "#429B46",
        "#64AD62",
        "#94C58C",
    ],
    [ //blue
        "#1E90FF",
        "#187DE9",
        "#126AD2",
        "#0C56BC",
        "#0643A5",
        "#00308F",
    ],
    [ //red
        "#ffedea",
        "#ffcec5",
        "#ffad9f",
        "#ff8a75",
        "#ff5533",
        "#e2492d",
        "#be3d26",
        "#9a311f",
        "#782618"
    ]
]
export const colorFamily = [
    "rgba(255, 0, 255, 0.4)", // Magenta,
    "rgba(0, 255, 255, 0.4)", // Cyan
    "rgba(0, 255, 0, 0.4)",   // Green
    "rgba(255, 255, 0, 0.4)", // Yellow
    "rgba(128, 0, 128, 0.4)", // Purple
    "rgba(255, 165, 0, 0.4)", // Orange
    "rgba(255, 192, 203, 0.4)", // Pink
    "rgba(165, 42, 42, 0.4)", // Brown
    "rgba(0, 128, 0, 0.4)",   // Dark Green
    "rgba(70, 130, 180, 0.4)", // Steel Blue
    "rgba(128, 128, 128, 0.4)", // Gray
    "rgba(0, 128, 128, 0.4)", // Teal

];
export const colorFamilyLight = [
    "rgba(255, 0, 255, 0.12)", // Magenta,
    "rgba(0, 255, 255, 0.1)", // Cyan
    "rgba(0, 255, 0, 0.1)",   // Green
    "rgba(255, 255, 0, 0.1)", // Yellow
    "rgba(128, 0, 128, 0.1)", // Purple
    "rgba(255, 165, 0, 0.1)", // Orange
    "rgba(255, 192, 203, 0.1)", // Pink
    "rgba(165, 42, 42, 0.1)", // Brown
    "rgba(0, 128, 0, 0.1)",   // Dark Green
    "rgba(70, 130, 180, 0.1)", // Steel Blue
    "rgba(128, 128, 128, 0.1)", // Gray
    "rgba(0, 128, 128, 0.1)", // Teal
    "rgba(225, 199, 199, 0.892)"

];
export function getRandomColor() {
    const randomIndex = Math.floor(Math.random() * colorFamily.length);
    return colorFamily[randomIndex];
}


