import React, { useEffect, useState, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { validationSchema_p } from "../Components/Validations";
import { ChatbotParticipation } from "../Chatbot/Chatbots";
import { Checkbox, RadioGroup } from "../Components";
import { yupResolver } from "@hookform/resolvers/yup";
import { db } from "../Firebase/firebasedb";
import { collection, addDoc, doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Modal, Button } from "react-bootstrap";

// const list_items = Object.entries(vals_understand).map(item => ({
//   name: item[0],
//   value: item[1]
// }));
const project_list = [
  {
    name: "highschool",
    question: "High School Summer Academy focused on STEM",
  },
  {
    name: "precollegestem",
    question: "Pre-college summer academy focused on STEM ",
  },
  { name: "precollegebridge", question: "Pre-college Summer Bridge Program" },
  {
    name: "collegecounseling",
    question: "College career counseling services ",
  },
  {
    name: "coaching",
    question:
      "Academic support/coaching services (e.g., study strategies, time management, test preparation)",
  },
  { name: "partners", question: "Study partners (peer tutoring) program" },
  { name: "community", question: "Learning communities" },
  { name: "finaid", question: "Financial aid or loan options" },
  { name: "si", question: "Supplemental Instruction (SI)/ Review sessions" },
  { name: "research", question: "Research internships in STEM" },
  { name: "abroad", question: "Study Abroad programs" },
  { name: "career", question: "College Career Services" },
  { name: "disability", question: "Services for students with disabilities" },
  { name: "careerfair", question: "Graduate School Career Fairs" },
  { name: "other", question: "Other, please describe" },
];

const likertOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

const projectOptions = [
  { name: "TAPDINTO-STEM Orientation Meeting" },
  { name: "Monthly Bridge meetings" },
  { name: "Weekly Cluster meetings" },
  { name: "Other" },
];

const scales = {
  Awareness: [
    "1 = not aware or not applicable",
    "2 = some awareness",
    "3 = slightly aware",
    "4 = aware",
    "5 = very aware",
  ],
  Participation: [
    "1 = did not participate  ",
    "2 = did not help me",
    "3 = somewhat helped me",
    "4 = mostly helped me",
    "5 = definitely helped me",
  ],
};

const ParticipationForm = () => {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  // const [userData, setUserData] = useState(null)

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectDestination, setRedirectDestination] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
        setRedirectDestination("/");
        setShouldRedirect(true);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          // setUserData(data);
          if (data.atype === "administrator") {
            setRedirectDestination("/dashboardadmin");
            setShouldRedirect(true);
          } else if (data.atype === "faculty") {
            setRedirectDestination("/dashboardfaculty");
            setShouldRedirect(true);
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setRedirectDestination("/dashboardstudent");
          setShouldRedirect(true);
        }
      });
    }
  }, [user]);

  const navigate = useNavigate();
  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectDestination);
    }
  }, [shouldRedirect, redirectDestination, navigate]);

  const formOptions = { resolver: yupResolver(validationSchema_p) };
  const methods = useForm(formOptions);
  const { handleSubmit, reset } = methods;

  const [botSubmit, setBotSubmit] = useState(false);

  useEffect(() => {
    console.log(botSubmit);
    if (botSubmit) {
      console.log("TRUE!!!");
      thisform.current &&
        thisform.current.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      // setBotSubmit(false);
    }
  }, [botSubmit]);

  function onSubmit(data) {
    // display form data on success
    console.log(data);
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
    writeToFirebase(data);
    // navigate("/dashboard");

    return false;
  }
  function writeToFirebase(data) {
    console.log(data);
    const docRef = addDoc(collection(db, "participationForm"), {
      beingAwareOne: data.awareness_highschool,
      beingAwareTwo: data.awareness_precollegestem,
      beingAwareThree: data.awareness_precollegebridge,
      beingAwareFour: data.awareness_collegecounseling,
      beingAwareFive: data.awareness_coaching,
      beingAwareSix: data.awareness_partners,
      beingAwareSeven: data.awareness_community,
      beingAwareEight: data.awareness_finaid,
      beingAwareNine: data.awareness_si,
      beingAwareTen: data.awareness_research,
      beingAwareEleven: data.awareness_abroad,
      beingAwareTwelve: data.awareness_career,
      beingAwareThirteen: data.awareness_disability,
      beingAwareFourteen: data.awareness_careerfair,
      beingAwareOther: data.awareness_other_specify,
      // beingAwareFifteen: data.awareness_other,
      participationOne: data.participation_highschool,
      participationTwo: data.participation_precollegestem,
      participationThree: data.participation_precollegebridge,
      participationFour: data.participation_collegecounseling,
      participationFive: data.participation_coaching,
      participationSix: data.participation_partners,
      participationSeven: data.participation_community,
      participationEight: data.participation_finaid,
      participationNine: data.participation_si,
      participationTen: data.participation_research,
      participationEleven: data.participation_abroad,
      participationTwelve: data.participation_career,
      participationThirteen: data.participation_disability,
      participationFourteen: data.participation_careerfair,
      participationOther: data.participation_other_specify,
      // participationFifteen: data.participation_other,
      activities: data.projectParticipation,
      // activitiesOther: data.projectParticipation.other,
    }).then(() => {
      console.log("Document written with ID: ", docRef.id);
      handleShow();
    });
  }

  const thisform = useRef();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    navigate("/dashboardstudent");
  };
  const handleShow = () => {
    setShow(true);
  };

  // usePrompt("Are you sure you want to leave?", true);

  return (
    <div className="container">
      <div className="card m-3">
        <h5 className="card-header">Student Participation Form</h5>
        <div className="card-body">
          <p className="card-text mb-3">
            Please specify the extent to which you are aware of each of the
            following and the extent to which you have participated. If a
            service does not apply to you, please leave blank.
          </p>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Submission Successful!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Your form has been submitted successfully!</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} ref={thisform}>
              {["Awareness", "Participation"].map((item) => (
                <>
                  <div className="mt-3" key={item + "1"}>
                    Use the following scale to respond to the{" "}
                    {item.toLocaleLowerCase()} questions:
                    <ul>
                      {scales[item].map((scale) => (
                        <li key={scale}>{scale}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="row mb-3" key={item + "2"}>
                    <div className="col-5 col-sm-6 col-md-8 col-lg-7 col-xl-8"></div>
                    <div className="fw-bold col-7 col-sm-6 col-md-4 col-lg-5 col-xl-4">
                      {item}
                    </div>
                  </div>
                  {project_list &&
                    project_list.map((pitem) => (
                      <fieldset className="form-group" key={item + pitem.name}>
                        <div className="row">
                          <RadioGroup
                            label={pitem.question}
                            name={
                              item.toLowerCase() +
                              "_" +
                              pitem.name.toLowerCase()
                            }
                            options={likertOptions}
                            className="mb-3 row"
                            labelclass="col-5 col-sm-6 col-md-8 col-lg-7 col-xl-8"
                            optionclass="col-7 col-sm-6 col-md-4 col-lg-5 col-xl-4"
                            otherlabelname={
                              item.toLowerCase() +
                              "_" +
                              pitem.name.toLowerCase() +
                              "_specify"
                            }
                          />
                        </div>
                      </fieldset>
                    ))}
                </>
              ))}
              <Checkbox
                label="Project Participation (Check all that apply)"
                groupname="projectParticipation"
                options={projectOptions}
                className="col-sm-12 col-md-4 col-lg-4 mb-3"
              />
              <div className="form-group">
                <button
                  type="submit"
                  disabled={botSubmit}
                  // onClick={() => handleSubmit(onSubmit)}
                  className="btn btn-primary mr-1"
                >
                  {botSubmit ? "Already submitted by bot!" : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={() => reset()}
                  className="btn btn-secondary"
                >
                  Reset
                </button>
              </div>
            </form>
            <ChatbotParticipation
              validationSchema={validationSchema_p}
              setFormEnd={setBotSubmit}
              navigate={navigate}
            />
          </FormProvider>
        </div>
      </div>
    </div>
  );
};
export default ParticipationForm;
