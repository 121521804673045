import React, { useState, useEffect } from "react";

import { geoCentroid } from "d3-geo";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
} from "react-simple-maps";
import { scaleQuantile } from "d3-scale";

import allStates from "../Fixed Sources/allstates.json";
import ReactTooltip from "react-tooltip";
import { colorScales } from "../Fixed Sources/colorScales.js";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
    VT: [50, -8],
    NH: [34, 2],
    MA: [30, -1],
    RI: [28, 2],
    CT: [35, 10],
    NJ: [34, 1],
    DE: [33, 0],
    MD: [47, 10],
    DC: [49, 21]
};

const MapChart = ({ latestAnswerDict, mainKey = null, setTooltipContent, colorPallete = null }) => {
    const potentialKeys = ['State']
    const [content, setContent] = useState("");
    console.log('mainKey', mainKey)
    useEffect(() => {
        console.log('content: ', content);
    }, [content])
    let aggregatedData = {}

    if (latestAnswerDict) {

        if (!mainKey) {
            for (let i = 0; i < potentialKeys.length; i++) {
                const key = potentialKeys[i];
                if (key in latestAnswerDict) {
                    mainKey = key
                    break;
                }
            }

        }
        console.log(mainKey != null && potentialKeys.includes(mainKey))
        if (mainKey != null && potentialKeys.includes(mainKey)) {
            const uniqueValues = new Set(latestAnswerDict[mainKey])
            uniqueValues.forEach(val => {
                aggregatedData[val] = 0

            })
            console.log('mainKey', mainKey)
            console.log('latestAnswerDict', latestAnswerDict)
            if (latestAnswerDict[mainKey]) {
                latestAnswerDict[mainKey].forEach(val => {
                    aggregatedData[val] += 1
                })
            }
            console.log("aggregatedData:", aggregatedData);
            console.log('allStates', allStates)
        }
        else {
            console.log('no main key for Map Chart: Chart unavailable')
        }

    }
    // const randomScale = colorScales[Math.floor(Math.random() * colorScales.length)];
    const randomScale = colorScales[1];
    console.log('colorPallete', colorPallete)
    const colorScale = scaleQuantile()
        .domain(Object.values(aggregatedData))
        .range(colorPallete ? colorPallete : randomScale);


    return (<div data-tip='random'>
        {/* <ReactTooltip offset={{ top: 335, left: 45 }} >
            {content}
        </ReactTooltip> */}
        {/* <ReactTooltip /> */}
        <ReactTooltip backgroundColor='blue' fontSize='18'>{content}</ReactTooltip>
        <p style={{ textAlign: 'center', fontSize: "0.8vw" }}>{mainKey} Count - Map Visualization</p>
        <ComposableMap projection="geoAlbersUsa">

            <Geographies geography={geoUrl}>
                {({ geographies }) => (
                    <>
                        {geographies.map(geo => {
                            // console.log(geo)
                            const cur = allStates.find(s => s.val === geo.id);
                            const val = aggregatedData[cur.id]
                            if (val) {
                                return <Geography
                                    key={geo.rsmKey}
                                    stroke="#FFF"
                                    geography={geo}
                                    // fill="#782618"
                                    fill={colorScale(val ? val : "#EEE")}
                                    onMouseEnter={() => {
                                        console.log(geo.properties.name)
                                        setContent(geo.properties.name + ": " + val + ' submission(s).')
                                        // setTooltipContent(`${geo.properties.name}`);
                                    }}
                                    onMouseLeave={() => {
                                        // setTooltipContent("");
                                    }}

                                    style={{
                                        // default: {
                                        //     fill: "#D6D6DA",
                                        //     outline: "none"
                                        // },
                                        hover: {
                                            fill: "skyblue",
                                            outline: "none"
                                        },
                                        pressed: {
                                            fill: "blue",
                                            outline: "none"
                                        }
                                    }}
                                />
                            }
                            else {
                                return <Geography
                                    key={geo.rsmKey}
                                    stroke="#FFF"
                                    geography={geo}
                                    fill="gray"
                                    onMouseEnter={() => {
                                        console.log(geo.properties.name)
                                        setContent(geo.properties.name + ": 0 submission(s).")
                                        // setTooltipContent(`${geo.properties.name}`);
                                    }}
                                />
                            }

                        }

                        )}
                        {geographies.map(geo => {
                            const centroid = geoCentroid(geo);
                            const cur = allStates.find(s => s.val === geo.id);
                            // console.log(cur)
                            return (
                                <g key={geo.rsmKey + "-name"}>
                                    {cur &&
                                        centroid[0] > -160 &&
                                        centroid[0] < -67 &&
                                        (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                            <Marker coordinates={centroid}>
                                                <text y="2" fontSize={14} textAnchor="middle">
                                                    {cur.id}
                                                </text>
                                            </Marker>
                                        ) : (
                                            <Annotation
                                                subject={centroid}
                                                dx={offsets[cur.id][0]}
                                                dy={offsets[cur.id][1]}
                                            >
                                                <text x={4} fontSize={14} alignmentBaseline="middle">
                                                    {cur.id}
                                                </text>
                                            </Annotation>
                                        ))}
                                </g>
                            );
                        })}
                    </>
                )}
            </Geographies>
        </ComposableMap>
    </div>

    );
};

export default MapChart;
