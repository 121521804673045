import { Update } from "./Update";

export const vals1 = {
  firstName: "What is your first name?",
  middleName: "What is your middle name?",
  lastName: "What is your last name?",
  mailingAddress:
    "Now please fill in your address. Let's start with your street address.",
  city: "Please enter your city.",
  state: "Please enter your state.",
  zip: "Please enter your zipcode.",
  email: "Please enter your email.",
  telephone: "What is your telephone number?",
  callType: "What is your preferred way of contact: voice or TDD?",
  call: "What is the voice/TDD number we can reach you?",
  gender:
    "What is your gender? Please select: 1. male, 2.female, 3. Transgender Female, 4. Transgender Male, 5. Gender Non-Conforming, 6. Prefer Not to Answer",
  dob: "What is your date of birth? (Please enter in format YYYY-MM-DD)",
  citizen: "Are you a US citizen? Please answer yes or no. ",
  resident: "Are you a US permanant resident? Please answer yes or no.",
  year: "Which year are your in? (Freshman, sophomore, junior, senior or graduate?)",
  major: "What is your major?",
  gpa: "What is your GPA?",
  degreeComplete:
    "When will you complete your current degree? Please enter in format YYYY-MM",
  transfer: "Are you a transfer student? Please answer yes or no.",
  veteran: "Are you a veteran? Please answer yes or no.",
  ethnicity: "Are you hispanic/latino? Please answer yes or no.",
  maleDegree:
    "What is the highest educational degree completed by your male parent/guardian? Please select one from the following: 1. High School/GED, 2. Technical training, 3. Associates, 4. Bachelors, 5. Masters, 6 Doctors, 7, Unknown",
  femaleDegree:
    "What is the highest educational degree completed by your female parent/guardian? Please select one from the following: 1. High School/GED, 2. Technical training, 3. Associates, 4. Bachelors, 5. Masters, 6 Doctors, 7, Unknown",
  income:
    "Please, estimate the combined annual income of your parents or guardians during your high school years (in US Dollars). Select one from the following: 1. Less than $20000, 2. $20000-$40000, 3. $40000-$60000, 4. $60000-$80000, 5. $80000-$100000, 6. >$100000, 7. Unknown.",
};

export const vals2 = {
  "race.0": "Are you American Indian or Alaska Native?",
  "race.1": "Are you Asian?",
  "race.2": "Are you White?",
  "race.3": "Are you Black or African American?",
  "race.4": "Are you Native Hawaiian or Other Pacific Islander",
  "race.other":
    "Please indicate if you identify with a race not mentioned above.",
  "condition.0": "Are you on the Autism spectrum/have Asperger's?",
  "condition.1": "Do you have ADD/ADHD?",
  "condition.2": "Are you deaf or hard-of-hearing?",
  "condition.3": "Do you have physical, orthopedic, or mobility challenges?",
  "condition.4": "Do you have systemic health or medical challenges?",
  "condition.5": "Do you have psychological or psychiatric challenges?",
  "condition.6": "Do you have a learning disability?",
  "condition.7": "Do you have a visual impairment?",
  "condition.8": "Do you have a speech impairment?",
  "condition.9": "Do you have acquired or traumatic brain injury?",
  "condition.other":
    "Please indicate if you have other disability conditions not mentioned above.",
  "accessibility.0": "Do you need exam accommodations?",
  "accessibility.1": "Do you need reader of scribe services?",
  "accessibility.2": "Do you need note-taking services?",
  "accessibility.3": "Do you need alternative formats for course materials?",
  "accessibility.4": "Do you need early access to course content?",
  "accessibility.5": "Do you need preferred seating?",
  "accessibility.other": "Please indicate if you need other accommodations?",
  "educassit.federal":
    "Are you currently receiving federal grant? If yes, please specify:",
  "educassit.1": "Are you currently receiving federal student loan?",
  "educassit.2": "Are you currently receiving private loan?",
  "educassit.3": "Are you currently receiving a scholarship?",
  "educassit.other":
    "Please indicate if you are currently receiving other educational assistance.",
};

export const vals3 = {
  pmailingAddress:
    "For permanent address, let's start with your street address.",
  pcity: "Please enter your city.",
  pstate: "Please enter your state.",
  pzip: "Please enter your zipcode.",
  pemail: "Please enter your email.",
  ptelephone: "What is your telephone number?",
  pcallType: "What is your preferred way of contact: voice or TDD?",
  pcall:
    "What is the number we can reach you? You can leave it blank if it's not applicable.",
  guardian: "Please indicate your guardian's name if applicable. ",
  signature: "Please enter your initials to sign. ",
  date: "Please enter today's date in format YYYY-MM-DD",
};

export const labels_mentor = {
  firstName: "First Name",
  middleName: "Middle Name",
  lastName: "Last Name",
  mailingAddress: "Mailing Address",
  city: "City",
  state: "State",
  zip: "Zipcode",
  email: "Email",
  // telephone: "Telephone",
  callType: "Call Type",
  call: "Call Number",
  gender: "Gender",
  dob: "Date of Birth",
  citizen: "US Citizen",
  resident: "US permanant resident",
  year: "School Year",
  major: "Major",
  gpa: "GPA",
  degreeComplete: "Degree Completion Date",
  transfer: "Transfer Student",
  veteran: "Veteran",
  ethnicity: "Hispanic/Latino",
  maleDegree: "Male parent/guardian highest educational degree",
  femaleDegree: "Female parent/guardian highest educational degree",
  income: "Income level",
  "race.0": "American Indian or Alaska Native",
  "race.1": "Asian",
  "race.2": "White",
  "race.3": "Black or African American",
  "race.4": "Native Hawaiian or Other Pacific Islander",
  "race.other": "Other",
  // "race.5": "Prefer not to answer",
  "condition.0": "Asperger or Autism",
  "condition.1": "ADD or ADHD",
  "condition.2": "Deaf or Hard-of-Hearing",
  "condition.3": "Physical or Orthopedic or Mobility",
  "condition.4": "Systemic Health or Medical",
  "condition.5": "Psychological or Psychiatric",
  "condition.6": "Learning Disability",
  "condition.7": "Blind or Visual Impairment",
  "condition.8": "Speech Impairment",
  "condition.9": "Acquired or Traumatic Brain Injury",
  "condition.other": "Other Condition",
  // const obj2 = { race: [], condition: [] };
  // ifDifferent: "Is your permanent address different from above?",
  "accessibility.0": "Exam accommodations",
  "accessibility.1": "Reader of scribe services",
  "accessibility.2": "Note-taking services",
  "accessibility.3": "Alternative formats for course materials",
  "accessibility.4": "Early access to course content",
  "accessibility.5": "Preferred seating",
  "accessibility.other": "Other accommodations",
  "educassit.federal": "Federal grant",
  "educassit.1": "Federal student loan?",
  "educassit.2": "Private loan?",
  "educassit.3": "Scholarship?",
  "educassit.other": "Other educational assistance.",
  pmailingAddress: "Permanent address: Street",
  pcity: "Permanent address: City.",
  pstate: "Permanent address: State.",
  pzip: "Permanent address: Zipcode.",
  pemail: "Permanent Email.",
  ptelephone: "Permanent: Telephone Number",
  pcallType: "Permanent Contact Type",
  pcall: "Permanent Number",
  guardian: "Guardian's Name",
  signature: "Initials",
  date: "Date",
};

export const vals_understand = {
  initOne:
    "I understand that I must participate in all TAPDINTO-STEM monthly meetings.",
  initTwo:
    "I understand that I must participate in all TAPDINTO-STEM cluster meetings.",
  initThree:
    "I understand that I must complete at least two hours of traditional and online mentoring activities per week.",
  initFour:
    "I understand that I must continue to pursue a major in science, technology, engineering, or mathematics (STEM) as a full-time student while at current university in order to keep the stipend.",
  initFive:
    "I understand that I will strive to maintain GPA of 3.0 or higher in order to remain eligible for the stipend",
  initSix:
    "I understand that I must attend the annual TAPDINTO-STEM Spring Conference.",
  initSeven:
    "I understand that demographic data (gender, race, type of disability, etc.), academic data (GPA, etc.), and progress made towards my degree will be provided to NSF as part of receiving the stipend and agreeing to participate as a Bridge Scholar.",
  initEight:
    "I understand that I am expected to participate in surveys, interviews, and/or focus groups by a local evaluation team. I understand my participation is encouraged but I have the right to not participate at the time I am asked.",
  // initNine:
  //   "I understand that I am required to participate in any surveys conducted on behalf of NSF as part of the scholarship funding.",
};

export const vals_transcript = {
  firstName: "What is your first name?",
  lastName: "What is your last name?",
  school: "What is your university/college?",
  major: "What is your major?",
  minor: "Please indicate your minor if applicable?",
  degree: "Please indicate degree type: associates, bachelors, or masters?",
  degreeComplete:
    "When will you complete your current degree? Please enter in format YYYY-MM",
  academicyear:
    "Which academic year are your in? (freshman, sophomore, junior, senior or graduate?)",
  session:
    "What is your current school session? Please choose one of the following: fall semester, spring semester, summer semester, autum quarter, winter quarter, spring quarter, or summer quarter ",
  outcome: "What is the highest level of Education you expect to complete?",
  accomplishments: "Please tell us your accomplishments this semester.",
};

export const vals_intern = {
  company: "What is the company that you interned at?",
  hours: "How many hours in total have you worked?",
  pay: "How much were you paid?",
  work: "What did you do?",
};

export const vals_research = {
  topic: "What is your research topic?",
  faculty: "Who is your faculty mentor?",
  summary: "Please summarize your research.",
};

export const labels_transcript = {
  firstName: "First Name",
  lastName: "Last Name",
  school: "University/College",
  major: "Major",
  minor: "Minor",
  degree: "Degree Type",
  degreeComplete: "Degree Completion Date",
  academicyear: "Academic Standing",
  session: "School Session",
  outcome: "Expected Education Outcome",
  accomplishments: "Accomplishments",
  company: "Company",
  hours: "Total Hours",
  pay: "Pay",
  work: "Job Content",
  topic: "Research Topic",
  faculty: "Faculty Mentor",
  summary: "Summary",
};

export const vals_awareness = {
  awareness_highschool: "High School Summer Academy focused on STEM",
  awareness_precollegestem: "Pre-college summer academy focused on STEM ",
  awareness_precollegebridge: "Pre-college Summer Bridge Program",
  awareness_collegecounseling: "College career counseling services ",
  awareness_coaching:
    "Academic support/coaching services (e.g., study strategies, time management, test preparation)",
  awareness_partners: "Study partners (peer tutoring) program",
  awareness_community: "Learning communities",
  awareness_finaid: "Financial aid or loan options",
  awareness_si: "Supplemental Instruction (SI)/ Review sessions",
  awareness_research: "Research internships in STEM",
  awareness_abroad: "Study Abroad programs",
  awareness_career: "College Career Services",
  awareness_disability: "Services for students with disabilities",
  awareness_careerfair: "Graduate School Career Fairs",
  awareness_other_specify: "Other, please describe",
  awareness_other: "Please rate",
};

export const vals_participation = {
  participation_highschool: "High School Summer Academy focused on STEM",
  participation_precollegestem: "Pre-college summer academy focused on STEM ",
  participation_precollegebridge: "Pre-college Summer Bridge Program",
  participation_collegecounseling: "College career counseling services ",
  participation_coaching:
    "Academic support/coaching services (e.g., study strategies, time management, test preparation)",
  participation_partners: "Study partners (peer tutoring) program",
  participation_community: "Learning communities",
  participation_finaid: "Financial aid or loan options",
  participation_si: "Supplemental Instruction (SI)/ Review sessions",
  participation_research: "Research internships in STEM",
  participation_abroad: "Study Abroad programs",
  participation_career: "College Career Services",
  participation_disability: "Services for students with disabilities",
  participation_careerfair: "Graduate School Career Fairs",
  participation_other_specify: "Other, please describe",
  participation_other: "Please rate",
};

export const vals_project = {
  "projectParticipation.0":
    "Have you been involved in TAPDINTO-STEM Orientation Meeting?",
  "projectParticipation.1":
    "Have you been involved in Monthly Bridge meetings?",
  "projectParticipation.2":
    "Have you been involved in Weekly Cluster meetings?",
  "projectParticipation.other":
    "Have you been involved in other similar activities? Please specify.",
};

export const labels_participate = {
  awareness_highschool:
    "Awareness rating: High School Summer Academy focused on STEM",
  awareness_precollegeStem:
    "Awareness rating: Pre-college summer academy focused on STEM ",
  awareness_precollegeBridge:
    "Awareness rating: Pre-college Summer Bridge Program",
  awareness_collegeCounseling:
    "Awareness rating: College career counseling services ",
  awareness_coaching:
    "Awareness rating: Academic support/coaching services (e.g., study strategies, time management, test preparation)",
  awareness_partners:
    "Awareness rating: Study partners (peer tutoring) program",
  awareness_community: "Awareness rating: Learning communities",
  awareness_finaid: "Awareness rating: Financial aid or loan options",
  awareness_si:
    "Awareness rating: Supplemental Instruction (SI)/ Review sessions",
  awareness_research: "Awareness rating: Research internships in STEM",
  awareness_abroad: "Awareness rating: Study Abroad programs",
  awareness_career: "Awareness rating: College Career Services",
  awareness_disability:
    "Awareness rating: Services for students with disabilities",
  awareness_careerfair: "Awareness rating: Graduate School Career Fairs",
  awareness_other_specify: "Awareness: Other, please describe",
  awareness_other: "Awareness: Other rate",
  participation_highschool:
    "Participation rating: High School Summer Academy focused on STEM",
  participation_precollegeStem:
    "Participation rating: Pre-college summer academy focused on STEM ",
  participation_precollegeBridge:
    "Participation rating: Pre-college Summer Bridge Program",
  participation_collegeCounseling:
    "Participation rating: College career counseling services ",
  participation_coaching:
    "Participation rating: Academic support/coaching services (e.g., study strategies, time management, test preparation)",
  participation_partners:
    "Participation rating: Study partners (peer tutoring) program",
  participation_community: "Participation rating: Learning communities",
  participation_finaid: "Participation rating: Financial aid or loan options",
  participation_si:
    "Participation rating: Supplemental Instruction (SI)/ Review sessions",
  participation_research: "Participation rating: Research internships in STEM",
  participation_abroad: "Participation rating: Study Abroad programs",
  participation_career: "Participation rating: College Career Services",
  participation_disability:
    "Participation rating: Services for students with disabilities",
  participation_careerfair:
    "Participation rating: Graduate School Career Fairs",
  participation_other_specify: "Participation Other, please describe",
  participation_other: "Participation Other rate",
  "projectParticipation.0": "Involved in TAPDINTO-STEM Orientation Meeting?",
  "projectParticipation.1": "Involved in Monthly Bridge meetings?",
  "projectParticipation.2": "Involved in Weekly Cluster meetings?",
  "projectParticipation.other": "Other: ",
};

export const vals_institute = {
  school: "What is your university/college?",
  nstudent: "What is the total number of students (degree seeking)?",
  nstudentstem: "What is the total number of STEM students (degree seeking)?",
  nstudentDisable:
    "What is the total number of students with disabilities (degree seeking)?",
  nstudentStemDisable:
    "What is the total number of STEM students with disabilities (degree seeking)?",
  latino: "What is the number of Hispanic or Latino degree seeking students?",
  nonlatino:
    "What is the number of Non-Hispanic or Latino degree seeking students?",
  native:
    "What is the number of degree seeking students of American Indian or Alaska Native?",
  asian: "What is the number of degree seeking students of Asian?",
  black:
    "What is the number of degree seeking students of Black or African American?",
  islander:
    "What is the number of degree seeking students of Native Hawaiian or Other Pacific Islander?",
  arab: "What is the number of degree seeking students of Middle Eastern or North African?",
  white: "What is the number of degree seeking students of  White?",
  multiple: "What is the number of degree seeking students of Multiple races?",
  na: "What is the number of degree seeking students of race Not reported?",
  latinoDisable:
    "What is the number of disabled degree seeking students of Hispanic or Latino?",
  nonlatinoDisable:
    "What is the number of disabled degree seeking students of non Hispanic or Latino?",
  nativeDisable:
    "What is the number of disabled degree seeking students of American Indian or Alaska Native?",
  asianDisable:
    "What is the number of disabled degree seeking students of Asian?",
  blackDisable:
    "What is the number of disabled degree seeking students of Black or African American?",
  islanderDisable:
    "What is the number of disabled degree seeking students of Native Hawaiian or Other Pacific Islander?",
  arabDisable:
    "What is the number of disabled degree seeking students of Middle Eastern or North African?",
  whiteDisable:
    "What is the number of disabled degree seeking students of White?",
  multipleDisable:
    "What is the number of disabled degree seeking students of Multiple races?",
  naDisable:
    "What is the number of disabled degree seeking students of Race Not reported?",
  "service.0":
    "Does your institution provide Accommodation Letters to faculty?",
  "service.1": "Does your institution provide Accommodations Assessment?",
  "service.2":
    "Does your institution provide Campus authority for accommodation implementation?",
  "service.3": "Does your institution provide Test/assignment proctoring?",
  "service.4":
    "Does your institution provide Process for developing formal accommodation plans?",
  "service.5": "Does your institution provide Academic advising?",
  "service.6": "Does your institution provide Career advising?",
  "service.7":
    "Does your institution provide Student Organization sponsorship?",
  "service.8": "Does your institution provide Faculty consultations?",
  "service.9": "Does your institution provide Student satisfaction surveys?",
  "service.10":
    "Does your institution provide External work with local high schools?",
  "service.11":
    "Does your institution provide External work with local rehabilitation agencies?",
  "service.12":
    "Does your institution provide External work with local community service providers?",
  "service.other":
    "If your institution provide other access/disability services to students, please specify here.",
  brain:
    "What is the number of students having Acquired/Traumatic Brain Injury?",
  add: "What is the number of students having ADD/ADHD?",
  blind: "What is the number of students being Blind/Visual Impairmed?",
  deaf: "What is the number of students being Deaf/Hard-of-Hearing?",
  learning: "What is the number of students having Learning Disability?",
  orthopedic:
    "What is the number of students having Physical/Orthopedic/Mobility Disability?",
  psycho: "What is the number of students having Psychological/Psychiatric?",
  speech: "What is the number of students having Speech Impairment?",
  medical: "What is the number of students having Systemic Health/Medical?",
  OtherS: "Please specify other disability types.",
  OtherN: "What is the number of students having the above disability?",
  brainStem:
    "What is the number of STEM students having Acquired/Traumatic Brain Injury?",
  addStem: "What is the number of STEM students having ADD/ADHD?",
  blindStem: "What is the number of STEM students being Blind/Visual Impaired?",
  deafStem: "What is the number of STEM students being Deaf/Hard-of-Hearing?",
  learningStem:
    "What is the number of STEM students having Learning Disability?",
  orthopedicStem:
    "What is the number of STEM students having Physical/Orthopedic/Mobility Disability?",
  psychoStem:
    "What is the number of STEM students having Psychological/Psychiatric?",
  speechStem: "What is the number of STEM students having Speech Impairment?",
  medicalStem:
    "What is the number of STEM students having Systemic Health/Medical?",
  OtherSStem: "Please specify other disability. ",
  OtherNStem:
    "What is the number of STEM students having the above disability?",
  "office.0":
    "Which of the following have disability/access office reporting line? Stand-alone department",
  "office.1": "Part of Dive rsity, Access, and/or Equity department",
  "office.2": "Part of Counseling department",
  "office.3": "Part of Other Student Affairs department",
  "office.part": "Part of Other Campus Division (Please Specify)",
  fte: "How many FTE are in your department that primarily serve students with disabilities? Please select one option from the following: 1. 0.1 - 0.9, 2: 1.0–1.9, 3: 2.0–2.9, 4: 3.0–3.9, 5: 4.0–4.9, or 6: Over 5 ?",
  refer:
    "Where do you get most of your student referrals for accommodations? 1. faculty, 2. staff or 3. yourself?",
};

export const labels_institute = {
  school: "University/college",
  nstudent: "Total number of students (degree seeking)",
  nstudentstem: "Total number of STEM students (degree seeking)",
  nstudentDisable:
    "Total number of students with disabilities (degree seeking)",
  nstudentStemDisable:
    "Total number of STEM students with disabilities (degree seeking)",
  latino: "Number of Hispanic or Latino degree seeking students",
  nonlatino: "Number of Non-Hispanic or Latino degree seeking students",
  native:
    "Number of degree seeking students of American Indian or Alaska Native",
  asian: "Number of degree seeking students of Asian",
  black: "Number of degree seeking students of Black or African American",
  islander:
    "Number of degree seeking students of Native Hawaiian or Other Pacific Islander",
  arab: "Number of degree seeking students of Middle Eastern or North African",
  white: "Number of degree seeking students of  White",
  multiple: "Number of degree seeking students of Multiple races",
  na: "Number of degree seeking students of race Not reported",
  latinoDisable:
    "Number of disabled degree seeking students of Hispanic or Latino",
  nonlatinoDisable:
    "Number of disabled degree seeking students of non Hispanic or Latino",
  nativeDisable:
    "Number of disabled degree seeking students of American Indian or Alaska Native",
  asianDisable: "Number of disabled degree seeking students of Asian",
  blackDisable:
    "Number of disabled degree seeking students of Black or African American",
  islanderDisable:
    "Number of disabled degree seeking students of Native Hawaiian or Other Pacific Islander",
  arabDisable:
    "Number of disabled degree seeking students of Middle Eastern or North African",
  whiteDisable: "Number of disabled degree seeking students of White",
  multipleDisable:
    "Number of disabled degree seeking students of Multiple races",
  naDisable: "Number of disabled degree seeking students of Race Not reported",
  "service.0": "Accommodation Letters to faculty",
  "service.1": "Accommodations Assessment",
  "service.2": "Campus authority for accommodation implementation",
  "service.3": "Test/assignment proctoring",
  "service.4": "Process for developing formal accommodation plans",
  "service.5": "Academic advising",
  "service.6": "Career advising",
  "service.7": "Student Organization sponsorship",
  "service.8": "Faculty consultations",
  "service.9": "Student satisfaction surveys",
  "service.10": "External work with local high schools",
  "service.11": "External work with local rehabilitation agencies",
  "service.12": "External work with local community service providers",
  "service.other": "Other access/disability services",
  brain: "Number of students suffering from Acquired/Traumatic Brain Injury",
  add: "Number of students suffering from ADD/ADHD",
  blind: "Number of students suffering from Blind/Visual Impairment",
  deaf: "Number of students suffering from Deaf/Hard-of-Hearing",
  learning: "Number of students suffering from Learning Disability",
  orthopedic:
    "Number of students suffering from Physical/Orthopedic/Mobility Disability",
  psycho: "Number of students suffering from Psychological/Psychiatric",
  speech: "Number of students suffering from Speech Impairment",
  medical: "Number of students suffering from Systemic Health/Medical",
  OtherS: "Other disability types.",
  OtherN: "Number of students suffering from the above disability",
  brainStem:
    "Number of STEM students suffering from Acquired/Traumatic Brain Injury",
  addStem: "Number of STEM students suffering from ADD/ADHD",
  blindStem: "Number of STEM students suffering from Blind/Visual Impairment",
  deafStem: "Number of STEM students suffering from Deaf/Hard-of-Hearing",
  learningStem: "Number of STEM students suffering from Learning Disability",
  orthopedicStem:
    "Number of STEM students suffering from Physical/Orthopedic/Mobility Disability",
  psychoStem:
    "Number of STEM students suffering from Psychological/Psychiatric",
  speechStem: "Number of STEM students suffering from Speech Impairment",
  medicalStem: "Number of STEM students suffering from Systemic Health/Medical",
  OtherSStem: "Other disability type",
  OtherNStem: "Number of STEM students suffering from the above disability",
  "office.0": "Stand-alone department",
  "office.1": "Part of Dive rsity, Access, and/or Equity department",
  "office.2": "Part of Counseling department",
  "office.3": "Part of Other Student Affairs department",
  "office.other": "Part of Other Campus Division (Please Specify)",
  fte: "FTE in your department that primarily serve students with disabilities",
  refer: "Referrals for accommodations",
};

export const keys1 = Object.keys(vals1);
export const keys2 = Object.keys(vals2);
export const keys3 = Object.keys(vals3);
export const keys_understand = Object.keys(vals_understand);
export const keys_transcript = Object.keys(vals_transcript);
export const keys_intern = Object.keys(vals_intern);
export const keys_research = Object.keys(vals_research);
export const keys_participation = Object.keys(vals_participation);
export const keys_awareness = Object.keys(vals_awareness);
export const keys_project = Object.keys(vals_project);
export const keys_institute = Object.keys(vals_institute);

const question_obj = (i, keys, vals, base = 0) => {
  return {
    id: (i + base).toString(),
    message: vals[keys[i]],
    trigger: keys[i],
  };
};

const answer_obj = (i, keys, vals, validationSchema) => {
  return {
    id: keys[i],
    user: true,
    trigger: keys[i] + "_u",
    validator: (value) => {
      try {
        validationSchema.fields[keys[i]] === undefined ||
          validationSchema.fields[keys[i]].validateSync(value);
        return true;
      } catch (err) {
        return err.errors;
      }
    },
  };
};

const update_obj = (i, keys, vals, base = 0, handleUpdate) => {
  return {
    id: keys[i] + "_u",
    component: <Update handleUpdate={handleUpdate} />,
    replace: true,
    trigger: (i + base + 1).toString(),
  };
};

export const Steps = (keys, vals, handleUpdate, validationSchema, base = 0) => {
  let out = [];
  for (let key in vals) {
    let i = keys.indexOf(key);
    out = [
      ...out,
      question_obj(i, keys, vals, base),
      answer_obj(i, keys, vals, validationSchema),
      update_obj(i, keys, vals, base, handleUpdate),
    ];
  }
  return out;
};

export const ModifySteps = (endtrigger, labels) =>
  Object.keys(labels).map((k) => ({
    id: labels[k].toLowerCase() + "-update",
    update: k,
    trigger: endtrigger,
  }));
