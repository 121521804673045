import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { BarCharts } from './BarCharts';
import { LineChart } from './LineCharts';
import { db } from "../Firebase/firebasedb";
import { doc, setDoc, getDoc, orderBy, serverTimestamp, collection, query, where, onSnapshot, getDocs } from "firebase/firestore";
import { PieChart } from "./PieChart";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Input, RadioGroup, Textarea, Checkbox, CheckboxFollowup } from "../Components";
import { BivariableCharts } from "./BivariableCharts";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { CustomMenu, FormLibrary, generateRandomAnswersBulk, generateRandomNumber, notQuestionTypes } from "../Automatic Forms/Utils";
import { Card } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import theme from "../Theme/theme";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-info"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
const CustomToggleChartSelection = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-secondary"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));

const LinkedFormsComponent = ({ formId,
    formLibrary,
    primaryFormSubmissionData,
    setLinked,
    setMergedSubmissionDataExternal }) => {
    const [currentLinkedForms, setCurrentLinkedForms] = useState([]);
    const [currentLinkedVariables, setCurrentLinkVariables] = useState({});
    const [allStoredSubmissions, setAllStoreSubmissions] = useState({});
    const [currentLinkedSubmissions, setCurrentLinkedSubmissions] = useState({});
    const notQuestionTypesExtended = notQuestionTypes.concat(['file_upload']) //no visualization for file_upload for now
    const [mergedFormSubmissionData, setMergedFormSubmissionData] = useState([]);



    useEffect(() => {
        if (currentLinkedForms.length > 0) {
            console.log('currentLinkedForms', currentLinkedForms)
        }
    }, [currentLinkedForms])
    const {
        register,
        watch,
        formState: { errors },
        getValues,
        setError,
        clearErrors,
    } = useFormContext();
    useEffect(() => {

        if (currentLinkedVariables && Object.keys(currentLinkedVariables).length > 0) {
            console.log('currentLinkedVariables (Child)', currentLinkedVariables)
            let mergedInfo = []

            for (const [form_id, link_vars] of Object.entries(currentLinkedVariables)) {
                let linkedInfo = {}
                if (!currentLinkedSubmissions[form_id]) { //info not exists yet

                }
                else {
                    const secondaryFormSubmissionData = currentLinkedSubmissions[form_id]
                    console.log(`Link Info Exists - ${form_id} : `, currentLinkedSubmissions[form_id]);

                    if (primaryFormSubmissionData) {
                        console.log("Primary Info: ", primaryFormSubmissionData);
                        const primaryVariable = link_vars['primaryVariable']
                        const secondaryVariable = link_vars['secondaryVariable']
                        primaryFormSubmissionData.forEach(prim_formData => {
                            secondaryFormSubmissionData.forEach(sec_formData => {
                                if (prim_formData[primaryVariable] == sec_formData[secondaryVariable]) {
                                    mergedInfo.push({ ...sec_formData, ...prim_formData });
                                }
                            })
                        })
                        console.log(`merged Info ${formId} (${primaryVariable}) - ${form_id} (${secondaryVariable}): `, mergedInfo)
                        setLinked(true);
                        setMergedFormSubmissionData([...mergedInfo]);



                    }
                }
            }

        }
        else {
            setLinked(false);
        }
    }, [currentLinkedVariables, currentLinkedSubmissions])
    useEffect(() => {
        if (mergedFormSubmissionData.length > 0) {
            setMergedSubmissionDataExternal([...mergedFormSubmissionData]);

        }
        else {
            setMergedSubmissionDataExternal([]);
        }
    }, [mergedFormSubmissionData])

    useEffect(() => {
        ///Linked Form Submission Data (Real Database) !!!///
        //  const q2 = query(formSubmissionRef, where("formID", "==", form_id));


        // getDocs(q2)
        //     .then((querySnapshot) => {
        //         console.log(querySnapshot.size)
        //         let answerDict = {};
        //         let rawData = [];
        //         const allKeys = [];

        //         querySnapshot.forEach((docSnapShot) => {
        //             const data = docSnapShot.data();
        //             rawData.push(data);
        //         })
        //         setAllStoreSubmissions({
        //             ...allStoredSubmissions,
        //             [form_id]: rawData
        //         });
        //     })
        //     .catch((error) => {
        //         console.log("Error getting documents: ", error);
        //     });

        /* Synthesized Data */
        if (currentLinkedForms.length > 0) {

            currentLinkedForms.forEach(form_id => {
                if (!allStoredSubmissions[form_id]) {
                    const randomNumber = generateRandomNumber(70, 200)

                    generateRandomAnswersBulk(form_id, randomNumber).then(randomResponsesArray => {
                        console.log(`randomResponsesArray - ${form_id}`, randomResponsesArray)
                        setAllStoreSubmissions({
                            ...allStoredSubmissions,
                            [form_id]: randomResponsesArray
                        });
                    })
                }

            });
        }
        else {
            setLinked(false);

        }

    }, [currentLinkedForms])
    useEffect(() => {
        console.log('allStoredSubmissions', allStoredSubmissions)
        const linkedInfo = {}
        currentLinkedForms.forEach(linkedFormID => {
            let startDate = getValues(`startDate-${linkedFormID}`)
            let endDate = getValues(`endDate-${linkedFormID}`)
            console.log(startDate, endDate)
            let [startDateTemp, endDateTemp] = validateDate(startDate, endDate, linkedFormID)
            if (startDateTemp && endDateTemp) {
                console.log('date-qualified linked form', linkedFormID)
                if (allStoredSubmissions[linkedFormID]) { //structure linked forms by date
                    let rawInfo = allStoredSubmissions[linkedFormID]
                    let filteredRawInfo = []
                    rawInfo.forEach(data => {
                        let createdAt = new Date(1900, 1, 1);
                        if (data.createdAt) {
                            if (typeof data.createdAt.toDate == 'function') {
                                createdAt = data.createdAt.toDate()
                            }
                            else if (data.createdAt instanceof Date) {
                                createdAt = data.createdAt;
                            }
                        }
                        if (createdAt > startDateTemp && createdAt <= endDateTemp) {
                            filteredRawInfo.push(data)
                        }
                    })
                    linkedInfo[linkedFormID] = filteredRawInfo
                }
            }
        })
        setCurrentLinkedSubmissions({ ...linkedInfo });


    }, [allStoredSubmissions, currentLinkedForms])
    useEffect(() => {
        if (currentLinkedSubmissions && Object.keys(currentLinkedSubmissions).length > 0) {
            console.log('currentLinkedSubmissions (Child)', currentLinkedSubmissions)
        }
        else {
            console.log('currentLinkedSubmissions (Child): no linked form!')
        }

    }, [currentLinkedSubmissions])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            console.log('watch:', value)
            const linkedInfo = {}

            currentLinkedForms.forEach(linkedFormID => {
                let startDate = value[`startDate-${linkedFormID}`]
                let endDate = value[`endDate-${linkedFormID}`]
                console.log(startDate, endDate)
                let [startDateTemp, endDateTemp] = validateDate(startDate, endDate, linkedFormID)
                if (startDateTemp && endDateTemp) {
                    console.log('date-qualified linked form', linkedFormID)
                    if (allStoredSubmissions[linkedFormID]) { //structure linked forms by date
                        let rawInfo = allStoredSubmissions[linkedFormID]
                        let filteredRawInfo = []
                        rawInfo.forEach(data => {
                            const createdAt = data.createdAt ? data.createdAt.toDate() : new Date(1900, 1, 1);
                            if (createdAt > startDateTemp && createdAt <= endDateTemp) {
                                filteredRawInfo.push(data)
                            }
                        })
                        linkedInfo[linkedFormID] = filteredRawInfo
                    }
                }


            })
            setCurrentLinkedSubmissions({ ...linkedInfo });
        })



        return () => subscription.unsubscribe();
    }, [watch, allStoredSubmissions, currentLinkedForms]);

    const validateDate = (startDate, endDate, formID) => {
        let startDateTemp = startDate;
        let endDateTemp = endDate;
        if (startDate == '') {
            startDateTemp = new Date(1900, 1, 1);
        }
        else {
            startDateTemp = new Date(startDate);
        }
        if (endDate == '') {
            endDateTemp = todayDate;
        }
        else {
            endDateTemp = new Date(endDate);
        }
        if (startDateTemp > todayDate) {
            setError(`startDate-${formID}`, { type: 'custom', message: 'Start date must be before today!' });
        }
        else if (endDateTemp > todayDate) {
            setError(`endDate-${formID}`, { type: 'custom', message: 'End date must be before today!' });
        }
        else if (startDateTemp >= endDateTemp) {
            setError(`startDate-${formID}`, { type: 'custom', message: 'Start Date must be before End Date!' });
        }

        else {
            clearErrors(`startDate-${formID}`);
            clearErrors(`endDate-${formID}`);
            return [startDateTemp, endDateTemp]
        }
        return [false, false]
    }

    if (formLibrary.length > 0) {
        let mainFormVariables = ['userID']
        let mainFormData = {}
        formLibrary.map(formData => {
            if (formData.formID == formId) {
                mainFormData = formData
                let formQuestions = formData['form_questions']
                // console.log('main formQuestions', formQuestions)
                formQuestions.forEach(q => {
                    if (q.questionID) {
                        if (!notQuestionTypesExtended.includes(q.question_type))
                            mainFormVariables.push(q.questionID);
                    }
                })
            }
        })
        console.log('mainFormVariables', mainFormVariables)

        return <div>
            <Dropdown
                onSelect={(eventKey, event) => {

                    setCurrentLinkedForms([...currentLinkedForms, eventKey])
                }}>
                <Dropdown.Toggle variant='outline-primary' className='mb-1'>
                    Link Another Form
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu} >
                    {formLibrary.map(formData => {
                        const formID = formData.formID
                        const formTitle = formData.formLongName ? formData.formLongName : formData.formTitle
                        if (formID != formId && !currentLinkedForms.includes(formID)) {
                            return <Dropdown.Item eventKey={formID}>
                                {formTitle}
                            </Dropdown.Item>
                        }


                    })}
                </Dropdown.Menu>
            </Dropdown>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {
                    currentLinkedForms.map((formID, index) => {
                        let formTitle = ''
                        let secondaryVariables = ['userID']
                        let localFormData = {}
                        {
                            formLibrary.map(formData => {
                                const localformID = formData.formID
                                if (localformID == formID) {
                                    let formQuestions = formData['form_questions']
                                    localFormData = formData
                                    formTitle = formData.formLongName ? formData.formLongName : formData.formTitle

                                    formQuestions.forEach(q => {
                                        if (q.questionID) {
                                            if (!notQuestionTypesExtended.includes(q.question_type))
                                                secondaryVariables.push(q.questionID);
                                        }
                                    })
                                    console.log('secondary formQuestionIDs', secondaryVariables)
                                }


                            })
                        }
                        let currentSelectedSecondaryVar = 'None'
                        let currentSelectedPrimaryVar = 'None'

                        if (currentLinkedVariables[formID]) {
                            let currentLinkInfo = currentLinkedVariables[formID]
                            currentSelectedPrimaryVar = currentLinkInfo['primaryVariable'] ? currentLinkInfo['primaryVariable'] : 'None'
                            currentSelectedSecondaryVar = currentLinkInfo['secondaryVariable'] ? currentLinkInfo['secondaryVariable'] : 'None'
                        }
                        let numberOfRecords = 0
                        if (allStoredSubmissions[formID]) {
                            numberOfRecords = allStoredSubmissions[formID].length;
                        }
                        return <Card style={{
                            width: '20rem',
                            flex: "1",
                            margin: 2,
                        }}
                        >
                            <Card.Header
                                // style={{ display: 'flex' }}
                                as="h5" >Linked Form {`${index + 1}`}

                                <AiFillCloseCircle style={{
                                    color: 'gray',
                                    float: 'right',
                                    verticalAlign: 'top',
                                    position: 'relative',
                                    top: "10%",
                                }} onClick={() => {
                                    let linkedForms = currentLinkedForms
                                    var index = currentLinkedForms.indexOf(formID);
                                    if (index !== -1) {

                                        const removedFormID = linkedForms.splice(index, 1);
                                        // console.log(linkedForms)
                                        // setValue('linkedForms', [...linkedForms])
                                        if (currentLinkedVariables[removedFormID]) {
                                            console.log('removedFormID', removedFormID)
                                            delete currentLinkedVariables[removedFormID]
                                            if (currentLinkedVariables) {
                                                console.log('remaining Linked Variables', currentLinkedVariables)
                                                setCurrentLinkVariables({ ...currentLinkedVariables })
                                            }
                                        }
                                        setCurrentLinkedForms([...linkedForms])
                                    }
                                }} />




                            </Card.Header>
                            <Card.Body>
                                <Card.Title>{formTitle}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">ID: {formID} - # of Records : {numberOfRecords}</Card.Subtitle>
                                <div className='row'>
                                    <div className='col-sm-4 col-md-3 col-lg-4 form-check mb-1'>
                                        <Input
                                            name={`startDate-${formID}`}
                                            type="date"
                                            label={'Start Date'}
                                            required={true}
                                            // className="mb-3 col-md-4"
                                            // defaultValue={format(todayDate)}
                                            instructions={'Start date of responses.'}
                                        />
                                    </div>
                                    <div className=' col-sm-4 col-md-3 col-lg-4 form-check mx-3 mb-1'>
                                        <Input
                                            name={`endDate-${formID}`}
                                            type="date"
                                            label={'End Date'}
                                            // className="mb-3 col-md-4"
                                            required={true}
                                            // defaultValue={format(new Date(), "yyyy-mm-dd")}
                                            instructions={'End date of responses.'}
                                        />
                                    </div>
                                </div>
                                <Card.Text>
                                    Link By:
                                </Card.Text>

                                {/* First Drop Down */}
                                <Dropdown
                                    onSelect={(eventKey, event) => {
                                        const primaryVariable = eventKey
                                        let currentLinkInfo = {}
                                        if (currentLinkedVariables[formID]) {
                                            currentLinkInfo = currentLinkedVariables[formID]
                                            currentLinkInfo['primaryVariable'] = primaryVariable
                                        }
                                        else {
                                            currentLinkInfo = {
                                                primaryVariable: primaryVariable
                                            }
                                        }

                                        setCurrentLinkVariables({
                                            ...currentLinkedVariables,
                                            [formID]: currentLinkInfo
                                        })
                                    }}>
                                    <Dropdown.Toggle variant='outline-primary'>
                                        Main-Form Variable: <b>{currentSelectedPrimaryVar}</b>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu} >
                                        {mainFormVariables.map(variable => {
                                            return <Dropdown.Item eventKey={variable}>
                                                {variable}
                                            </Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* Second Drop Down */}
                                <Dropdown className='mt-1'
                                    onSelect={(eventKey, event) => {
                                        const secondaryVariable = eventKey
                                        let currentLinkInfo = {}
                                        if (currentLinkedVariables[formID]) {
                                            currentLinkInfo = currentLinkedVariables[formID]
                                            currentLinkInfo['secondaryVariable'] = secondaryVariable
                                        }
                                        else {
                                            currentLinkInfo = {
                                                secondaryVariable: secondaryVariable
                                            }
                                        }

                                        setCurrentLinkVariables({
                                            ...currentLinkedVariables,
                                            [formID]: currentLinkInfo
                                        })
                                    }}>
                                    <Dropdown.Toggle variant='outline-secondary'>
                                        Linked-Form Variable: <b>{currentSelectedSecondaryVar}</b>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu} >
                                        {secondaryVariables.map(secondaryVar => {
                                            return <Dropdown.Item eventKey={secondaryVar}>
                                                {secondaryVar}
                                            </Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>

                            </Card.Body>
                        </Card>
                    })
                }
            </div >


        </div >
    }
    return <></>

}
const todayDate = new Date();
const formSubmissionRef = collection(db, "automatic_form_submissions");

export function ChartRendering() {
    const validationSchema = Yup.object().shape({
        startDate: Yup.string().test('start date vs end date comparison',
            'Start date cannot be after end date', (result) => {
                const date1 = new Date(startDate);
                const date2 = new Date(endDate);
                console.log('startDate', startDate);

                console.log('endDate', endDate);
                // console.log('startDateRequired', startDateRequired)
                if (startDate == "" && endDate == "")
                    return true
                return date1 < date2;
            }),
        endDate: Yup.string().test('start date vs end date comparison',
            'End date cannot be before Start date!', (result) => {
                const date1 = new Date(startDate);
                const date2 = new Date(endDate);

                // console.log(endDate);
                // console.log(startDate)
                if (startDate == "" && endDate == "")
                    return true

                return date1 < date2;
            }),
    })
    const formOptions = { resolver: yupResolver(validationSchema) };
    const methods = useForm(formOptions);
    const { handleSubmit, reset, watch, register, setError, clearErrors, setValue, getValues, formState: { errors } } = methods;
    console.log(errors)
    const startDate = watch('startDate');

    const endDate = watch('endDate');
    const onSubmit = (data) => console.log(data);
    //Drop-down
    const [chartTitle, setChartTitle] = useState(null);
    const [currentEventKey, setCurrentEventKey] = useState(null)
    const [latestAnswerDict, setLatestAnswerDict] = useState(null)
    const [formData, setFormData] = useState(null);
    const [primaryFormSubmissionData, setPrimaryFormSubmissionData] = useState(null);
    const [primaryFormSubmissionDataFiltered, setPrimaryFormSubmissionDataFiltered] = useState([]);

    const [formSubmissionData, setFormSubmissionData] = useState(null);
    const [formSubmissionDataFiltered, setFormSubmissionDataFiltered] = useState([]);



    const [allVariables, setAllVariables] = useState([]);
    const [currentLinkedVariables, setCurrentLinkVariables] = useState([]);
    const [allStoredSubmissions, setAllStoreSubmissions] = useState({});

    const [currentLinkedSubmissions, setCurrentLinkSubmissions] = useState({});
    const [linked, setLinked] = useState(false);
    useEffect(() => {
        if (!linked) {
            setLatestAnswerDict(null);
        }
    }, [linked])




    const { formId } = useParams();
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (formId) {
            const formRef = doc(db, "form_library", formId)
            const unsubscribe1 = onSnapshot(formRef, (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    console.log('formData', data);
                    setFormData({ ...data });
                }

            })
        }




        // /Main Submission Data///

        // const q2 = query(formSubmissionRef, where("formID", "==", formId));
        // const unsubscribe2 = onSnapshot(q2, querySnapshot => {
        //     console.log(querySnapshot.size)
        //     let answerDict = {};
        //     let rawData = [];
        //     const allKeys = [];

        //     querySnapshot.forEach((docSnapShot) => {
        //         const data = docSnapShot.data();
        //         rawData.push(data);
        //     })
        //     // setLatestAnswerDict({ ...answerDict });
        //     setPrimaryFormSubmissionData(rawData);

        //     console.log(answerDict);
        // })

        // /Instead, use synthesized data!
        const randomNumber = generateRandomNumber(70, 200)
        const generate = generateRandomAnswersBulk(formId, randomNumber).then(randomResponsesArray => {
            console.log('randomResponsesArray', randomResponsesArray)
            setPrimaryFormSubmissionData(randomResponsesArray);

        });


    }, [])
    useEffect(() => {
        if (chartTitle) {
            // console.log(chartData)
            setChartData({
                ...chartData,
                title: chartTitle,

                // labels: Object.keys(aggregatedData),
                // values: Object.values(aggregatedData)
            })
        }
    }, [chartTitle])
    useEffect(() => {
        if (chartData) {
            // console.log(chartData)
        }
    }, [chartData])
    const validateDate = (startDate, endDate) => {
        let startDateTemp = startDate;
        let endDateTemp = endDate;
        if (startDate == '') {
            startDateTemp = new Date(1900, 1, 1);
        }
        else {
            startDateTemp = new Date(startDate);
        }
        if (endDate == '') {
            endDateTemp = todayDate;
        }
        else {
            endDateTemp = new Date(endDate);
        }
        if (startDateTemp > todayDate) {
            setError('startDate', { type: 'custom', message: 'Start date must be before today!' });
        }
        else if (endDateTemp > todayDate) {
            setError('endDate', { type: 'custom', message: 'End date must be before today!' });
        }
        else if (startDateTemp >= endDateTemp) {
            setError('startDate', { type: 'custom', message: 'Start Date must be before End Date!' });
        }

        else {
            clearErrors('startDate');
            clearErrors('endDate');
            return [startDateTemp, endDateTemp]
        }
        return [false, false]
    }
    // useEffect(() => {
    //     let answerDict = {};
    //     const allKeys = [];
    //     let filteredData = [];
    //     if (formSubmissionData) {
    //         console.log("start date", startDate);
    //         console.log("end date", endDate);
    //         // let startDateTemp = startDate;
    //         // let endDateTemp = endDate;
    //         let [startDateTemp, endDateTemp] = validateDate(startDate, endDate)
    //         if (startDateTemp && endDateTemp) {
    //             formSubmissionData.forEach((data) => {
    //                 // const excludedKeys = ['formID', 'formSubmissionID', 'createdAt']
    //                 const excludedKeys = []
    //                 const createdAt = data.createdAt ? data.createdAt.toDate() : new Date(1900, 1, 1);
    //                 if (createdAt > startDateTemp && createdAt <= endDateTemp) {
    //                     filteredData.push(data)
    //                     for (const [key, value] of Object.entries(data)) {
    //                         if (allKeys.length <= 0 && !excludedKeys.includes(key)) {
    //                             allKeys.push(key);
    //                         }
    //                         if (typeof value === 'string' || value instanceof String) {
    //                             if (key in answerDict) {
    //                                 answerDict[key].push(value);
    //                             }
    //                             else {
    //                                 answerDict[key] = [value];
    //                             }
    //                         }
    //                         else {
    //                             if (typeof value === 'object') {
    //                                 // console.log(value)
    //                                 if (value != null) {
    //                                     for (const [key2, value2] of Object.entries(value)) {
    //                                         // console.log(key2)
    //                                         if (key2.includes('checkOther') === false && value2 !== '' && value2 !== false) {
    //                                             if (key in answerDict) {
    //                                                 answerDict[key].push(value2);
    //                                             }
    //                                             else {
    //                                                 answerDict[key] = [value2];
    //                                             }
    //                                         }

    //                                     }
    //                                 }

    //                             }
    //                         }

    //                         // answerDict[key] = [...answerDict[key], value]
    //                     }
    //                     allKeys.sort();
    //                 }


    //             })
    //             setFormSubmissionDataFiltered([...filteredData])
    //             setLatestAnswerDict({ ...answerDict });
    //         }


    //     }

    // }, [formSubmissionData, startDate, endDate])
    useEffect(() => {
        let answerDict = {};
        const allKeys = [];
        let filteredData = [];
        if (primaryFormSubmissionData) {
            console.log("start date", startDate);
            console.log("end date", endDate);
            // let startDateTemp = startDate;
            // let endDateTemp = endDate;
            let [startDateTemp, endDateTemp] = validateDate(startDate, endDate)
            if (startDateTemp && endDateTemp) {
                primaryFormSubmissionData.forEach((data) => {
                    // const excludedKeys = ['formID', 'formSubmissionID', 'createdAt']
                    const excludedKeys = []
                    // console.log('typeof createdAt', typeof data.createdAt)
                    // console.log('createdAt', data.createdAt)
                    let createdAt = new Date(1900, 1, 1);
                    if (data.createdAt) {
                        if (typeof data.createdAt.toDate == 'function') {
                            createdAt = data.createdAt.toDate()
                        }
                        else if (data.createdAt instanceof Date) {
                            createdAt = data.createdAt;
                        }
                    }
                    if (createdAt > startDateTemp && createdAt <= endDateTemp) {
                        filteredData.push(data)
                        // for (const [key, value] of Object.entries(data)) {
                        //     if (allKeys.length <= 0 && !excludedKeys.includes(key)) {
                        //         allKeys.push(key);
                        //     }
                        //     if (typeof value === 'string' || value instanceof String) {
                        //         if (key in answerDict) {
                        //             answerDict[key].push(value);
                        //         }
                        //         else {
                        //             answerDict[key] = [value];
                        //         }
                        //     }
                        //     else {
                        //         if (typeof value === 'object') {
                        //             // console.log(value)
                        //             if (value != null) {
                        //                 for (const [key2, value2] of Object.entries(value)) {
                        //                     // console.log(key2)
                        //                     if (key2.includes('checkOther') === false && value2 !== '' && value2 !== false) {
                        //                         if (key in answerDict) {
                        //                             answerDict[key].push(value2);
                        //                         }
                        //                         else {
                        //                             answerDict[key] = [value2];
                        //                         }
                        //                     }

                        //                 }
                        //             }

                        //         }
                        //     }
                        // }
                        // allKeys.sort();
                    }


                })
                // console.log('filteredData', filteredData)
                setPrimaryFormSubmissionDataFiltered([...filteredData])
                // setLatestAnswerDict({ ...answerDict });
            }


        }

    }, [primaryFormSubmissionData, startDate, endDate])
    useEffect(() => {
        if (linked) {
            console.log('has linked form!', currentLinkedSubmissions)
        }
        else {
            console.log('no linked form!')
            let answerDict = {};
            // const allKeys = [];
            setFormSubmissionData([...primaryFormSubmissionDataFiltered])
            // primaryFormSubmissionDataFiltered.forEach((data) => {
            //     for (const [key, value] of Object.entries(data)) {
            //         // if (allKeys.length <= 0 && !excludedKeys.includes(key)) {
            //         //     allKeys.push(key);
            //         // }

            //         if (typeof value === 'string' || value instanceof String) {
            //             if (key in answerDict) {
            //                 answerDict[key].push(value);
            //             }
            //             else {
            //                 answerDict[key] = [value];
            //             }
            //         }
            //         else {
            //             if (typeof value === 'object') {
            //                 // console.log(value)
            //                 if (value != null) {
            //                     for (const [key2, value2] of Object.entries(value)) {
            //                         // console.log(key2)
            //                         if (key2.includes('checkOther') === false && value2 !== '' && value2 !== false) {
            //                             if (key in answerDict) {
            //                                 answerDict[key].push(value2);
            //                             }
            //                             else {
            //                                 answerDict[key] = [value2];
            //                             }
            //                         }

            //                     }
            //                 }

            //             }
            //         }
            //     }
            //     setLatestAnswerDict({ ...answerDict });
            // })


            // allKeys.sort();
        }

    }, [primaryFormSubmissionDataFiltered, linked])
    useEffect(() => {
        if (formSubmissionData && formSubmissionData.length > 0) {
            console.log('formSubmissionData', formSubmissionData)
            let answerDict = {};
            // const allKeys = [];
            formSubmissionData.forEach((data) => {
                for (const [key, value] of Object.entries(data)) {
                    // if (allKeys.length <= 0 && !excludedKeys.includes(key)) {
                    //     allKeys.push(key);
                    // }

                    if (typeof value === 'string' || value instanceof String) {
                        if (key in answerDict) {
                            answerDict[key].push(value);
                        }
                        else {
                            answerDict[key] = [value];
                        }
                    }
                    else {
                        if (typeof value === 'object') {
                            // console.log(value)
                            if (value != null) {
                                for (const [key2, value2] of Object.entries(value)) {
                                    // console.log(key2)
                                    if (key2.includes('checkOther') === false && value2 !== '' && value2 !== false) {
                                        if (key in answerDict) {
                                            answerDict[key].push(value2);
                                        }
                                        else {
                                            answerDict[key] = [value2];
                                        }
                                    }

                                }
                            }

                        }
                    }
                }
                setLatestAnswerDict({ ...answerDict });
            })
        }
    }, [formSubmissionData])
    useEffect(() => {
        if (allVariables.length > 0) {
            // console.log(allVariables)
            if (currentEventKey == null) {
                setCurrentEventKey(allVariables[0]);
            }
        }
    }, [allVariables])
    useEffect(() => {
        if (currentEventKey && latestAnswerDict) {
            console.log(currentEventKey)
            const mainKey = currentEventKey

            let aggregatedData = {}
            const uniqueValues = new Set(latestAnswerDict[mainKey])
            uniqueValues.forEach(val => {
                aggregatedData[val] = 0

            })
            latestAnswerDict[mainKey].forEach(val => {
                aggregatedData[val] += 1
            })

            setChartData({
                title: mainKey,
                data: aggregatedData,
                // labels: Object.keys(aggregatedData),
                // values: Object.values(aggregatedData)
            })

        }
    }, [currentEventKey, latestAnswerDict])
    const supportedCharts = ['Bar Chart', 'Pie Chart', 'Line Chart', 'Radar Chart']

    const [currentSelectedChart, setCurrentSelectedChart] = useState(supportedCharts[0])

    const RenderSelectedChart = ({ chartType, chartData }) => {
        if (chartType == 'Pie Chart') {
            return <PieChart chartData={chartData} />
        }
        else if (chartType == 'Bar Chart') {
            return <BarCharts chartData={chartData} />
        }
        else if (chartType == 'Line Chart') {
            return <LineChart chartData={chartData} />
        }
        return <></>
    }
    const [formLibrary, setFormLibrary] = useState([]);
    useEffect(() => {
        console.log('formLibrary', formLibrary)
    }, [formLibrary])
    // useEffect(() => {

    //     if (currentLinkedVariables && Object.keys(currentLinkedVariables).length > 0) {
    //         console.log('currentLinkedVariables (Parent)', currentLinkedVariables)

    //         for (const [form_id, link_vars] of Object.entries(currentLinkedVariables)) {
    //             let linkedInfo = {}
    //             if (!allStoredSubmissions[form_id]) { //info not exists yet
    //                 const q2 = query(formSubmissionRef, where("formID", "==", form_id));
    //                 const unsubscribe2 = onSnapshot(q2, querySnapshot => {
    //                     console.log(querySnapshot.size)
    //                     let answerDict = {};
    //                     let rawData = [];
    //                     const allKeys = [];

    //                     querySnapshot.forEach((docSnapShot) => {
    //                         const data = docSnapShot.data();
    //                         rawData.push(data);
    //                     })
    //                     // setLatestAnswerDict({ ...answerDict });
    //                     // setCurrentLinkSubmissions({...currentLinkedSubmissions,
    //                     //     [form_id]: rawData
    //                     // });
    //                     setAllStoreSubmissions({
    //                         ...allStoredSubmissions,
    //                         [form_id]: rawData
    //                     });


    //                 })
    //             }
    //             else {
    //                 console.log("Link Info Exists: ", allStoredSubmissions[form_id]);
    //             }
    //         }

    //     }
    //     else {
    //         setCurrentLinkSubmissions({})
    //     }
    // }, [currentLinkedVariables])
    // useEffect(() => {
    //     console.log('allStoredSubmissions', allStoredSubmissions)
    //     const linkedInfo = {}
    //     for (const [linkedFormID, submissions] of Object.entries(allStoredSubmissions)) {
    //         if (Object.keys(currentLinkedVariables).includes(linkedFormID)) {
    //             // console.log(linkedFormID, 'is linked! Submissions: ', submissions)
    //             linkedInfo[linkedFormID] = submissions;
    //             setCurrentLinkSubmissions(linkedInfo);
    //         }
    //     }
    // }, [allStoredSubmissions, currentLinkedVariables])
    // useEffect(() => {
    //     console.log('currentLinkedSubmissions', currentLinkedSubmissions)
    //     if (formSubmissionData) {
    //         if (currentLinkedSubmissions && Object.keys(currentLinkedSubmissions).length > 0) {
    //             console.log('formSubmissionData', formSubmissionData)

    //             console.log('currentLinkedSubmissions', currentLinkedSubmissions)

    //         }
    //         else {
    //             console.log('currentLinkedSubmissions: no linked form!')


    //         }
    //     }

    // }, [currentLinkedSubmissions, formSubmissionData])

    return <div>
        <div className='row' style={{
            display: 'flex',
            justifyContent: 'center',
            // width: 'fit-content',
            alignContent: 'center',
            textAlign: 'center'
        }}>
            <div className='col-md-12' style={{ width: 'fit-content' }}>
                <h3 style={{ borderRadius: '20px', padding: 10, fontStyle: "italic", backgroundColor: theme.highlightColor, color: 'white' }}>
                    Disclaimer: The data here is randomly generated for visualization purposes. <br></br>Real responses will be connected in the future once design is finalized.
                </h3>
            </div>

        </div>
        <FormLibrary setFormLibraryExternal={setFormLibrary} accountType={'hub-lead-admin'} publishedOnly={false} />
        {formData &&
            <div className='row'>
                <div className='col-md-10'>
                    <h2>{formData.formLongName ? formData.formLongName : formData.formTitle}
                    </h2>
                </div>
                <div className='col-md-2'>
                    {formLibrary.length > 0 && <Dropdown>
                        <Dropdown.Toggle>
                            View Another Form
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomMenu} >
                            {formLibrary.map(formData => {
                                const formID = formData.formID
                                const formTitle = formData.formLongName ? formData.formLongName : formData.formTitle
                                return <Dropdown.Item href={`/charts/${formID}`}>
                                    <Link to={`/charts/${formID}`} target="_blank" rel="noopener noreferrer"
                                        style={{ textDecoration: "none", color: 'black' }}
                                    >
                                        {formTitle}
                                    </Link>
                                </Dropdown.Item>

                            })}
                        </Dropdown.Menu>
                    </Dropdown>}
                </div>

            </div>
        }
        {primaryFormSubmissionData &&
            <h3>Total Original Records: <b>{primaryFormSubmissionData.length}</b></h3>
        }
        {(linked || startDate != '' || endDate != '') && formSubmissionData && <h5>Number of Filtered/Linked Records: <b>{formSubmissionData.length}</b></h5>}
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <LinkedFormsComponent formLibrary={formLibrary}
                    formId={formId}
                    setMergedSubmissionDataExternal={setFormSubmissionData}
                    primaryFormSubmissionData={primaryFormSubmissionData}
                    setLinked={setLinked} />

                <div className='row'>
                    <div className='col-sm-4 col-md-3 col-lg-2 form-check'>
                        <Input
                            name={'startDate'}
                            type="date"
                            label={'Start Date'}
                            required={true}
                            // className="mb-3 col-md-4"
                            // defaultValue={format(todayDate)}
                            instructions={'Start date of responses.'}
                        />
                    </div>
                    <div className=' col-sm-4 col-md-3 col-lg-2 form-check mx-3'>
                        <Input
                            name={'endDate'}
                            type="date"
                            label={'End Date'}
                            // className="mb-3 col-md-4"
                            required={true}
                            // defaultValue={format(new Date(), "yyyy-mm-dd")}
                            instructions={'End date of responses.'}
                        />
                    </div>
                </div>
                {formSubmissionData && <BivariableCharts
                    latestAnswerDict={latestAnswerDict}
                    // allVariables={allVariables}
                    rawData={formSubmissionData}
                />}

            </form>
        </FormProvider>

    </div>
}
