import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { processID } from "../Automatic Forms/Utils";
import theme from "../Theme/theme";
import { AiFillQuestionCircle } from "react-icons/ai";
export function Matrix({ q, latestRetrievedForm }) {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    let rowText = []
    let columnsText = []
    let answerDictTemp = {};
    q.options.forEach(option => {
        rowText.push(option)
        answerDictTemp[option] = null
    })
    q.columns_options.forEach(option => {
        columnsText.push(option)
    })
    const header = ''
    let { questionID, field_annotations } = q
    if (!field_annotations) {
        field_annotations = {}
    }
    // console.log('latestRetrievedForm', latestRetrievedForm)
    let initialAnswers = new Array(q.options.length).fill(new Array(q.columns_options.length).fill(false))
    if (latestRetrievedForm) {
        if (latestRetrievedForm[questionID]) {
            for (const [rowValue, columnValue] of Object.entries(latestRetrievedForm[questionID])) {
                console.log(rowValue, columnValue);
                let processedRowText = []
                rowText.forEach(txt => processedRowText.push(processID(txt)))

                let rowIndex = processedRowText.indexOf(rowValue);
                let columnIndex = columnsText.indexOf(columnValue);
                console.log(rowIndex, columnIndex);
                if (rowIndex != -1 && columnIndex != -1) {
                    initialAnswers[rowIndex] = initialAnswers[rowIndex].map((value, index) => index === columnIndex);

                }
            }
        }
    }
    console.log('initialAnswers', initialAnswers)
    const answers = initialAnswers;
    return <>
        <p className={`${errors[questionID] ? 'error-message' : ''}`} style={{ marginBottom: 2 }}>{q.question_text}</p>
        <i className={`${errors[questionID] ? 'error-message' : ''}`} >{q.question_description ? q.question_description : ''}</i>

        <table className="matrix">
            <thead>
                <tr>
                    <th >{header}</th>
                    {columnsText.map((text, i) => {
                        const optionID = `${questionID}-${processID(text)}`

                        const annotation = field_annotations[optionID] ? field_annotations[optionID] : null
                        return <th key={i}>{text}
                            {annotation && <AiFillQuestionCircle
                                style={{ marginLeft: '4px', marginBottom: '3px', color: theme.highlightColor, fontSize: '15px' }}
                                data-tip={annotation} />}
                        </th>
                    }

                    )}
                </tr>
            </thead>
            <tbody>
                {answers.map((row, rowIndex) => {
                    const optionID = `${questionID}-${processID(rowText[rowIndex])}`

                    const annotation = field_annotations[optionID] ? field_annotations[optionID] : null
                    return <tr key={rowIndex}>
                        <td className="matrix-row-label">
                            {rowText[rowIndex]}
                            {annotation && <AiFillQuestionCircle
                                style={{ marginLeft: '4px', marginBottom: '3px', color: theme.highlightColor, fontSize: '15px' }}
                                data-tip={annotation} />}
                        </td>
                        {row.map((cell, columnIndex) => {
                            console.log('${questionID}.${processID(rowText[rowIndex])}', `${questionID}.${processID(rowText[rowIndex])}`, 'columnsText[columnIndex]', columnsText[columnIndex])
                            const optionID = `${questionID}-${processID(rowText[rowIndex])}`
                            const annotation = field_annotations[optionID] ? field_annotations[optionID] : null
                            console.log('field_annotations', field_annotations, optionID);
                            return <td key={`${rowIndex}-${columnIndex}`}>
                                <input
                                    type="radio"
                                    name={`row-${rowIndex}`}
                                    id={`${questionID}-${rowText[rowIndex]}-${columnsText[columnIndex]}`}
                                    value={columnsText[columnIndex]}
                                    defaultChecked={cell}
                                    // checked={answers[rowIndex][columnIndex]}
                                    // onChange={() => updateAnswer(rowIndex, columnIndex)}
                                    {...register(`${questionID}.${processID(rowText[rowIndex])}`)}
                                    disabled={q.readonly}

                                />
                                {/* {annotation && <AiFillQuestionCircle
                                style={{ marginLeft: '4px', marginBottom: '3px', color: theme.highlightColor, fontSize: '15px' }}
                                data-tip={annotation} />} */}
                            </td>
                        }
                        )}
                    </tr>
                }

                )}
            </tbody>
        </table>
        <div className="error-message">{errors[questionID]?.message}</div>
    </>

}
export function MatrixPreview({ q }) {
    const {
        watch,
        formState: { errors },
    } = useFormContext();
    let rowText = []
    let columnsText = []
    // console.log('matrixPreview q', q)
    q.options.forEach(option => {
        rowText.push(option)
    })
    q.columns_options.forEach(option => {
        columnsText.push(option)
    })
    let { questionID, field_annotations } = q
    if (!field_annotations) {
        field_annotations = {}
    }
    const header = ''
    let initialAnswers = new Array(q.options.length).fill(new Array(q.columns_options.length).fill(false))

    // console.log('initialAnswers', initialAnswers)
    const answers = initialAnswers;
    return <>
        <p style={{ marginBottom: 2 }}>{watch(`${questionID}-question`)}</p>
        <i>{watch(`${questionID}-description`)}</i>

        <table className="matrix">
            <thead>
                <tr>
                    <th >{header}</th>
                    {columnsText.map((text, i) => {
                        const optionID = `${questionID}-${processID(text)}`
                        const annotation = field_annotations[optionID] ? field_annotations[optionID] : null
                        return <th key={i}>{text}
                            {annotation && <AiFillQuestionCircle
                                style={{ marginLeft: '4px', marginBottom: '3px', color: theme.highlightColor, fontSize: '15px' }}
                                data-tip={annotation} />}
                        </th>
                    }

                    )}
                </tr>
            </thead>
            <tbody>
                {answers.map((row, rowIndex) => {
                    const optionID = `${questionID}-${processID(rowText[rowIndex])}`
                    const annotation = field_annotations[optionID] ? field_annotations[optionID] : null
                    return <tr key={rowIndex}>
                        <td className="matrix-row-label">
                            {rowText[rowIndex]}
                            {annotation && <AiFillQuestionCircle
                                style={{ marginLeft: '4px', marginBottom: '3px', color: theme.highlightColor, fontSize: '15px' }}
                                data-tip={annotation} />}
                        </td>
                        {row.map((cell, columnIndex) => (
                            <td key={`${rowIndex}-${columnIndex}`}>
                                <input
                                    type="radio"
                                    name={`row-${rowIndex}`}
                                    id={`${questionID}-${rowText[rowIndex]}-${columnsText[columnIndex]}`}
                                    value={columnsText[columnIndex]}

                                // checked={answers[rowIndex][columnIndex]}
                                // onChange={() => updateAnswer(rowIndex, columnIndex)}

                                />
                            </td>
                        ))}
                    </tr>
                }

                )}
            </tbody>
        </table>
    </>

}
