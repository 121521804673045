
import { NavBar } from "../Components/NavBar";
import DashboardAdmin from "./DashboardAdmin";
import DashboardStudent from "./DashboardStudent";
import { useEffect, useState } from "react";
import Home from "./Home";
import { isCampusLead, isHubLead, isPortalAdmin } from "../Fixed Sources/accountTypes";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import LoginForm from "../Forms/LoginForm";
const RenderComponent = ({ user, userData }) => {
    if (user) {
        if (userData) {
            // console.log('userData', userData)
            console.log('Role: ', userData.atype)
            const role = userData.atype;

            if (isPortalAdmin(role)) {
                return <DashboardAdmin role={'portal-admin'} />
            }
            else if (isHubLead(role)) {
                return <DashboardAdmin role={'hub-lead'} />
            }
            else if (isCampusLead(role)) {
                return <DashboardAdmin role={role} />
            }
            else if (role == 'faculty') {
                return <DashboardStudent role={role} />
            }
            else if (role == 'student' || role == 'student-mentor') {
                return <DashboardStudent role={'student'} />
            }
        }
    }

    return <LoginForm />

}
const DefaultHomePage = ({ user }) => {
    const analytics = getAnalytics();
    const [userData, setUserData] = useState(null);
    useEffect(() => {
        if (userData) {
            const { atype, userID, institution } = userData
            console.log('userData', userData)
            setUserId(analytics, userID);
            logEvent(analytics, 'main_dashboard', { timeStamp: new Date(), atype, institution });
        }
    }, [userData])
    return (
        <div>
            <NavBar setUserDataExternal={setUserData} />
            <RenderComponent userData={userData} user={user} />
        </div>


    );
};
export default DefaultHomePage;