import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Input } from "../Components";
import { AutomaticQuestion } from "../Automatic Forms/AutomaticComponents";
import ReactTooltip from 'react-tooltip';
import { AiFillQuestionCircle } from "react-icons/ai";
import theme from '../Theme/theme'
import { Collapse } from "react-bootstrap";
import { processID } from "../Automatic Forms/Utils";
export const CheckboxFollowup = ({ className,
  questions,
  style,
  currentFormDisplayQuestion,
  latestRetrievedForm,
  instructions }) => {
  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext();

  function Test() {
    questions.map((q) => {
      if (q.question_type == 'conditional') {
        return (<Input
          name={q.questionID}
          label={q.question_text}
          type="checkbox"
          className="form-check col"
          labelClass="form-check-label"
          inputClass="form-check-input"
        />)
      }
    })
    return <div></div>;
  }
  const highlightColor = theme.highlightColor;
  const parentQuestionID = questions[0].questionID
  // let parentChecked = watch(parentQuestionID);
  //   console.log(parentChecked);
  let latestValue = '';

  let [parentChecked, setParentChecked] = useState(watch(parentQuestionID));
  const [firstInteraction, setFirstInteraction] = useState(false);

  useEffect(() => {
    if (latestRetrievedForm && !firstInteraction) {
      setParentChecked(latestRetrievedForm[questions[0].questionID] === true)
    }
    const subscription = watch((value, { name, type }) => {
      // console.log(value, name, type);
      setFirstInteraction(true);
      if (name == questions[0].questionID)
        setParentChecked(watch(name))

    })
    return () => subscription.unsubscribe();
  }, [watch]);
  useEffect(() => {
    console.log(parentChecked)
  }, [parentChecked]);

  return (
    <div>
      {/* <Test></Test> */}
      <ReactTooltip backgroundColor={highlightColor} />

      {questions.map(q => {
        if (q.question_type === 'conditional') {
          // console.log(style)
          return (<Input
            labelStyle={style}
            name={q.questionID}
            label={q.question_text}
            type="checkbox"
            className="form-check col"
            labelClass="form-check-label"
            inputClass="form-check-input"
            defaultChecked={latestRetrievedForm ? latestRetrievedForm[q.questionID] === true : false}
            instructions={instructions}
          />)
        }
      })}
      
      <Collapse in= {parentChecked}>
      <div className="row conditional-container">
        {/* <div>{parentChecked? "true": "false"}</div>
            <div>{firstInteraction? "true": "false"}</div> */}
        { questions.map(followup_q => {
          // console.log(followup_q.parent_questionID, parentQuestionID)

          if (followup_q.parent_questionID === parentQuestionID) {
            const independentQ = JSON.parse(JSON.stringify(followup_q));
            independentQ.parent_questionID = "";
            return <AutomaticQuestion
              q={independentQ}
              currentFormDisplayQuestion={currentFormDisplayQuestion}
              latestRetrievedForm={latestRetrievedForm}
            />
            // return <p>checked</p>
          }
          // else {
          //     return <p>{followup_q.questionID}</p>
          // }
        })}
      
      </div>
      </Collapse>
        

    </div>
  );
};
export const ConditionalPreview = ({ className,
  questions,
  style,
  currentFormDisplayQuestion,
  latestRetrievedForm,
  instructions }) => {
  const {
    register,
    watch,
    getValues,
    formState: { errors }
  } = useFormContext();
  console.log('q_family', questions)

  const highlightColor = theme.highlightColor;
  const parentQuestionID = questions[0].questionID
  // let parentChecked = watch(parentQuestionID);
  //   console.log(parentChecked);
  let latestValue = '';

  let [parentChecked, setParentChecked] = useState(watch(parentQuestionID));
  // const [firstInteraction, setFirstInteraction] = useState(false);

  useEffect(() => {
    // if (latestRetrievedForm && !firstInteraction) {
    //   setParentChecked(latestRetrievedForm[questions[0].questionID] === true)
    // }
    const subscription = watch((value, { name, type }) => {
      // console.log(value, name, type);
      // setFirstInteraction(true);
      if (name == questions[0].questionID)
        setParentChecked(watch(name))

    })
    return () => subscription.unsubscribe();
  }, [watch]);
  useEffect(() => {
    console.log(parentChecked)
  }, [parentChecked]);

  return (
    <div>
      {/* <Test></Test> */}
      <ReactTooltip backgroundColor={highlightColor} />

      {questions.map(q => {
        if (q.question_type === 'conditional') {
          // console.log(style)
          return (<Input
            labelStyle={style}
            name={q.questionID}
            label={q.question_text}
            type="checkbox"
            className="form-check col"
            labelClass="form-check-label"
            inputClass="form-check-input"
            // defaultChecked={latestRetrievedForm ? latestRetrievedForm[q.questionID] === true : false}
            instructions={instructions}
          />)
        }
      })}
      <Collapse in= {parentChecked}>
      <div className="row conditional-container-2">
        {/* <div>{parentChecked? "true": "false"}</div>
            <div>{firstInteraction? "true": "false"}</div> */}
        { questions.map(followup_q => {
          // console.log(followup_q.parent_questionID, parentQuestionID)
          
          if (followup_q.parent_questionID === parentQuestionID) {
            const independentQ = JSON.parse(JSON.stringify(followup_q));
            independentQ.parent_questionID = "";
            independentQ.questionID = independentQ.questionID + '-preview';
            independentQ.question_text = watch(`${followup_q.questionID}`)
            let previewOptions = [];
            independentQ.options.forEach(option=>{
                const watch_option = watch(`${followup_q.questionID}-${processID(option)}`)
                if (watch_option) {
                  previewOptions.push(watch_option);

                }
                else {
                  if (option.includes('New Option')) {
                    previewOptions.push('New Option');

                  }
                  else {
                    previewOptions.push(option);

                  }
                }

            })
            independentQ.options = previewOptions
            console.log('independentQ.options',  independentQ.options)
            
            if (!independentQ.question_text) {
              independentQ.question_text = watch(`${followup_q.questionID}-question`)
    
                if (!independentQ.question_text) {
                  independentQ.question_text = followup_q.question_text
                }
    
            }
            return <AutomaticQuestion
              q={independentQ}
              // currentFormDisplayQuestion={currentFormDisplayQuestion}
              // latestRetrievedForm={latestRetrievedForm}
            />
            // return <p>checked</p>
          }
          // else {
          //     return <p>{followup_q.questionID}</p>
          // }
        })}
      
      </div>
      </Collapse>

    </div>
  );
};
